import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterSelectApi from '../FilterSelectApi';
import FilterContainer from '../FilterContainer';
import FilterRadios from '../FilterRadios';
import moment from 'moment';
import FilterSearch from '../FilterSearch';

// const phatHanhs = [
//   {
//     label: 'Tất cả',
//     value: 'all',
//   },
//   {
//     label: 'Đang phát hành',
//     value: 'active',
//   },
//   {
//     label: 'Ngưng phát hành',
//     value: 'inactive',
//   },
// ];
const hieuLucs = [
  {
    label: 'Chưa đến ngày',
    value: 'pending',
  },
  {
    label: 'Đang áp dụng',
    value: 'active',
  },
  {
    label: 'Hết hiệu lực',
    value: 'inactive',
  },
];

function FilterFlashsale({ setCondition }) {
  const originFilter = {
    vat_tu: null,
    ma_chietkhau: '',
    ten_chietkhau: '',
    // phat_hanh: 'active',
    hieu_luc: 'active',
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.ma_chietkhau) {
      condition.ma_chietkhau = {
        $regex: filter.ma_chietkhau.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.ten_chietkhau) {
      condition.ten_chietkhau = {
        $regex: filter.ten_chietkhau.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.vat_tu) {
      condition.details = {
        $elemMatch: {
          ma_vt: filter.vat_tu.ma_vt,
        },
      };
    }
    const today = moment();
    switch (filter.hieu_luc) {
      case 'pending':
        condition.tu_ngay = { $gt: today };
        break;
      case 'inactive':
        condition.den_ngay = { $lt: today };
        break;
      default: // acitve
        condition.tu_ngay = { $lte: today };
        condition.den_ngay = { $gte: today };
        break;
    }
    // switch (filter.phat_hanh) {
    //   case 'active':
    //     condition.status = true;
    //     break;
    //   case 'inactive':
    //     condition.status = false;
    //     break;
    //   default:
    //     break;
    // }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Mã Flashsale"
          placeholder="Tìm theo mã"
          value={filter.ma_chietkhau}
          onSearch={(value) => setFilter({ ...filter, ma_chietkhau: value })}
        />
        <FilterSearch
          title="Tên Flashsale"
          placeholder="Tìm theo tên"
          value={filter.ten_chietkhau}
          onSearch={(value) => setFilter({ ...filter, ten_chietkhau: value })}
        />
        <FilterSelectApi
          title="Hàng Hóa"
          apiCode="dmvt"
          placeholder="Chọn hàng hóa"
          selectedValue={filter.vat_tu}
          value={filter.vat_tu || { ma_vt: '', ten_vt: '' }}
          searchFileds={['ma_vt', 'ten_vt']}
          getOptionLabel={(option) => option.ten_vt}
          onSelect={(value) => setFilter({ ...filter, vat_tu: value })}
        />
        <FilterRadios
          values={hieuLucs}
          defaultValue={filter.hieu_luc}
          title="Thời gian áp dụng"
          onChange={(val) => setFilter({ ...filter, hieu_luc: val })}
        />
        {/* <FilterRadios
          values={phatHanhs}
          defaultValue={filter.phat_hanh}
          title="Phát hành"
          onChange={(val) => setFilter({ ...filter, phat_hanh: val })}
        /> */}
      </Stack>
    </FilterContainer>
  );
}

export default FilterFlashsale;
