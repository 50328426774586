import React, { useEffect, useState } from 'react';
import ModalBase from '../ModalBase';
import { Box } from '@mui/material';
import TableDisplay from '~/components/table/TableDisplay';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import moment from 'moment';
import { LABEL_KHO } from '~/utils/label.constant';

const columns = [
  {
    name: 'Mã CT',
    selector: (row) => row.ma_ct,
    width: '80px',
    wrap: true,
  },
  {
    name: 'Số CT',
    selector: (row) => row.so_ct,
    width: '80px',
    wrap: true,
  },
  {
    name: 'Ngày CT',
    selector: (row) => row.ngay_ct,
    format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
    width: '120px',
    wrap: true,
  },
  {
    name: 'Mã hàng',
    selector: (row) => row.ma_vt,
    width: '120px',
    wrap: true,
  },
  {
    name: 'Tên hàng',
    selector: (row) => row.ten_vt,
    width: '120px',
    wrap: true,
  },
  {
    name: LABEL_KHO,
    selector: (row) => row.ten_kho,
    width: '120px',
    wrap: true,
  },
  {
    name: 'Diễn giải',
    selector: (row) => row.dien_giai,
    minWidth: '200px',
    wrap: true,
  },
  {
    name: 'SL nhập',
    selector: (row) => row.sl_nhap,
    width: '120px',
    wrap: true,
  },
  {
    name: 'Tiền nhập',
    selector: (row) => row.tien_nhap,
    format: (row) => numeralCustom(row.tien_nhap).format(),
    width: '120px',
    wrap: true,
  },
  {
    name: 'SL xuất',
    selector: (row) => row.sl_xuat,
    width: '120px',
    wrap: true,
  },
  {
    name: 'Tiền xuất',
    selector: (row) => row.tien_xuat,
    format: (row) => numeralCustom(row.tien_xuat).format(),
    width: '120px',
    wrap: true,
  },
];

function ReportSCTVTModal({ open, onClose, data, masterQueryObject }) {
  const { asyncGetReport } = useApisContext();
  const [reportData, setReportData] = useState([]);

  const getReport = async () => {
    try {
      if (!data?.ma_vt) return;
      const query = {
        t: 1,
        cType: '$and',
        tu_ngay: masterQueryObject.tu_ngay,
        den_ngay: moment(masterQueryObject.den_ngay)
          .hours(23)
          .minutes(59)
          .seconds(59)
          .milliseconds(999)
          .toISOString(),
        ma_vt: data.ma_vt || '',
        ma_kho: masterQueryObject?.ma_kho || '',
      };
      const resp = await asyncGetReport({
        apiCode: 'sctvt',
        queryObject: query,
      });
      setReportData(resp);
    } catch (error) {}
  };

  useEffect(() => {
    getReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, masterQueryObject]);

  return (
    <ModalBase
      title={`Chi tiết nhập xuất tồn - ${data?.ten_vt}`}
      width="1100px"
      open={open}
      handleClose={onClose}
    >
      <Box>
        <TableDisplay columns={columns} data={reportData} />
      </Box>
    </ModalBase>
  );
}

export default ReportSCTVTModal;
