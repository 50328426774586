import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';

function ChietKhauSection({ show, setCollapses, control }) {
  return (
    <CollapseSection
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, chiet_khau: !prev.chiet_khau }))
      }
      title="Chiết khấu theo hóa đơn"
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Controller
            name="ty_le_ck_hd"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tỷ lệ CK hóa đơn"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  // luu gia tri
                  const value = numeralCustom(e.target.value).value();
                  onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tien_ck_hd"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tiền CK hóa đơn"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  // luu gia tri
                  const value = numeralCustom(e.target.value).value();
                  onChange(value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default ChietKhauSection;
