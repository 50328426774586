import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import React from 'react';

function WrapperInput({
  children,
  id,
  labelWidth,
  label,
  required,
  errorMessage,
  wrapInputRef,
  labelSx = {},
  count,
  alignItems = 'center',
  ...props
}) {
  return (
    <Box>
      <Stack
        direction="row"
        spacing="10px"
        sx={{ width: '100%' }}
        alignItems={alignItems}
        justifyContent="center"
        {...props}
      >
        {label && (
          <Typography
            component="label"
            htmlFor={id}
            sx={{
              width: labelWidth,
              textWrap: 'nowrap',
              fontWeight: 600,
              textAlign: 'left',
              ...labelSx,
            }}
          >
            {label}
            {required && (
              <Box component="sup" sx={{ color: 'error.main' }}>
                *
              </Box>
            )}
            {count && (
              <Stack
                component="span"
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  display: 'inline-flex',
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: 'whitish.gray',
                  fontSize: '10px',
                  marginLeft: '5px',
                }}
              >
                {count}
              </Stack>
            )}
          </Typography>
        )}
        <Stack sx={{ flex: 1 }} ref={wrapInputRef}>
          {children}
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Box sx={{ width: labelWidth }}></Box>
        {errorMessage && (
          <FormHelperText
            error
            sx={{
              fontSize: '11px',
              fontStyle: 'italic',
              color: 'error.main',
              lineHeight: '14px',
              paddingLeft: '5px',
            }}
          >
            {errorMessage}
          </FormHelperText>
        )}
      </Stack>
    </Box>
  );
}

export default WrapperInput;
