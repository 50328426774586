import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalForm from '~/components/modal/ModalForm';
import useApisContext from '~/hooks/hookContext/useApisContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import { Stack } from '@mui/material';
import InfoSection from './info-section/InfoSection';
import ChiTietSection from './chitiet-section/ChiTietSection';

const schema = yup.object({
  ma: yup.string().required('Vui lòng nhập mã'),
  ten: yup.string().required('Vui lòng nhập tên'),
  so_lan_sd: yup
    .number()
    .typeError('Vui lòng nhập số lần sử dụng')
    .required('Vui lòng nhập số lần sử dụng'),
  hieu_luc_tu: yup
    .date()
    .typeError('Vui lòng chọn ngày bắt đầu')
    .required('Vui lòng chọn ngày bắt đầu'),
  hieu_luc_den: yup
    .date()
    .typeError('Vui lòng chọn ngày kết thúc')
    .required('Vui lòng chọn ngày kết thúc'),
});

const defaultData = {
  ma: '',
  ten: '',
  so_lan_sd: 1,
  hieu_luc_tu: moment().format('YYYY-MM-DD'),
  hieu_luc_den: moment().add(7, 'days').format('YYYY-MM-DD'),
  gt_don_hang_toi_thieu: 0,
  so_lan_da_su_dung: 0,
  sl_toi_thieu: 1,
  so_tien: 0,
  ty_le: 0,
  so_tien_max: 0,
  status: false,
};

function FormEvoucher({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        hieu_luc_tu: defaultValues.hieu_luc_tu
          ? moment(defaultValues.hieu_luc_tu).format('YYYY-MM-DD')
          : null,
        hieu_luc_den: defaultValues.hieu_luc_den
          ? moment(defaultValues.hieu_luc_den).format('YYYY-MM-DD')
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState(defaultValues?.vts || []);
  const [collapses, setCollapses] = useState({ chi_tiet: true });

  const handleReset = () => {
    reset(originalData);
    setDetails(defaultValues?.vts || []);
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'evoucher',
      data: { ...values, vts: details },
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="E-voucher"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        <InfoSection
          control={control}
          errors={errors}
          register={register}
          isEdit={isEdit}
        />
        <ChiTietSection
          show={collapses.chi_tiet}
          setCollapses={setCollapses}
          details={details}
          setDetails={setDetails}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormEvoucher;
