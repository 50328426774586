import React from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import { Controller } from 'react-hook-form';
import RichtextInput from '~/components/input/RichtextInput';

function DescriptionSection({ control, show, setCollapses }) {
  return (
    <CollapseSection
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, mieu_ta: !prev.mieu_ta }))
      }
      title="Mô tả chi tiết"
    >
      <Controller
        control={control}
        name="mieu_ta"
        render={({ field: { onChange, value } }) => (
          <RichtextInput
            placeholder="Nhập mô tả sản phẩm"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </CollapseSection>
  );
}

export default DescriptionSection;
