import React, { useState, useEffect, useMemo } from 'react';
import ButtonBase from '../button/ButtonBase';
import ModalBase from './ModalBase';
import { Box, Grid, Paper, Stack } from '@mui/material';
import FilterRadios from '../filter/FilterRadios';
import CheckboxInput from '../input/CheckboxInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';
import {
  ADD_RIGHT,
  DELETE_RIGHT,
  UPDATE_RIGHT,
  VIEW_RIGHT,
  VIEWOFOTHER_RIGHT,
} from '~/utils/constants';
import useAlertContext from '~/hooks/hookContext/useAlertContext';

const rightMapper = {
  [VIEW_RIGHT]: 'Xem',
  [VIEWOFOTHER_RIGHT]: 'Xem của người khác',
  [ADD_RIGHT]: 'Thêm mới',
  [UPDATE_RIGHT]: 'Cập nhật',
  [DELETE_RIGHT]: 'Xóa',
};

const allRight = [
  VIEW_RIGHT,
  VIEWOFOTHER_RIGHT,
  ADD_RIGHT,
  UPDATE_RIGHT,
  DELETE_RIGHT,
];

const groups = [
  {
    label: 'Danh mục',
    value: 'category',
    items: [
      // module
      {
        _id: 'dmvt',
        title: 'Hàng hóa',
        code: 'dmvt',
        rights: allRight,
      },
      {
        _id: 'dmnvt',
        title: 'Nhóm hàng',
        code: 'dmnvt',
        rights: allRight,
      },
      {
        _id: 'dmdvt',
        title: 'Đơn vị tính',
        code: 'dmdvt',
        rights: allRight,
      },
      {
        _id: 'dmqddvt',
        title: 'Quy đổi đơn vị tính',
        code: 'dmqddvt',
        rights: allRight,
      },
      {
        _id: 'dmlo',
        title: 'Lô hàng hóa',
        code: 'dmlo',
        rights: allRight,
      },
      {
        _id: 'dmxuatxu',
        title: 'Xuất xứ',
        code: 'dmxuatxu',
        rights: allRight,
      },
      {
        _id: 'customer',
        title: 'Khách hàng, Nhà cung cấp',
        code: 'customer',
        rights: allRight,
      },
      {
        _id: 'dmnhkh',
        title: 'Nhóm khách hàng',
        code: 'dmnhkh',
        rights: allRight,
      },
      {
        _id: 'dmnhncc',
        title: 'Nhóm nhà cung cấp',
        code: 'dmnhncc',
        rights: allRight,
      },
      {
        _id: 'dmgiaban',
        title: 'Chính sách giá bán',
        code: 'dmgiaban',
        rights: allRight,
      },
      {
        _id: 'dmkhuyenmai',
        title: 'Chính sách khuyến mãi',
        code: 'dmkhuyenmai',
        rights: allRight,
      },
      {
        _id: 'dmchietkhau',
        title: 'Chiết khấu, giảm giá',
        code: 'dmchietkhau',
        rights: allRight,
      },
      {
        _id: 'evoucher',
        title: 'Evoucher',
        code: 'evoucher',
        rights: allRight,
      },
      {
        _id: 'ecompromotion_flashsales',
        title: 'Flashsale',
        code: 'ecompromotion_flashsales',
        rights: allRight,
      },
      {
        _id: 'dmkhuvuc',
        title: 'Danh mục khu vực',
        code: 'dmkhuvuc',
        rights: allRight,
      },
      {
        _id: 'tinhthanh',
        title: 'Danh mục tỉnh thành',
        code: 'tinhthanh',
        rights: allRight,
      },
      {
        _id: 'cart2',
        title: 'Giỏ hàng',
        code: 'cart2',
        rights: allRight,
      },
      {
        _id: 'thongtingiaohang',
        title: 'Thông tin giao hàng',
        code: 'thongtingiaohang',
        rights: allRight,
      },
    ],
  },
  {
    label: 'Chứng từ',
    value: 'voucher',
    items: [
      // module
      {
        _id: 'pbl_pharma',
        title: 'Đơn hàng website',
        code: 'pbl_pharma',
        rights: allRight,
      },
      {
        _id: 'hd2',
        title: 'Hóa đơn bán hàng',
        code: 'hd2',
        rights: allRight,
      },
      {
        _id: 'hd3',
        title: 'Trả hàng',
        code: 'hd3',
        rights: allRight,
      },
      {
        _id: 'pn1',
        title: 'Phiếu mua hàng',
        code: 'pn1',
        rights: allRight,
      },
      {
        _id: 'pnk',
        title: 'Nhập kho nội bộ',
        code: 'pnk',
        rights: allRight,
      },
      {
        _id: 'pn5',
        title: 'Trả hàng nhập',
        code: 'pn5',
        rights: allRight,
      },
      {
        _id: 'pxk',
        title: 'Xuất hủy',
        code: 'pxk',
        rights: allRight,
      },
      {
        _id: 'pkk',
        title: 'Kiểm kho',
        code: 'pkk',
        rights: allRight,
      },
      {
        _id: 'pt1',
        title: 'Phiếu thu',
        code: 'pt1',
        rights: allRight,
      },
      {
        _id: 'pc1',
        title: 'Phiếu chi',
        code: 'pc1',
        rights: allRight,
      },
    ],
  },
  {
    label: 'Báo cáo',
    value: 'report',
    items: [
      {
        _id: 'thnxt',
        title: 'Tổng hợp nhập xuất tồn',
        code: 'thnxt',
        rights: [VIEW_RIGHT, VIEWOFOTHER_RIGHT],
      },
      {
        _id: 'tontucthoi',
        title: 'Tồn kho tức thời',
        code: 'tontucthoi',
        rights: [VIEW_RIGHT, VIEWOFOTHER_RIGHT],
      },
    ],
  },
  {
    label: 'Nội bộ',
    value: 'internal',
    items: [
      {
        _id: 'dmchucvu',
        title: 'Danh mục chức vụ',
        code: 'dmchucvu',
        rights: allRight,
      },
      {
        _id: 'group',
        title: 'Danh mục phụ cấp',
        code: 'group',
        rights: allRight,
      },
      {
        _id: 'dmnv',
        title: 'Danh mục nhân viên',
        code: 'dmnv',
        rights: allRight,
      },
      {
        _id: 'dmbp',
        title: 'Danh mục bộ phận',
        code: 'dmbp',
        rights: allRight,
      },
    ],
  },
  {
    label: 'Hệ thống',
    value: 'system',
    items: [
      // module
      {
        _id: 'usergroup',
        title: 'Nhóm người dùng',
        code: 'usergroup',
        rights: allRight,
      },
      {
        _id: 'participant',
        title: 'Người dùng',
        code: 'participant',
        rights: allRight,
      },
      {
        _id: 'dmkho',
        title: 'Chi nhánh',
        code: 'dmkho',
        rights: allRight,
      },
      {
        _id: 'ptthanhtoan',
        title: 'Phương thức thanh toán',
        code: 'ptthanhtoan',
        rights: allRight,
      },
      {
        _id: 'dmkenhbanhang',
        title: 'Kênh bán hàng',
        code: 'dmkenhbanhang',
        rights: allRight,
      },
      {
        _id: 'dmcpmh',
        title: 'Chi phí mua hàng',
        code: 'dmcpmh',
        rights: allRight,
      },
    ],
  },
];

function AuthLocalModal({ userGroup, open, onClose }) {
  const { asyncSearchList } = useApisContext();
  const [, setOpenBackdrop] = useBackdropContext();

  const [rightObj, setRightObj] = useState({});
  const [group, setGroup] = useState(groups[0]);
  // get rights
  const getRights = async () => {
    try {
      setOpenBackdrop(true);
      const resp = await asyncSearchList({
        apiCode: 'right',
        condition: { page: 1, limit: 90000, q: { email: userGroup._id } },
      });
      const rightToSave = {};
      (resp || []).forEach((r) => (rightToSave[r.module] = r));
      setRightObj(rightToSave);
    } catch (error) {
    } finally {
      setOpenBackdrop(false);
    }
  };
  useEffect(() => {
    if (open) {
      getRights();
    }
    return () => setGroup(groups[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalBase
      open={open}
      handleClose={onClose}
      title={`Phân quyền: ${userGroup?.group_name}`}
      width="1000px"
    >
      <Grid container spacing="10px">
        <Grid item xs={12} md={3}>
          <Box sx={{ padding: '5px 0' }}>
            {groups?.length > 0 && (
              <FilterRadios
                title="Nhóm"
                values={groups}
                defaultValue={groups[0].value}
                onChange={(value) => {
                  const newGroup = groups.find((item) => item.value === value);
                  setGroup(newGroup);
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Stack
            spacing="10px"
            sx={{
              padding: '5px 2px',
              maxHeight: 'calc(90vh - 20px - 39px - 39px)',
              overflow: 'auto',
            }}
          >
            {group?.items?.length > 0 &&
              group.items.map((module) => (
                <ModuleComponent
                  key={module._id}
                  module={module}
                  rightObj={rightObj}
                  userGroup={userGroup}
                  getRights={getRights}
                />
              ))}
          </Stack>
        </Grid>
      </Grid>
    </ModalBase>
  );
}

function ModuleComponent({ module, rightObj, userGroup, getRights }) {
  const showAlert = useAlertContext();
  const { asyncSearchList, asyncPostData, asyncPutData } = useApisContext();
  const [, setOpenBackdrop] = useBackdropContext();

  const [trangThais, setTrangThais] = useState([]);

  const addOrUpdateRight = async ({ type, value }) => {
    setOpenBackdrop(true);
    if (!!currentRight) {
      await asyncPutData({
        apiCode: 'right',
        uniqueValue: currentRight._id,
        data: { [type]: value, email: userGroup._id, module: module.code },
      });
      await getRights();
    } else {
      await asyncPostData({
        apiCode: 'right',
        data: { [type]: value, email: userGroup._id, module: module.code },
      });
      await getRights();
    }
    setOpenBackdrop(false);
  };

  const handleTotalCheck = async (check) => {
    setOpenBackdrop(true);
    try {
      if (!currentRight) {
        const resp = await asyncPostData({
          apiCode: 'right',
          data: {
            [VIEW_RIGHT]: check,
            email: userGroup._id,
            module: module.code,
          },
        });
        const rights =
          resp?.module === 'participant'
            ? [ADD_RIGHT, UPDATE_RIGHT, DELETE_RIGHT]
            : [VIEWOFOTHER_RIGHT, ADD_RIGHT, UPDATE_RIGHT, DELETE_RIGHT];
        for (let i = 0; i < rights.length; i++) {
          await asyncPutData({
            apiCode: 'right',
            uniqueValue: resp?._id,
            data: {
              [rights[i]]: check,
              email: userGroup._id,
              module: module.code,
            },
          });
        }
      } else {
        for (let i = 0; i < allRight.length; i++) {
          await asyncPutData({
            apiCode: 'right',
            uniqueValue: currentRight._id,
            data: {
              [allRight[i]]: check,
              email: userGroup._id,
              module: module.code,
            },
          });
        }
      }
      await getRights();
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setOpenBackdrop(false);
    }
  };

  // get trang thai
  const getTrangThai = async () => {
    const resp = await asyncSearchList({
      apiCode: 'trangthai',
      withIdApp: false,
      condition: { page: 1, limit: 90000, q: { ma_ct: module.code } },
    });
    setTrangThais(resp);
  };

  const currentRight = useMemo(() => {
    const res = rightObj[module.code] || null;
    return res;
  }, [module.code, rightObj]);

  const totalCheck = useMemo(() => {
    return (
      !!currentRight &&
      currentRight[VIEW_RIGHT] &&
      currentRight[VIEWOFOTHER_RIGHT] &&
      currentRight[ADD_RIGHT] &&
      currentRight[UPDATE_RIGHT] &&
      currentRight[DELETE_RIGHT]
    );
  }, [currentRight]);

  useEffect(() => {
    getTrangThai();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module.code]);

  return (
    <Paper sx={{ padding: '10px' }}>
      <Stack direction="row" spacing="5px" sx={{ width: '100%' }}>
        <CheckboxInput
          label={module.title}
          labelSx={{ fontSize: '14px', fontWeight: 500 }}
          checked={totalCheck}
          onChange={() => handleTotalCheck(!totalCheck)}
        />
      </Stack>
      <Grid container>
        {module.rights.map((right) => (
          <CheckBoxComponent
            key={right}
            right={right}
            module={module}
            addOrUpdateRight={addOrUpdateRight}
            currentRight={currentRight}
            trangThais={trangThais}
          />
        ))}
      </Grid>
    </Paper>
  );
}

function CheckBoxComponent({
  right,
  module,
  currentRight,
  addOrUpdateRight,
  trangThais = [],
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {openModal && (
        <ModalBase
          title="Trạng thái có quyền"
          open={openModal}
          width="500px"
          handleClose={() => setOpenModal(false)}
        >
          <Stack
            sx={{
              width: '100%',
              paddingRight: '2px',
              maxHeight: 'calc(90vh - 20px - 39px - 39px)',
              overflow: 'auto',
            }}
          >
            {trangThais?.map((trangThai) => {
              let isChecked =
                currentRight?.[`${right}_trang_thai`]?.[trangThai.stt];
              if (isChecked === undefined) {
                isChecked = true;
              }
              return (
                <CheckboxInput
                  key={trangThai._id}
                  label={trangThai.ten_trang_thai}
                  checked={isChecked}
                  onChange={(e) => {
                    addOrUpdateRight({
                      type: `${right}_trang_thai`,
                      value: {
                        ...currentRight?.[`${right}_trang_thai`],
                        [trangThai.stt]: e.target.checked,
                      },
                    });
                  }}
                />
              );
            })}
          </Stack>
        </ModalBase>
      )}
      <Grid item xs={right === 'viewOfOther' ? 3 : 2.25}>
        <Stack sx={{ width: '100%' }}>
          <CheckboxInput
            disabled={
              (right === VIEW_RIGHT || right === VIEWOFOTHER_RIGHT) &&
              module.code === 'participant'
            }
            label={rightMapper[right]}
            checked={currentRight?.[right] || false}
            onChange={(e) => {
              addOrUpdateRight({ type: right, value: e.target.checked });
            }}
          />
          {currentRight?.[right] === true &&
            !!trangThais &&
            trangThais.length > 0 && (
              <ButtonBase
                variant="outlined"
                sx={{ width: 'fit-content' }}
                onClick={() => setOpenModal(true)}
              >
                Trạng thái
              </ButtonBase>
            )}
        </Stack>
      </Grid>
    </>
  );
}

export default AuthLocalModal;
