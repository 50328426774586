import React, { useEffect, useState } from 'react';
import { Badge, Button } from '@mui/material';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import ModalList from './ModalList';

function Pbl({ customer }) {
  const showAlert = useAlertContext();
  const { asyncGetList } = useApisContext();
  const [countAll, setCountAll] = useState(0);
  const [open, setOpen] = useState(false);

  const getAllCountPbl = async () => {
    try {
      const resp = await asyncGetList({
        apiCode: 'hd2_web',
        condition: { count: 1, q: { ma_kh: customer?.ma_kh, status: true } },
      });
      if (resp) {
        setCountAll(resp?.rows_number || 0);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Lỗi khi tải đơn hàng website',
      });
    }
  };

  useEffect(() => {
    getAllCountPbl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalList
        open={open}
        onClose={() => setOpen(false)}
        defaultCondition={{ ma_kh: customer.ma_kh, status: true }}
      />
      <Badge badgeContent={countAll} color="error">
        <Button
          variant="contained"
          onClick={() => setOpen(true)}
          sx={{
            backgroundColor: 'secondary.main',
            color: 'whitish.pureWhite',
            '&:hover': { backgroundColor: 'secondary.main' },
          }}
        >
          Đơn website
        </Button>
      </Badge>
    </>
  );
}

export default Pbl;
