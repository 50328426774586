import { Box, FormHelperText } from '@mui/material';
import React from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';

function MotaSection({ show, setCollapses, register, errors }) {
  return (
    <CollapseSection
      title="Mô tả ngắn"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, mo_ta: !prev.mo_ta }))
      }
    >
      <Box>
        <AreaInput
          name="mo_ta_ngan"
          register={register}
          placeholder="Nhập mô tả ngắn"
        />
        {errors?.mo_ta_ngan?.message && (
          <FormHelperText error>{errors.mo_ta_ngan.message}</FormHelperText>
        )}
      </Box>
    </CollapseSection>
  );
}

export default MotaSection;
