import React, { useEffect, useState } from 'react';
import { Box, MenuItem, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { formatDateDisplay } from '~/utils/helpers';
import DatetimeInput from './DatetimeInput';

function TextDatetimeInput({
  disabled,
  wrapperSx,
  format = 'DD/MM/YYYY - HH:mm',
  defaultDate,
  onDateChange, // date => {}
}) {
  const [date, setDate] = useState(
    moment(defaultDate).format('YYYY-MM-DD HH:mm')
  );
  const [open, setOpen] = useState(false);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    onDateChange?.(newDate);
  };
  useEffect(() => {
    setDate(moment(defaultDate).format('YYYY-MM-DD HH:mm'));
  }, [defaultDate]);

  return (
    <Box>
      <Box sx={{ position: 'fixed', visibility: 'hidden' }}>
        <DatetimeInput
          open={open}
          onClose={() => setOpen(false)}
          labelWidth="0px"
          value={date}
          onChange={handleDateChange}
        />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        component={MenuItem}
        spacing="4px"
        disabled={disabled}
        sx={{
          height: '24px',
          padding: '0 5px',
          borderRadius: '4px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'whitish.gray',
          },
          ...wrapperSx,
        }}
        onClick={() => setOpen(true)}
      >
        <Typography sx={{ fontSize: '12px' }}>
          {formatDateDisplay(moment(date), format)}
        </Typography>
      </Stack>
    </Box>
  );
}

export default TextDatetimeInput;
