import React, { useEffect } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { HiOutlineBell } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { getNotifications } from '~/redux/actions/notification.action';
import MenuHover from '~/components/menu/MenuHover';

function Notification() {
  const showAlert = useAlertContext();
  const { callApi } = useApisContext();
  const notifications = useSelector((state) => state.notification.data);

  const dispatch = useDispatch();

  useEffect(() => {
    getNotifications({ callApi, showAlert, dispatch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MenuHover
      content={
        <Box sx={{ width: '300px', padding: '10px' }}>
          <Typography
            sx={{ fontSize: '14px', fontWeight: 500, padding: '5px 0' }}
          >
            Thông báo
          </Typography>
          <Divider />
          <Stack sx={{ paddingTop: '10px' }}>
            <Typography sx={{ textAlign: 'center', fontStyle: 'italic' }}>
              Không có thông báo mới
            </Typography>
            {/* <MenuItem sx={{ borderRadius: '4px' }}>Thông báo 1</MenuItem>
            <MenuItem sx={{ borderRadius: '4px' }}>Thông báo 2</MenuItem> */}
          </Stack>
        </Box>
      }
    >
      <Button
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          textTransform: 'none',
          color: 'initial',
          '&:hover': {
            backgroundColor: 'unset',
          },
        }}
        endIcon={<HiOutlineBell size={14} />}
      >
        Thông báo
      </Button>
    </MenuHover>
  );
}

export default Notification;
