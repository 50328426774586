import React from 'react';
import { Avatar, Stack, } from '@mui/material';
import LogoIcon from '~/assets/img/logo.svg';

function Logo() {
  return (
    <Stack direction="row" alignItems="center" spacing="5px">
      <Avatar src={LogoIcon} sx={{ width: '100px', height: '48px' }} />
      {/* <Typography
        sx={{ textDecoration: 'none', fontSize: '20px', fontWeight: 600 }}
      >
        POSPLUS
      </Typography> */}
    </Stack>
  );
}

export default Logo;
