import { Grid } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import ModalBase from '~/components/modal/ModalBase';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';
import SelectInput from '~/components/input/SelectInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { KIEU_PHAN_BO } from '~/utils/constants';

const schema = yup.object({
  chi_phi: yup
    .object()
    .typeError('Vui lòng chọn loại chi phí')
    .required('Vui lòng chọn loại chi phí'),
  phan_bo: yup
    .object()
    .typeError('Vui lòng chọn kiểu phân bổ')
    .required('Vui lòng chọn kiểu phân bổ'),
});

function FormAddChiPhi({
  open,
  handleClose = () => {},
  isEdit = false,
  addChiphi = () => {},
  defaultValue,
}) {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: !!defaultValue
      ? {
          ...defaultValue,
          chi_phi: defaultValue.ma_cp
            ? { ma_cp: defaultValue.ma_cp, ten_cp: defaultValue.ten_cp }
            : null,
          phan_bo: defaultValue.kieu_pb
            ? KIEU_PHAN_BO.find((item) => item.value === defaultValue.kieu_pb)
            : null,
        }
      : null,
  });

  const generateDataAdd = (values) => {
    const { chi_phi, phan_bo, ...fiels } = values;
    const result = {
      ...fiels,
      ma_cp: chi_phi?.ma_cp || '',
      ten_cp: chi_phi?.ten_cp || '',
      kieu_pb: phan_bo?.value || '',
      ten_kieu_pb: phan_bo?.name || '',
    };
    return result;
  };

  const handleSave = (values) => {
    const dataAdd = generateDataAdd(values);
    addChiphi(dataAdd);
    handleClose();
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="500px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} chi phí`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="chi_phi"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                disabled={isEdit}
                label="Chi phí"
                required
                apiCode="dmcpmh"
                placeholder="Loại chi phí"
                searchFileds={['ma_cp', 'ten_cp']}
                getOptionLabel={(option) => option.ten_cp}
                selectedValue={value}
                value={value || { ma_cp: '', ten_cp: '' }}
                onSelect={onChange}
                isOpenDm={false}
                errorMessage={errors?.chi_phi?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="tien_cp_nt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tiền chi phí"
                type="text"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="phan_bo"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                required
                labelWidth="30%"
                label="Kiểu phân bổ"
                placeholder="Kiểu phân bổ"
                options={KIEU_PHAN_BO}
                getOptionLabel={(option) => option.name}
                selectedValue={value}
                value={value || { name: '', value: '' }}
                onSelect={(newValue) => onChange(newValue)}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormAddChiPhi;
