import React, { memo } from 'react';
import { Badge, Box, Paper, Stack, Typography } from '@mui/material';
import { v4 } from 'uuid';

function ButtonOption({
  children,
  startIcon,
  endIcon,
  notification = false,
  popupOptions,
  style,
  primary,
  active,
  menuColor = '#fff',
  menuWidth = '250px',
  activeColor,
  onClick = () => {},
  sx = {},
  menuSx = {},
  ...props
}) {
  return (
    <>
      <Badge
        variant="dot"
        color="secondary"
        invisible={!notification}
        sx={{
          display: 'block',
          '& .MuiBadge-dot': {
            minWidth: '6px',
            height: '6px',
            top: '25%',
          },
        }}
      >
        <Stack
          style={style}
          direction="row"
          alignItems="center"
          gap="10px"
          sx={{
            height: '100%',
            position: 'relative',
            padding: '8px',
            transition: 'all linear 0.1s',
            cursor: 'pointer',
            backgroundColor: active ? 'primary.main' : '',
            '&:hover': {
              backgroundColor: primary
                ? active
                  ? 'primary.main'
                  : 'primary.second'
                : 'divider',
              '& .submenu': {
                height: 'auto',
              },
            },
            ...sx,
          }}
          {...props}
          onClick={onClick}
        >
          {startIcon && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ color: primary ? 'whitish.pureWhite' : '' }}
            >
              {startIcon}
            </Stack>
          )}
          {!!children && (
            <Typography
              sx={{
                fontSize: '14px',
                textWrap: 'nowrap',
                color: primary ? 'whitish.pureWhite' : 'inherit',
              }}
            >
              {children}
            </Typography>
          )}
          {endIcon && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ color: primary ? 'whitish.pureWhite' : '' }}
            >
              {endIcon}
            </Stack>
          )}
          {/* Menu */}
          {popupOptions?.length > 0 && (
            <Paper
              className="submenu"
              sx={{
                position: 'absolute',
                zIndex: 10,
                left: 0,
                top: '100%',
                backgroundColor: 'whitish.pureWhite',
                minWidth: '200px',
                height: '0',
                overflow: 'hidden',
                ...menuSx,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: 'auto',
                  backgroundColor: menuColor,
                }}
              >
                {popupOptions.map(({ text, onClick, ...props }) => (
                  <ButtonOption
                    key={v4()}
                    {...props}
                    onClick={() => {
                      onClick?.();
                    }}
                  >
                    {text}
                  </ButtonOption>
                ))}
              </Box>
            </Paper>
          )}
        </Stack>
      </Badge>
    </>
  );
}

export default memo(ButtonOption);
