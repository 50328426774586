import { ID_APP } from '~/utils/constants';
import { updateNotifications } from '../reducrers/notifycation.reducer';

export const getNotifications = async ({ showAlert, callApi, dispatch }) => {
  try {
    const resp = await callApi({
      method: 'get',
      endpoint: `/notification?q={"exfields.id_app":"${ID_APP}"}`,
    });
    if (resp) {
      dispatch(updateNotifications(resp));
    }
  } catch (error) {
    showAlert({
      type: 'error',
      message: error?.message || 'Something went wrong',
    });
  }
};
