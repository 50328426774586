import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import ModalForm from '~/components/modal/ModalForm';
import InfoSection from './info-section/InfoSection';
import DiaChiSection from './diachi-section/DiaChiSection';
import GhiChuSection from './ghichu-section/GhiChuSection';

const schema = yup.object({
  ten_kh: yup.string().required('Vui lòng nhập tên khách hàng'),
  email: yup.string().email('Email không đúng định dạng'),
  dien_thoai: yup.string().matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g, {
    message: 'Số điện thoại không hợp lệ',
    excludeEmptyString: true,
  }),
});

const defaultData = {
  ma_kh: '',
  ten_kh: '',
  dien_thoai: '',
  email: '',
  ghi_chu: '',
  ma_so_thue: '',
  status: true,
  nhom_ncc: null,
  tinh_thanh_obj: null,
  quan_huyen_obj: null,
  xa_phuong_obj: null,
  dia_chi: '',
  ncc_yn: true,
  kh_yn: false,
};

function FormNCC({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        nhom_ncc: defaultValues.nh_kh
          ? {
              _id: defaultValues.nh_kh,
              group_name: defaultValues.ten_nh_kh,
            }
          : null,
        tinh_thanh_obj: defaultValues.tinh_thanh
          ? {
              ma_tinh_thanh: defaultValues.tinh_thanh,
              ten_tinh_thanh: defaultValues.ten_tinh_thanh,
            }
          : null,
        quan_huyen_obj: defaultValues.quan_huyen
          ? {
              ma_quan_huyen: defaultValues.quan_huyen,
              ten_quan_huyen: defaultValues.ten_quan_huyen,
            }
          : null,
        xa_phuong_obj: defaultValues.xa_phuong
          ? {
              ma_xa_phuong: defaultValues.xa_phuong,
              ten_xa_phuong: defaultValues.ten_xa_phuong,
            }
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [collapses, setCollapses] = useState({ ghi_chu: true, dia_chi: true });

  const handleReset = () => {
    reset(originalData);
  };

  const tinhThanh = watch('tinh_thanh_obj');
  const quanHuyen = watch('quan_huyen_obj');

  const generateDataPost = (values) => {
    const {
      tinh_thanh_obj,
      quan_huyen_obj,
      xa_phuong_obj,
      nhom_ncc,
      ...fields
    } = values;
    return {
      ...fields,
      tinh_thanh: tinh_thanh_obj?.ma_tinh_thanh || '',
      ten_tinh_thanh: tinh_thanh_obj?.ten_tinh_thanh || '',
      quan_huyen: quan_huyen_obj?.ma_quan_huyen || '',
      ten_quan_huyen: quan_huyen_obj?.ten_quan_huyen || '',
      xa_phuong: xa_phuong_obj?.ma_xa_phuong || '',
      ten_xa_phuong: xa_phuong_obj?.ten_xa_phuong || '',
      nh_kh: nhom_ncc?._id || '',
      ten_nh_kh: nhom_ncc?.group_name || '',
    };
  };

  // handle submit
  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'customer',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="800px"
        title="nhà cung cấp"
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Stack
          spacing="20px"
          sx={{
            width: '100%',
            padding: '10px',
            maxHeight: 'calc(90vh - 20px - 39px - 39px)',
            overflow: 'auto',
          }}
        >
          <InfoSection
            control={control}
            errors={errors}
            isEdit={isEdit}
            register={register}
          />
          <DiaChiSection
            control={control}
            quanHuyen={quanHuyen}
            register={register}
            setCollapses={setCollapses}
            setValue={setValue}
            show={collapses.dia_chi}
            tinhThanh={tinhThanh}
          />
          <GhiChuSection
            register={register}
            setCollapses={setCollapses}
            show={collapses.ghi_chu}
          />
        </Stack>
      </ModalForm>
    </>
  );
}

export default FormNCC;
