import useToken from './useToken';
import { PUBLIC_URL } from '~/utils/constants';

function useLinkImage(token) {
  const accessToken = useToken();
  return function (link) {
    if (!link) return null;
    return `${PUBLIC_URL}${link}?access_token=${token || accessToken}`;
  };
}

export default useLinkImage;
