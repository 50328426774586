import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { numeralCustom } from '~/utils/helpers';
import FormLo from '../../lo/FormLo';
import FormDVT from '../../dvt/FormDVT';
import FormProduct from '../../product/FormProduct';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import moment from 'moment';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';

const baseSchema = {
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  sl_xuat: yup
    .number()
    .typeError('Số lượng tối thiểu là 1')
    .min(1, 'Số lượng tối thiểu là 1'),
};

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();
  const [schema, setSchema] = useState(yup.object(baseSchema));
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          vat_tu: defaultValues.ma_vt
            ? {
                ma_vt: defaultValues.ma_vt,
                ten_vt: defaultValues.ten_vt,
                ma_dvt: defaultValues.ma_dvt,
                ma_lo_yn: !!defaultValues.ma_lo,
                gia_ban_le: defaultValues.gia_ban_nt,
              }
            : null,
          lo: defaultValues.ma_lo
            ? { ma_lo: defaultValues.ma_lo, ten_lo: defaultValues.ten_lo }
            : null,
        }
      : {
          sl_xuat: 1,
          tien_ck_nt: 0,
          ty_le_ck: 0,
        },
  });

  const vatTu = watch('vat_tu');

  const getLos = async () => {
    try {
      const data = await asyncSearchList({
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 999999,
          q: { status: true, ma_vt: vatTu?.ma_vt },
        },
      });
      if (data && Array.isArray(data) && data.length === 1) {
        setValue('lo', data[0]);
      }
    } catch (error) {
      showAlert(error?.message || 'Something went wrong!');
    }
  };

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  useEffect(() => {
    setValue('gia_ban_nt', vatTu?.gia_ban_le || 0);
    setValue('don_vi_tinh', {
      ma_dvt: vatTu?.ma_dvt || '',
      ten_dvt: vatTu?.ten_dvt || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatTu]);

  // useEffect(() => {
  //   const tienHang = (giaBanLeGoc || 0) * (soLuongXuat || 0);
  //   setValue('tien_hang_nt', tienHang);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [giaBanLeGoc]);

  // useEffect(() => {
  //   const newTienCK = ((tienHang || 0) * (tyLeCk || 0)) / 100;
  //   setValue('tien_ck_nt', newTienCK);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tienHang]);

  useEffect(() => {
    if (vatTu?.ma_lo_yn) {
      getLos();
      setSchema(
        yup.object({
          ...baseSchema,
          lo: yup
            .object()
            .typeError('Vui lòng chọ lô')
            .required('Vui lòng chọn lô'),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatTu]);

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} Chi tiết`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="vat_tu"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Hàng hóa"
                required
                disabled={isEdit}
                apiCode="dmvt"
                placeholder="Chọn hàng bán"
                searchFileds={['ma_vt', 'ten_vt']}
                getOptionLabel={(option) => option.ten_vt}
                selectedValue={value}
                value={value || { ma_vt: '', ten_vt: '' }}
                onSelect={onChange}
                FormAdd={FormProduct}
                errorMessage={errors?.vat_tu?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="don_vi_tinh"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                disabled
                labelWidth="30%"
                label="Đơn vị tính"
                apiCode="dmdvt"
                placeholder="Đơn vị tính"
                searchFileds={['ma_dvt', 'ten_dvt']}
                getOptionLabel={(option) => option.ma_dvt}
                selectedValue={value}
                value={value || { ma_dvt: '' }}
                onSelect={onChange}
                FormAdd={FormDVT}
                errorMessage={errors?.don_vi_tinh?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="gia_ban_nt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                disabled
                label="Giá bán"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="sl_xuat"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                required
                labelWidth="30%"
                label="Số lượng"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = numeralCustom(e.target.value).value();
                  onChange(val);
                }}
                errorMessage={errors?.sl_xuat?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="ty_le_ck"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tỷ lệ CK (%)"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  // luu gia tri
                  const value = numeralCustom(e.target.value).value();
                  onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tien_ck_nt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tiền chiết khấu"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  // luu gia tri
                  const value = numeralCustom(e.target.value).value();
                  onChange(value);
                }}
              />
            )}
          />
        </Grid>
        {vatTu?.ma_lo_yn && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="lo"
              render={({ field: { value, onChange } }) => (
                <SelectApiInput
                  label="Lô hàng"
                  required
                  labelWidth="30%"
                  apiCode="dmlo"
                  placeholder="Chọn lô hàng hóa"
                  searchFileds={['ma_lo', 'ten_lo']}
                  condition={!!vatTu ? { ma_vt: vatTu?.ma_vt } : {}}
                  getOptionLabel={(option) =>
                    `${option.ma_lo}${
                      option.han_sd
                        ? ` (${moment(option.han_sd).format('DD/MM/YYYY')})`
                        : ''
                    }`
                  }
                  selectedValue={value}
                  value={value || { ma_lo: '', ten_lo: '' }}
                  onSelect={onChange}
                  FormAdd={FormLo}
                  errorMessage={errors?.lo?.message}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CollapseSection title="Ghi chú" show>
            <AreaInput
              labelWidth="0%"
              placeholder="Nhập ghi chú sản phẩm"
              name="dien_giai"
              register={register}
            />
          </CollapseSection>
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormAddDetail;
