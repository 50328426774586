import React from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterSelectApi from '../FilterSelectApi';
import FormUserGroup from '~/components/form/usergroup/FormUserGroup';
import { useLocation } from 'react-router-dom';
import FilterContainer from '../FilterContainer';

function FilterParticipant({ setCondition }) {
  const location = useLocation();
  const originFilter = {
    email: '',
    name: '',
    userGroup: location.state?.userGroup || null,
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.email) {
      condition.email = {
        $regex: filter.email,
        $options: 'i',
      };
    }
    if (filter.name) {
      condition.name = {
        $regex: filter.name.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.userGroup) {
      condition.group_id = filter.userGroup._id;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Email"
          placeholder="Tìm theo email"
          value={filter.email}
          onSearch={(value) => setFilter({ ...filter, email: value })}
        />
        <FilterSearch
          title="Tên"
          placeholder="Tìm theo email"
          value={filter.name}
          onSearch={(value) => setFilter({ ...filter, name: value })}
        />
        <FilterSelectApi
          apiCode="usergroup"
          placeholder="Chọn nhóm người dùng"
          searchFileds={['group_name']}
          title="Nhóm người dùng"
          getOptionLabel={(option) => option.group_name}
          selectedValue={filter.userGroup}
          value={filter.userGroup || { _id: '', group_name: '' }}
          onSelect={(value) => setFilter({ ...filter, userGroup: value })}
          FormAdd={FormUserGroup}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterParticipant;
