import React, { useId, useState } from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import { MdClose } from 'react-icons/md';
import { HiOutlineSelector } from 'react-icons/hi';
import WrapperInput from './WrapperInput';

function SelectInput({
  label,
  required = false,
  multiple,
  disabled,
  isOptionEqualToValue = () => true,
  options = [],
  value,
  selectedValue,
  getOptionLabel = () => {},
  onSelect = () => {},
  filterOptions,
  renderOption,
  sx = {},
  placeholder = '',
  errorMessage,
  labelWidth = '20%',
  readOnly,
  ...props
}) {
  const id = useId();
  const [searchText, setSearchText] = useState('');

  return (
    <WrapperInput
      id={id}
      label={label}
      labelWidth={labelWidth}
      required={required}
      errorMessage={errorMessage}
      {...props}
    >
      <Autocomplete
        readOnly={readOnly}
        multiple={multiple}
        disabled={disabled}
        isOptionEqualToValue={isOptionEqualToValue}
        popupIcon={<HiOutlineSelector size={14} />}
        clearIcon={!!selectedValue ? <MdClose size={14} /> : null}
        clearOnBlur={false}
        options={options}
        value={value}
        onBlur={() => setSearchText('')}
        onChange={(e, newValue) => {
          onSelect(newValue);
        }}
        ListboxProps={{
          className: 'custome-scrolly',
          sx: {
            '& .MuiAutocomplete-option': { fontSize: '12px' },
          },
        }}
        noOptionsText={
          <Stack spacing="10px" alignItems="center">
            <Typography
              sx={{
                fontSize: '14px',
                fontStyle: 'italic',
                textAlign: 'center',
              }}
            >
              Không tìm thấy kết quả
            </Typography>
          </Stack>
        }
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        renderOption={renderOption}
        sx={{ width: '100%', ...sx }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            autoComplete="off"
            variant="standard"
            size="small"
            value={searchText}
            sx={{
              '&:hover .btn-clear-select': {
                display: 'flex',
              },
              '& .MuiInputBase-root': { padding: '0 5px 0 0 !important' },
              '& .MuiFormLabel-root': {
                fontSize: '12px',
                fontWeight: 600,
                color: 'primary.main',
                transform: 'translate(14px, -12px)',
                paddingRight: '5px',
                backgroundColor: 'whitish.pureWhite',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
                padding: '5px 0 !important',
                '&:placeholder': {
                  fontSize: '14px',
                },
              },
            }}
            onChange={(e) => setSearchText(e.target.value)}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
            }}
          />
        )}
      />
    </WrapperInput>
  );
}

export default SelectInput;
