import React, { useMemo } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import ListBase from '~/components/listBase/ListBase';
import { dsDanhMuc } from '~/utils/data';

/**
 * apicode ưu tiên chọn trong danh mục trước,
 * nếu danh mục không có thì lấy apicode trên param
 */
function List() {
  const { apicode } = useParams();
  const danhMuc = useMemo(() => {
    return dsDanhMuc[apicode];
  }, [apicode]);
  if (!danhMuc) {
    return <Navigate to="/404" />;
  }

  return (
    <ListBase
      title={danhMuc?.title}
      columns={danhMuc?.columns}
      showColumns={danhMuc?.showColumns}
      apiCode={danhMuc?.apiCode || apicode}
      uniqueKey={danhMuc.uniqueKey}
      Form={danhMuc.Form}
      Filter={danhMuc?.Filter}
      expandableRows={danhMuc?.expandableRows}
      expandOnRowClicked={danhMuc?.expandOnRowClicked}
      expandableRowsComponent={danhMuc?.expandableRowsComponent}
      expandableRowsHideExpander={danhMuc?.expandableRowsHideExpander}
      showStatus={danhMuc?.showStatus}
    />
  );
}

export default List;
