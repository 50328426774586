import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';

function ThueTNCNSection({ show, setCollapses, register, errors, control }) {
  return (
    <CollapseSection
      title="Thuế TNCN"
      show={show}
      onCollapse={() => setCollapses((prev) => ({ ...prev, thue: !prev.thue }))}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="50%"
            label="Mã số thuế"
            placeholder="Nhập mã số thuế"
            name="ttncn_mst"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            type="number"
            labelWidth="50%"
            label="Số người phụ thuộc"
            placeholder="Nhập số người phụ thuộc"
            name="ttncn_so_nguoi_phu_thuoc"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ttncn_muc_gt_phu_thuoc"
            render={({ field: { onChange, value } }) => (
              <TextInput
                labelWidth="50%"
                label="Mức giảm trừ phụ thuộc"
                placeholder="Nhập mức giảm trừ phụ thuộc"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const number = e.target.value;
                  onChange(numeralCustom(number).value());
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'neutral.iconColor',
                      }}
                    >
                      VND
                    </Typography>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            type="number"
            labelWidth="50%"
            label="Số tháng giảm trừ bản thân"
            placeholder="Nhập số tháng giảm trừ bản thân"
            name="ttncn_so_thang_gt_ban_than"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ttncn_muc_gt_ban_than"
            render={({ field: { onChange, value } }) => (
              <TextInput
                labelWidth="50%"
                label="Mức giảm trừ bản thân"
                placeholder="Nhập mức giảm trừ bản thân"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const number = e.target.value;
                  onChange(numeralCustom(number).value());
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'neutral.iconColor',
                      }}
                    >
                      VND
                    </Typography>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default ThueTNCNSection;
