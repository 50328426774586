import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterContainer from '../FilterContainer';
import FilterSelectApi from '../FilterSelectApi';

function FilterQddvt({ setCondition }) {
  const originFilter = {
    vat_tu: null,
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.vat_tu) {
      condition.ma_vt = filter.vat_tu.ma_vt;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSelectApi
          apiCode="dmvt"
          searchFileds={['ma_vt', 'ten_vt']}
          title="Hàng hóa"
          placeholder="Chọn một hàng hóa"
          getOptionLabel={(option) => option.ten_vt}
          selectedValue={filter.vat_tu}
          value={filter.vat_tu || { ma_vt: '', ten_vt: '' }}
          onSelect={(value) => setFilter({ ...filter, vat_tu: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterQddvt;
