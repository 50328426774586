import React from 'react';
import { SnackbarProvider } from './snackbarContext.js/index.js';
import { SocketProvider } from './socketContext/index.js';
import GlobalThemProvider from './themeContext/index.js';
import ApisProvider from './apisContext/index.js';
import ConfirmProvider from './confirmContext/ConfirmProvider.js';
import BackdropProvider from './backdropContext/index.js';
import AlertProvider from './alertContext/index.js';

function ContextProvider({ children }) {
  return (
    <>
      <GlobalThemProvider>
        <BackdropProvider>
          <SnackbarProvider>
            <ConfirmProvider>
              <AlertProvider>
                <ApisProvider>
                  <SocketProvider>{children}</SocketProvider>
                </ApisProvider>
              </AlertProvider>
            </ConfirmProvider>
          </SnackbarProvider>
        </BackdropProvider>
      </GlobalThemProvider>
    </>
  );
}

export default ContextProvider;
