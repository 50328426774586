import { useContext } from 'react';
import { BackdropContext } from '~/context/backdropContext';

const useBackdropContext = () => {
  const value = useContext(BackdropContext);
  if (!value) {
    throw new Error('Backdrop context must be used inside backdrop provider');
  }
  return value;
};
export default useBackdropContext;
