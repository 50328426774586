import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import FilterRadios from '~/components/filter/FilterRadios';
import { useSelector } from 'react-redux';
import { useOrderContext } from '~/pages/order/OrderPage';

function Pttt() {
  const { currentHd } = useSelector((state) => state.order);
  const ptttData = useSelector((state) => state.pttt);
  const { handleUpdateCurrentHd } = useOrderContext();

  const handlePtttChange = (id) => {
    const val = pttts.find((item) => item.value === id);
    if (!val) return;
    handleUpdateCurrentHd({
      ht_thanh_toan: val.value,
      ten_ht_thanh_toan: val.label,
      tk_no: val.tk_cn,
    });
  };

  const pttts = useMemo(() => {
    if (!ptttData?.data) {
      return [];
    }
    const result = ptttData?.data.reduce((acc, item) => {
      return [...acc, { label: item.ten, value: item._id, ...item }];
    }, []);
    return result;
  }, [ptttData?.data]);

  return (
    <Box sx={{ padding: '0 2px' }}>
      {pttts?.length > 0 && (
        <FilterRadios
          title="Phương thức thanh toán"
          values={pttts}
          showActive={false}
          defaultValue={currentHd?.ht_thanh_toan || ''}
          onChange={handlePtttChange}
        />
      )}
    </Box>
  );
}

export default Pttt;
