import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { privateRoutes, restrictedRoutes } from '.';
import NotFoundPage from '~/pages/notfound/NotFoundPage';

function RenderRoute() {
  return (
    <Routes>
      {restrictedRoutes.map((route) => (
        <Route key={route.id} path={route.path} element={route.page} />
      ))}
      {privateRoutes.map((route) => (
        <Route key={route.id} path={route.path} element={route.page} />
      ))}
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default memo(RenderRoute);
