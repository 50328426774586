import React from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';

function GhiChuSection({ show, setCollapses, register }) {
  return (
    <CollapseSection
      title="Ghi chú"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, ghi_chu: !prev.ghi_chu }))
      }
    >
      <AreaInput
        name="ghi_chu"
        placeholder="Ghi chú khách hàng"
        register={register}
      />
    </CollapseSection>
  );
}

export default GhiChuSection;
