import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextInput from '~/components/input/TextInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckboxInput from '~/components/input/CheckboxInput';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import ModalForm from '~/components/modal/ModalForm';
import { VIEW_RIGHT, VIEWOFOTHER_RIGHT } from '~/utils/constants';
import useAlertContext from '~/hooks/hookContext/useAlertContext';

const schema = yup.object({
  group_name: yup.string().required('Vui lòng nhập tên nhóm'),
});

const defaultData = {
  group_name: '',
  is_employee_group: false,
  is_customer_group: false,
  is_partner_group: false,
  status: true,
};

function FormUserGroup({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const showAlert = useAlertContext();
  const { asyncPostData, asyncPutData } = useApisContext();
  const originalData = defaultValues || defaultData;
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const setupDefaultRight = async (group_id) => {
    try {
      await asyncPostData({
        apiCode: 'right',
        data: {
          [VIEW_RIGHT]: true,
          [VIEWOFOTHER_RIGHT]: true,
          email: group_id,
          module: 'participant',
        },
      });
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleSave = async (values, mode) => {
    const resp = await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'usergroup',
      data: values,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
    if (!isEdit) {
      await setupDefaultRight(resp?._id);
    }
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="700px"
      title="nhóm người dùng"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            label="Tên nhóm"
            placeholder="Tên nhóm người dùng"
            name="group_name"
            register={register}
            required
            errorMessage={errors?.group_name?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="is_employee_group"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Là nhóm người dùng nhân viên"
                name="is_employee_group"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="is_customer_group"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Là nhóm người dùng khách hàng"
                name="is_customer_group"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="is_partner_group"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Là nhóm cộng tác viên"
                name="is_partner_group"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        {isEdit && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Còn sử dụng"
                  name="status"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </ModalForm>
  );
}

export default FormUserGroup;
