import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Badge,
  Button,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { PiTextColumns } from 'react-icons/pi';
import { SiMicrosoftexcel } from 'react-icons/si';
import { TbTableExport } from 'react-icons/tb';
import ButtonBase from '~/components/button/ButtonBase';
import ListLayout from '~/components/layouts/ListLayout';
import ModalColumns from '~/components/modal/ModalColumns';
import ModalExportExcel from '~/components/modal/ModalExportExcel';
import ModalImportExcel from '~/components/modal/ModalImportExcel';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { MdClose } from 'react-icons/md';
import { BiTransferAlt } from 'react-icons/bi';
import MenuHover from '~/components/menu/MenuHover';
import FormTinhThanh from '~/components/form/tinhthanh/FormTinhThanh';
import FilterTinhThanh from '~/components/filter/tinhthanh/FilterTinhThanh';
import ModalChuyenKhuVuc from '~/components/modal/ModalChuyenKhuVuc';
import useAuthorize from '~/hooks/useAuthorize';

const apiCode = 'tinhthanh';
const title = 'tỉnh thành';

function ListTinhThanhPage() {
  const allowAuthorize = useAuthorize();
  const { asyncGetList } = useApisContext();
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [load, setLoad] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [condition, setCondition] = useState({});
  const [showColumnsState, setShowColumnsState] = useState([0]);
  const [openExcel, setOpenExcel] = useState(false);
  const [openModalColumns, setOpenModalColumns] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [opentExportExcel, setOpenExportExcel] = useState(false);
  const [openModalChuyenKhuVuc, setOpenModalChuyenKhuVuc] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationOption, setPaginationOption] = useState({
    limit: 10,
    page: 1,
    totalRows: 0,
  });
  const btnDeleteRef = useRef();
  const renderCountRef = useRef(1);

  const columns = [
    {
      name: 'Tên tỉnh / thành phố',
      selector: (row) => row.ten_tinh_thanh,
      wrap: true,
    },
  ];

  // row per page change
  const handleRowPerPageChange = (value) => {
    setPaginationOption({ ...paginationOption, page: 1, limit: value });
  };
  // handle row clicked
  const handleRowClicked = (row) => {
    const index = data.findIndex((item) => item._id === row._id);
    setCurrentIndex(index);
    setOpenForm(true);
    setIsEdit(true);
  };
  // handle close form
  const handleCloseForm = (setStateFunc) => {
    setStateFunc(false);
    setCurrentIndex(-1);
  };
  // get products
  const getListData = async () => {
    if (loading) return;
    setLoading(true);
    const resp = await asyncGetList({
      apiCode,
      condition: {
        limit: paginationOption.limit,
        page: paginationOption.page,
        q: condition,
      },
      withIdApp: false,
    });
    const respCount = await asyncGetList({
      apiCode,
      condition: {
        count: 1,
        q: condition,
      },
      withIdApp: false,
    });
    if (respCount) {
      setPaginationOption({
        ...paginationOption,
        totalRows: respCount.rows_number,
      });
    }
    if (resp) {
      setData(resp);
    }
    setLoading(false);
  };

  const exportToExcel = async () => {
    setOpenExportExcel(true);
  };
  // handle clear select
  const handleClearSelect = () => {
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const currentColumns = useMemo(() => {
    if (!showColumnsState) return [];
    const result = (columns || []).reduce((acc, col, index) => {
      if ((showColumnsState || []).includes(index)) {
        return [...acc, col];
      } else {
        return acc;
      }
    }, []);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showColumnsState]);

  useEffect(() => {
    if (renderCountRef.current > 1) {
      getListData();
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    }
    renderCountRef.current += 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOption.limit, paginationOption.page, load]);

  useEffect(() => {
    setPaginationOption({ ...paginationOption, page: 1 });
    setLoad(load + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return (
    <>
      <ListLayout
        ref={btnDeleteRef}
        title={title}
        apiCode={apiCode}
        columns={currentColumns}
        data={data}
        getListData={getListData}
        handleRowClicked={handleRowClicked}
        handleRowPerPageChange={handleRowPerPageChange}
        loading={loading}
        paginationOption={paginationOption}
        renderOuter={() => (
          <>
            <ModalColumns
              open={openModalColumns}
              onClose={() => setOpenModalColumns(false)}
              columns={columns}
              showColumns={showColumnsState}
              setShowColumns={setShowColumnsState}
            />
            <ModalChuyenKhuVuc
              open={openModalChuyenKhuVuc}
              handleClose={() => setOpenModalChuyenKhuVuc(false)}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setToggleCleared={setToggleCleared}
              setLoad={setLoad}
            />
            {openForm && (
              <FormTinhThanh
                open={openForm}
                defaultValues={data[currentIndex]}
                isEdit={isEdit}
                handleClose={() => handleCloseForm(setOpenForm)}
                setLoad={setLoad}
                currentIndex={currentIndex}
                rows={data}
                setCurrentIndex={setCurrentIndex}
              />
            )}
            {openExcel && (
              <ModalImportExcel
                open={openExcel}
                handleClose={() => setOpenExcel(false)}
                apiCode={apiCode}
                setLoad={setLoad}
              />
            )}
            {opentExportExcel && (
              <ModalExportExcel
                open={opentExportExcel}
                handleClose={() => setOpenExportExcel(false)}
                apiCode={apiCode}
              />
            )}
          </>
        )}
        renderFilter={() => <FilterTinhThanh setCondition={setCondition} />}
        renderHeaderList={() => (
          <>
            {selectedRows.length > 0 && (
              <Badge
                badgeContent={selectedRows.length}
                color="secondary"
                sx={{
                  '& .MuiBadge-badge': {
                    right: 8,
                    top: 2,
                    border: `2px solid #fff`,
                    padding: '0 4px',
                  },
                }}
              >
                <MenuHover
                  content={
                    <Stack sx={{ minWidth: '150px' }}>
                      {allowAuthorize({
                        type: 'view',
                        module: 'tinhthanh',
                      }) && (
                        <MenuItem sx={{ columnGap: '10px' }}>
                          <TbTableExport size={14} />
                          <Typography sx={{ fontSize: '14px' }}>
                            Xuất file
                          </Typography>
                        </MenuItem>
                      )}
                      {allowAuthorize({
                        type: 'update',
                        module: 'tinhthanh',
                      }) && (
                        <MenuItem
                          sx={{ columnGap: '10px' }}
                          onClick={() => setOpenModalChuyenKhuVuc(true)}
                        >
                          <BiTransferAlt size={14} />
                          <Typography sx={{ fontSize: '14px' }}>
                            Chuyển khu vực
                          </Typography>
                        </MenuItem>
                      )}
                      <MenuItem
                        sx={{ columnGap: '10px' }}
                        onClick={handleClearSelect}
                      >
                        <MdClose size={14} />
                        <Typography sx={{ fontSize: '14px' }}>
                          Bỏ chọn tất cả
                        </Typography>
                      </MenuItem>
                    </Stack>
                  }
                >
                  <Button
                    variant="contained"
                    sx={{
                      height: '30px',
                      textTransform: 'none',
                      color: 'whitish.pureWhite',
                    }}
                  >
                    Thao tác
                  </Button>
                </MenuHover>
              </Badge>
            )}
            {allowAuthorize({ type: 'add', module: 'tinhthanh' }) && (
              <ButtonBase
                startIcon={<SiMicrosoftexcel fontSize="14px" />}
                onClick={() => setOpenExcel(true)}
              >
                Nhập excel
              </ButtonBase>
            )}
            {allowAuthorize({ type: 'view', module: 'tinhthanh' }) && (
              <ButtonBase
                startIcon={<TbTableExport fontSize="14px" />}
                onClick={exportToExcel}
              >
                Xuất excel
              </ButtonBase>
            )}
            <IconButton
              sx={{
                width: '30px',
                height: '30px',
                backgroundColor: 'primary.main',
                color: 'whitish.pureWhite',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
              onClick={() => setOpenModalColumns(true)}
            >
              <PiTextColumns size={14} />
            </IconButton>
          </>
        )}
        setPaginationOption={setPaginationOption}
        setSelectedRows={setSelectedRows}
        setToggleCleared={setToggleCleared}
        toggleCleared={toggleCleared}
      />
    </>
  );
}

export default ListTinhThanhPage;
