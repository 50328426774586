import React, { useState } from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import { cloneDeep } from 'lodash';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import { Stack } from '@mui/material';
import ButtonBase from '~/components/button/ButtonBase';
import TableDisplay from '~/components/table/TableDisplay';
import { BsPlusCircle } from 'react-icons/bs';
import FormAddVAT from './FormAddVAT';

const columns = [
  {
    name: 'Số hóa đơn',
    selector: (row) => row.so_hd,
    left: true,
    wrap: true,
    width: '100px',
  },
  {
    name: 'Ngày hóa đơn',
    selector: (row) => row.ngay_hd,
    format: (row) => formatDateDisplay(row.ngay_hd, 'DD/MM/YYYY HH:mm'),
    left: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: 'Thuế suất (%)',
    selector: (row) => row.ma_thue,
    left: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: 'Tiền hàng',
    selector: (row) => row.t_tien_nt,
    format: (row) => numeralCustom(row.t_tien_nt).format(),
    left: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: 'Tiền thuế',
    selector: (row) => row.t_thue_nt,
    format: (row) => numeralCustom(row.t_thue_nt).format(),
    left: true,
    wrap: true,
    minWidth: '120px',
  },
  {
    name: 'Nhà cung cấp',
    selector: (row) => row.ten_kh,
    right: true,
    wrap: true,
    minWidth: '150px',
  },
];

function VATSection({
  show,
  setCollapses,
  vats = [],
  setVats = () => {},
  defaultValues,
}) {
  const [openForm, setOpenForm] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);

  const handleOpenForm = () => {
    setOpenForm(true);
  };
  const handleCloseForm = () => {
    setOpenForm(false);
    setDefaultValue(null);
  };
  const handleAddVAT = (data) => {
    const vatsClone = cloneDeep(vats);
    const index = vatsClone.findIndex((item) => item._id === data?._id);
    if (index >= 0) {
      vatsClone.splice(index, 1, data);
    } else {
      data.new = true;
      vatsClone.push(data);
    }
    setVats(vatsClone);
  };
  const handleDeleteVAT = (row) => {
    const vatsMoi = vats.filter((item) => item._id !== row?._id);
    setVats(vatsMoi);
  };
  const handleRowClicked = (row) => {
    setDefaultValue(row);
    handleOpenForm();
  };

  return (
    <>
      {openForm && (
        <FormAddVAT
          open={openForm}
          handleClose={handleCloseForm}
          addVAT={handleAddVAT}
          defaultValue={defaultValue}
          defaultValues={defaultValues}
          isEdit={!!defaultValue}
        />
      )}
      <CollapseSection
        title="VAT"
        show={show}
        onCollapse={() => setCollapses((prev) => ({ ...prev, vat: !prev.vat }))}
      >
        <Stack sx={{ width: '100%' }} spacing="10px">
          <Stack direction="row" spacing="5px" alignItems="center">
            <ButtonBase
              variant="outlined"
              startIcon={<BsPlusCircle style={{ fontSize: '14px' }} />}
              onClick={handleOpenForm}
            >
              Thêm VAT
            </ButtonBase>
          </Stack>
          <TableDisplay
            data={vats}
            columns={columns}
            onRowClicked={handleRowClicked}
            handleDelete={handleDeleteVAT}
          />
        </Stack>
      </CollapseSection>
    </>
  );
}

export default VATSection;
