import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Stack } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import moment from 'moment';
import SelectApiInput from '~/components/input/SelectApiInput';
import CheckboxInput from '~/components/input/CheckboxInput';
import DateInput from '~/components/input/DateInput';
import ModalForm from '~/components/modal/ModalForm';

const schema = yup.object({
  ma_lo: yup.string().required('Vui lòng nhập mã lô'),
  han_sd: yup
    .date()
    .typeError('Vui lòng nhập hạn sử dụng')
    .required('Vui lòng nhập hạn sử dụng'),
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
});
const defaultData = {
  ma_lo: '',
  ten_lo: '',
  vat_tu: null,
  han_sd: '',
  status: true,
};

export default function FormLo({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : { ma_vt: '', ten_vt: '' },
        han_sd: moment(defaultValues.han_sd).format('YYYY-MM-DD'),
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { vat_tu, ...fields } = values;
    return {
      ...fields,
      ten_lo: fields.ma_lo || 'ten_lo',
      ma_vt: vat_tu?.ma_vt || '',
      ten_vt: vat_tu?.ten_vt || '',
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmlo',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="700px"
        title="lô"
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Grid container spacing="20px">
          <Grid item xs={12} md={6}>
            <Stack spacing="20px">
              <TextInput
                required
                labelWidth="30%"
                readOnly={isEdit}
                label="Số lô"
                placeholder="VD: L0001"
                name="ma_lo"
                register={register}
                errorMessage={errors?.ma_lo?.message}
              />
              <Controller
                control={control}
                name="han_sd"
                render={({ field: { onChange, value } }) => (
                  <DateInput
                    required
                    label="Hạn sử dụng"
                    labelWidth="30%"
                    value={value}
                    onChange={onChange}
                    errorMessage={errors?.han_sd?.message}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing="20px">
              <Controller
                control={control}
                name="vat_tu"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    required
                    readOnly={isEdit}
                    label="Hàng hóa"
                    apiCode="dmvt"
                    placeholder="Hàng hóa theo lô"
                    searchFileds={['ma_vt', 'ten_vt']}
                    getOptionLabel={(option) => option.ten_vt}
                    selectedValue={value}
                    value={value || { ma_vt: '', ten_vt: '' }}
                    onSelect={onChange}
                    condition={{ status: true, ma_lo_yn: true }}
                    errorMessage={errors?.vat_tu?.message}
                  />
                )}
              />
              {isEdit && (
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value } }) => (
                    <CheckboxInput
                      label="Còn sử dụng"
                      name="status"
                      checked={value || false}
                      onChange={onChange}
                    />
                  )}
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </ModalForm>
    </>
  );
}
