import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';

function ChitietSection({ show, setCollapses, control, loaiChietKhau }) {
  return (
    <CollapseSection
      title="Chi tiết chiết khấu"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, chi_tiet: !prev.chi_tiet }))
      }
    >
      <Grid container spacing="20px">
        {loaiChietKhau?.value === 2 && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="vat_tu"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Hàng hóa"
                  apiCode="dmvt"
                  placeholder="Chọn hàng hóa"
                  searchFileds={['ma_vt', 'ten_vt']}
                  getOptionLabel={(option) => option.ten_vt}
                  selectedValue={value}
                  value={value || { ma_vt: '', ten_vt: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
        )}
        {loaiChietKhau?.value === 2 && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="nhom_vat_tu"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Nhóm hàng"
                  apiCode="dmnvt"
                  placeholder="Chọn nhóm hàng hóa"
                  searchFileds={['ten_nvt']}
                  getOptionLabel={(option) => option.ten_nvt}
                  selectedValue={value}
                  value={value || { _id: '', ten_nvt: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="khach_hang"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Khách hàng"
                apiCode="customer"
                placeholder="Chọn khách hàng"
                searchFileds={['ma_kh', 'ten_kh']}
                condition={{ kh_yn: true, ncc_yn: { $ne: true } }}
                getOptionLabel={(option) => option.ten_kh}
                selectedValue={value}
                value={value || { ma_kh: '', ten_kh: '' }}
                onSelect={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="nhom_khach"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Nhóm khách hàng"
                apiCode="dmnhkh"
                placeholder="Chọn nhóm khách hàng"
                searchFileds={['group_name']}
                condition={{ isvendor: { $ne: true } }}
                getOptionLabel={(option) => option.group_name}
                selectedValue={value}
                value={value || { _id: '', group_name: '' }}
                onSelect={onChange}
              />
            )}
          />
        </Grid>
        {loaiChietKhau?.value === 2 && (
          <Grid item xs={12} md={6}>
            <Controller
              name="sl_tu"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Số lượng từ"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid>
        )}
        {loaiChietKhau?.value === 2 && (
          <Grid item xs={12} md={6}>
            <Controller
              name="sl_den"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Số lượng đến"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            name="ty_le_ck"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tỷ lệ CK"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      sx={{
                        fontSize: '14px',
                        marginLeft: '10px',
                        color: 'gray',
                      }}
                    >
                      %
                    </Typography>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tien_ck"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tiền CK"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      sx={{
                        fontSize: '14px',
                        marginLeft: '10px',
                        color: 'gray',
                      }}
                    >
                      VND
                    </Typography>
                  ),
                }}
              />
            )}
          />
        </Grid>
        {loaiChietKhau?.value === 1 && (
          <Grid item xs={12} md={6}>
            <Controller
              name="gt_hd_tu"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Giá trị đơn từ"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          marginLeft: '10px',
                          color: 'gray',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}
        {loaiChietKhau?.value === 1 && (
          <Grid item xs={12} md={6}>
            <Controller
              name="gt_hd_den"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Giá trị đơn đến"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '14px',
                          marginLeft: '10px',
                          color: 'gray',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            name="ap_dung_lan_mua_tu_thu"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Từ đơn thứ"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="ap_dung_lan_mua_den_thu"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Đến đơn thứ"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default ChitietSection;
