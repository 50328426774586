import React, { useEffect, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { BsPlusCircle } from 'react-icons/bs';
import ButtonBase from '~/components/button/ButtonBase';
import CollapseSection from '~/components/collapse/CollapseSection';
import { IoMdTrash } from 'react-icons/io';
import TableDisplay from '~/components/table/TableDisplay';
import FormAddTonDayKy from './FormAddTonDauKy';
import { v4 } from 'uuid';
import { LABEL_KHO } from '~/utils/label.constant';
import { formatDateDisplay } from '~/utils/helpers';
import { cloneDeep } from 'lodash';

const columns = [
  {
    name: 'Năm',
    width: '80px',
    selector: (row) => row.nam,
  },
  {
    name: 'Số lô',
    width: '140px',
    wrap: true,
    selector: (row) => row.ma_lo,
  },
  {
    name: 'Hạn dùng',
    wrap: true,
    selector: (row) => row.han_su_dung,
    format: (row) => formatDateDisplay(row.han_su_dung),
  },
  {
    name: LABEL_KHO,
    wrap: true,
    selector: (row) => row.ten_kho,
  },
  {
    name: 'Tồn đầu kỳ',
    wrap: true,
    selector: (row) => row.ton00,
  },
  {
    name: 'Dư đầu kỳ',
    wrap: true,
    selector: (row) => row.du00,
  },
];

function TonDauKySection({ show, setCollapses, data, setData }) {
  const [openForm, setOpenForm] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleClear, setToggleClear] = useState(false);

  const addTonDauKy = (tonDauKy, isEdit) => {
    if (isEdit) {
      const index = data.findIndex((item) => item._id === tonDauKy._id);
      setData((prev) => {
        prev.splice(index, 1, tonDauKy);
        return prev;
      });
    } else {
      setData([...data, { ...tonDauKy, _id: v4() }]);
    }
  };

  // handle row clicked
  const handleRowClicked = (data) => {
    setDefaultValue(data);
    setIsEdit(true);
    setOpenForm(true);
  };

  // handleSelectRowsChange
  const handleSelectRowsChange = ({ selectedRows: _selectedRows }) => {
    setSelectedRows(_selectedRows);
  };

  // handle delete
  const handleDelete = () => {
    if (!selectedRows || selectedRows.length <= 0) return;
    const ids = selectedRows.map((item) => item._id);
    let dataCopy = cloneDeep(data);
    dataCopy = dataCopy.filter((item) => !ids.includes(item._id));
    setData(dataCopy);
  };

  useEffect(() => {
    setSelectedRows([]);
    setToggleClear((prev) => !prev);
  }, [data]);

  return (
    <>
      {openForm && (
        <FormAddTonDayKy
          open={openForm}
          handleClose={() => {
            setOpenForm(false);
            setIsEdit(false);
            setDefaultValue(null);
          }}
          isEdit={isEdit}
          addTonDauKy={addTonDauKy}
          defaultValue={defaultValue}
        />
      )}
      <CollapseSection
        title="Tồn đầu kỳ"
        show={show}
        onCollapse={() =>
          setCollapses((prev) => ({ ...prev, cdvt: !prev.cdvt }))
        }
      >
        <Stack spacing="5px" alignItems="flex-start">
          <Stack direction="row" alignItems="center" spacing="5px">
            <ButtonBase
              variant="outlined"
              startIcon={<BsPlusCircle style={{ fontSize: '14px' }} />}
              onClick={() => {
                setOpenForm(true);
              }}
            >
              Thêm dòng
            </ButtonBase>
            {!!selectedRows && selectedRows.length > 0 && (
              <IconButton
                sx={{
                  backgroundColor: 'error.main',
                  color: 'whitish.pureWhite',
                  borderRadius: '4px',
                  width: '28px',
                  height: '28px',
                  '&:hover': {
                    backgroundColor: 'error.main',
                  },
                }}
                onClick={handleDelete}
              >
                <IoMdTrash size={14} />
              </IconButton>
            )}
          </Stack>
          {data && Array.isArray(data) && data.length > 0 && (
            <TableDisplay
              title="tồn đầu kỳ"
              columns={columns}
              data={data || []}
              onRowClicked={handleRowClicked}
              handleDelete={handleDelete}
              selectableRows
              onSelectedRowsChange={handleSelectRowsChange}
              clearSelectedRows={toggleClear}
            />
          )}
        </Stack>
      </CollapseSection>
    </>
  );
}

export default TonDauKySection;
