import { Box, Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';

function DiaChiSection({
  show,
  setCollapses,
  register,
  control,
  setValue,
  tinhThanh,
  quanHuyen,
}) {
  return (
    <CollapseSection
      title="Địa chỉ (khu vực)"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, dia_chi: !prev.dia_chi }))
      }
    >
      <Box>
        <Grid container spacing="20px">
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tinh_thanh_obj"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Tỉnh (Khu vực)"
                  apiCode="tinhthanh"
                  placeholder="Chọn tỉnh / thành phố"
                  searchFileds={['ten_tinh_thanh']}
                  getOptionLabel={(option) => option.ten_tinh_thanh}
                  selectedValue={value}
                  value={value || { ma_tinh_thanh: '', ten_tinh_thanh: '' }}
                  onSelect={(newValue) => {
                    setValue('quan_huyen_obj', null);
                    setValue('xa_phuong_obj', null);
                    onChange(newValue);
                  }}
                  withIdApp={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="quan_huyen_obj"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  disabled={!tinhThanh}
                  labelWidth="30%"
                  label="Quận / huyện"
                  apiCode="quanhuyen"
                  placeholder="Chọn quận / huyện"
                  searchFileds={['ten_quan_huyen']}
                  condition={{ ma_tinh_thanh: tinhThanh?.ma_tinh_thanh }}
                  getOptionLabel={(option) => option.ten_quan_huyen}
                  selectedValue={value}
                  value={value || { ma_quan_huyen: '', ten_quan_huyen: '' }}
                  onSelect={(newValue) => {
                    setValue('xa_phuong_obj', null);
                    onChange(newValue);
                  }}
                  withIdApp={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="xa_phuong_obj"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  disabled={!quanHuyen}
                  labelWidth="30%"
                  label="Xã / phường"
                  apiCode="xaphuong"
                  placeholder="Chọn xã / phường"
                  searchFileds={['ten_xa_phuong']}
                  condition={{ ma_quan_huyen: quanHuyen?.ma_quan_huyen }}
                  getOptionLabel={(option) => option.ten_xa_phuong}
                  selectedValue={value}
                  value={value || { ma_xa_phuong: '', ten_xa_phuong: '' }}
                  onSelect={onChange}
                  withIdApp={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="Địa chỉ cụ thể"
              placeholder="số 5, đường 100A, ấp An Thuận"
              name="dia_chi"
              register={register}
            />
          </Grid>
        </Grid>
      </Box>
    </CollapseSection>
  );
}

export default DiaChiSection;
