import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import SelectApiInput from '~/components/input/SelectApiInput';
import ButtonBase from '~/components/button/ButtonBase';
import { v4 } from 'uuid';
import { FiSave } from 'react-icons/fi';

const schema = yup.object({
  ma_cn: yup.string().required('Vui lòng nhập mã chức năng'),
  mau_in: yup
    .object()
    .typeError('Vui lòng chọn mẫu in')
    .required('Vui lòng chọn mẫu in'),
});

const defaultData = {
  ma_cn: '',
  mau_in: null,
};

function FormPrinter({ open, handleClose, isEdit, addDetail, defaultValues }) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        mau_in: defaultValues.id_mau_in
          ? {
              ma_cn: defaultValues.ma_cn || '',
              id_mau_in: defaultValues.id_mau_in || '',
              ten_mau_in: defaultValues.ten_mau_in || '',
            }
          : null,
      }
    : defaultData;
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const maCN = watch('ma_cn');

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Sửa' : 'Thêm'} mẫu in`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextInput
            required
            label="Mã CN"
            labelWidth="30%"
            placeholder="Nhập mã chức năng"
            name="ma_cn"
            register={register}
            errorMessage={errors?.ma_cn?.message}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Controller
            control={control}
            name="mau_in"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                required
                disabled={!maCN}
                label="Mẫu in"
                apiCode="rpt"
                placeholder="Chọn mẫu in"
                searchFileds={['ma_cn', 'ten_mau_in']}
                condition={{ ma_cn: maCN || '' }}
                getOptionLabel={(option) =>
                  option.ma_cn ? `${option.ma_cn} - ${option.ten_mau_in}` : null
                }
                renderOption={({ key, ...optionProps }, option) => {
                  return (
                    <MenuItem key={key} {...optionProps}>
                      {option.ma_cn} - {option.ten_mau_in}
                    </MenuItem>
                  );
                }}
                selectedValue={value}
                value={value || { ma_cn: '', ten_mau_in: '' }}
                onSelect={onChange}
                errorMessage={errors?.mau_in?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormPrinter;
