import React, { useEffect, useId, useState } from 'react';
import { Stack, TextField } from '@mui/material';
import WrapperInput from './WrapperInput';
import ChangeMode from '../product-line/ChangeMode';
import { numeralCustom } from '~/utils/helpers';

function ChangeModeInput({
  name,
  label,
  errorMessage,
  required = false,
  placeholder,
  disabled = false,
  textFieldSx = {},
  register = () => {},
  defaultValue,
  textFieldProps = {},
  InputProps = {},
  labelWidth = '20%',
  labelSx = {},
  count,
  defaultTyLeCK = 0,
  defaultTienCk = 0,
  tienGoc = 0,
  onChietKhauChange = ({ ty_le_ck, tien_ck }) => {},
  ...props
}) {
  const id = useId();
  const [isPercent, setIsPercent] = useState(false);
  const [giaTriChietKhau, setGiaTriChietKhau] = useState({
    ty_le_ck: defaultTyLeCK,
    tien_ck: defaultTienCk,
  });

  const handleGiaTriChietKhauChange = (e) => {
    let value = isPercent
      ? e.target.value
      : numeralCustom(e.target.value).value();
    if (isPercent) {
      if (value > 100) {
        value = 100;
      }
      const tienCk = (tienGoc * value) / 100;
      setGiaTriChietKhau({ ty_le_ck: value, tien_ck: tienCk });
      onChietKhauChange({ ty_le_ck: value, tien_ck: tienCk });
    } else {
      if (value > tienGoc) {
        value = tienGoc;
      }
      const tyLeCk = (value * 100) / tienGoc;
      setGiaTriChietKhau({ ty_le_ck: tyLeCk, tien_ck: value });
      onChietKhauChange({ ty_le_ck: tyLeCk, tien_ck: value });
    }
  };

  useEffect(() => {
    setGiaTriChietKhau({
      ty_le_ck: defaultTyLeCK,
      tien_ck: defaultTienCk,
    });
  }, [defaultTienCk, defaultTyLeCK]);

  return (
    <WrapperInput
      id={id}
      label={label}
      labelWidth={labelWidth}
      required={required}
      errorMessage={errorMessage}
      labelSx={labelSx}
      count={count}
      {...props}
    >
      <TextField
        id={id}
        type={isPercent ? 'number' : 'text'}
        value={
          isPercent
            ? giaTriChietKhau.ty_le_ck
            : numeralCustom(giaTriChietKhau.tien_ck).format()
        }
        onChange={handleGiaTriChietKhauChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        disabled={disabled}
        name={name}
        {...register(name)}
        inputProps={{ min: 0 }}
        InputProps={{
          endAdornment: (
            <Stack
              direction="row"
              alignItems="center"
              sx={{ marginLeft: '5px' }}
            >
              <ChangeMode
                onChange={({ isPercent }) => setIsPercent(isPercent)}
              />
            </Stack>
          ),
          ...InputProps,
        }}
        fullWidth
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: 0,
          },
          '& .MuiFormLabel-root': {
            fontSize: '12px',
            fontWeight: 600,
            color: 'primary.main',
            paddingRight: '5px',
            transform: 'translate(14px, -12px)',
            backgroundColor: 'whitish.pureWhite',
          },
          '& .MuiInputBase-input': {
            fontSize: '14px',
            '&:placeholder': {
              fontSize: '14px',
            },
          },
          ...textFieldSx,
        }}
        variant="standard"
        autoComplete="off"
        {...textFieldProps}
      />
    </WrapperInput>
  );
}

export default ChangeModeInput;
