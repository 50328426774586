import React, { useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import CheckboxInput from '~/components/input/CheckboxInput';
import DatetimeInput from '~/components/input/DatetimeInput';
import { LABEL_KHO } from '~/utils/label.constant';

function InfoSection({ register, errors, control, setValue }) {
  const storeData = useSelector((state) => state.store);

  useEffect(() => {
    if (storeData && storeData.data.length === 1) {
      setValue('kho', storeData.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <TextInput
              labelWidth="30%"
              label="STT ưu tiên"
              placeholder="Số thứ tự ưu tiên"
              name="stt_uu_tien"
              register={register}
              errorMessage={errors?.stt_uu_tien?.message}
            />
            <Controller
              control={control}
              name="kho"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  labelWidth="30%"
                  label={LABEL_KHO}
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO}`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.kho?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="vat_tu"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Sản phẩm mua"
                  apiCode="dmvt"
                  placeholder="Chọn sản phẩm"
                  searchFileds={['ma_vt', 'ten_vt']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_vt}
                  selectedValue={value}
                  value={value || { ma_vt: '', ten_vt: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.vat_tu?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="nhom_vat_tu"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Nhóm hàng hóa"
                  apiCode="dmnvt"
                  placeholder="Chọn nhóm hàng hóa"
                  searchFileds={['ten_nvt']}
                  getOptionLabel={(option) => option.ten_nvt}
                  selectedValue={value}
                  value={value || { _id: '', ten_nvt: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="hieu_luc_tu"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Thời gian bắt đầu"
                  labelWidth="35%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_tu?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="hieu_luc_den"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Thời gian kết thúc"
                  labelWidth="35%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_den?.message}
                />
              )}
            />
            <TextInput
              type="number"
              labelWidth="35%"
              label="Số lượng từ"
              placeholder="Số lượng từ"
              name="sl_tu"
              register={register}
              errorMessage={errors?.sl_tu?.message}
            />
            <TextInput
              type="number"
              labelWidth="35%"
              label="Số lượng đến"
              placeholder="Số lượng đến"
              name="sl_den"
              register={register}
              errorMessage={errors?.sl_den?.message}
            />
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Phát hành"
                  name="status"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
