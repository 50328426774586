import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AdminLayout from '~/components/layouts/AdminLayout';
import ReportBase from '~/components/report/ReportBase';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import reports from '~/utils/report';

function ReportPage() {
  const showAlert = useAlertContext();
  const params = useParams();
  const [report, setReport] = useState();

  useEffect(() => {
    const reportCode = params?.report;
    const report = reports[reportCode];
    if (report) {
      setReport(report);
    } else {
      showAlert({
        type: 'error',
        message: `Loại báo cáo '${reportCode}' không tồn tại`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (!report) return;
  return (
    <AdminLayout>
      <ReportBase report={report} />
    </AdminLayout>
  );
}

export default ReportPage;
