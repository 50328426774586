import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';
import FilterRadios from '../FilterRadios';

function FilterKho({ setCondition }) {
  const originFilter = {
    kho: '',
    email: '',
    dien_thoai: '',
    dia_chi: '',
    trang_thai: 'true',
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.kho) {
      condition.$or = [
        {
          ma_kho: {
            $regex: filter.kho.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_kho: {
            $regex: filter.kho.split(' ').join('.*'),
            $options: 'i',
          },
        },
        { $text: { $search: filter.kho } },
      ];
    }
    if (filter.email) {
      condition.email = {
        $regex: filter.email.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.dien_thoai) {
      condition['exfields.dien_thoai'] = {
        $regex: filter.dien_thoai.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.dia_chi) {
      condition.dia_chi = {
        $regex: filter.dia_chi.split(' ').join('.*'),
        $options: 'i',
      };
    }
    switch (filter.trang_thai) {
      case 'true':
        condition.status = true;
        break;
      case 'false':
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Mã, tên kho"
          placeholder="Tìm theo mã hoặc tên kho"
          value={filter.kho}
          onSearch={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterSearch
          title="Email"
          placeholder="Tìm theo email"
          value={filter.email}
          onSearch={(value) => setFilter({ ...filter, email: value })}
        />
        <FilterSearch
          title="Điện thoại"
          placeholder="Tìm theo số điện thoại"
          value={filter.dien_thoai}
          onSearch={(value) => setFilter({ ...filter, dien_thoai: value })}
        />
        <FilterSearch
          title="Địa chỉ"
          placeholder="Tìm theo địa chỉ"
          value={filter.dia_chi}
          onSearch={(value) => setFilter({ ...filter, dia_chi: value })}
        />
        <FilterRadios
          title="Trạng thái"
          values={[
            { label: 'Tất cả', value: 'all' },
            { label: 'Còn hoạt động', value: 'true' },
            { label: 'Ngừng hoạt động', value: 'false' },
          ]}
          defaultValue={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterKho;
