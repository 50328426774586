import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Stack } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { numeralCustom, postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { LABEL_KHO } from '~/utils/label.constant';
import moment from 'moment';
import SelectApiInput from '~/components/input/SelectApiInput';
import FormLo from '../lo/FormLo';

const baseSchema = {
  nam: yup.string().required('Vui lòng nhập năm đầu kỳ'),
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  kho: yup
    .object()
    .typeError(`Vui lòng chọn ${LABEL_KHO}`)
    .required(`Vui lòng chọn ${LABEL_KHO}`),
  ton00: yup
    .number()
    .typeError('Vui lòng nhâp tồn đầu kỳ')
    .required('Vui lòng nhâp tồn đầu kỳ'),
};

const defaultData = {
  nam: moment().year(),
  vat_tu: null,
  lo: null,
  kho: null,
  ton00: 0,
  du00: 0,
};

export default function FormCDVT({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? {
              ma_vt: defaultValues.ma_vt,
              ten_vt: defaultValues.ten_vt,
              ma_lo_yn: !!defaultValues.ma_lo,
            }
          : null,
        lo: defaultValues.ma_lo ? { ma_lo: defaultValues.ma_lo } : null,
        kho: defaultValues.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const [schema, setSchema] = useState(yup.object(baseSchema));
  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const vatTu = watch('vat_tu');

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { vat_tu, lo, kho, ...fields } = values;
    return {
      ...fields,
      ma_vt: vat_tu?.ma_vt || '',
      ten_vt: vat_tu?.ten_vt || '',
      ma_lo: lo?.ma_lo || '',
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'cdvt',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    if (vatTu?.ma_lo_yn) {
      setSchema(
        yup.object({
          ...baseSchema,
          lo: yup
            .object()
            .typeError('Vui lòng chọn lô')
            .required('Vui lòng chọn lô'),
        })
      );
    } else {
      setSchema(yup.object(baseSchema));
    }
  }, [vatTu]);
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="800px"
      title="hàng tồn đầu kỳ"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="kho"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label={LABEL_KHO}
                  required
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO}`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.kho?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="vat_tu"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Hàng hóa"
                  required
                  apiCode="dmvt"
                  placeholder="Chọn hàng hóa"
                  searchFileds={['ma_vt', 'ten_vt']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_vt}
                  selectedValue={value}
                  value={value || { ma_vt: '', ten_vt: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.vat_tu?.message}
                />
              )}
            />
            {vatTu?.ma_lo_yn && (
              <Controller
                control={control}
                name="lo"
                render={({ field: { onChange, value } }) => (
                  <SelectApiInput
                    labelWidth="30%"
                    label="Lô - HSD"
                    required
                    apiCode="dmlo"
                    placeholder="Chọn lô hàng hóa"
                    searchFileds={['ma_lo']}
                    condition={{ status: true, ma_vt: vatTu?.ma_vt }}
                    getOptionLabel={(option) => option.ma_lo}
                    selectedValue={value}
                    value={value || { ma_lo: '' }}
                    onSelect={onChange}
                    errorMessage={errors?.lo?.message}
                    FormAdd={FormLo}
                  />
                )}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              label="Năm đầu kỳ"
              labelWidth="30%"
              placeholder="Nhập năm đầu kỳ"
              name="nam"
              required
              register={register}
              errorMessage={errors?.nam?.message}
            />
            <TextInput
              type="number"
              label="Tồn đầu kỳ"
              labelWidth="30%"
              placeholder="Nhập tồn kho hàng hóa"
              name="ton00"
              required
              register={register}
              errorMessage={errors?.ton00?.message}
            />
            <Controller
              control={control}
              name="du00"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Giá trị tồn"
                  placeholder="Nhập giá trị tồn kho đầu kỳ"
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </ModalForm>
  );
}
