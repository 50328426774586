import { Avatar, Typography } from '@mui/material';
import React from 'react';
import ListBase from '~/components/listBase/ListBase';
import ModalBase from '~/components/modal/ModalBase';
import {
  formatDateDisplay,
  generateLinkImage,
  numeralCustom,
} from '~/utils/helpers';
import ProductImage from '~/assets/img/product.png';

const columns = [
  {
    name: '',
    selector: (row) => row.picture,
    cell: (row) => {
      return (
        <Avatar
          src={row.picture ? generateLinkImage(row.picture) : ProductImage}
          imgProps={{ loading: 'lazy' }}
          sx={{
            width: '50px',
            height: '50px',
            borderRadius: '10px',
          }}
        />
      );
    },
    width: '80px',
  },
  {
    name: 'Hàng hóa',
    selector: (row) => row.ten_vt,
    cell: (row) => {
      return (
        <Typography>
          {row.ten_vt} ({row.ma_vt})
        </Typography>
      );
    },
    width: '200px',
  },
  {
    name: 'Đơn vị tính',
    selector: (row) => row.exfields?.ten_dvt,
    width: '100px',
  },
  {
    name: 'Đơn giá',
    selector: (row) => row.gia_ban_nt,
    format: (row) => numeralCustom(row.gia_ban_nt).format(),
    width: '100px',
  },
  {
    name: 'Số lượng',
    selector: (row) => row.sl_xuat,
    format: (row) => numeralCustom(row.sl_xuat).format(),
    width: '100px',
    center: true,
  },
  {
    name: 'Tiền hàng',
    selector: (row) => row.sl_xuat * row.gia_ban_nt,
    format: (row) => numeralCustom(row.sl_xuat * row.gia_ban_nt).format(),
    width: '100px',
    center: true,
  },
  {
    name: 'Ngày cập nhật',
    selector: (row) => row.date_updated,
    format: (row) => formatDateDisplay(row.date_updated, 'DD/MM/YYYY HH:mm'),
    width: '140px',
  },
  {
    name: 'Tình trạng',
    selector: (row) => (row.status ? 'Đã chọn' : 'Chưa chọn'),
    width: '100px',
  },
];

function ModalList({ open, onClose, defaultCondition = {} }) {
  return (
    <ModalBase
      open={open}
      handleClose={onClose}
      title="Giỏ hàng"
      width="1200px"
    >
      <ListBase
        onlyList
        apiCode="cart2"
        title="giỏ hàng"
        columns={columns}
        showColumns={[0, 1, 2, 3, 4, 5, 6, 7]}
        defaultCondition={defaultCondition}
      />
    </ModalBase>
  );
}

export default ModalList;
