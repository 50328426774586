import React, { useEffect, useState } from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import { cloneDeep } from 'lodash';
import FormAddChiPhi from './FormAddChiPhi';
import { numeralCustom } from '~/utils/helpers';
import { IconButton, Stack } from '@mui/material';
import ButtonBase from '~/components/button/ButtonBase';
import TableDisplay from '~/components/table/TableDisplay';
import { BsPlusCircle } from 'react-icons/bs';
import { IoMdTrash } from 'react-icons/io';
import { KIEU_PHAN_BO } from '~/utils/constants';

const columns = [
  {
    name: 'Tên chi phí',
    selector: (row) => row.ten_cp,
    left: true,
    wrap: true,
  },
  {
    name: 'Tiền chi phí',
    selector: (row) => row.tien_cp_nt,
    format: (row) => numeralCustom(row.tien_cp_nt).format(),
    center: true,
  },
  {
    name: 'Kiểu phân bổ',
    selector: (row) => {
      const kieuPb = KIEU_PHAN_BO.find((item) => item.value === row.kieu_pb);
      return kieuPb?.name || row.kieu_pb;
    },
    right: true,
  },
];

function ChiPhiSection({ show, setCollapses, chiphis, setChiphis }) {
  const [openForm, setOpenForm] = useState(false);
  const [defaultValue, setDefaultValue] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleClear, setToggleClear] = useState(false);

  const handleOpenForm = () => {
    setOpenForm(true);
  };
  const handleCloseForm = () => {
    setOpenForm(false);
    setDefaultValue(null);
  };

  const handleAddChiphi = (data) => {
    const chiphisClone = cloneDeep(chiphis);
    const index = chiphisClone.findIndex((item) => item.ma_cp === data.ma_cp);
    if (index >= 0) {
      chiphisClone.splice(index, 1, data);
    } else {
      chiphisClone.push(data);
    }
    setChiphis(chiphisClone);
  };

  // handleSelectRowsChange
  const handleSelectRowsChange = ({ selectedRows: _selectedRows }) => {
    setSelectedRows(_selectedRows);
  };

  const handleDeleteChiphi = (row) => {
    if (!selectedRows || selectedRows.length <= 0) return;
    const ids = selectedRows.map((item) => item.ma_cp);
    let chiphisCopy = cloneDeep(chiphis);
    chiphisCopy = chiphisCopy.filter((item) => !ids.includes(item.ma_cp));
    setChiphis(chiphisCopy);
  };

  const handleRowClicked = (row) => {
    setDefaultValue(row);
    handleOpenForm();
  };

  useEffect(() => {
    setSelectedRows([]);
    setToggleClear((prev) => !prev);
  }, [chiphis]);

  return (
    <>
      {openForm && (
        <FormAddChiPhi
          open={openForm}
          handleClose={handleCloseForm}
          addChiphi={handleAddChiphi}
          defaultValue={defaultValue}
        />
      )}
      <CollapseSection
        title="Chi phí mua hàng"
        show={show}
        onCollapse={() =>
          setCollapses((prev) => ({ ...prev, chiphi: !prev.chiphi }))
        }
      >
        <Stack sx={{ width: '100%' }} spacing="10px">
          <Stack direction="row" spacing="5px" alignItems="center">
            <ButtonBase
              variant="outlined"
              startIcon={<BsPlusCircle style={{ fontSize: '14px' }} />}
              onClick={handleOpenForm}
            >
              Thêm chi phí
            </ButtonBase>
            {!!selectedRows && selectedRows.length > 0 && (
              <IconButton
                sx={{
                  backgroundColor: 'error.main',
                  color: 'whitish.pureWhite',
                  borderRadius: '4px',
                  width: '28px',
                  height: '28px',
                  '&:hover': {
                    backgroundColor: 'error.main',
                  },
                }}
                onClick={handleDeleteChiphi}
              >
                <IoMdTrash size={14} />
              </IconButton>
            )}
          </Stack>
          <TableDisplay
            data={chiphis}
            columns={columns}
            onRowClicked={handleRowClicked}
            handleDelete={handleDeleteChiphi}
            selectableRows
            onSelectedRowsChange={handleSelectRowsChange}
            clearSelectedRows={toggleClear}
          />
        </Stack>
      </CollapseSection>
    </>
  );
}

export default ChiPhiSection;
