import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterSelectApi from '../FilterSelectApi';
import FilterContainer from '../FilterContainer';
import FilterRadios from '../FilterRadios';
import moment from 'moment';
import { LABEL_KHO } from '~/utils/label.constant';

const trangThais = [
  {
    label: 'Tất cả',
    value: '0',
  },
  {
    label: 'Đang phát hành',
    value: '1',
  },
  {
    label: 'Ngưng phát hành',
    value: '2',
  },
];
const hieuLucs = [
  {
    label: 'Chưa đến ngày',
    value: 'pending',
  },
  {
    label: 'Đang áp dụng',
    value: 'active',
  },
  {
    label: 'Hết hiệu lực',
    value: 'inactive',
  },
];

function FilterGiaBan({ setCondition }) {
  const originFilter = {
    kho: null,
    vat_tu: null,
    khach_hang: null,
    nhom_khach: null,
    phat_hanh: 0,
    hieu_luc: 'active',
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.vat_tu) {
      condition.ma_vt = filter.vat_tu.ma_vt;
    }
    if (filter.khach_hang) {
      condition.ma_kh = filter.khach_hang.ma_kh;
    }
    if (filter.nhom_khach) {
      condition.nh_kh = filter.nhom_khach._id;
    }
    const today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
    switch (filter.hieu_luc) {
      case 'pending':
        condition.hieu_luc_tu = { $gt: today };
        break;
      case 'inactive':
        condition.hieu_luc_den = { $lt: today };
        break;
      default: // acitve
        condition.hieu_luc_tu = {
          $lte: today,
        };
        condition.hieu_luc_den = {
          $gte: today,
        };
        break;
    }
    switch (filter.phat_hanh) {
      case '1':
        condition.status = true;
        break;
      case '2':
        condition.status = false;
        break;
      default:
        break;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSelectApi
          title={LABEL_KHO}
          apiCode="dmkho"
          placeholder={`Chọn ${LABEL_KHO}`}
          selectedValue={filter.kho}
          value={filter.kho || { ma_kho: '', ten_kho: '' }}
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) => option.ten_kho}
          onSelect={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterSelectApi
          title="Hàng Hóa"
          apiCode="dmvt"
          placeholder="Chọn hàng hóa"
          selectedValue={filter.vat_tu}
          value={filter.vat_tu || { ma_vt: '', ten_vt: '' }}
          searchFileds={['ma_vt', 'ten_vt']}
          getOptionLabel={(option) => option.ten_vt}
          onSelect={(value) => setFilter({ ...filter, vat_tu: value })}
        />
        <FilterSelectApi
          title="Khách hàng"
          apiCode="customer"
          placeholder="Chọn khách hàng"
          selectedValue={filter.khach_hang}
          value={filter.khach_hang || { ma_kh: '', ten_kh: '' }}
          searchFileds={['ma_kh', 'ten_kh']}
          condition={{ kh_yn: true, ncc_yn: { $ne: true } }}
          getOptionLabel={(option) =>
            option.ten_kh
              ? `${option.ten_kh} ${!!option.ma_kh ? `- ${option.ma_kh}` : ''}`
              : ''
          }
          onSelect={(value) => setFilter({ ...filter, khach_hang: value })}
        />
        <FilterSelectApi
          title="Nhóm khách"
          apiCode="dmnhkh"
          placeholder="Chọn nhóm khách"
          selectedValue={filter.nhom_khach}
          value={filter.nhom_khach || { _id: '', group_name: '' }}
          searchFileds={['group_name']}
          condition={{ isvendor: { $ne: true } }}
          getOptionLabel={(option) => option.group_name}
          onSelect={(value) => setFilter({ ...filter, nhom_khach: value })}
        />
        <FilterRadios
          values={hieuLucs}
          defaultValue={filter.hieu_luc}
          title="Thời gian áp dụng"
          onChange={(val) => setFilter({ ...filter, hieu_luc: val })}
        />
        <FilterRadios
          values={trangThais}
          defaultValue={filter.phat_hanh}
          title="Trạng thái"
          onChange={(val) => setFilter({ ...filter, phat_hanh: val })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterGiaBan;
