import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Badge,
  Button,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { BsTrash } from 'react-icons/bs';
import { PiTextColumns } from 'react-icons/pi';
import { SiMicrosoftexcel } from 'react-icons/si';
import { TbTableExport } from 'react-icons/tb';
import ButtonBase from '~/components/button/ButtonBase';
import ListLayout from '~/components/layouts/ListLayout';
import ModalColumns from '~/components/modal/ModalColumns';
import ModalExportExcel from '~/components/modal/ModalExportExcel';
import ModalImportExcel from '~/components/modal/ModalImportExcel';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import MenuHover from '~/components/menu/MenuHover';
import ChangeStatusInput from '~/components/input/ChangeStatusInput';
import FilterHD2 from '~/components/filter/hd2/FilterHD2';
import { LABEL_KHO } from '~/utils/label.constant';
import { Link } from 'react-router-dom';
import useAuthorize from '~/hooks/useAuthorize';
import ExpandPBL from '~/components/expand-row/ExpandPBL';

const apiCode = 'pbl';
const title = 'phiếu bản lẻ';

function ListPBLPage() {
  const { asyncSearchList } = useApisContext();
  const allowAuthorize = useAuthorize();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(1);
  const [loading, setLoading] = useState(false);
  const [condition, setCondition] = useState({ date_created: 1 });
  const [showColumnsState, setShowColumnsState] = useState([
    0, 1, 3, 4, 5, 6, 7
  ]);
  const [openExcel, setOpenExcel] = useState(false);
  const [openModalColumns, setOpenModalColumns] = useState(false);
  const [opentExportExcel, setOpenExportExcel] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationOption, setPaginationOption] = useState({
    limit: 10,
    page: 1,
    totalRows: 0,
  });
  const btnDeleteRef = useRef();
  const renderCountRef = useRef(1);

  const columns = [
    {
      name: 'Số CT',
      selector: (row) => row.so_ct,
      width: '80px',
    },
    {
      name: 'Ngày CT',
      selector: (row) => row.ngay_ct,
      format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
      width: '140px',
    },
    {
      name: LABEL_KHO,
      selector: (row) => row.ten_kho,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Khách hàng',
      selector: (row) => row.ten_kh,
      center: true,
      wrap: true,
      width: '120px',
    },
    {
      name: 'Nhân viên',
      selector: (row) => row.ten_nv,
      width: '120px',
      wrap: true,
      center: true,
    },
    {
      name: 'Tổng tiền hàng',
      selector: (row) => row.t_tien_nt,
      format: (row) => numeralCustom(row.t_tien_nt).format(),
      center: true,
      width: '120px',
    },
    {
      name: 'Tổng CK',
      selector: (row) => row.t_ck_nt,
      format: (row) => numeralCustom(row.t_ck_nt).format(),
      center: true,
      width: '120px',
    },
    {
      name: 'Thành tiền',
      selector: (row) => row.t_tt_nt,
      format: (row) => numeralCustom(row.t_tt_nt).format(),
      center: true,
      width: '120px',
    },
    {
      name: 'Phương thức thanh toán',
      selector: (row) => row.ten_pt_thanh_toan,
      width: '180px',
      right: true,
      wrap: true,
    },
  ];

  // row per page change
  const handleRowPerPageChange = (value) => {
    setPaginationOption({ ...paginationOption, page: 1, limit: value });
  };

  // get products
  const getListData = async () => {
    if (loading) return;
    setLoading(true);
    const resp = await asyncSearchList({
      apiCode,
      condition: {
        limit: paginationOption.limit,
        page: paginationOption.page,
        q: condition,
      },
    });
    const respCount = await asyncSearchList({
      apiCode,
      condition: {
        count: 1,
        q: condition,
      },
    });
    if (respCount) {
      setPaginationOption({
        ...paginationOption,
        totalRows: respCount.rows_number,
      });
    }
    if (resp) {
      setData(resp);
    }
    setLoading(false);
  };

  const exportToExcel = async () => {
    setOpenExportExcel(true);
  };

  // handle delete
  const handleDelete = () => {
    btnDeleteRef?.current?.click();
  };
  // handle clear select
  const handleClearSelect = () => {
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const currentColumns = useMemo(() => {
    if (!showColumnsState) return [];
    const result = (columns || []).reduce((acc, col, index) => {
      if ((showColumnsState || []).includes(index)) {
        return [...acc, col];
      } else {
        return acc;
      }
    }, []);
    result.unshift({
      name: 'Trạng thái',
      selector: (row) => row.ten_trang_thai,
      cell: (row) => {
        return (
          <ChangeStatusInput
            apiCode={apiCode}
            data={row}
            maCt={apiCode}
            showReturned={true}
            setLoad={setLoad}
            apiCodeReturn="hd7"
            linkReturn="/list/hd7"
          />
        );
      },
      width: '120px',
      wrap: true,
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showColumnsState]);

  useEffect(() => {
    if (renderCountRef.current > 1) {
      getListData();
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    }
    renderCountRef.current += 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOption.limit, paginationOption.page, load]);

  useEffect(() => {
    setPaginationOption({ ...paginationOption, page: 1 });
    setLoad(load + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return (
    <>
      <ListLayout
        ref={btnDeleteRef}
        title={title}
        apiCode={apiCode}
        columns={currentColumns}
        data={data}
        getListData={getListData}
        expandableRows
        expandOnRowClicked
        expandableRowsComponent={({ data }) => {
          return <ExpandPBL data={data} setLoad={setLoad} />;
        }}
        expandableRowsHideExpander
        handleRowPerPageChange={handleRowPerPageChange}
        loading={loading}
        paginationOption={paginationOption}
        renderOuter={() => (
          <>
            <ModalColumns
              open={openModalColumns}
              onClose={() => setOpenModalColumns(false)}
              columns={columns}
              showColumns={showColumnsState}
              setShowColumns={setShowColumnsState}
            />
            {openExcel && (
              <ModalImportExcel
                open={openExcel}
                handleClose={() => setOpenExcel(false)}
                apiCode={apiCode}
                setLoad={setLoad}
              />
            )}
            {opentExportExcel && (
              <ModalExportExcel
                open={opentExportExcel}
                handleClose={() => setOpenExportExcel(false)}
                apiCode={apiCode}
              />
            )}
          </>
        )}
        renderFilter={() => <FilterHD2 setCondition={setCondition} />}
        renderHeaderList={() => (
          <>
            {selectedRows.length > 0 && (
              <Badge
                badgeContent={selectedRows.length}
                color="secondary"
                sx={{
                  '& .MuiBadge-badge': {
                    right: 8,
                    top: 2,
                    border: `2px solid #fff`,
                    padding: '0 4px',
                  },
                }}
              >
                <MenuHover
                  content={
                    <Stack sx={{ minWidth: '150px' }}>
                      {allowAuthorize({ type: 'view', module: apiCode }) && (
                        <MenuItem sx={{ columnGap: '10px' }}>
                          <TbTableExport size={14} />
                          <Typography sx={{ fontSize: '14px' }}>
                            Xuất file
                          </Typography>
                        </MenuItem>
                      )}
                      <MenuItem
                        sx={{ columnGap: '10px' }}
                        onClick={handleClearSelect}
                      >
                        <MdClose size={14} />
                        <Typography sx={{ fontSize: '14px' }}>
                          Bỏ chọn tất cả
                        </Typography>
                      </MenuItem>
                      {allowAuthorize({ type: 'delete', module: apiCode }) && (
                        <MenuItem
                          sx={{ columnGap: '10px', color: 'error.main' }}
                          onClick={handleDelete}
                        >
                          <BsTrash size={14} />
                          <Typography
                            sx={{ fontSize: '14px', color: 'error.main' }}
                          >
                            Xóa bỏ
                          </Typography>
                        </MenuItem>
                      )}
                    </Stack>
                  }
                >
                  <Button
                    variant="contained"
                    sx={{
                      height: '30px',
                      textTransform: 'none',
                      color: 'whitish.pureWhite',
                    }}
                  >
                    Thao tác
                  </Button>
                </MenuHover>
              </Badge>
            )}
            {allowAuthorize({ type: 'add', module: apiCode }) && (
              <ButtonBase
                variant="contained"
                startIcon={<AiOutlinePlusCircle size={16} />}
                LinkComponent={Link}
                to="/order"
                target="_blank"
                sx={{ color: 'whitish.pureWhite', textTransform: 'none' }}
              >
                Tạo hóa đơn
              </ButtonBase>
            )}
            {allowAuthorize({ type: 'add', module: apiCode }) && (
              <ButtonBase
                startIcon={<SiMicrosoftexcel fontSize="14px" />}
                onClick={() => setOpenExcel(true)}
              >
                Nhập excel
              </ButtonBase>
            )}
            {allowAuthorize({ type: 'view', module: apiCode }) && (
              <ButtonBase
                startIcon={<TbTableExport fontSize="14px" />}
                onClick={exportToExcel}
              >
                Xuất excel
              </ButtonBase>
            )}
            <IconButton
              sx={{
                width: '30px',
                height: '30px',
                backgroundColor: 'primary.main',
                color: 'whitish.pureWhite',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
              onClick={() => setOpenModalColumns(true)}
            >
              <PiTextColumns size={14} />
            </IconButton>
          </>
        )}
        setPaginationOption={setPaginationOption}
        setSelectedRows={setSelectedRows}
        setToggleCleared={setToggleCleared}
        toggleCleared={toggleCleared}
      />
    </>
  );
}

export default ListPBLPage;
