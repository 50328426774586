import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';
import useToken from '~/hooks/useToken';
import { getProfile, getRights, getUser } from '~/redux/actions/auth.action';
import { getProductGroups } from '~/redux/actions/productGroup.action';
import { getPttt } from '~/redux/actions/pttt.action';
import { getStores } from '~/redux/actions/store.action';
import { getUserGroups } from '~/redux/actions/usergroup.action';

export default function PrivateRoute({ children }) {
  const token = useToken();
  const dataLogin = useSelector((state) => state.auth.login);
  const profileData = useSelector((state) => state.auth.profile);
  const userData = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [, setOpenBackdrop] = useBackdropContext();
  const showAlert = useAlertContext();
  const { asyncSearchList } = useApisContext();

  const fetchData = async () => {
    setOpenBackdrop(true);
    await getStores({ asyncSearchList, showAlert, dispatch });
    await getProductGroups({ asyncSearchList, showAlert, dispatch });
    await getPttt({ asyncSearchList, showAlert, dispatch });
    await getProfile({ dispatch, token, showAlert });
    await getUserGroups({ asyncSearchList, dispatch, showAlert });
    setOpenBackdrop(false);
  };

  useEffect(() => {
    if (navigator.onLine && dataLogin?.data?.token) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLogin?.data?.token]);

  useEffect(() => {
    if (profileData?.email) {
      getUser({
        asyncSearchList,
        dispatch,
        showAlert,
        email: profileData.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  useEffect(() => {
    if (userData?.group_id) {
      getRights({
        asyncSearchList,
        dispatch,
        showAlert,
        group_id: userData.group_id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  if (!dataLogin?.data?.token) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}
