import React from 'react';
import { Stack } from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import ImageInput from '~/components/input/ImageInput';
import useLinkImage from '~/hooks/useLinkImage';

function BannerSection({ show, setCollapses, setThumbnails, defaultValues }) {
  const generateLinkImage = useLinkImage();

  const handleFileChange = (key, file) => {
    setThumbnails((prev) => {
      return { ...prev, [key]: file };
    });
  };

  return (
    <CollapseSection
      show={show}
      title="Banner"
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, banner: !prev.banner }))
      }
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing="10px"
        sx={{ marginTop: '10px' }}
      >
        <ImageInput
          wrapperSx={{ width: '150px', height: '150px' }}
          url={generateLinkImage(defaultValues?.picture)}
          onChange={(file) => handleFileChange('picture', file)}
        />
        <ImageInput
          wrapperSx={{ width: '150px', height: '150px' }}
          url={generateLinkImage(defaultValues?.picture2)}
          onChange={(file) => handleFileChange('picture2', file)}
        />
        <ImageInput
          wrapperSx={{ width: '150px', height: '150px' }}
          url={generateLinkImage(defaultValues?.picture3)}
          onChange={(file) => handleFileChange('picture3', file)}
        />
      </Stack>
    </CollapseSection>
  );
}

export default BannerSection;
