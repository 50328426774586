import React, { useEffect } from 'react';
import FilterBox from './FilterBox';
import { useState } from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

function FilterRadios({
  title,
  onChange = () => {},
  values = [{ value: '', label: '' }],
  defaultValue,
  showActive = true,
}) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    onChange(value);
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <FilterBox title={title} active={true} showActive={showActive}>
      <RadioGroup name="radio" value={value} onChange={handleChange}>
        {values.map((item) => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '14px' } }}
            label={item.label}
            control={<Radio sx={{ padding: '6px' }} size="small" />}
          />
        ))}
      </RadioGroup>
    </FilterBox>
  );
}

export default FilterRadios;
