import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { v4 } from 'uuid';
import * as yup from 'yup';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import ModalBase from '~/components/modal/ModalBase';
import FormDVT from '../../dvt/FormDVT';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';

const schema = yup.object({
  don_vi_tinh: yup
    .object()
    .typeError('Vui lòng chọn đơn vị tính')
    .required('Vui lòng chọn đơn vị tính'),
  mau: yup
    .number()
    .typeError('Số lượng phải là số')
    .required('Vui lòng nhập số lượng'),
  gia_ban_nt: yup
    .number()
    .typeError('Giá bán phải là số')
    .required('Vui lòng nhập giá'),
});

function FormAddDvt({ open, handleClose, isEdit, addDvt, defaultValue = {} }) {
  const defaultData = {
    don_vi_tinh: null,
    mau: 0,
    gia_ban_nt: 0,
  };
  const originData = defaultValue
    ? {
        ...defaultData,
        ...defaultValue,
        don_vi_tinh: defaultValue?.ma_dvt
          ? { ma_dvt: defaultValue.ma_dvt, ten_dvt: defaultValue.ten_dvt }
          : null,
      }
    : defaultData;

  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: originData,
  });

  const generateDataAdd = (values) => {
    const { don_vi_tinh, ...fields } = values;
    return {
      ...fields,
      tu: 1,
      ma_dvt: don_vi_tinh.ma_dvt,
      ten_dvt: don_vi_tinh.ten_dvt,
    };
  };

  // handle save
  const handleSave = (values) => {
    const dataAdd = generateDataAdd(values);
    addDvt(dataAdd, isEdit);
    handleClose();
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="500px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} đơn vị tính`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase
          key={v4()}
          onClick={handleClose}
          startIcon={<MdClose style={{ fontSize: '16px' }} />}
          sx={{
            backgroundColor: 'error.main',
            '&:hover': { backgroundColor: 'error.main' },
          }}
        >
          Đóng
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="don_vi_tinh"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                readOnly={isEdit}
                labelWidth="35%"
                label="Đơn vị tính"
                apiCode="dmdvt"
                placeholder="Đơn vị tính"
                searchFileds={['ma_dvt', 'ten_dvt']}
                getOptionLabel={(option) => option.ten_dvt}
                selectedValue={value}
                value={value || { ma_dvt: '', ten_dvt: '' }}
                onSelect={onChange}
                FormAdd={FormDVT}
                errorMessage={errors?.don_vi_tinh?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            labelWidth="35%"
            type="number"
            register={register}
            name="mau"
            label="Số lượng quy đổi"
            errorMessage={errors?.mau?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="gia_ban_nt"
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="35%"
                value={numeralCustom(value).format()}
                onChange={(e) =>
                  onChange(numeralCustom(e.target.value).value())
                }
                label="Giá bán"
                errorMessage={errors?.gia_ban_nt?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormAddDvt;
