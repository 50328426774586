import { useSelector } from 'react-redux';
import {
  allowAddAuthorize,
  allowDeleteAuthorize,
  allowShowAuthorize,
  allowUpdateAuthorize,
} from '~/utils/helpers';

function useAuthorize() {
  const userData = useSelector((state) => state.auth.user);
  const rights = useSelector((state) => state.auth.rights);

  const allowAuthorize = ({ type, module }) => {
    // view, add, update, delete
    let allowFunc;
    switch (type) {
      case 'add':
        allowFunc = allowAddAuthorize;
        break;
      case 'update':
        allowFunc = allowUpdateAuthorize;
        break;
      case 'delete':
        allowFunc = allowDeleteAuthorize;
        break;
      default:
        allowFunc = allowShowAuthorize;
        break;
    }
    const check =
      userData?.admin || allowFunc({ rights: rights || [], module });
    return check;
  };
  return allowAuthorize;
}

export default useAuthorize;
