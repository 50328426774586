import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { IoIosCheckmark } from 'react-icons/io';

function FilterBox({ children, title, active, showActive = true }) {
  return (
    <Paper
      sx={{
        padding: '5px 10px 10px 10px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {showActive && (
        <>
          <Box
            sx={{
              display: active ? 'block' : 'none',
              position: 'absolute',
              top: 0,
              right: 0,
              fontSize: '12px',
              width: '40px',
              height: '40px',
              transform: 'rotate(45deg) translateY(-28px)',
              color: 'black',
              backgroundColor: 'success.main',
            }}
          ></Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="flex-end"
            className={active ? 'animate__animated animate__heartBeat' : ''}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: 'whitish.pureWhite',
            }}
          >
            <IoIosCheckmark size={18} />
          </Stack>
        </>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ marginBottom: '5px' }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography sx={{ fontSize: '12px', fontWeight: 600 }}>
            {title}
          </Typography>
        </Stack>
      </Stack>
      {children}
    </Paper>
  );
}

export default FilterBox;
