import React from 'react';
import AdminLayout from './AdminLayout';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useResponsive from '~/hooks/useResponsive';

const routes = [
  {
    text: 'Thông tin chung',
    path: '/profile',
  },
  {
    text: 'Đổi mật khẩu',
    path: '/password',
  },
  {
    text: 'Thiết bị khác',
    path: '/devices',
  },
];

function ProfileLayout({ children }) {
  const mdMatches = useResponsive({ key: 'up', breakpoint: 'md' });
  const location = useLocation();
  const navigate = useNavigate();

  const renderTitle = () => (
    <Typography
      component="h1"
      sx={{ fontSize: '20px', fontWeight: 500, paddingBottom: '10px' }}
    >
      Tài khoản của tôi
    </Typography>
  );

  return (
    <AdminLayout>
      <Box sx={{ height: '100%', padding: '10px 0' }}>
        {!mdMatches && renderTitle()}
        <Grid container spacing="10px" alignContent="flex-start">
          <Grid item xs={12} md={2.5}>
            <Stack direction={mdMatches ? 'column' : 'row'} spacing="5px">
              {routes.map((route) => {
                const isActive = location.pathname.includes(route.path);
                return (
                  <Button
                    key={route.text}
                    onClick={() => navigate(route.path)}
                    variant={isActive ? 'contained' : 'outlined'}
                    sx={
                      isActive
                        ? {
                            backgroundColor: 'primary.main',
                            color: 'whitish.pureWhite',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                            },
                          }
                        : {}
                    }
                  >
                    {route.text}
                  </Button>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} md={9.5}>
            <Box
              className="hidden-scroll"
              sx={{
                width: '100%',
                maxHeight: mdMatches
                  ? `calc(100vh - 50px - 42px - 20px)`
                  : 'calc(100vh - 50px - 40px - 50px - 20px)',
                overflow: 'auto',
              }}
            >
              {mdMatches && renderTitle()}
              <Box>{children}</Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </AdminLayout>
  );
}

export default ProfileLayout;
