import React, { forwardRef } from 'react';
import { Box, Collapse, IconButton, Stack, Typography } from '@mui/material';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

function CollapseSection({ children, title, show = true, onCollapse }, ref) {
  return (
    <Box
      ref={ref}
      sx={{
        width: '100%',
        border: '1px solid',
        borderColor: 'whitish.strockColor',
        borderRadius: '4px',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          padding: '5px',
          backgroundColor: 'whitish.strockColor',
        }}
      >
        <Typography
          sx={{ fontSize: '14px', textTransform: 'uppercase', fontWeight: 500 }}
        >
          {title}
        </Typography>
        {!!onCollapse && (
          <IconButton onClick={onCollapse} sx={{ padding: '5px' }}>
            {show ? <FaCaretUp size={16} /> : <FaCaretDown size={16} />}
          </IconButton>
        )}
      </Stack>
      <Collapse in={show}>
        <Box sx={{ padding: '5px' }}>{children}</Box>
      </Collapse>
    </Box>
  );
}

export default forwardRef(CollapseSection);
