import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Grid, Stack } from '@mui/material';
import TextInput from '../../input/TextInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import CheckboxInput from '~/components/input/CheckboxInput';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import ModalForm from '~/components/modal/ModalForm';
import FormUserGroup from '../usergroup/FormUserGroup';

const schemaBase = {
  email: yup.string().required('Vui lòng nhập trường này'),
  name: yup.string().required('Vui lòng nhập họ tên'),
};

const defaultData = {
  email: '',
  name: '',
  userGroup: null,
  auto_create_user: false,
  active: true,
  admin: false,
};

export default function FormParticipant({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        userGroup: defaultValues.group_id
          ? {
              _id: defaultValues.group_id,
              group_name: defaultValues.group_name,
            }
          : null,
        auto_create_user: false,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const [schema, setSchema] = useState(yup.object(schemaBase));
  const {
    handleSubmit,
    reset,
    control,
    watch,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [readOnlyPassword, setReadOnlyPassword] = useState(true);

  const autoCreateUser = watch('auto_create_user');

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { userGroup, ...data } = values;
    const result = { ...data };
    result.group_id = userGroup?._id || '';
    result.group_name = userGroup?.group_name || '';
    return result;
  };

  const handleSave = async (values, mode) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'participant',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  useEffect(() => {
    if (autoCreateUser === true) {
      setSchema(
        yup.object({
          ...schemaBase,
          password: yup.string().required('Vui lòng nhập mật khẩu'),
        })
      );
    } else {
      setSchema(yup.object(schemaBase));
    }
  }, [autoCreateUser]);

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="800px"
      title="người dùng"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <TextInput
              readOnly={isEdit}
              label="Tài khoản"
              placeholder="email@gmail.com"
              name="email"
              register={register}
              required
              errorMessage={errors?.email?.message}
            />
            <TextInput
              label="Họ tên"
              placeholder="Họ tên"
              name="name"
              required
              register={register}
              errorMessage={errors?.name?.message}
            />
            <Controller
              control={control}
              name="userGroup"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Nhóm"
                  apiCode="usergroup"
                  placeholder="Chọn nhóm người dùng"
                  searchFileds={['group_name']}
                  getOptionLabel={(option) => option.group_name}
                  selectedValue={value}
                  value={value || { _id: '', group_name: '' }}
                  onSelect={onChange}
                  FormAdd={FormUserGroup}
                />
              )}
            />
            {!!autoCreateUser && (
              <TextInput
                label="Mật khẩu"
                placeholder="Mật khẩu"
                name="password"
                type="password"
                required
                register={register}
                errorMessage={errors?.password?.message}
                textFieldProps={{
                  InputProps: {
                    readOnly: readOnlyPassword,
                  },
                  onFocus: () => setReadOnlyPassword(false),
                }}
              />
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="admin"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Là người quản trị"
                  name="admin"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Còn hoạt động"
                  name="status"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="auto_create_user"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Tạo người dùng nếu người dùng chưa được tạo"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </ModalForm>
  );
}
