import React from 'react';
import { Grid, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import { LABEL_KHO } from '~/utils/label.constant';
import DatetimeInput from '~/components/input/DatetimeInput';
import { formatDateDisplay } from '~/utils/helpers';

function InfoSection({ register, control, errors, setDetails, setValue }) {
  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              labelWidth="30%"
              type="text"
              label="Số CT"
              placeholder="Nhập hoặc tạo tự động"
              name="so_ct"
              register={register}
            />
            <Controller
              control={control}
              name="ngay_ct"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Ngày CT"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.ngay_ct?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="hd2"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  labelWidth="30%"
                  label="Hóa đơn trả"
                  apiCode="hd2"
                  placeholder="Tìm theo số chứng từ"
                  searchFileds={['so_ct']}
                  condition={{ status: true, trang_thai: '5' }}
                  getOptionLabel={(option) =>
                    option.so_ct
                      ? `Chứng từ : ${option.so_ct} - ${formatDateDisplay(
                          option.ngay_ct,
                          'DD/MM/YYYY HH:mm'
                        )}`
                      : ''
                  }
                  selectedValue={value}
                  value={value || { so_ct: '', ngay_ct: '' }}
                  onSelect={(val) => {
                    setDetails([]);
                    onChange(val);
                    setValue('ma_kho', val?.ma_kho || '');
                    setValue('ten_kho', val?.ten_kho || '');
                    setValue('ma_kh', val?.ma_kh || '');
                    setValue('ten_kh', val?.ten_kh || '');
                  }}
                  errorMessage={errors?.hd2?.message}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <TextInput
              label={LABEL_KHO}
              name="ma_kho"
              readOnly
              register={register}
              placeholder={`${LABEL_KHO} tự động theo hóa đơn trả`}
            />
            <TextInput
              label="Khách hàng"
              name="ma_kh"
              readOnly
              register={register}
              placeholder="Khách hàng tự động theo hóa đơn trả"
            />
            <Controller
              control={control}
              name="trang_thai"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  label="Trạng thái"
                  apiCode="trangthai"
                  placeholder="Chọn trạng thái phiếu"
                  searchFileds={['ten_trang_thai']}
                  getOptionLabel={(option) => option.ten_trang_thai}
                  selectedValue={value}
                  value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
                  onSelect={onChange}
                  withIdApp={false}
                  condition={{ ma_ct: 'HD3', status: true }}
                  errorMessage={errors?.trang_thai?.message}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
