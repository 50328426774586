import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { Snackbar, Stack, Typography } from '@mui/material';
import { BsWifiOff } from 'react-icons/bs';
import RenderRoute from './routes/RenderRoute';
import { PUBLIC_URL } from './utils/constants';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './pages/error/ErrorPage';

function App() {
  const [showNetwork, setShowNetwork] = useState(false);
  const [isOffline, setIsOffline] = useState(() => {
    return !navigator.onLine;
  });
  const timer = useRef();

  const connectSocket = () => {
    const socketURL = PUBLIC_URL;
    const socket = io(socketURL);
    if (socket) {
      window.socket = socket;
      // client-side
      socket.on('connect', () => {
        socket.on('notify:new', (data) => {
          console.log({ 'notify:new': data });
        });
      });
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (navigator.onLine) {
      connectSocket();
    } else {
      navigate('/bill');
    }
    return () => {
      delete window.socket;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOffline) {
      timer.current = setTimeout(() => {
        setShowNetwork(!showNetwork);
      }, 1000);
    }
    return () => {
      clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNetwork, isOffline]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
      setShowNetwork(false);
      connectSocket();
    };
    const handleOffline = () => {
      setIsOffline(true);
      window.socket?.close();
      delete window.socket;
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {
        <Snackbar
          open={showNetwork}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          message={
            <Stack
              direction="row"
              alignItems="center"
              color="#fff"
              spacing="5px"
            >
              <BsWifiOff size={14} />
              <Typography sx={{ fontSize: '12px', color: '#fff' }}>
                Không có internet
              </Typography>
            </Stack>
          }
          sx={{
            bottom: '8px !important',
            zIndex: 0,
            '& .MuiPaper-root': {
              padding: '0px',
              minWidth: 'unset',
              backgroundColor: 'darkmode.softDark',
              '& .MuiSnackbarContent-message': {
                padding: '4px 8px',
              },
            },
          }}
        />
      }
      <ErrorBoundary fallback={<ErrorPage />}>
        <RenderRoute />
      </ErrorBoundary>
    </>
  );
}

export default App;
