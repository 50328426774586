import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';

function FilterRPT({ setCondition }) {
  const [filter, setFilter] = useState({
    ma_cn: '',
    ten_mau_in: '',
  });

  useEffect(() => {
    const condition = {};
    if (filter.ma_cn) {
      condition.ma_cn = filter.ma_cn;
    }
    if (filter.ten_mau_in) {
      condition.ten_mau_in = {
        $regex: filter.ten_mau_in.split(' ').join('.*'),
        $options: 'i',
      };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Mã CN"
        onSearch={(value) => setFilter({ ...filter, ma_cn: value })}
      />
      <FilterSearch
        title="Tên mẫu in"
        onSearch={(value) => setFilter({ ...filter, ten_mau_in: value })}
      />
    </Stack>
  );
}

export default FilterRPT;
