import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { Box, Grid } from '@mui/material';
import TextInput from '~/components/input/TextInput';

const schema = yup.object({
  ten: yup.string().required('Vui lòng nhập tên nhóm'),
});

const defaultData = {
  ten: '',
  ty_le: 0,
};

function FormNHH({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        ty_le: defaultValues?.exfields?.ty_le || 0,
      }
    : defaultData;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const { asyncPostData, asyncPutData } = useApisContext();

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { ty_le, ...fields } = values;
    return {
      ...fields,
      exfields: { ty_le },
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmnhh',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="800px"
      title="nhóm hoa hồng"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              label="Tên nhóm"
              labelWidth="30%"
              required
              name="ten"
              register={register}
              errorMessage={errors?.ten?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              type="number"
              label="Tỷ lệ"
              labelWidth="30%"
              name="ty_le"
              register={register}
              InputProps={{ endAdornment: '%' }}
            />
          </Grid>
        </Grid>
      </Box>
    </ModalForm>
  );
}

export default FormNHH;
