import React from 'react';
import ModalBase from './ModalBase';
import { Box } from '@mui/material';
import TableDisplay from '../table/TableDisplay';
import { numeralCustom } from '~/utils/helpers';

const columns = [
  {
    name: 'Mã ĐVT',
    selector: (row) => row.ma_dvt,
    wrap: true,
  },
  {
    name: 'Tên ĐVT',
    selector: (row) => row.ten_dvt,
    wrap: true,
  },
  {
    name: 'Giá bán',
    selector: (row) => row.gia_ban_le,
    format: (row) => numeralCustom(row.gia_ban_le).format(),
    wrap: true,
  },
];

function DvtsModal({ open, onClose, dvts = [], handleAddProductToDetails }) {
  return (
    <ModalBase
      open={open}
      handleClose={onClose}
      title="Chọn đơn vị tính"
      width="600px"
    >
      <Box>
        <TableDisplay
          columns={columns}
          data={dvts}
          onRowClicked={(row) => {
            handleAddProductToDetails({
              product: row,
              gia_ban_le: row.gia_ban_le,
              ma_dvt: row.ma_dvt,
              ten_dvt: row.ten_dvt,
            });
            onClose();
          }}
        />
      </Box>
    </ModalBase>
  );
}

export default DvtsModal;
