import { Box, FormHelperText } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import RichtextInput from '~/components/input/RichtextInput';

function ContentSection({ show, setCollapses, control, errors }) {
  return (
    <CollapseSection
      title="Nội dung"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, content: !prev.content }))
      }
    >
      <Box>
        <Controller
          name="content"
          control={control}
          render={({ field: { value, onChange } }) => {
            return (
              <RichtextInput
                value={value}
                onChange={onChange}
                placeholder="Nhập nội dụng tin"
              />
            );
          }}
        />
        {errors?.content?.message && (
          <FormHelperText error>{errors.content.message}</FormHelperText>
        )}
      </Box>
    </CollapseSection>
  );
}

export default ContentSection;
