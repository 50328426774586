const { createSlice } = require('@reduxjs/toolkit');

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    data: [],
  },
  reducers: {
    updateNotifications(state, action) {
      state.data = action.payload;
    },
  },
});

export const { updateNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
