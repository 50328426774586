import React from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import { Controller } from 'react-hook-form';
import RichtextInput from '~/components/input/RichtextInput';

function DescriptionSection({ control, show, setCollapses }) {
  return (
    <CollapseSection
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, dien_giai: !prev.dien_giai }))
      }
      title="Mô tả chi tiết"
    >
      <Controller
        control={control}
        name="dien_giai"
        render={({ field: { onChange, value } }) => (
          <RichtextInput
            placeholder="Nhập mô tả chương trình Flashsale"
            value={value}
            onChange={onChange}
          />
        )}
      />
    </CollapseSection>
  );
}

export default DescriptionSection;
