import React from 'react';
import { Alert, Snackbar } from '@mui/material';

function SnackBarBase(
  {
    open,
    handleClose = () => {},
    type = 'error', // success
    message,
    vertical = 'top',
    horizontal = 'right',
    duration = 3000,
    sx = {},
  },
  ref
) {
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      sx={sx || { '& .MuiAlert-root': { boxShadow: '0 0 4px 4px #00000020' } }}
      autoHideDuration={duration}
    >
      <Alert severity={type} onClose={handleClose} sx={{ maxWidth: '60vw' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default SnackBarBase;
