import React from 'react';
import ExpandHd2 from '~/components/expand-row/ExpandHd2';
import ListBase from '~/components/listBase/ListBase';
import ModalBase from '~/components/modal/ModalBase';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import { LABEL_KHO } from '~/utils/label.constant';

const columns = [
  {
    name: 'Số CT',
    selector: (row) => row.so_ct,
    width: '80px',
    wrap: true,
  },
  {
    name: 'Ngày CT',
    selector: (row) => row.ngay_ct,
    format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY - HH:mm'),
    width: '140px',
    wrap: true,
  },
  {
    name: 'Khách hàng',
    selector: (row) => row.ten_kh,
    width: '140px',
    wrap: true,
  },
  {
    name: 'Nhân viên',
    selector: (row) => row.ten_nv || row.ma_nv,
    width: '140px',
    wrap: true,
  },
  {
    name: LABEL_KHO,
    selector: (row) => row.ten_kho,
    width: '140px',
    wrap: true,
  },
  {
    name: 'Tổng tiền hàng',
    selector: (row) => row.t_tien_nt,
    format: (row) => numeralCustom(row.t_tien_nt).format(),
    width: '120px',
    center: true,
    wrap: true,
  },
  {
    name: 'Tổng CK',
    selector: (row) => row.tc_ck_nt,
    format: (row) => numeralCustom(row.tc_ck_nt).format(),
    width: '100px',
    center: true,
    wrap: true,
  },
  {
    name: 'Thành tiền',
    selector: (row) => row.t_tt_nt,
    format: (row) => numeralCustom(row.t_tt_nt).format(),
    width: '100px',
  },
  {
    name: 'Khách đã trả',
    selector: (row) => row.exfields?.payed,
    format: (row) => numeralCustom(row.exfields?.payed).format(),
    width: '100px',
  },
];

function ModalList({ open, onClose, defaultCondition = {} }) {
  return (
    <ModalBase
      open={open}
      handleClose={onClose}
      title="Đơn đặt hàng"
      width="1200px"
    >
      <ListBase
        onlyList
        apiCode="hd2"
        title="đơn đặt hàng"
        columns={columns}
        showColumns={[0, 1, 2, 3, 6, 7, 8, 9]}
        defaultCondition={defaultCondition}
        showStatus={true}
        expandableRows={true}
        expandOnRowClicked={true}
        expandableRowsHideExpander={true}
        expandableRowsComponent={(props) => <ExpandHd2 {...props} hideAction />}
      />
    </ModalBase>
  );
}

export default ModalList;
