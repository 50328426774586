import React, { memo } from 'react';
import {
  Box,
  Button,
  Container,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { menus } from '~/utils/menu';
import MenuHover from '~/components/menu/MenuHover';
import { v4 } from 'uuid';
import { useSelector } from 'react-redux';
import { allowShowAuthorize } from '~/utils/helpers';
import SelectStore from '~/components/input/SelectStore';

function MenuBar() {
  const userData = useSelector((state) => state.auth.user);
  const rights = useSelector((state) => state.auth.rights);
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box sx={{ height: '42px', backgroundColor: 'primary.second' }}>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
        >
          <Stack
            direction="row"
            alignItems="stretch"
            gap="15px"
            sx={{ width: '100%', height: '100%' }}
          >
            {menus.map((menu) => {
              let isActive = false;
              const show =
                menu.show ||
                userData?.admin ||
                allowShowAuthorize({
                  rights: rights || [],
                  module: menu.subs
                    ? menu.subs.map((s) => s.module)
                    : menu.module,
                });
              if (!show) return null;
              if (menu.subs) {
                isActive = menu.subs.some((sub) => {
                  return location.pathname === sub.path;
                });
              } else if (menu.path) {
                isActive = location.pathname === menu.path;
              }
              if (menu.subs) {
                return (
                  <MenuHover
                    key={v4()}
                    content={
                      <Stack
                        sx={{
                          minWidth: '200px',
                          borderRadius: '4px',
                          overflow: 'hidden',
                        }}
                      >
                        {menu.subs.map((sub) => {
                          const active = location.pathname === sub.path;
                          const show =
                            userData?.admin ||
                            allowShowAuthorize({
                              rights: rights || [],
                              module: sub.module,
                            });
                          if (!show) return null;
                          return (
                            <MenuItem
                              key={v4()}
                              sx={{
                                columnGap: '10px',
                                color: 'whitish.pureWhite',
                                backgroundColor: active
                                  ? 'primary.main'
                                  : 'primary.second',
                                '&:hover': {
                                  backgroundColor: 'primary.main',
                                },
                              }}
                              onClick={() => navigate(sub.path)}
                            >
                              {sub.icon}
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  color: 'whitish.pureWhite',
                                }}
                              >
                                {sub.text}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Stack>
                    }
                  >
                    <Button
                      sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        textTransform: 'none',
                        color: 'whitish.pureWhite',
                        backgroundColor: isActive ? 'primary.second' : '',
                        '&:hover': {
                          backgroundColor: 'unset',
                        },
                      }}
                      endIcon={menu.icon}
                    >
                      {menu.text}
                    </Button>
                  </MenuHover>
                );
              } else {
                return (
                  <Button
                    key={v4()}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 400,
                      textTransform: 'none',
                      color: 'whitish.pureWhite',
                      backgroundColor: isActive ? 'primary.second' : '',
                      '&:hover': {
                        backgroundColor: 'unset',
                      },
                    }}
                    endIcon={menu.icon}
                    onClick={() => navigate(menu.path)}
                  >
                    {menu.text}
                  </Button>
                );
              }
            })}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing="5px"
            sx={{ grow: 1, height: '100%' }}
          >
            <SelectStore />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}

export default memo(MenuBar);
