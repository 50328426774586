import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import InfoSection from './info-section/InfoSection';
import ChiTietSection from './chitiet-section/ChiTietSection';
import { Stack } from '@mui/material';
import DescriptionSection from './description-section/DescriptionSection';

const schema = yup.object({
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
  trang_thai: yup
    .object()
    .typeError('Vui lòng chọn trạng thái')
    .required('Vui lòng chọn trạng thái'),
  ngay_ct: yup
    .date()
    .typeError('Vui lòng nhập ngày chứng từ')
    .required('Vui lòng nhập ngày chứng từ'),
});

export default function FormPKK({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const defaultData = {
    so_ct: '',
    ngay_ct: moment().format('YYYY-MM-DD HH:mm'),
    kho: null,
    trang_thai: null,
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        kho: {
          ma_kho: defaultValues?.ma_kho,
          ten_kho: defaultValues?.ten_kho,
        },
        trang_thai: {
          ma_trang_thai: defaultValues?.trang_thai,
          ten_trang_thai: defaultValues?.ten_trang_thai,
        },
        ngay_ct: moment(defaultValues.ngay_ct).format('YYYY-MM-DD HH:mm'),
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [details, setDetails] = useState(defaultValues?.details || []);
  // const [chiphis, setChiphis] = useState(defaultValues?.ctcpmhs || []);
  // const [vats, setVats] = useState(defaultValues?.vatvaos || []);
  const [collapses, setCollapses] = useState({
    chitiet: true,
    chiphi: true,
    mota: true,
    vat: true,
  });

  const handleReset = () => {
    reset(originalData);
    setDetails(defaultValues?.details || []);
  };

  const generateDataPost = (values) => {
    const { kho, trang_thai, ...data } = values;
    const result = {
      ...data,
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      trang_thai: trang_thai?.ma_trang_thai || '',
      ten_trang_thai: trang_thai?.ten_trang_thai || '',
      details,
    };
    return result;
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'pkk',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      showNavigator={false}
      width="1200px"
      title="phiếu kiểm kho"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack
        spacing="10px"
        sx={{
          width: '100%',
          padding: '10px',
          maxHeight: 'calc(90vh - 20px - 39px - 39px)',
          overflow: 'auto',
        }}
      >
        {/* Thong tin */}
        <InfoSection
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
        />
        {/* Chi tiet */}
        <ChiTietSection
          show={collapses.chitiet}
          setCollapses={setCollapses}
          details={details}
          setDetails={setDetails}
        />
        {/* Mo ta */}
        <DescriptionSection
          show={collapses.mota}
          setCollapses={setCollapses}
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}
