import { openDB, deleteDB } from 'idb';

async function connectIndexedDB({ databaseName, storeName }) {
  if (!databaseName || !storeName) return;
  const db = await openDB(databaseName, 1, {
    upgrade(db) {
      // Create a store of objects
      db.createObjectStore(storeName, {
        // The 'id' property of the object will be the key.
        keyPath: '_id',
      });
    },
  });

  // Add an item:
  const add = async (data) => {
    const existed = await get(data._id);
    if (!existed) {
      const res = await db.add(storeName, data);
      return res;
    }
    return undefined;
  };

  // Add multiple items in one transaction:
  const addAll = async (datas = []) => {
    if (!Array.isArray(datas)) {
      datas = [];
    }
    const ids = await db.getAllKeys(storeName);
    const datasToSave = datas?.filter((data) => {
      return !ids.includes(data._id);
    });
    const tx = db.transaction(storeName, 'readwrite');
    const txs = datasToSave.map((data) => tx.store.add(data));
    const res = await Promise.all(txs);
    return res;
  };

  // get an item
  const get = async (key) => {
    if (!key) return;
    const record = await db.get(storeName, key);
    return record;
  };

  // get all items
  const getAll = async () => {
    const records = await db.getAll(storeName);
    return records;
  };

  // get all keys
  const getAllKeys = async () => {
    const keys = await db.getAllKeys(storeName);
    return keys;
  };

  // update an item
  const updateOne = async (data) => {
    const updated = await db.put(storeName, data);
    return updated;
  };

  // delete an item
  const deleteOne = async (key) => {
    const deleted = await db.delete(storeName, key);
    return deleted;
  };

  // clear
  const clearAll = async () => {
    await db.clear(storeName);
  };

  return {
    add,
    addAll,
    get,
    getAll,
    getAllKeys,
    updateOne,
    deleteOne,
    clearAll,
  };
}

export const deleteDatabase = async (databaseName) => {
  await deleteDB(databaseName);
};
export default connectIndexedDB;
