import { formatDateDisplay, numeralCustom } from './helpers';
import FormNVT from '~/components/form/productGroup/FormNVT';
import FormDVT from '~/components/form/dvt/FormDVT';
import FormKho from '~/components/form/kho/FormKho';
import FilterProductGroup from '~/components/filter/productGroup/FilterProductGroup';
import FilterDVT from '~/components/filter/donViTinh/FilterDVT';
import FilterKho from '~/components/filter/kho/FilterKho';
import FormPNK from '~/components/form/pnk/FormPNK';
import FormLo from '~/components/form/lo/FormLo';
import FilterLo from '~/components/filter/lo/FilterLo';
import FilterPNK from '~/components/filter/pnk/FilterPNK';
import FormPKK from '~/components/form/pkk/FormPKK';
import FilterPKK from '~/components/filter/pkk/FilterPKK';
import FormPXDC from '~/components/form/pdn/FormPDN';
import FilterPDN from '~/components/filter/pdn/FilterPDN';
import FilterNghiepVu from '~/components/filter/nghiepvu/FilterNghiepVu';
import FormKB from '~/components/form/kb/FormKB';
import FilterKB from '~/components/filter/kenhBan/FilterKB';
import FormPTTT from '~/components/form/pttt/FormPTTT';
import FilterPTTT from '~/components/filter/pttt/FilterPTTT';
import FormCustomer from '~/components/form/customer/FormCustomer';
import FilterCustomer from '~/components/filter/customer/FilterCustomer';
import FormPT from '~/components/form/phieuthu/FormPT';
import FilterPT from '~/components/filter/phieuThu/FilterPT';

import FormPBL from '~/components/form/pbl/FormPBL';
import FormPC from '~/components/form/phieuchi/FormPC';
import FilterPC from '~/components/filter/phieuChi/FilterPC';
import FilterPBL from '~/components/filter/pbl/FilterPBL';
import FormNhanVien from '~/components/form/nhanvien/FormNhanVien';
import FilterNhanVien from '~/components/filter/nhanvien/FilterNhanVien';
import FilterUserGroup from '~/components/filter/usergroup/FilterUserGroup';
import FormUserGroup from '~/components/form/usergroup/FormUserGroup';
import FilterParticipant from '~/components/filter/participant/FilterParticipant';
import FormParticipant from '~/components/form/participant/FormParticipant';
import FormNghiepVu from '~/components/form/nghiepvu/FormNghiepVu';
import FormXuatXu from '~/components/form/xuatxu/FormXuatXu';
import FilterXuatXu from '~/components/filter/xuatxu/FilterXuatXu';
import FormPN1 from '~/components/form/pn1/FormPN1';
import FilterPN1 from '~/components/filter/pn1/FilterPN1';
import FormCPMH from '~/components/form/dmcpmh/FormCPMH';
import FilterCPMH from '~/components/filter/dmcpmh/FilterCPMH';
import FilterRPT from '~/components/filter/rpt/FilterRPT';
import FormRPT from '~/components/form/rpt/FormRPT';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import FormNhomKhach from '~/components/form/nhkh/FormNhomKhach';
import FilterNhomKhach from '~/components/filter/nhkh/FilterNhomKhach';
import FilterNhomNCC from '~/components/filter/nhncc/FilterNhomNCC';
import FilterNCC from '~/components/filter/ncc/FilterNCC';
import FormNCC from '~/components/form/ncc/FormNCC';
import FormNhomNCC from '~/components/form/nhncc/FormNhomNCC';
import FormHD7 from '~/components/form/hd7/FormHD7';
import FormSO1 from '~/components/form/so1/FormSO1';
import FilterSO1 from '~/components/filter/so1/FilterSO1';
import FormGiaBan from '~/components/form/dmgiaban/FormGiaBan';
import FilterGiaBan from '~/components/filter/dmgiaban/FilterGiaBan';
import FormChietKhau from '~/components/form/dmchietkhau/FormChietKhau';
import FormEvoucher from '~/components/form/evoucher/FormEvoucher';
import FilterEvoucher from '~/components/filter/evoucher/FilterEvoucher';
import FormPN5 from '~/components/form/pn5/FormPN5';
import FilterPN5 from '~/components/filter/pn5/FilterPN5';
import FormKhuyenMai from '~/components/form/dmkhuyenmai/FormKhuyenMai';
import FilterKhuyenMai from '~/components/filter/dmkhuyenmai/FilterKhuyenMai';
import FilterChietKhau from '~/components/filter/dmchietkhau/FilterChietKhau';
import FormKhuVuc from '~/components/form/khuvuc/FormKhuVuc';
import FilterKhuVuc from '~/components/filter/dmkhuvuc/FilterKhuVuc';
import { QUYEN_TRUY_CAP } from './constants';
import FormBoPhan from '~/components/form/dmbp/FormBoPhan';
import FilterBoPhan from '~/components/filter/dmbp/FilterBoPhan';
import FormPosition from '~/components/form/postion/FormPosition';
import FilterPosition from '~/components/filter/position/FilterPosition';
import FilterPhuCap from '~/components/filter/dmphucap/FilterPhuCap';
import FormPhuCap from '~/components/form/dmphucap/FormPhuCap';
import ExpandPkk from '~/components/expand-row/ExpandPkk';
import ExpandPN1 from '~/components/expand-row/ExpandPN1';
import { LABEL_KHO } from './label.constant';
import ExpandPNK from '~/components/expand-row/ExpandPNK';
import ExpandPn5 from '~/components/expand-row/ExpandPn5';
import FormPXH from '~/components/form/pxh/FormPXH';
import FilterPXH from '~/components/filter/pxh/FilterPXH';
import ExpandPXH from '~/components/expand-row/ExpandPXH';
import FormFlashsale from '~/components/form/flashshale/FormFlashsale';
import FilterFlashsale from '~/components/filter/flashsale/FilterFlashsale';
import ExpandUserGroup from '~/components/expand-row/ExpandUserGroup';
import ExpandParticipant from '~/components/expand-row/ExpandParticipant';
import ExpandPT from '~/components/expand-row/ExpandPT';
import ExpandPC from '~/components/expand-row/ExpandPC';
import CheckboxInput from '~/components/input/CheckboxInput';
import FormCDVT from '~/components/form/cdvt/FormCDVT';
import FilterCDVT from '~/components/filter/cdvt/FilterCDVT';
import ExpandPBL from '~/components/expand-row/ExpandPBL';
import FormQddvt from '~/components/form/dmqddvt/FormQddvt';
import FilterQddvt from '~/components/filter/dmqddvt/FilterQddvt';
import FormThongTinGiaoHang from '~/components/form/thongtingiaohang/FormThongTinGiaoHang';
import FilterThongTinGiaoHang from '~/components/filter/thongtingiaohang/FilterThongTinGiaoHang';
import FormNews from '~/components/form/news/FormNews';
import FilterNews from '~/components/filter/news/FilterNews';
import FormGroupNews from '~/components/form/group-news/FormGroupNews';
import FilterGroupNews from '~/components/filter/groupnews/FilterGroupNews';
import FormNHH from '~/components/form/dmnhh/FormNHH';
import FilterDmnhh from '~/components/filter/dmnhh/FilterDmnhh';
import FilterHD7 from '~/components/filter/hd7/FilterHD7';
import FormHD3 from '~/components/form/hd3/FormHD3';
import FilterHD3 from '~/components/filter/hd3/FilterHD3';
import ExpandHd3 from '~/components/expand-row/ExpandHd3';
import ExpandPDN from '~/components/expand-row/ExpandPDN';

const dsDanhMuc = {
  dmnvt: {
    title: 'nhãn hiệu',
    Filter: FilterProductGroup,
    Form: FormNVT,
    columns: [
      {
        name: 'Tên',
        width: '200px',
        wrap: true,
        conditionalCellStyles: [
          {
            when: (row) => row.status === false,
            style: { color: 'red' },
          },
        ],
        selector: (row) => row.ten_nvt,
      },
      {
        name: 'Nhóm mẹ',
        selector: (row) => row.ten_nh_me,
      },
    ],
    showColumns: [0, 1],
  },
  dmdvt: {
    title: 'đơn vị tính',
    uniqueKey: 'ma_dvt',
    Filter: FilterDVT,
    Form: FormDVT,
    columns: [
      {
        name: 'Mã',
        width: '150px',
        selector: (row) => row.ma_dvt,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_dvt,
      },
    ],
    showColumns: [0, 1],
  },
  dmkho: {
    title: 'chi nhánh',
    uniqueKey: 'ma_kho',
    Form: FormKho,
    Filter: FilterKho,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_kho,
        minWidth: '120px',
        maxWidth: '120px',
        wrap: true,
        conditionalCellStyles: [
          {
            when: (row) => row.status === false,
            style: { color: 'red' },
          },
        ],
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_kho,
        minWidth: '200px',
        wrap: true,
        conditionalCellStyles: [
          {
            when: (row) => row.status === false,
            style: { color: 'red' },
          },
        ],
      },
      {
        name: 'Địa chỉ',
        selector: (row) => row.dia_chi,
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Điện thoại',
        selector: (row) => row.exfields?.dien_thoai,
        minWidth: '150px',
        maxWidth: '150px',
        center: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        minWidth: '200px',
        maxWidth: '200px',
        right: true,
      },
    ],
    showColumns: [0, 1, 2, 3],
  },
  dmlo: {
    title: 'lô',
    Form: FormLo,
    Filter: FilterLo,
    columns: [
      {
        name: 'Mã lô',
        selector: (row) => row.ma_lo,
        width: '100px',
        wrap: true,
        conditionalCellStyles: [
          {
            when: (row) => row.status === false,
            style: { color: 'red' },
          },
        ],
      },
      {
        name: 'Tên hàng',
        selector: (row) => row.ten_vt,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Hạn sử dụng',
        conditionalCellStyles: [
          {
            when: (row) => {
              const expireDate = moment(row.han_sd);
              return expireDate <= moment();
            },
            style: { color: 'red' },
          },
          {
            when: (row) => {
              const expireDate = moment(row.han_sd);
              return (
                expireDate <= moment().add(3, 'months') && expireDate > moment()
              );
            },
            style: { color: 'orange' },
          },
        ],
        selector: (row) => row.han_sd,
        format: (row) => formatDateDisplay(row.han_sd),
        width: '150px',
      },
      {
        name: 'Người tạo',
        selector: (row) => row.user_created,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Người sửa',
        selector: (row) => row.user_updated,
        width: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4],
  },
  dmkenhbanhang: {
    title: 'Kênh bán',
    Form: FormKB,
    Filter: FilterKB,
    columns: [
      {
        name: 'Mã Kênh',
        selector: (row) => row.ma_kenh,
        minWidth: '120px',
        maxWidth: '120px',
      },
      {
        name: 'Tên Kênh',
        selector: (row) => row.ten_kenh,
        minWidth: '100px',
      },
      {
        name: 'Người tạo',
        selector: (row) => row.user_created,
        center: true,
        minWidth: '120px',
      },
      {
        name: 'Người sửa',
        selector: (row) => row.user_updated,
        center: true,
        minWidth: '120px',
      },
    ],
    showColumns: [0, 1],
  },
  ptthanhtoan: {
    title: 'phương thức thanh toán',
    Form: FormPTTT,
    Filter: FilterPTTT,
    columns: [
      {
        name: 'Tên phương thức',
        selector: (row) => row.ten,
        minWidth: '150px',
        conditionalCellStyles: [
          {
            when: (row) => row.status === false,
            style: { color: 'red' },
          },
        ],
      },
      {
        name: 'Tài khoản',
        selector: (row) => row.tai_khoan_nh,
        minWidth: '120px',
      },
      {
        name: 'Chủ tài khoản',
        selector: (row) => row.chu_tai_khoan,
        minWidth: '140px',
      },
      {
        name: 'Ngân hàng',
        selector: (row) => row.ngan_hang,
        minWidth: '140px',
      },
    ],
    showColumns: [0, 1, 2, 3],
  },
  customer: {
    title: 'khách hàng',
    Form: FormCustomer,
    Filter: FilterCustomer,
    showStatus: true,
    columns: [
      {
        name: 'Mã khách',
        selector: (row) => row.ma_kh,
        width: '120px',
        wrap: true,
        conditionalCellStyles: [
          {
            when: (row) => !row.status,
            style: { color: 'red' },
          },
        ],
      },
      {
        name: 'Tên khách',
        selector: (row) => row.ten_kh,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Tên CSKD',
        selector: (row) => row.ten_kh2,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Số điện thoại',
        selector: (row) => row.dien_thoai,
        width: '140px',
      },
      {
        name: 'Nhóm khách',
        selector: (row) => row.ten_nh_kh,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Ngày sinh',
        selector: (row) => row.ngay_sinh,
        format: (row) =>
          row.ngay_sinh ? formatDateDisplay(row.ngay_sinh) : '',
        width: '120px',
        wrap: true,
        center: true,
      },
      {
        name: 'CMND/CCCD',
        selector: (row) => row.id_number,
        width: '120px',
        wrap: true,
        right: true,
        center: true,
      },
      {
        name: 'Mã số thuế',
        selector: (row) => row.ma_so_thue,
        width: '120px',
        wrap: true,
        center: true,
      },
      {
        name: 'Tỉnh thành',
        selector: (row) => row.ten_tinh_thanh,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Quận, huyện',
        selector: (row) => row.ten_quan_huyen,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Xã, phường',
        selector: (row) => row.ten_xa_phuong,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Địa chỉ',
        selector: (row) => row.dia_chi,
        width: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  dmncc: {
    title: 'nhà cung cấp',
    apiCode: 'customer',
    Form: FormNCC,
    Filter: FilterNCC,
    columns: [
      {
        name: 'Mã NCC',
        selector: (row) => row.ma_kh,
        width: '130px',
        wrap: true,
        conditionalCellStyles: [
          {
            when: (row) => !row.status,
            style: { color: 'red' },
          },
        ],
      },
      {
        name: 'Tên NCC',
        selector: (row) => row.ten_kh,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Số điện thoại',
        selector: (row) => row.dien_thoai,
        width: '140px',
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Mã số thuế',
        selector: (row) => row.ma_so_thue,
        width: '120px',
        wrap: true,
        center: true,
      },
      {
        name: 'Nhóm NCC',
        selector: (row) => row.ten_nh_kh,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tỉnh thành',
        selector: (row) => row.ten_tinh_thanh,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Quận, huyện',
        selector: (row) => row.ten_quan_huyen,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Xã, phường',
        selector: (row) => row.ten_xa_phuong,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Địa chỉ',
        selector: (row) => row.dia_chi,
        width: '120px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4],
  },
  pt1: {
    title: 'phiếu thu',
    Form: FormPT,
    Filter: FilterPT,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPT,
    showStatus: true,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        left: true,
        width: '80px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '130px',
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        width: '120px',
      },
      {
        name: 'Người nộp',
        selector: (row) => row.ten_kh,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Giá trị',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        width: '100px',
      },
      {
        name: 'Ghi chú',
        selector: (row) => row.dien_giai,
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  pc1: {
    title: 'phiếu chi',
    Form: FormPC,
    Filter: FilterPC,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPC,
    showStatus: true,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        left: true,
        width: '80px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '130px',
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        width: '120px',
      },
      {
        name: 'Người nhận',
        selector: (row) => row.ten_kh,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Giá trị',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        width: '100px',
      },
      {
        name: 'Ghi chú',
        selector: (row) => row.dien_giai,
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  pbl: {
    apiCode: 'pbl',
    title: 'Phiếu bán lẻ',
    Form: FormPBL,
    Filter: FilterPBL,
    showStatus: true,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPBL,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '80px',
      },
      {
        name: 'Ngày CT',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '140px',
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Khách hàng',
        selector: (row) => row.ten_kh,
        center: true,
        wrap: true,
        width: '120px',
      },
      {
        name: 'Nhân viên',
        selector: (row) => row.ten_nv,
        width: '120px',
        wrap: true,
        center: true,
      },
      {
        name: 'Tổng tiền hàng',
        selector: (row) => row.t_tien_nt,
        format: (row) => numeralCustom(row.t_tien_nt).format(),
        center: true,
        width: '120px',
      },
      {
        name: 'Tổng CK',
        selector: (row) => row.t_ck_nt,
        format: (row) => numeralCustom(row.t_ck_nt).format(),
        center: true,
        width: '120px',
      },
      {
        name: 'Thành tiền',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        center: true,
        width: '120px',
      },
      {
        name: 'Phương thức thanh toán',
        selector: (row) => row.ten_pt_thanh_toan,
        width: '180px',
        right: true,
        wrap: true,
      },
    ],
    showColumns: [0, 1, 3, 4, 5, 6, 7],
  },
  hd3_web: {
    apiCode: 'hd3_web',
    title: 'trả hàng website',
    Form: () => {},
    Filter: () => {},
    columns: [],
    showColumns: [0, 1, 2, 3, 4],
  },
  // pbl: {
  //   apiCode: 'pbl_pharma',
  //   title: 'đơn hàng website',
  //   Form: FormPBL,
  //   Filter: FilterPBL,
  //   showStatus: true,
  //   expandableRows: true,
  //   expandOnRowClicked: true,
  //   expandableRowsHideExpander: true,
  //   expandableRowsComponent: ExpandPBL,
  //   columns: [
  //     {
  //       name: 'Số CT',
  //       selector: (row) => row.so_ct,
  //       width: '80px',
  //     },
  //     {
  //       name: 'Ngày CT',
  //       selector: (row) => row.ngay_ct,
  //       format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
  //       width: '140px',
  //     },
  //     {
  //       name: LABEL_KHO,
  //       selector: (row) => row.ten_kho,
  //       width: '120px',
  //       wrap: true,
  //     },
  //     {
  //       name: 'Khách hàng',
  //       selector: (row) => row.ten_kh,
  //       center: true,
  //       wrap: true,
  //       width: '120px',
  //     },
  //     {
  //       name: 'Nhân viên',
  //       selector: (row) => row.ten_nv,
  //       width: '120px',
  //       wrap: true,
  //       center: true,
  //     },
  //     {
  //       name: 'Tổng tiền hàng',
  //       selector: (row) => row.t_tien_nt,
  //       format: (row) => numeralCustom(row.t_tien_nt).format(),
  //       center: true,
  //       width: '120px',
  //     },
  //     {
  //       name: 'Tổng CK',
  //       selector: (row) => row.t_ck_nt,
  //       format: (row) => numeralCustom(row.t_ck_nt).format(),
  //       center: true,
  //       width: '120px',
  //     },
  //     {
  //       name: 'Thành tiền',
  //       selector: (row) => row.t_tt_nt,
  //       format: (row) => numeralCustom(row.t_tt_nt).format(),
  //       center: true,
  //       width: '120px',
  //     },
  //     {
  //       name: 'Phương thức thanh toán',
  //       selector: (row) => row.ten_pt_thanh_toan,
  //       width: '180px',
  //       right: true,
  //       wrap: true,
  //     },
  //   ],
  //   showColumns: [0, 1, 3, 4, 5, 6, 7],
  // },
  so1: {
    title: 'đơn đặt hàng',
    Form: FormSO1,
    Filter: FilterSO1,
    columns: [
      {
        name: 'Trạng thái',
        selector: (row) => row.ten_trang_thai,
        cell: (row) => (
          <Box
            style={{
              backgroundColor: row.color,
              color: 'white',
              padding: '2px 4px',
              borderRadius: '4px',
              textWrap: 'nowrap',
            }}
          >
            {row.ten_trang_thai}
          </Box>
        ),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '80px',
        wrap: true,
      },
      {
        name: 'Ngày CT',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Kho',
        selector: (row) => row.ten_kho,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Khách hàng',
        selector: (row) => row.ten_kh,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.t_tien_hang_nt,
        format: (row) => numeralCustom(row.t_tien_hang_nt).format(),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tiền CK',
        selector: (row) => row.t_ck_nt,
        format: (row) => numeralCustom(row.t_ck_nt).format(),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Thành tiền',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        width: '120px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 5, 6, 7],
  },
  // hd2: {
  //   title: 'đơn đặt hàng',
  //   Form: FormHD2,
  //   Filter: FilterHD2,
  //   expandableRows: true,
  //   expandOnRowClicked: true,
  //   expandableRowsHideExpander: true,
  //   expandableRowsComponent: ExpandPNK,
  //   showStatus: true,
  //   columns: [
  //     {
  //       name: 'Số CT',
  //       selector: (row) => row.so_ct,
  //       width: '100px',
  //       wrap: true,
  //     },
  //     {
  //       name: 'Ngày CT',
  //       selector: (row) => row.ngay_ct,
  //       format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
  //       width: '140px',
  //       wrap: true,
  //     },
  //     {
  //       name: 'Khách hàng',
  //       selector: (row) => row.ten_kh,
  //       width: '140px',
  //       wrap: true,
  //     },
  //     {
  //       name: 'Nhân viên',
  //       selector: (row) => row.ten_nv,
  //       width: '140px',
  //       wrap: true,
  //     },
  //     {
  //       name: 'Kho',
  //       selector: (row) => row.ten_kho,
  //       width: '140px',
  //       wrap: true,
  //     },
  //     {
  //       name: 'Kênh bán',
  //       selector: (row) => row.ten_kenh,
  //       width: '140px',
  //       wrap: true,
  //     },
  //     {
  //       name: 'Tổng tiền hàng',
  //       selector: (row) => row.t_tien_nt,
  //       format: (row) => numeralCustom(row.t_tien_nt).format(),
  //       width: '120px',
  //     },
  //     {
  //       name: 'Tổng CK',
  //       selector: (row) => row.tc_ck_nt,
  //       format: (row) => numeralCustom(row.tc_ck_nt).format(),
  //       width: '120px',
  //     },
  //     {
  //       name: 'Thành tiền',
  //       selector: (row) => row.t_tt_nt,
  //       format: (row) => numeralCustom(row.t_tt_nt).format(),
  //       width: '120px',
  //     },
  //   ],
  //   showColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  // },
  hd3: {
    title: 'trả hàng',
    Form: FormHD3,
    Filter: FilterHD3,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandHd3,
    showStatus: true,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '80px',
        wrap: true,
      },
      {
        name: 'Ngày CT',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Khách hàng',
        selector: (row) => row.ten_kh,
        width: '120px',
        wrap: true,
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ma_kho,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.t_tien,
        format: (row) => numeralCustom(row.t_tien).format(),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tiền CK',
        selector: (row) => row.t_ck_nt,
        format: (row) => numeralCustom(row.t_ck_nt).format(),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tiền trả lại',
        selector: (row) => row.t_tt,
        format: (row) => numeralCustom(row.t_tt).format(),
        width: '120px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  hd7: {
    title: 'trả hàng',
    Form: FormHD7,
    Filter: FilterHD7,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '80px',
        wrap: true,
      },
      {
        name: 'Ngày CT',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '140px',
        wrap: true,
      },
      {
        name: 'Khách hàng',
        selector: (row) => row.ten_kh,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Nhân viên',
        selector: (row) => row.ten_nv || row.ma_nv,
        width: '140px',
        wrap: true,
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Tổng tiền hàng',
        selector: (row) => row.t_tien_nt,
        format: (row) => numeralCustom(row.t_tien_nt).format(),
        width: '120px',
        center: true,
        wrap: true,
      },
      {
        name: 'Tổng CK',
        selector: (row) => row.tc_ck_nt,
        format: (row) => numeralCustom(row.tc_ck_nt).format(),
        width: '100px',
        center: true,
        wrap: true,
      },
      {
        name: 'Thành tiền',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        width: '100px',
      },
      {
        name: 'Khách đã trả',
        selector: (row) => row.exfields?.payed,
        format: (row) => numeralCustom(row.exfields?.payed).format(),
        width: '100px',
      },
    ],
    showColumns: [0, 1, 2, 3, 5, 6, 7],
  },
  pnk: {
    title: 'nhập kho nội bộ',
    Form: FormPNK,
    Filter: FilterPNK,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPNK,
    showStatus: true,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '100px',
        wrap: true,
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        center: true,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '120px',
      },
      {
        name: 'Tổng số lượng',
        selector: (row) => row.t_sl,
        format: (row) => numeralCustom(row.t_sl).format(),
        width: '120px',
        center: true,
        wrap: true,
      },
      {
        name: 'Tổng giá trị nhập',
        selector: (row) => row.t_tien_nhap,
        format: (row) => numeralCustom(row.t_tien_nhap).format(),
        width: '120px',
        center: true,
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  pkk: {
    title: 'phiếu kiểm kho',
    Form: FormPKK,
    Filter: FilterPKK,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPkk,
    showStatus: true,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '100px',
        left: true,
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        width: '150px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        center: true,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '150px',
      },
      {
        name: 'Tổng tồn sổ sách',
        selector: (row) => row.t_sl_ton_ss,
        right: true,
        width: '150px',
      },
      {
        name: 'Tổng tồn thực tế',
        selector: (row) => row.t_sl_ton_tt,
        right: true,
        width: '150px',
      },
      {
        name: 'Tổng chênh lệch',
        selector: (row) => row.t_sl,
        right: true,
        width: '150px',
      },
    ],
    showColumns: [0, 2, 3, 4, 5],
  },
  pxk_hanghuy: {
    apiCode: 'pxk',
    title: 'phiếu xuất hủy',
    Form: FormPXH,
    Filter: FilterPXH,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPXH,
    showStatus: true,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '100px',
        left: true,
        wrap: true,
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        center: true,
        width: '120px',
        wrap: true,
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        width: '120px',
        wrap: true,
        center: true,
      },
      {
        name: 'Nhà cung cấp',
        selector: (row) => row.ten_kh || row.ma_kh,
        width: '150px',
        wrap: true,
        center: true,
      },
      {
        name: 'Tổng số lượng',
        selector: (row) => row.t_sl,
        width: '150px',
        center: true,
        wrap: true,
      },
      {
        name: 'Tiền hủy',
        selector: (row) => row.t_tien_xuat,
        format: (row) => numeralCustom(row.t_tien_xuat).format(),
        width: '150px',
        right: true,
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  pdn: {
    title: 'phiếu xuất điều chuyển',
    Form: FormPXDC,
    Filter: FilterPDN,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPDN,
    showStatus: true,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '80px',
      },
      {
        name: 'Ngày chứng từ',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '140px',
        wrap: true,
      },
      {
        name: `Từ ${LABEL_KHO}`,
        selector: (row) => row.ten_kho_x,
        width: '150px',
        wrap: true,
      },
      {
        name: `Đến ${LABEL_KHO}`,
        selector: (row) => row.ten_kho_n,
        width: '150px',
        wrap: true,
      },
      {
        name: `Tổng số lượng`,
        selector: (row) => row.t_sl,
        width: '120px',
        wrap: true,
        center: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  },
  dmchucvu: {
    title: 'chức vụ',
    apiCode: 'group',
    Form: FormPosition,
    Filter: FilterPosition,
    columns: [
      {
        name: 'Số thứ tự',
        selector: (row) => row.stt,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Tên chức vụ',
        selector: (row) => row.group_name,
        width: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1],
  },
  dmphucap: {
    title: 'phụ cấp',
    apiCode: 'group',
    Form: FormPhuCap,
    Filter: FilterPhuCap,
    columns: [
      {
        name: 'Số thứ tự',
        selector: (row) => row.stt,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Tên phụ cấp',
        selector: (row) => row.group_name,
        width: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1],
  },
  dmnv: {
    title: 'nhân viên',
    uniqueKey: 'ma_nv',
    Form: FormNhanVien,
    Filter: FilterNhanVien,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_nv,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_nv,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Điện thoại',
        selector: (row) => row.dien_thoai,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Ngày sinh',
        selector: (row) => row.ngay_sinh,
        format: (row) => formatDateDisplay(row.ngay_sinh),
        width: '120px',
      },
      {
        name: 'Bộ phận',
        selector: (row) => row.ten_bp,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Giới tính',
        selector: (row) => row.gioi_tinh,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Email',
        selector: (row) => row.email,
        width: '140px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  dmbp: {
    title: 'bộ phận',
    Form: FormBoPhan,
    Filter: FilterBoPhan,
    columns: [
      {
        name: 'Mã bộ phận',
        selector: (row) => row.ma_bp,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tên bộ phận',
        selector: (row) => row.ten_bp,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Người phụ trách',
        selector: (row) => row.ten_phu_trach,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Bộ phận cha',
        selector: (row) => row.ma_bp_me,
        width: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3],
  },
  usergroup: {
    title: 'nhóm người dùng',
    uniqueKey: '_id',
    Filter: FilterUserGroup,
    Form: FormUserGroup,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandUserGroup,
    columns: [
      {
        name: 'Tên nhóm',
        selector: (row) => row.group_name,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Ngày tạo',
        selector: (row) => row.date_created,
        format: (row) => formatDateDisplay(row.date_created),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Người tạo',
        selector: (row) => row.user_created,
        width: '200px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2],
  },
  participant: {
    title: 'người dùng',
    Filter: FilterParticipant,
    Form: FormParticipant,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandParticipant,
    columns: [
      {
        name: 'Người sử dụng',
        selector: (row) => row.email,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.name,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Nhóm',
        selector: (row) => row.group_name,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Quản trị viên',
        selector: (row) => row.admin,
        cell: (row) => {
          return (
            <CheckboxInput
              checked={row.admin}
              readOnly
              wrapperSx={{ display: 'flex', justifyContent: 'center' }}
            />
          );
        },
        width: '120px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3],
  },
  dmnghiepvu: {
    title: 'nghiệp vụ',
    Filter: FilterNghiepVu,
    Form: FormNghiepVu,
    columns: [
      {
        name: 'Mã chứng từ',
        selector: (row) => row.ma_ct,
        minWidth: '100px',
      },
      {
        name: 'Mã nghiệp vụ',
        selector: (row) => row.ma_nghiep_vu,
        minWidth: '100px',
      },
      {
        name: 'Tên nghiệp vụ',
        selector: (row) => row.ten_nghiep_vu,
        minWidth: '100px',
        right: true,
      },
    ],
    showColumns: [0, 1, 2],
  },
  dmxuatxu: {
    title: 'xuất xứ',
    Form: FormXuatXu,
    Filter: FilterXuatXu,
    columns: [
      {
        name: 'Mã xuất xứ',
        selector: (row) => row.ma_xuat_xu,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Tên xuất xứ',
        selector: (row) => row.ten_xuat_xu,
        minWidth: '150px',
        wrap: true,
      },
      {
        name: 'Người tạo',
        selector: (row) => row.user_created,
        format: (row) => formatDateDisplay(row.user_created),
        center: true,
        minWidth: '120px',
      },
      {
        name: 'Người sửa',
        selector: (row) => row.user_updated,
        format: (row) => formatDateDisplay(row.user_updated),
        center: true,
        minWidth: '120px',
      },
    ],
    showColumns: [0, 1],
  },
  pn1: {
    title: 'phiếu mua hàng',
    Form: FormPN1,
    Filter: FilterPN1,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPN1,
    showStatus: {
      showReturned: true,
      apiCodeReturn: 'pn5',
      linkReturn: '/list/pn5',
    },
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '80px',
        wrap: true,
      },
      {
        name: 'Ngày CT',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Nhà cung cấp',
        selector: (row) => row.ten_kh,
        minWidth: '140px',
        wrap: true,
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        minWidth: '120px',
        wrap: true,
      },
      {
        name: 'Tổng số lượng',
        selector: (row) => row.t_sl,
        format: (row) => numeralCustom(row.t_sl).format(),
        width: '120px',
        wrap: true,
        center: true,
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.t_tien_hang,
        format: (row) => numeralCustom(row.t_tien_hang).format(),
        minWidth: '100px',
        wrap: true,
      },
      {
        name: 'Tiền CK',
        selector: (row) => row.t_ck,
        format: (row) => numeralCustom(row.t_ck).format(),
        minWidth: '100px',
        wrap: true,
      },
      {
        name: 'Tiền phí',
        selector: (row) => row.t_cp_nt,
        format: (row) => numeralCustom(row.t_cp_nt).format(),
        minWidth: '100px',
        wrap: true,
      },
      {
        name: 'Tổng tiền',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        minWidth: '100px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  },
  pn5: {
    title: 'trả hàng mua',
    Filter: FilterPN5,
    Form: FormPN5,
    expandableRows: true,
    expandOnRowClicked: true,
    expandableRowsHideExpander: true,
    expandableRowsComponent: ExpandPn5,
    showStatus: true,
    columns: [
      {
        name: 'Số CT',
        selector: (row) => row.so_ct,
        width: '80px',
        wrap: true,
      },
      {
        name: 'Ngày CT',
        selector: (row) => row.ngay_ct,
        format: (row) => formatDateDisplay(row.ngay_ct),
        width: '100px',
        wrap: true,
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ma_kho,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Nhà cung cấp',
        selector: (row) => row.ma_kh,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tiền hàng',
        selector: (row) => row.t_tien_hang_nt,
        format: (row) => numeralCustom(row.t_tien_hang_nt).format(),
        minWidth: '100px',
        wrap: true,
      },
      {
        name: 'Tiền CK',
        selector: (row) => row.t_ck_nt,
        format: (row) => numeralCustom(row.t_ck_nt).format(),
        minWidth: '100px',
        wrap: true,
      },
      {
        name: 'Tiền trả',
        selector: (row) => row.t_tt_nt,
        format: (row) => numeralCustom(row.t_tt_nt).format(),
        minWidth: '100px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  dmcpmh: {
    title: 'chi phí mua hàng',
    Form: FormCPMH,
    Filter: FilterCPMH,
    columns: [
      {
        name: 'Mã phí',
        selector: (row) => row.ma_cp,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Tên phí',
        selector: (row) => row.ten_cp,
      },
    ],
    showColumns: [0, 1],
  },
  rpt: {
    title: 'mẫu in',
    Filter: FilterRPT,
    Form: FormRPT,
    columns: [
      {
        name: 'Mã CN',
        selector: (row) => row.ma_cn,
        width: '100px',
      },
      {
        name: 'Tên mẫu in',
        selector: (row) => row.ten_mau_in,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  ecompromotion_flashsales: {
    title: 'Flash sale',
    Form: FormFlashsale,
    Filter: FilterFlashsale,
    showStatus: true,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_chietkhau,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_chietkhau,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Từ ngày',
        selector: (row) => row.tu_ngay,
        format: (row) => formatDateDisplay(row.tu_ngay, 'DD/MM/YYYY HH:mm'),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Đến ngày',
        selector: (row) => row.den_ngay,
        format: (row) => formatDateDisplay(row.den_ngay, 'DD/MM/YYYY HH:mm'),
        width: '120px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  dmnhkh: {
    title: 'nhóm khách hàng',
    apiCode: 'dmnhkh',
    Form: FormNhomKhach,
    Filter: FilterNhomKhach,
    columns: [
      {
        name: 'Tên nhóm',
        selector: (row) => row.group_name,
      },
    ],
    showColumns: [0],
  },
  dmnhncc: {
    title: 'nhóm nhà cung cấp',
    apiCode: 'dmnhncc',
    Form: FormNhomNCC,
    Filter: FilterNhomNCC,
    columns: [
      {
        name: 'Tên nhóm',
        selector: (row) => row.group_name,
      },
    ],
    showColumns: [0],
  },
  dmgiaban: {
    title: 'gia bán',
    apiCode: 'dmgiaban',
    Form: FormGiaBan,
    Filter: FilterGiaBan,
    columns: [
      {
        name: 'Hàng hóa',
        selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
        cell: (row) => {
          return (
            <Typography>
              {row.ten_vt} ({row.ma_vt})
            </Typography>
          );
        },
        minWidth: '200px',
        wrap: true,
      },
      {
        name: 'Giá bán',
        selector: (row) => row.gia_ban_le,
        format: (row) => numeralCustom(row.gia_ban_le).format(),
        width: '100px',
        wrap: true,
      },
      {
        name: 'Từ ngày',
        selector: (row) => row.hieu_luc_tu,
        format: (row) => formatDateDisplay(row.hieu_luc_tu),
        width: '100px',
        wrap: true,
      },
      {
        name: 'Đến ngày',
        selector: (row) => row.hieu_luc_den,
        format: (row) => formatDateDisplay(row.hieu_luc_den),
        width: '100px',
        wrap: true,
      },
      {
        name: LABEL_KHO,
        selector: (row) => row.ten_kho,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Khách hàng',
        selector: (row) => row.ten_kh,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Nhóm khách',
        selector: (row) => row.ten_nh_kh,
        width: '120px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
  },
  dmchietkhau: {
    title: 'chiết khấu, giảm giá',
    apiCode: 'dmchietkhau',
    Form: FormChietKhau,
    Filter: FilterChietKhau,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma_chietkhau,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten_chietkhau,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Hàng hóa',
        selector: (row) => row.ten_vt,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Tỷ lệ Ck',
        selector: (row) => row.ty_le_ck,
        format: (row) => numeralCustom(row.ty_le_ck).format(),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tiền Ck',
        selector: (row) => row.tien_ck,
        format: (row) => numeralCustom(row.tien_ck).format(),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Từ ngày',
        selector: (row) => row.hieu_luc_tu,
        format: (row) => formatDateDisplay(row.hieu_luc_tu, 'DD/MM/YYYY HH:mm'),
        width: '150px',
        wrap: true,
      },
      {
        name: 'Đến ngày',
        selector: (row) => row.hieu_luc_den,
        format: (row) =>
          formatDateDisplay(row.hieu_luc_den, 'DD/MM/YYYY HH:mm'),
        width: '150px',
        wrap: true,
      },
      {
        name: 'Chi nhánh',
        selector: (row) => row.ten_kho,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Nhóm hàng',
        selector: (row) => row.ten_nvt,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Khách hàng',
        selector: (row) => row.ten_kh,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Nhóm khách',
        selector: (row) => row.ten_nh_kh,
        width: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 3, 4, 5, 6],
  },
  dmkhuyenmai: {
    title: 'khuyến mãi, quà tặng',
    Form: FormKhuyenMai,
    Filter: FilterKhuyenMai,
    columns: [
      {
        name: 'Ưu tiên',
        selector: (row) => row.stt_uu_tien,
        width: '80px',
        wrap: true,
      },
      {
        name: 'Sản phẩm mua',
        selector: (row) => row.ten_vt,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Chi nhánh',
        selector: (row) => row.ten_kho,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Hiệu lực từ',
        selector: (row) => row.hieu_luc_tu,
        format: (row) => formatDateDisplay(row.hieu_luc_tu, 'DD/MM/YYYY HH:mm'),
        width: '140px',
        wrap: true,
      },
      {
        name: 'Hiệu lực đến',
        selector: (row) => row.hieu_luc_den,
        format: (row) =>
          formatDateDisplay(row.hieu_luc_den, 'DD/MM/YYYY HH:mm'),
        width: '140px',
        wrap: true,
      },
      {
        name: 'Số lượng từ',
        selector: (row) => row.sl_tu,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Số lượng đến',
        selector: (row) => row.sl_den,
        width: '100px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4],
  },
  evoucher: {
    title: 'E-voucher',
    apiCode: 'evoucher',
    Form: FormEvoucher,
    Filter: FilterEvoucher,
    columns: [
      {
        name: 'Mã',
        selector: (row) => row.ma,
        width: '80px',
        wrap: true,
      },
      {
        name: 'Tên',
        selector: (row) => row.ten,
        width: '140px',
        wrap: true,
      },
      {
        name: 'Số lần SD',
        selector: (row) => row.so_lan_sd,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Đã SD (lần)',
        selector: (row) => row.so_lan_da_su_dung,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Từ ngày',
        selector: (row) => row.hieu_luc_tu,
        format: (row) => formatDateDisplay(row.hieu_luc_tu),
        width: '100px',
        wrap: true,
      },
      {
        name: 'Đến ngày',
        selector: (row) => row.hieu_luc_den,
        format: (row) => formatDateDisplay(row.hieu_luc_den),
        width: '100px',
        wrap: true,
      },
      {
        name: 'GT đơn từ (VND)',
        selector: (row) => row.gt_don_hang_toi_thieu,
        format: (row) => numeralCustom(row.gt_don_hang_toi_thieu).format(),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Số lượng thỏa ĐK',
        selector: (row) => row.sl_toi_thieu,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tỷ lệ giảm (%)',
        selector: (row) => row.ty_le,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tiền giảm (VND)',
        selector: (row) => row.so_tien,
        format: (row) => numeralCustom(row.so_tien).format(),
        width: '120px',
        wrap: true,
      },
      {
        name: 'Giảm tối đa (VND)',
        selector: (row) => row.so_tien_max,
        format: (row) => numeralCustom(row.so_tien_max).format(),
        width: '120px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  dmkhuvuc: {
    title: 'khu vực',
    Form: FormKhuVuc,
    Filter: FilterKhuVuc,
    columns: [
      {
        name: 'Mã khu vực',
        width: '150px',
        selector: (row) => row.ma_kv,
      },
      {
        name: 'Tên khu vực',
        width: '150px',
        selector: (row) => row.ten_kv,
      },
      {
        name: 'Quyền truy cập',
        width: '200px',
        wrap: true,
        selector: (row) =>
          QUYEN_TRUY_CAP.find((item) => item.value === row.visible_to)?.label,
      },
      {
        name: 'Ghi chú',
        selector: (row) => row.ghi_chu,
      },
    ],
    showColumns: [0, 1, 2, 3],
  },
  cdvt: {
    title: 'hàng tồn đầu kỳ',
    Form: FormCDVT,
    Filter: FilterCDVT,
    columns: [
      {
        name: 'Năm',
        width: '80px',
        selector: (row) => row.nam,
      },
      {
        name: 'Hàng hóa',
        width: '200px',
        wrap: true,
        selector: (row) => (row.ten_vt ? `${row.ten_vt} (${row.ma_vt})` : ''),
      },
      {
        name: 'Số lô',
        width: '140px',
        wrap: true,
        selector: (row) => row.ma_lo,
      },
      {
        name: LABEL_KHO,
        width: '150px',
        wrap: true,
        selector: (row) => row.ten_kho,
      },
      {
        name: 'Tồn đầu kỳ',
        width: '150px',
        wrap: true,
        selector: (row) => row.ton00,
      },
      {
        name: 'Giá trị tồn',
        width: '150px',
        wrap: true,
        selector: (row) => row.du00,
        format: (row) => numeralCustom(row.du00).format(),
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  dmqddvt: {
    title: 'quy đổi đơn vị tính',
    Form: FormQddvt,
    Filter: FilterQddvt,
    columns: [
      {
        name: 'Hàng hóa',
        selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
        grow: 1,
        minWidth: '200px',
      },
      {
        name: 'Đơn vị gốc',
        selector: (row) => row.ma_dvt_goc,
        width: '120px',
        center: true,
      },
      {
        name: 'Đơn vị quy đổi',
        selector: (row) => row.ma_dvt,
        width: '120px',
        center: true,
      },
      {
        name: 'Số lượng quy đổi',
        selector: (row) => row.tu,
        width: '120px',
        center: true,
      },
      {
        name: 'Giá bán',
        selector: (row) => row.gia_ban_nt,
        format: (row) => numeralCustom(row.gia_ban_nt).format(),
        width: '120px',
        center: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5],
  },
  thongtingiaohang: {
    title: 'thông tin giao hàng',
    Form: FormThongTinGiaoHang,
    Filter: FilterThongTinGiaoHang,
    columns: [
      {
        name: 'Tên người nhận',
        selector: (row) => row.exfields?.ten_nguoi_nhan,
        width: '150px',
        wrap: true,
      },
      {
        name: 'Khách hàng',
        selector: (row) => (row.ma_kh ? `${row.ten_kh} (${row.ma_kh})` : ''),
        width: '140px',
        wrap: true,
      },
      {
        name: 'Điện thoại',
        selector: (row) => row.dien_thoai,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Tỉnh / thành',
        selector: (row) => row.ten_tinh_thanh,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Quận / huyện',
        selector: (row) => row.ten_quan_huyen,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Xã / phường',
        selector: (row) => row.ten_xa_phuong,
        width: '120px',
        wrap: true,
      },
      {
        name: 'Số nhà, tên đường',
        selector: (row) => row.dia_chi,
        minWidth: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5, 6],
  },
  news2: {
    title: 'tin tức',
    Form: FormNews,
    Filter: FilterNews,
    columns: [
      {
        name: 'Tiêu đề',
        selector: (row) => row.title,
        width: '250px',
        wrap: true,
      },
      {
        name: 'Loại tin',
        selector: (row) => row.cate_name,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Ngày tạo',
        selector: (row) => row.date_created,
        format: (row) =>
          formatDateDisplay(row.date_created, 'DD/MM/YYYY HH:mm'),
        width: '140px',
        wrap: true,
      },
      {
        name: 'Ngày cập nhật',
        selector: (row) => row.date_updated,
        format: (row) =>
          formatDateDisplay(row.date_updated, 'DD/MM/YYYY HH:mm'),
        width: '140px',
        wrap: true,
      },
      {
        name: 'Người tạo',
        selector: (row) => row.name_user_created,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Người sửa',
        selector: (row) => row.name_user_updated,
        width: '100px',
        wrap: true,
      },
      {
        name: 'Hiển thị',
        selector: (row) => row.status,
        cell: (row) => (
          <CheckboxInput
            wrapperSx={{ justifyContent: 'center' }}
            checked={row.status}
          />
        ),
        width: '100px',
        wrap: true,
        center: true,
      },
    ],
    showColumns: [0, 1, 2, 3, 4, 5, 6],
  },
  groupnews: {
    title: 'loại tin',
    apiCode: 'group',
    Form: FormGroupNews,
    Filter: FilterGroupNews,
    columns: [
      {
        name: 'Tên chức vụ',
        selector: (row) => row.group_name,
        width: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1],
  },
  dmnhh: {
    title: 'nhóm hoa hồng',
    apiCode: 'dmnhh',
    Form: FormNHH,
    Filter: FilterDmnhh,
    columns: [
      {
        name: 'Tên nhóm',
        selector: (row) => row.ten,
        width: '200px',
        wrap: true,
      },
      {
        name: 'Hoa hồng (%)',
        selector: (row) => row.exfields.ty_le,
        width: '150px',
        wrap: true,
      },
    ],
    showColumns: [0, 1],
  },
};

export { dsDanhMuc };
