import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import FormLo from '../../lo/FormLo';
import FormProduct from '../../product/FormProduct';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { isArray } from 'lodash';
import SelectInput from '~/components/input/SelectInput';

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  sl_xuat: yup
    .number()
    .typeError('Số lượng tối thiểu là 1')
    .min(1, 'Số lượng tối thiểu là 1'),
});

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const defaultData = {
    vat_tu: null,
    don_vi_tinh: null,
    lo: null,
    gia_ban_le_goc: 0,
    sl_xuat: 0,
    ty_le_ck: 0,
    tien_ck_sl: 0,
    quy_cach: '',
    thue_suat: 0,
    tien_hang_nt: 0,
    tien_nt: 0,
    tien_xuat_nt: 0,
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? {
              ma_vt: defaultValues.ma_vt,
              ten_vt: defaultValues.ten_vt,
              ma_dvt: defaultValues.ma_dvt,
              ma_lo_yn: !!defaultValues.ma_lo,
              gia_ban_le: defaultValues.gia_ban_le_goc,
              thue_suat_nk: defaultValues.thue_suat,
              quy_cach: defaultValues.quy_cach,
            }
          : null,
        don_vi_tinh: defaultValues?.ma_dvt
          ? {
              ma_dvt: defaultValues?.ma_dvt || '',
              ten_dvt: defaultValues?.ten_dvt || '',
              gia_ban_nt: defaultValues.gia_ban_le_goc,
            }
          : null,
        lo: defaultValues.ma_lo ? { ma_lo: defaultValues.ma_lo } : null,
        tien_ck_sl: defaultValues?.tien_ck_nt
          ? defaultValues.tien_ck_nt / (defaultValues.sl_xuat || 1)
          : 0,
      }
    : defaultData;
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();
  const {
    handleSubmit,
    control,
    register,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: originalData,
  });
  const [dvts, setDvts] = useState([]);
  const [los, setLos] = useState([]);

  const vatTu = watch('vat_tu');
  const soLuongXuat = watch('sl_xuat');
  const thueSuat = watch('thue_suat');
  const giaBan = watch('gia_ban_nt');
  const tienCkSl = watch('tien_ck_sl');
  const giaBanLeGoc = watch('gia_ban_le_goc');

  const findDvts = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'dmqddvt',
        condition: { page: 1, limit: 999999, q: { ma_vt: vatTu?.ma_vt } },
      });
      if (resp && isArray(resp)) {
        let result = resp.map((item) => ({
          ma_dvt: item.ma_dvt,
          ten_dvt: item.ten_dvt,
          gia_ban: item.gia_ban_nt,
        }));
        result = result.filter((item) => item.ma_dvt !== vatTu.ma_dvt);
        result.push({
          ma_dvt: vatTu?.ma_dvt || '',
          ten_dvt: vatTu?.ten_dvt || '',
          gia_ban: vatTu.gia_ban_le,
        });
        setDvts(result);
      }
    } catch (error) {
      showAlert(error?.message || 'Lỗi khi tải thông tin đơn vị tính');
    }
  };

  const getLos = async () => {
    try {
      const data = await asyncSearchList({
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 999999,
          q: { status: true, ma_vt: vatTu?.ma_vt },
        },
      });
      if (data && Array.isArray(data)) {
        setLos(data);
      }
    } catch (error) {
      showAlert(error?.message || 'Lỗi khi lấy thông tin lô');
    }
  };

  const generateDataPost = (values) => {
    const { vat_tu, don_vi_tinh, lo, tien_ck_sl, sl_xuat, ...fields } = values;
    return {
      ...fields,
      ma_vt: vat_tu?.ma_vt || '',
      ten_vt: vat_tu?.ten_vt || '',
      ma_dvt: don_vi_tinh?.ma_dvt || '',
      ten_dvt: don_vi_tinh?.ten_dvt || '',
      ma_lo: lo?.ma_lo || '',
      han_sd: lo?.han_sd || '',
      sl_xuat,
      tien_ck_nt: tien_ck_sl * sl_xuat,
    };
  };

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(generateDataPost(values), isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  useEffect(() => {
    const newGiaBan = giaBanLeGoc - (tienCkSl || 0);
    setValue('gia_ban_nt', newGiaBan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giaBanLeGoc, tienCkSl]);

  useEffect(() => {
    const newTienHang =
      (giaBanLeGoc || 0) * (soLuongXuat || 0) * (1 + (thueSuat || 0) / 100);
    const newTienXuat =
      (giaBan || 0) * (soLuongXuat || 0) * (1 + (thueSuat || 0) / 100);
    setValue('tien_hang_nt', newTienHang);
    setValue('tien_xuat_nt', newTienXuat)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giaBanLeGoc, giaBan,  thueSuat, soLuongXuat]);

  useEffect(() => {
    if (vatTu?.ma_lo_yn) {
      getLos();
    }
    if (vatTu?.ma_vt) {
      findDvts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatTu]);

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} Chi tiết`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Box
        sx={{
          width: '100%',
          padding: '10px',
          maxHeight: 'calc(90vh - 20px - 39px - 39px)',
          overflow: 'auto',
        }}
      >
        <Grid container spacing={2}>
          {/* vat tu */}
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="vat_tu"
              render={({ field: { value, onChange } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Hàng hóa"
                  required
                  disabled={isEdit}
                  apiCode="dmvt"
                  placeholder="Chọn hàng bán"
                  searchFileds={['ma_vt', 'ten_vt']}
                  getOptionLabel={(option) => option.ten_vt}
                  selectedValue={value}
                  value={value || { ma_vt: '', ten_vt: '' }}
                  onSelect={(val) => {
                    onChange(val);
                    setValue('thue_suat', val?.thue_suat_nk || 0);
                    setValue('quy_cach', val?.quy_cach || '');
                  }}
                  FormAdd={FormProduct}
                  errorMessage={errors?.vat_tu?.message}
                />
              )}
            />
          </Grid>
          {/* don vi tinh */}
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="don_vi_tinh"
              render={({ field: { value, onChange } }) => (
                <SelectInput
                  required
                  label="Đơn vị tính"
                  labelWidth="30%"
                  placeholder="Chọn đơn vị tính"
                  value={value || { ma_dvt: '', ten_dvt: '' }}
                  selectedValue={value}
                  onSelect={(val) => {
                    onChange(val);
                    setValue('gia_ban_le_goc', val?.gia_ban || 0);
                  }}
                  options={dvts}
                  getOptionLabel={(option) => option.ma_dvt}
                  errorMessage={errors?.don_vi_tinh?.message}
                />
              )}
            />
          </Grid>
          {/* gia goc */}
          <Grid item xs={12} md={6}>
            <Controller
              name="gia_ban_le_goc"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  readOnly
                  label="Giá gốc"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid>
          {/* thue */}
          <Grid item xs={12} md={6}>
            <Controller
              name="thue_suat"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Thuế VAT"
                  InputProps={{ endAdornment: '%' }}
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    // luu gia tri
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid>
          {/* ty le ck */}
          <Grid item xs={12} md={6}>
            <Controller
              name="ty_le_ck"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Tỷ lệ CK"
                  value={numeralCustom(value).format()}
                  InputProps={{ endAdornment: '%' }}
                  onChange={(e) => {
                    // luu gia tri
                    const value = numeralCustom(e.target.value).value();
                    onChange(value);
                    // tinh lai tien ck
                    const newTienCkSl = (giaBanLeGoc * value) / 100;
                    setValue('tien_ck_sl', newTienCkSl);
                  }}
                />
              )}
            />
          </Grid>
          {/* tien ck */}
          <Grid item xs={12} md={6}>
            <Controller
              name="tien_ck_sl"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Tiền CK"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    // luu gia tri
                    const value = numeralCustom(e.target.value).value();
                    onChange(value);
                    setValue('ty_le_ck', 0);
                  }}
                />
              )}
            />
          </Grid>
          {/* gia ban */}
          <Grid item xs={12} md={6}>
            <Controller
              name="gia_ban_nt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  readOnly
                  label="Đơn giá"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid>
          {/* so luong */}
          <Grid item xs={12} md={6}>
            <Controller
              name="sl_xuat"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  required
                  type="number"
                  labelWidth="30%"
                  label="Số lượng"
                  // value={numeralCustom(value).format()}
                  value={value}
                  onChange={(e) => {
                    // const val = numeralCustom(e.target.value).value();
                    const val = e.target.value;
                    const newTienhang = (giaBanLeGoc || 0) * (val || 1);
                    setValue('tien_hang_nt', newTienhang);
                    onChange(val);
                  }}
                  errorMessage={errors?.sl_xuat?.message}
                />
              )}
            />
          </Grid>
          {/* quy cach */}
          <Grid item xs={12} md={6}>
            <TextInput
              readOnly
              labelWidth="30%"
              label="Quy cách"
              name="quy_cach"
              register={register}
              placeholder="Tự động theo hàng hóa"
            />
          </Grid>
          {/* tien hang */}
          <Grid item xs={12} md={6}>
            <Controller
              name="tien_hang_nt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  readOnly
                  labelWidth="30%"
                  label="Tiền hàng"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    // luu gia tri
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid>
          {/* tien xuat */}
          <Grid item xs={12} md={6}>
            <Controller
              name="tien_xuat_nt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  readOnly
                  labelWidth="30%"
                  label="Thành tiền"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    // luu gia tri
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid>
          {/* lo */}
          {vatTu?.ma_lo_yn && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="lo"
              render={({ field: { value, onChange } }) => (
                <SelectApiInput
                  disabled={!vatTu}
                  label="Lô - HSD"
                  labelWidth="30%"
                  apiCode="tontucthoi"
                  placeholder="Chọn lô hàng (nếu có)"
                  searchFileds={['ma_lo']}
                  condition={!!vatTu ? { ma_vt: vatTu?.ma_vt } : {}}
                  getOptionLabel={(option) => {
                    const currentLo = los.find(
                      (item) => item.ma_lo === option.ma_lo
                    );
                    return option.ma_lo
                      ? `${option.ma_lo} - ${formatDateDisplay(
                          currentLo?.han_sd,
                          'DD/MM/YYYY'
                        )} ${option.ton00 ? ` - Tồn: ${option.ton00}` : ''}`
                      : '';
                  }}
                  selectedValue={value}
                  value={value || { ma_lo: '', ton00: '' }}
                  onSelect={onChange}
                  FormAdd={FormLo}
                  errorMessage={errors?.lo?.message}
                />
              )}
            />
          </Grid>
          )}
        </Grid>
      </Box>
    </ModalBase>
  );
}

export default FormAddDetail;
