import React from 'react';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectMultiApiInput from '~/components/input/SelectMultiApiInput';
import FormUserGroup from '../../usergroup/FormUserGroup';

function UserGroupsSection({ show, setCollapses, control }) {
  return (
    <CollapseSection
      title="Nhóm người dùng có quyền"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, usergroups: !prev.usergroups }))
      }
    >
      <Box>
        <Controller
          control={control}
          name="usergroups"
          render={({ field: { onChange, value } }) => (
            <SelectMultiApiInput
              apiCode="usergroup"
              placeholder="Chọn nhóm người dùng"
              searchFileds={['group_name']}
              limitTags={5}
              getOptionLabel={(option) => option.group_name}
              value={value}
              onSelect={onChange}
              isOptionEqualToValue={(option, value) => {
                return option._id === value._id;
              }}
              FormAdd={FormUserGroup}
            />
          )}
        />
      </Box>
    </CollapseSection>
  );
}

export default UserGroupsSection;
