import React, { forwardRef } from 'react';
import AdminLayout from './AdminLayout';
import { Box, Grid, Stack, Typography } from '@mui/material';
import TableCustomPagination from '../table/TableCustomPagination';

function ListLayout(
  {
    title,
    apiCode,
    columns,
    data,
    getListData,
    handleRowClicked,
    handleRowPerPageChange,
    loading,
    paginationOption,
    setPaginationOption,
    setSelectedRows,
    toggleCleared,
    setToggleCleared,
    renderFilter,
    renderHeaderList,
    renderOuter,
    expandableRows,
    expandOnRowClicked,
    expandableRowsComponent,
    expandableRowsHideExpander,
    onlyList = false,
  },
  ref
) {
  const renderList = () => {
    return (
      <TableCustomPagination
        ref={ref}
        apiCode={apiCode}
        columns={columns}
        data={data}
        title={title}
        getListData={getListData}
        handleRowClicked={handleRowClicked}
        handleRowPerPageChange={handleRowPerPageChange}
        loading={loading}
        paginationOption={paginationOption}
        setPaginationOption={setPaginationOption}
        setSelectedRows={setSelectedRows}
        toggleCleared={toggleCleared}
        setToggleCleared={setToggleCleared}
        expandableRows={expandableRows}
        expandOnRowClicked={expandOnRowClicked}
        expandableRowsComponent={expandableRowsComponent}
        expandableRowsHideExpander={expandableRowsHideExpander}
      />
    );
  };
  if (onlyList) {
    return renderList();
  }
  return (
    <>
      {renderOuter?.()}
      <AdminLayout>
        <Box sx={{ padding: '10px 0' }}>
          <Grid container spacing="10px" alignItems="flex-start">
            <Grid item xs={0} md={2.5} xl={2}>
              {renderFilter?.()}
            </Grid>
            <Grid item xs={12} md={9.5} xl={10}>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                  Danh sách {title}
                </Typography>
                <Stack direction="row" spacing="5px">
                  {renderHeaderList?.()}
                </Stack>
              </Stack>
              {renderList()}
            </Grid>
          </Grid>
        </Box>
      </AdminLayout>
    </>
  );
}

export default forwardRef(ListLayout);
