import { Grid, Stack } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import DateInput from '~/components/input/DateInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import FormBoPhan from '../../dmbp/FormBoPhan';
import CheckboxInput from '~/components/input/CheckboxInput';
import FormParticipant from '../../participant/FormParticipant';

function InfoSection({ register, errors, control, isEdit }) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Grid container spacing="20px">
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              readOnly={isEdit}
              labelWidth="30%"
              label="Mã nhân viên"
              placeholder="Nhập hoặc tạo tự động"
              name="ma_nv"
              register={register}
            />
            <TextInput
              labelWidth="30%"
              label="Tên nhân viên"
              placeholder="Nhập tên nhân viên"
              name="ten_nv"
              required
              register={register}
              errorMessage={errors?.ten_nv?.message}
            />
            <TextInput
              labelWidth="30%"
              label="Điện thoại"
              placeholder="Nhập số điện thoại"
              name="dien_thoai"
              required
              register={register}
              errorMessage={errors?.dien_thoai?.message}
            />
            <Controller
              control={control}
              name="ngay_sinh"
              render={({ field: { onChange, value } }) => (
                <DateInput
                  labelWidth="30%"
                  label="Ngày sinh"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="chuc_vu"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Chức vụ"
                  apiCode="group"
                  placeholder="Chọn chức vụ"
                  searchFileds={['group_name']}
                  condition={{ status: true, group_type: 'DMCV' }}
                  getOptionLabel={(option) => option.group_name}
                  selectedValue={value}
                  value={value}
                  onSelect={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="lam_viec"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Còn làm việc"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <TextInput
              labelWidth="30%"
              label="Email"
              placeholder="Nhập email nhân viên"
              name="email"
              register={register}
              errorMessage={errors?.email?.message}
            />
            <TextInput
              labelWidth="30%"
              label="Địa chỉ"
              placeholder="Nhập địa chỉ nhân viên"
              name="dia_chi"
              register={register}
            />
            <TextInput
              labelWidth="30%"
              label="Mã quét vân tay"
              placeholder="Nhập mã trên máy quét vân tay"
              name="device_user_id"
              register={register}
            />
            <Controller
              control={control}
              name="bo_phan"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Bộ phận"
                  apiCode="dmbp"
                  placeholder="Chọn bộ phận"
                  searchFileds={['ma_bp', 'ten_bp']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_bp}
                  selectedValue={value}
                  value={value || { ma_bp: '', ten_bp: '' }}
                  onSelect={onChange}
                  FormAdd={FormBoPhan}
                />
              )}
            />
            <Controller
              control={control}
              name="account"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Tài khoản đăng nhập"
                  apiCode="participant"
                  placeholder="Chọn tài khoản đăng nhập"
                  searchFileds={['email', 'name']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.email}
                  selectedValue={value}
                  value={value || { email: '' }}
                  onSelect={onChange}
                  FormAdd={FormParticipant}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
