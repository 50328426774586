import React, { useEffect, useState } from 'react';
import { Collapse, Stack, TextField } from '@mui/material';
import { LuPencil } from 'react-icons/lu';

function NoteLine({ show = true, defaultValue, onNoteChange }) {
  const [text, setText] = useState(defaultValue || '');

  const handleNoteChange = (e) => {
    if (e.which === 13 && text !== defaultValue) {
      onNoteChange(text);
    }
  };

  useEffect(() => {
    setText(defaultValue || '');
  }, [defaultValue]);

  return (
    <Collapse in={show}>
      <Stack
        direction="row"
        spacing="10px"
        alignItems="center"
        sx={{
          width: '100%',
          height: '30px',
          marginTop: '5px',
          border: '1px solid',
          borderColor: 'whitish.gray',
          padding: '0 10px',
          borderRadius: '6px',
          transition: 'all linear 0.1s',
          '&:focus-within': {
            borderColor: 'primary.main',
          },
        }}
      >
        <LuPencil size={14} />
        <TextField
          variant="outlined"
          placeholder="Ghi chú hàng hóa"
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyUp={handleNoteChange}
          onBlur={() => handleNoteChange({ which: 13 })}
          autoComplete="off"
          sx={{
            '& .MuiInputBase-input': { padding: '5px' },
            '& fieldset': {
              border: 'none !important',
            },
          }}
        />
      </Stack>
    </Collapse>
  );
}

export default NoteLine;
