import React, { useEffect, useState } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import TextSelectApiInput from './TextSelectApiInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { Link } from 'react-router-dom';

function ChangeStatusInput({
  maCt,
  apiCode,
  apiCodeReturn,
  linkReturn,
  data,
  setLoad,
  showReturned,
}) {
  const showAlert = useAlertContext();
  const { asyncPutData } = useApisContext();

  const handleChangeStatus = async (trangThai) => {
    try {
      const resp = await asyncPutData({
        apiCode: apiCode,
        uniqueValue: data._id,
        data: {
          ...data,
          trang_thai: trangThai.ma_trang_thai,
          ten_trang_thai: trangThai.ten_trang_thai,
        },
      });
      if (!resp?.error) {
        showAlert({
          type: 'success',
          message: `Số chứng từ '${data.so_ct}' đã đổi trạng thái từ '${data.ten_trang_thai}' sang '${trangThai.ten_trang_thai}'`,
        });
        setLoad((prev) => prev + 1);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  return (
    <Stack spacing="2px">
      <TextSelectApiInput
        apiCode="trangthai"
        withIdApp={false}
        placeholder="Tìm kiếm trạng thái"
        onSelect={handleChangeStatus}
        searchFileds={['ma_trang_thai', 'ten_trang_thai']}
        condition={{
          ma_ct: maCt,
          ma_trang_thai: { $ne: data?.trang_thai || '' },
        }}
        getOptionLabel={(option) => option.ten_trang_thai}
        renderDisplay={(props) => (
          <Chip
            label={data?.ten_trang_thai}
            variant="filled"
            size="small"
            sx={{
              backgroundColor: data?.color,
              color: 'whitish.pureWhite',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: data?.color,
              },
            }}
            {...props}
          />
        )}
        menuWidth="250px"
      />
      {showReturned && (
        <Returned
          apiCodeReturn={apiCodeReturn}
          originId={data._id}
          to={linkReturn}
        />
      )}
    </Stack>
  );
}

export default ChangeStatusInput;

function Returned({ originId, apiCodeReturn, to = '' }) {
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();
  const [hd, setHd] = useState();

  // find hd3
  const findHd = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: apiCodeReturn,
        condition: {
          q: { details: { $elemMatch: { id_hd: originId } } },
        },
      });
      if (resp && Array.isArray(resp) && resp.length > 0) {
        setHd(resp[0]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };
  useEffect(() => {
    findHd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originId]);

  if (hd) {
    return (
      <Typography
        component={Link}
        to={to}
        state={{ so_ct: hd.so_ct }}
        sx={{
          fontSize: '12px',
          lineHeight: '12px',
          textAlign: 'center',
          '&:hover': {
            color: 'secondary.main',
          },
        }}
      >
        Xem đơn trả
      </Typography>
    );
  }
  return null;
}
