import React from 'react';
import ExpandPBL from '~/components/expand-row/ExpandPBL';
import ListBase from '~/components/listBase/ListBase';
import ModalBase from '~/components/modal/ModalBase';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import { LABEL_KHO } from '~/utils/label.constant';

const columns = [
  {
    name: 'Số CT',
    selector: (row) => row.so_ct,
    width: '80px',
  },
  {
    name: 'Ngày CT',
    selector: (row) => row.ngay_ct,
    format: (row) => formatDateDisplay(row.ngay_ct, 'DD/MM/YYYY HH:mm'),
    width: '140px',
  },
  {
    name: LABEL_KHO,
    selector: (row) => row.ten_kho,
    width: '120px',
    wrap: true,
  },
  {
    name: 'Khách hàng',
    selector: (row) => row.ten_kh,
    center: true,
    wrap: true,
    width: '120px',
  },
  {
    name: 'Nhân viên',
    selector: (row) => row.ten_nv,
    width: '120px',
    wrap: true,
    center: true,
  },
  {
    name: 'Tổng tiền hàng',
    selector: (row) => row.t_tien_nt,
    format: (row) => numeralCustom(row.t_tien_nt).format(),
    center: true,
    width: '120px',
  },
  {
    name: 'Tổng CK',
    selector: (row) => row.t_ck_nt,
    format: (row) => numeralCustom(row.t_ck_nt).format(),
    center: true,
    width: '120px',
  },
  {
    name: 'Thành tiền',
    selector: (row) => row.t_tt_nt,
    format: (row) => numeralCustom(row.t_tt_nt).format(),
    center: true,
    width: '120px',
  },
  {
    name: 'Phương thức thanh toán',
    selector: (row) => row.ten_pt_thanh_toan,
    width: '180px',
    right: true,
    wrap: true,
  },
];

function ModalList({ open, onClose, defaultCondition = {} }) {
  return (
    <ModalBase
      open={open}
      handleClose={onClose}
      title="Đơn hàng website"
      width="1200px"
    >
      <ListBase
        onlyList
        apiCode="hd2_web"
        title="đơn hàng website"
        columns={columns}
        showColumns={[0, 1, 3, 4, 5, 6, 7]}
        defaultCondition={defaultCondition}
        showStatus={true}
        expandableRows={true}
        expandOnRowClicked={true}
        expandableRowsHideExpander={true}
        expandableRowsComponent={(props) => (
          <ExpandPBL {...props} hideAction={true} />
        )}
      />
    </ModalBase>
  );
}

export default ModalList;
