import { initCurrentStore, updateStore } from '../reducrers/store.reducer';
import { isArray } from 'lodash';

export const getStores = async ({ asyncSearchList, showAlert, dispatch }) => {
  try {
    const data = await asyncSearchList({
      apiCode: 'dmkho',
      condition: { page: 1, limit: 10000, q: { status: true } },
    });
    if (data && isArray(data)) {
      dispatch(updateStore(data));
      if (data.length > 0) {
        dispatch(initCurrentStore(data[0]));
      }
    }
  } catch (error) {
    showAlert({
      type: 'error',
      message: error?.message || 'Something went wrong!',
    });
  }
};
