import React, { memo, useMemo } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';
import { useSelector } from 'react-redux';
import FilterRadios from '../FilterRadios';
import FilterList from '../FilterList';

const typeValues = [
  {
    label: 'Hàng HOT',
    value: 'hot',
  },
  {
    label: 'Hàng nổi',
    value: 'banner_small',
  },
  {
    label: 'Bán chạy',
    value: 'bestseller',
  },
  {
    label: 'Hàng mới',
    value: 'newproduct',
  },
  {
    label: 'Hàng giảm giá',
    value: 'ty_le_ck0',
    expr: { $gt: 0 },
  },
  {
    label: 'Có hóa đơn',
    value: 'exfields.hoa_don',
  },
];

function FilterProduct({ setCondition }) {
  const originFilter = {
    vat_tu: '',
    nhom_vat_tu: [],
    type: [],
    ton_kho: 'all',
    loai_vt: '',
    tg_sx: '',
    nha_sx: '',
    trang_thai: 'true',
  };
  const { data: productGroups } = useSelector((state) => state.productGroup);
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  const groups = useMemo(() => {
    if (!productGroups || !Array.isArray(productGroups)) return [];
    return (productGroups || []).reduce((acc, group) => {
      return [...acc, { label: group.ten_nvt, value: group._id }];
    }, []);
  }, [productGroups]);

  useEffect(() => {
    let condition = {};
    if (filter.vat_tu) {
      condition.$or = [
        {
          ma_vt: {
            $regex: filter.vat_tu.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_vt: {
            $regex: filter.vat_tu.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_vt_en: {
            $regex: filter.vat_tu.split(' ').join('.*'),
            $options: 'i',
          },
        },
      ];
    }
    if (filter.nha_sx) {
      condition.nha_sx = {
        $regex: filter.nha_sx.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.loai_vt) {
      condition.loai_vt = {
        $regex: filter.loai_vt,
        $options: 'i',
      };
    }
    if (filter.tg_sx) {
      condition.tg_sx = filter.tg_sx.trim();
    }
    if (filter.nhan_hieu) {
      condition.nhan_hieu = {
        $regex: filter.nhan_hieu.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if ((filter.nhom_vat_tu || []).length > 0) {
      condition.ma_nvt = { $in: filter.nhom_vat_tu };
    }
    if (filter.type.length > 0) {
      filter.type.forEach((item) => {
        const currentItem = typeValues.find((t) => t.value === item);
        if (currentItem.expr) {
          condition[item] = currentItem.expr;
        } else {
          condition[item] = true;
        }
      });
    }
    switch (filter.trang_thai) {
      case 'true':
        condition.status = true;
        break;
      case 'false':
        condition.status = false;
        break;
      default: // all
        condition.status = { $in: [true, false] };
        break;
    }
    setCondition(condition);
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Mã, tên hàng"
          placeholder="Tìm theo mã, tên, tên tiếng anh"
          value={filter.vat_tu}
          onSearch={(value) => setFilter({ ...filter, vat_tu: value })}
        />
        <FilterSearch
          title="Loại xe"
          placeholder="Tìm theo loại xe"
          value={filter.loai_vt}
          onSearch={(value) => setFilter({ ...filter, loai_vt: value })}
        />
        <FilterSearch
          title="Đời xe"
          placeholder="Tìm theo đời xe"
          value={filter.tg_sx}
          onSearch={(value) => setFilter({ ...filter, tg_sx: value })}
        />
        <FilterSearch
          title="Nhà sản xuất"
          placeholder="Tìm theo nhà sản xuất"
          value={filter.nha_sx}
          onSearch={(value) => setFilter({ ...filter, nha_sx: value })}
        />
        <FilterList
          title="Nhãn hiệu"
          items={groups}
          defaultValues={filter.nhom_vat_tu}
          onChange={(value) => setFilter({ ...filter, nhom_vat_tu: value })}
        />
        <FilterRadios
          title="Trạng thái"
          values={[
            { label: 'Tất cả', value: 'all' },
            { label: 'Đang kinh doanh', value: 'true' },
            { label: 'Ngừng kinh doanh', value: 'false' },
          ]}
          defaultValue={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default memo(FilterProduct);
