import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatDateDisplay, numeralCustom } from '~/utils/helpers';
import FormProduct from '../../product/FormProduct';
import FormDVT from '../../dvt/FormDVT';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import FormLo from '../../lo/FormLo';
import moment from 'moment';

const originSchema = {
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
};

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const defaultData = {
    vat_tu: null,
    don_vi_tinh: null,
    gia_von_nt: 0,
    sl_xuat: 0,
    tien_xuat_nt: 0,
    lo: null,
  };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? {
              ma_vt: defaultValues.ma_vt,
              ten_vt: defaultValues.ten_vt,
              ma_dvt: defaultValues.ma_dvt,
              ten_dvt: defaultValues.ten_dvt,
              ma_lo_yn: !!defaultValues.ma_lo,
            }
          : null,
        don_vi_tinh: defaultValues.ma_dvt
          ? { ma_dvt: defaultValues.ma_dvt }
          : null,
        lo: defaultValues.ma_lo
          ? { ma_lo: defaultValues.ma_lo, ten_lo: defaultValues.ten_lo }
          : null,
      }
    : defaultData;
  const [schema, setSchema] = useState(yup.object(originSchema));
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues ? originData : defaultData,
  });
  const vatTu = watch('vat_tu');
  const soLuongXuat = watch('sl_xuat');
  const giaVon = watch('gia_von_nt');

  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();

  const getLos = async () => {
    try {
      const data = await asyncSearchList({
        apiCode: 'dmlo',
        condition: {
          page: 1,
          limit: 999999,
          q: {
            status: true,
            ma_vt: vatTu?.ma_vt,
            han_sd: { $gt: moment().hours(0).minutes(0).seconds(0) },
          },
        },
      });
      if (data && Array.isArray(data) && data.length === 1) {
        setValue('lo', data[0]);
      } else {
        setValue('lo', null);
      }
    } catch (error) {
      showAlert(error?.message || 'Something went wrong!');
    }
  };

  useEffect(() => {
    // tinh tien hang
    const newTienXuat = (soLuongXuat || 0) * (giaVon || 0);
    setValue('tien_xuat_nt', newTienXuat || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soLuongXuat, giaVon]);

  useEffect(() => {
    if (vatTu) {
      if (vatTu.ma_lo_yn) {
        if (!isEdit) {
          getLos();
        }
        setSchema(
          yup.object({
            ...originSchema,
            lo: yup
              .object()
              .typeError('Vui lòng chọn lô')
              .required('Vui lòng chọn lô'),
          })
        );
      } else {
        setSchema(yup.object(originSchema));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatTu, isEdit]);

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} hàng mua`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="vat_tu"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                readOnly={isEdit}
                label="Hàng hóa"
                required
                apiCode="dmvt"
                placeholder="Hàng nhập kho"
                searchFileds={['ma_vt', 'ten_vt']}
                getOptionLabel={(option) => option.ten_vt}
                selectedValue={value}
                value={value || { ma_vt: '', ten_vt: '' }}
                onSelect={(val) => {
                  onChange(val);
                  setValue(
                    'don_vi_tinh',
                    !!val ? { ma_dvt: val.ma_dvt, ten_dvt: val.ten_dvt } : null
                  );
                  setValue('gia_von_nt', val?.gia_mua || 0);
                }}
                FormAdd={FormProduct}
                errorMessage={errors?.vat_tu?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="don_vi_tinh"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Đơn vị tính"
                readOnly
                apiCode="dmdvt"
                placeholder="Đơn vị tính tự động theo hàng hóa"
                searchFileds={['ma_dvt', 'ten_dvt']}
                getOptionLabel={(option) => option.ma_dvt}
                selectedValue={value}
                value={value || { ma_dvt: '' }}
                onSelect={onChange}
                FormAdd={FormDVT}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="gia_von_nt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Giá vốn"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = numeralCustom(e.target.value).value();
                  const newTienXuat = (val || 0) * soLuongXuat;
                  onChange(val);
                  setValue('tien_xuat_nt', newTienXuat);
                }}
                errorMessage={errors?.gia_von?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="sl_xuat"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  labelWidth="30%"
                  label="Số lượng xuất"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                    const newTienXuat = (val || 0) * (giaVon || 0);
                    setValue('tien_xua_nt', newTienXuat);
                  }}
                  errorMessage={errors?.sl_xuat?.message}
                />
              );
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="tien_xuat_nt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tiền xuất"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        {!!vatTu && vatTu.ma_lo_yn && (
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="lo"
              render={({ field: { value, onChange } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Lô - HSD"
                  required
                  apiCode="dmlo"
                  placeholder="Chọn lô"
                  searchFileds={['ma_lo', 'ten_lo']}
                  condition={{
                    ma_vt: vatTu.ma_vt,
                    han_sd: { $gt: moment().hours(0).minutes(0).seconds(0) },
                    status: true,
                  }}
                  getOptionLabel={(option) =>
                    `${option.ma_lo}${
                      option.han_sd
                        ? ` - ${formatDateDisplay(option.han_sd)}`
                        : ''
                    }`
                  }
                  selectedValue={value}
                  value={value || { ma_lo: '', ten_lo: '' }}
                  onSelect={onChange}
                  FormAdd={FormLo}
                  errorMessage={errors?.lo?.message}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </ModalBase>
  );
}

export default FormAddDetail;
