import React, { useEffect, useState } from 'react';
import FilterBox from './FilterBox';
import { Stack } from '@mui/material';
import CheckboxInput from '../input/CheckboxInput';

function FilterList({
  title,
  defaultValues = [],
  items = [{ value: '', label: 'Label' }],
  onChange = () => {},
}) {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState(defaultValues);

  const handleValueChange = (checked, value) => {
    if (checked) {
      setValues((prev) => [...prev, value]);
    } else {
      setValues((prev) => prev.filter((item) => item !== value));
    }
  };
  useEffect(() => {
    if (values.length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
    onChange(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);
  useEffect(() => {
    setValues(defaultValues);
  }, [defaultValues]);

  return (
    <FilterBox title={title} active={active}>
      <Stack sx={{ maxHeight: '50vh', overflow: 'auto' }}>
        {items.map((item) => (
          <CheckboxInput
            key={item.value}
            label={item.label}
            checked={values.includes(item.value)}
            onChange={(_, checked) => handleValueChange(checked, item.value)}
          />
        ))}
      </Stack>
    </FilterBox>
  );
}

export default FilterList;
