import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import SelectApiInput from '~/components/input/SelectApiInput';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';

const schema = yup.object({
  ten_nvt: yup.string().required('Vui lòng nhập tên nhóm'),
});

export default function FormNVT({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultValues,
        nhomMe: defaultValues.nh_me
          ? {
              _id: defaultValues.nh_me,
              ten_nvt: defaultValues.ten_nh_me,
            }
          : null,
      }
    : {
        ten_nvt: '',
        nhomMe: null,
        status: true,
        la_nhom_hang_hoa: false,
      };
  const { asyncPostData, asyncPutData } = useApisContext();

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          nhomMe: defaultValues.nh_me
            ? {
                _id: defaultValues.nh_me,
                ten_nvt: defaultValues.ten_nh_me,
              }
            : null,
        }
      : {},
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { nhomMe, ...data } = values;
    const result = { ...data };
    result.nh_me = nhomMe?._id || '';
    result.ten_nh_me = nhomMe?.ten_nvt || '';
    return result;
  };

  const handleSave = async (values, mode) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmnvt',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="800px"
        title="nhóm hàng"
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Grid container spacing="20px">
          <Grid item xs={12} md={6}>
            <TextInput
              label="Tên nhãn hiệu"
              labelWidth="30%"
              placeholder="KIA"
              name="ten_nvt"
              required
              register={register}
              errorMessage={errors?.ten_nvt?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="nhomMe"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Nhóm mẹ"
                  labelWidth="30%"
                  placeholder="Chọn nhóm mẹ"
                  apiCode="dmnvt"
                  searchFileds={['ten_nvt']}
                  condition={
                    isEdit && !!defaultValues
                      ? { _id: { $ne: defaultValues._id } }
                      : undefined
                  }
                  getOptionLabel={(option) => option.ten_nvt}
                  selectedValue={value}
                  value={value || { _id: '', ten_nvt: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Stack spacing="10px">
              <Controller
                control={control}
                name="la_nhom_hang_hoa"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Là nhóm hàng hóa"
                    name="la_nhom_hang_hoa"
                    checked={value || false}
                    onChange={onChange}
                  />
                )}
              />
              {isEdit && (
                <Controller
                  control={control}
                  name="status"
                  render={({ field: { onChange, value } }) => (
                    <CheckboxInput
                      label="Còn sử dụng"
                      name="status"
                      checked={value || false}
                      onChange={onChange}
                    />
                  )}
                />
              )}
            </Stack>
          </Grid> */}
        </Grid>
      </ModalForm>
    </>
  );
}
