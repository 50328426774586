import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectInput from '~/components/input/SelectInput';
import TextInput from '~/components/input/TextInput';
import { QUYEN_TRUY_CAP } from '~/utils/constants';

function InfoSection({ isEdit, errors, register, control }) {
  return (
    <CollapseSection show title="Thông tin">
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            required
            readOnly={isEdit}
            label="Mã khu vực"
            placeholder="Nhập mã khu vực"
            name="ma_kv"
            register={register}
            errorMessage={errors?.ma_kv?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Tên khu vực"
            placeholder="Nhập tên khu vực"
            name="ten_kv"
            required
            register={register}
            errorMessage={errors?.ten_kv?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="visible"
            render={({ field: { onChange, value } }) => {
              return (
                <SelectInput
                  labelWidth="30%"
                  label="Quyền truy cập"
                  placeholder="Chọn quyền truy cập"
                  value={value || { label: '', value: '' }}
                  selectedValue={value}
                  onSelect={onChange}
                  options={QUYEN_TRUY_CAP}
                  getOptionLabel={(option) => option.label}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
