import { IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';

function ChangeMode({ onChange = () => {} }) {
  const [isPercent, setIsPercent] = useState(false);

  useEffect(() => {
    onChange({ isPercent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPercent]);

  return (
    <IconButton
      onClick={() => setIsPercent(!isPercent)}
      size="small"
      sx={{
        fontSize: '10px',
        color: 'whitish.pureWhite',
        borderRadius: '2px 0px 0px 2px',
        width: '32px',
        height: '22px',
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.main',
        },
      }}
    >
      {isPercent ? '%' : 'VND'}
    </IconButton>
  );
}

export default ChangeMode;
