const { createSlice } = require('@reduxjs/toolkit');

const productSlice = createSlice({
  name: 'product',
  initialState: {
    data: [],
  },
  reducers: {
    updateProducts(state, action) {
      state.data = action.payload;
    },
  },
});

export const { updateProducts } = productSlice.actions;
export default productSlice.reducer;
