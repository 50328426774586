import { Box, Paper } from '@mui/material';
import React from 'react';
import Top from './top/Top';
import Info from './info/Info';
import Bottom from './bottom/Bottom';
import { ORDER_HEADER_HEIGHT } from '~/utils/constants';

function Right() {
  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100vh - ${ORDER_HEADER_HEIGHT} - 10px)`,
        overflow: 'auto',
      }}
    >
      <Paper sx={{ width: '100%', height: '100%', padding: '5px 10px' }}>
        <Top />
        <Info />
        <Bottom />
      </Paper>
    </Box>
  );
}

export default Right;
