const colors = [
  '#47cc58',
  '#DF2E38',
  '#005a9f',
  '#0240b8',
  '#40128B',
  '#900C3F',
  '#EA1179',
  '#BA704F',
];

const themes = [
  ...colors.map((color, index) => ({
    id: index,
    colors: {
      main: color,
      second: `${color}dd`,
      third: `${color}aa`,
      four: `${color}77`,
      fif: `${color}44`,
      opacity: `${color}22`,
    },
  })),
];

export default themes;
// 0123456789abcdef
