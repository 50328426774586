import React, { memo, useState } from 'react';
import ModalBase from './ModalBase';
import ButtonBase from '../button/ButtonBase';
import TableDisplay from '../table/TableDisplay';
import { IconButton, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { useEffect } from 'react';
import { CiExport } from 'react-icons/ci';
import { saveAs } from 'file-saver';
import { MdClose } from 'react-icons/md';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';

function ModalExportExcel({
  open,
  handleClose = () => {},
  apiCode = 'dmvt',
  filenameExport = 'san_pham.xlsx',
}) {
  const { callApi, asyncPostData } = useApisContext();
  const [, setBackdrop] = useBackdropContext();
  const showAlert = useAlertContext();

  const [fileExcels, setFileExcels] = useState([]);

  const getFileExcels = async () => {
    let condition = {
      code: apiCode,
    };
    condition = JSON.stringify(condition);
    const resp = await callApi({
      endpoint: `/exportexceltemplate?page=1&q=${condition}`,
      method: 'get',
    });
    if (resp && resp.length > 0) {
      setFileExcels(resp);
    }
  };

  // handle export excel
  const handleExportExcel = async (template) => {
    try {
      setBackdrop(true);
      const resp = await asyncPostData({
        method: 'get',
        apiCode,
        endpoint: `?type_data=xlsx&limit=1000000000&id_template=${template._id}`,
        data: {
          responseType: 'blob',
        },
      });
      saveAs(resp, filenameExport);
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Lỗi khi xuất file excel',
      });
    } finally {
      setBackdrop(false);
    }
  };

  useEffect(() => {
    if (apiCode) {
      getFileExcels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode]);

  const columns = useMemo(() => {
    return [
      {
        name: 'Tên file',
        selector: (row) => row.title,
        grow: 1,
      },
      {
        name: 'Xuất file',
        selector: (row) => (
          <IconButton onClick={() => handleExportExcel(row)}>
            <CiExport size={14} />
          </IconButton>
        ),
        grow: 1,
        right: true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalBase
      width="600px"
      title="File excel"
      open={open}
      handleClose={handleClose}
      sx={{ zIndex: '9999999' }}
      actions={[
        <ButtonBase
          key={1}
          onClick={handleClose}
          startIcon={<MdClose style={{ fontSize: '16px' }} />}
          sx={{
            backgroundColor: 'error.main',
            '&:hover': { backgroundColor: 'error.main' },
          }}
        >
          Đóng
        </ButtonBase>,
      ]}
    >
      <TableDisplay columns={columns} data={fileExcels} />
      <Stack sx={{ mt: 1 }}>
        <Typography sx={{ textAlign: 'center', color: 'secondary.main' }}>
          Vui lòng không thoát ra khi đang xuất file
        </Typography>
      </Stack>
    </ModalBase>
  );
}

export default memo(ModalExportExcel);
