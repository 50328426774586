import { Grid, Stack } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import CheckboxInput from '~/components/input/CheckboxInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';

function InfoSection({ isEdit, register, errors, control }) {
  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <TextInput
              readOnly={isEdit}
              label="Mã NCC"
              placeholder="Nhập hoặc tạo tự động"
              name="ma_kh"
              register={register}
            />
            <TextInput
              label="Tên NCC"
              placeholder="Dược Hậu Giang"
              name="ten_kh"
              register={register}
              required
              errorMessage={errors?.ten_kh?.message}
            />
            <Controller
              control={control}
              name="nhom_ncc"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Nhóm NCC"
                  apiCode="dmnhncc"
                  placeholder="Chọn nhóm nhà cung cấp"
                  searchFileds={['group_name']}
                  condition={{ isvendor: true }}
                  getOptionLabel={(option) => option.group_name}
                  selectedValue={value}
                  value={value || { _id: '', group_name: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              labelWidth="30%"
              label="Mã số thuế"
              placeholder="Mã số thuế"
              name="ma_so_thue"
              register={register}
            />
            <TextInput
              labelWidth="30%"
              label="Số điện thoại"
              placeholder="0358 500 772"
              name="dien_thoai"
              register={register}
              errorMessage={errors.dien_thoai?.message}
            />
            <TextInput
              labelWidth="30%"
              label="Email"
              placeholder="ntt@gmail.com"
              name="email"
              register={register}
              errorMessage={errors.email?.message}
            />
            {isEdit && (
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Còn hoạt động"
                    name="status"
                    checked={value || false}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
