import React, { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { Grid } from '@mui/material';
import TextInput from '~/components/input/TextInput';
import AreaInput from '~/components/input/AreaInput';

const schema = yup.object({});

export default function FormRPT({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const defaultData = {
    ma_cn: '',
    ten_mau_in: '',
    html_template: '',
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    return values;
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'rpt',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      showNavigator={false}
      width="1000px"
      title="mẫu in"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing={2}>
        <Grid item xs={16} md={6}>
          <TextInput
            label="Mã CN"
            labelWidth="25%"
            placeholder="Nhập mã chức năng"
            name="ma_cn"
            required
            register={register}
            errorMessage={errors?.ma_cn?.message}
          />
        </Grid>
        <Grid item xs={16} md={6}>
          <TextInput
            label="Tên mẫu in"
            labelWidth="25%"
            placeholder="Nhập tên mẫu in"
            name="ten_mau_in"
            required
            register={register}
            errorMessage={errors?.ten_mau_in?.message}
          />
        </Grid>
        <Grid item xs={16}>
          <AreaInput
            label="HTML mẫu in"
            labelWidth="12%"
            register={register}
            name="html_template"
            placeholder="Nhập html mẫu in"
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
}
