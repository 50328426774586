import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useToken from '~/hooks/useToken';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import { LOAI_VAT_TU } from '~/utils/constants';
import ModalForm from '~/components/modal/ModalForm';
import InfoGeneralSection from './info-general-section/InfoGeneralSection';
import DescriptionSection from './description-section/DescriptionSection';
import TonKhoSection from './tonkho-section/TonKhoSection';
import { Stack } from '@mui/material';
import DinhMucSection from './dinhmuc-section/DinhMucSection';
import ButtonBase from '~/components/button/ButtonBase';
import { FiLock, FiUnlock } from 'react-icons/fi';
import useAuthorize from '~/hooks/useAuthorize';
import TonDauKySection from './tondauky-section/TonDauKySection';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import DvtVariantSection from './dvtvariant-section/DvtVariantSection';

const schema = yup.object({
  ten_vt: yup.string().required('Vui lòng nhập tên hàng hóa'),
  don_vi_tinh: yup
    .object()
    .typeError('Vui lòng chọn đơn vị tính')
    .shape({
      ma_dvt: yup.string().required(),
      ten_dvt: yup.string().required(),
    })
    .required('Vui lòng chọn đơn vị tính'),
});

const defaultData = {
  ma_vt: '',
  ten_vt: '',
  ten_vt_en: '',
  loai_vt: '',
  tg_sx: '',
  nha_sx: '',
  nhom_vat_tu: null,
  don_vi_tinh: null,
  loai_vat_tu: null,
  xuat_xu: null,
  vi_tri: '',
  nhan_hieu: '', //nhan hieu
  gia_mua: 0,
  gia_ban_le: 0,
  ton_thoi_thieu: 0,
  ton_toi_da: 9999999,
  trong_luong: 0,
  ty_le_ck0: 0,
  hoa_don: false,
  ma_lo_yn: false,
  tg_tk: true,
  banner_small: false,
  hot: false,
  bestseller: false,
  newproduct: false,
  mieu_ta: '',
  thue_suat_nk: 0,
  status: true,
};

function FormProduct({
  open,
  defaultValues,
  isEdit = false,
  handleClose = () => {},
  setLoad = () => {},
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        hoa_don: defaultValues?.exfields?.hoa_don || false,
        nhom_vat_tu: defaultValues.ma_nvt
          ? { _id: defaultValues.ma_nvt, ten_nvt: defaultValues.ten_nvt }
          : null,
        don_vi_tinh: defaultValues.ma_dvt
          ? { ma_dvt: defaultValues.ma_dvt, ten_dvt: defaultValues.ten_dvt }
          : null,
        loai_vat_tu: defaultValues.ma_lvt
          ? LOAI_VAT_TU.find((item) => item.ma_lvt === defaultValues.ma_lvt)
          : null,
        xuat_xu: defaultValues.ma_xuat_xu
          ? {
              ma_xuat_xu: defaultValues.ma_xuat_xu,
              ten_xuat_xu: defaultValues.ten_xuat_xu,
            }
          : null,
      }
    : defaultData;
  const showAlert = useAlertContext();
  const { uploadFile, asyncPostData, asyncPutData } = useApisContext();
  const [, setBackdrop] = useBackdropContext();
  const token = useToken();
  const allowAuthorize = useAuthorize();
  const [thumbnails, setThumbnails] = useState({
    picture: null,
    picture2: null,
    picture3: null,
  });
  const [tonDayKys, setTonDauKys] = useState([]);
  const [dvts, setDvts] = useState([]);
  // const [dvts, setDvts] = useState(defaultValues?.ds_dvt || []);
  const [collapses, setCollapses] = useState({
    dvt_khac: true,
    dinh_muc: true,
    mieu_ta: true,
    info: true,
    lo: true,
    ton_kho: true,
    cdvt: true,
  });
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });


  const handleReset = () => {
    reset(originalData);
    // setDvts(defaultValues?.ds_dvt || []);
    setThumbnails({
      picture: null,
      picture2: null,
      picture3: null,
    });
  };

  const convertDataToPost = async (values) => {
    const {
      nhom_vat_tu,
      don_vi_tinh,
      xuat_xu,
      loai_vat_tu,
      hoa_don,
      ...fields
    } = values;
    const result = { ...fields, exfields: { hoa_don } };
    result.ma_nvt = nhom_vat_tu?._id || '';
    result.ten_nvt = nhom_vat_tu?.ten_nvt || '';
    result.ma_dvt = don_vi_tinh?.ma_dvt || '';
    result.ten_dvt = don_vi_tinh?.ten_dvt || '';
    result.ma_xuat_xu = xuat_xu?.ma_xuat_xu || '';
    result.ten_xuat_xu = xuat_xu?.ten_xuat_xu || '';
    result.ma_lvt = loai_vat_tu?.ma_lvt || '';

    // save picture
    const entrieImages = Object.entries(thumbnails);
    for (let i = 0; i < entrieImages.length; i++) {
      const entry = entrieImages[i];
      if (!!entry[1]) {
        const formData = new FormData();
        formData.append('file', entry[1]);
        const resp = await uploadFile({ formData, folder: 'products', token });
        if (resp) {
          result[entry[0]] = resp.fileUrl;
        }
      } else if (entry[1] === undefined) {
        result[entry[0]] = '';
      }
    }
    return result;
  };

  // handle save
  const handleSave = async (values, mode) => {
    const dataPost = await convertDataToPost(values);
    if (dataPost?.thue_suat_nk > 0 && !dataPost?.exfields?.hoa_don) {
      showAlert({
        type: 'warning',
        message: "Vui lòng chọn vào mục 'Có hóa đơn' cho hàng hóa có thuế VAT",
      });
      return;
    }
    const resp = await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmvt',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
    // ton dau ky
    if (resp?.ma_vt && tonDayKys?.length > 0 && !isEdit) {
      try {
        setBackdrop(true);
        for (let i = 0; i < tonDayKys.length; i++) {
          const dataToPost = tonDayKys[i];
          delete dataToPost._id;
          await asyncPostData({
            apiCode: 'cdvt',
            data: {
              ...dataToPost,
              ma_vt: resp.ma_vt,
              ten_vt: resp.ten_vt || '',
            },
          });
          if (dataToPost.ma_lo) {
            await asyncPostData({
              apiCode: 'dmlo',
              data: {
                ma_lo: dataToPost.ma_lo,
                ten_lo: dataToPost.ma_lo,
                han_sd: dataToPost.han_su_dung,
                ma_vt: resp.ma_vt,
                ten_vt: resp.ten_vt,
              },
            });
          }
        }
      } catch (error) {
        showAlert({
          type: 'error',
          message: error?.message || 'Something went wrong!',
        });
      } finally {
        setBackdrop(false);
      }
    }
    // don vi tinh
    if (resp?.ma_vt && dvts?.length > 0 && !isEdit) {
      try {
        setBackdrop(true);
        for (let i = 0; i < dvts.length; i++) {
          const dataToPost = dvts[i];
          delete dataToPost._id;
          await asyncPostData({
            apiCode: 'dmqddvt',
            data: {
              ...dataToPost,
              ma_dvt_goc: resp.ma_dvt,
              ma_vt: resp.ma_vt,
              ten_vt: resp.ten_vt || '',
            },
          });
        }
      } catch (error) {
        showAlert({
          type: 'error',
          message: error?.message || 'Lỗi khi tạo đơn vị tính',
        });
      } finally {
        setBackdrop(false);
      }
    }
  };

  useEffect(() => {
    reset(originalData);
    // setDvts(defaultValues?.ds_dvt || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="hàng hóa"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
      startActions={
        !!defaultValues
          ? [
              allowAuthorize({ type: 'update', module: 'dmvt' }) ? (
                <ButtonBase
                  key="action-1"
                  onClick={() => {
                    handleSave(
                      { ...originalData, status: !defaultValues.status },
                      2
                    );
                  }}
                  startIcon={
                    defaultValues.status === true ? (
                      <FiLock size={16} />
                    ) : (
                      <FiUnlock size={16} />
                    )
                  }
                  sx={
                    defaultValues.status === true
                      ? {
                          backgroundColor: 'warning.main',
                          '&:hover': { backgroundColor: 'warning.main' },
                        }
                      : {}
                  }
                >
                  {defaultValues.status === true
                    ? 'Ngừng kinh doanh'
                    : 'Cho phép kinh doanh'}
                </ButtonBase>
              ) : null,
            ]
          : []
      }
    >
      <Stack
        spacing="20px"
        sx={{
          width: '100%',
          padding: '10px',
          maxHeight: 'calc(90vh - 20px - 39px - 39px)',
          overflow: 'auto',
        }}
      >
        <InfoGeneralSection
          control={control}
          defaultValues={defaultValues}
          errors={errors}
          isEdit={isEdit}
          register={register}
          setThumbnails={setThumbnails}
        />
        {allowAuthorize({ type: 'view', module: 'dmqddvt' }) && (
          <DvtVariantSection
            isEdit={isEdit}
            show={collapses.dvt_khac}
            setCollapses={setCollapses}
            dvts={dvts}
            setDvts={setDvts}
            product={defaultValues}
          />
        )}
        <DinhMucSection
          control={control}
          setCollapses={setCollapses}
          show={collapses.dinh_muc}
        />
        {!isEdit && (
          <TonDauKySection
            show={collapses.cdvt}
            setCollapses={setCollapses}
            data={tonDayKys}
            setData={setTonDauKys}
          />
        )}
        <DescriptionSection
          control={control}
          show={collapses.mieu_ta}
          setCollapses={setCollapses}
        />
        {isEdit && allowAuthorize({ type: 'view', module: 'thnxt' }) && (
          <TonKhoSection
            maVt={defaultValues?.ma_vt}
            show={collapses.ton_kho}
            setCollapses={setCollapses}
          />
        )}
      </Stack>
    </ModalForm>
  );
}

export default FormProduct;
