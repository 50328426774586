import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';
import FilterRadios from '../FilterRadios';

function FilterPTTT({ setCondition }) {
  const originFilter = { pttt: '', trang_thai: 'true' };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.pttt) {
      condition.ten = { $regex: filter.pttt, $options: 'i' };
    }
    switch (filter.trang_thai) {
      case 'true':
        condition.status = true;
        break;
      case 'false':
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Tên phương thức"
          placeholder="Tìm theo tên"
          value={filter.pttt}
          onSearch={(value) => setFilter({ ...filter, pttt: value })}
        />
        <FilterRadios
          title="Trạng thái"
          values={[
            { label: 'Tất cả', value: 'all' },
            { label: 'Còn sử dụng', value: 'true' },
            { label: 'Không sử dụng', value: 'false' },
          ]}
          defaultValue={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterPTTT;
