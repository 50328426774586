import React from 'react';
import { VscSymbolColor } from 'react-icons/vsc';
import { Grid, Box, Button } from '@mui/material';
import useResponsive from '~/hooks/useResponsive';
import themes from '~/utils/themes';
import { useGlobalTheme } from '~/context/themeContext';
import MenuHover from '~/components/menu/MenuHover';

function Themes({ renderButton }) {
  const mdMatches = useResponsive({ matchKey: 'up', breakpoint: 'md' });
  const [themeId, setThemeId] = useGlobalTheme();

  const handleChangeTheme = (theme) => {
    setThemeId(theme.id);
  };

  return (
    <MenuHover
      content={
        <Box sx={{ padding: '5px' }}>
          <Box sx={{ width: mdMatches ? '80px' : '168px' }}>
            <Grid container spacing="5px">
              {themes.map((theme) => (
                <Grid key={theme.id} item xs={3}>
                  <Box
                    sx={{
                      width: '100%',
                      paddingTop: '100%',
                      backgroundColor: theme.colors.main,
                      cursor: 'pointer',
                      pointerEvents: themeId === theme.id ? 'none' : 'all',
                      borderRadius: themeId === theme.id ? '50%' : '2px',
                      '&:hover': { opacity: '0.9' },
                    }}
                    onClick={() => handleChangeTheme(theme)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      }
    >
      <Button
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          textTransform: 'none',
          color: 'initial',
          '&:hover': {
            backgroundColor: 'unset',
          },
        }}
        endIcon={<VscSymbolColor size={14} />}
      >
        Chủ đề
      </Button>
    </MenuHover>
  );
}

export default Themes;
