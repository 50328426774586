import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';
import FilterContainer from '../FilterContainer';

function FilterPhuCap({ setCondition }) {
  const originFilter = { phu_cap: '' };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = { status: true, group_type: 'PHUCAP' };
    if (filter.phu_cap) {
      condition.group_name = {
        $regex: filter.phu_cap.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Tên phụ cấp"
          placeholder="Tìm theo tên"
          value={filter.phu_cap}
          onSearch={(value) => setFilter({ phu_cap: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterPhuCap;
