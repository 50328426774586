import React, { useState } from 'react';
import ModalBase from './ModalBase';
import ButtonBase from '../button/ButtonBase';
import { MdClose } from 'react-icons/md';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { Box } from '@mui/material';
import SelectApiInput from '../input/SelectApiInput';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';
import useApisContext from '~/hooks/hookContext/useApisContext';

function ModalChuyenKhuVuc({
  open,
  handleClose,
  selectedRows = [],
  setSelectedRows,
  setToggleCleared,
  setLoad,
}) {
  const [, setBackdrop] = useBackdropContext();
  const { asyncPutData } = useApisContext();
  const showAlert = useAlertContext();
  const [selectedKhuVuc, setSelectedKhuVuc] = useState();

  const handleCloseForm = () => {
    handleClose();
    setSelectedKhuVuc(undefined);
  };

  // chuyen nhom hang
  const handleChuyenNhomHang = async () => {
    try {
      setBackdrop(true);
      let item;
      for (let i = 0; i < selectedRows.length; i++) {
        item = selectedRows[i];
        await asyncPutData({
          apiCode: 'tinhthanh',
          uniqueValue: item._id,
          data: { ma_nvt: selectedKhuVuc._id },
        });
        if (i === selectedRows.length - 1) {
          showAlert({
            type: 'success',
            message: 'Đã chuyển khu vực thành công',
          });
          handleCloseForm();
        }
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setBackdrop(false);
      setLoad((prev) => prev + 1);
      setSelectedRows([]);
      setToggleCleared((prev) => !prev);
    }
  };

  return (
    <ModalBase
      width="500px"
      title="Chuyển khu vực"
      open={open}
      handleClose={handleCloseForm}
      actions={[
        <ButtonBase
          key={1}
          disabled={!selectedKhuVuc}
          onClick={handleChuyenNhomHang}
        >
          Chuyển
        </ButtonBase>,
        <ButtonBase
          key={2}
          onClick={handleCloseForm}
          startIcon={<MdClose style={{ fontSize: '16px' }} />}
          sx={{
            backgroundColor: 'error.main',
            '&:hover': { backgroundColor: 'error.main' },
          }}
        >
          Đóng
        </ButtonBase>,
      ]}
    >
      <Box>
        <SelectApiInput
          labelWidth="30%"
          label="Chuyển sang khu vực"
          placeholder="Chọn khu vực chuyển"
          apiCode="dmkhuvuc"
          searchFileds={['ma_kv', 'ten_kv']}
          getOptionLabel={(option) => option.ten_kv}
          selectedValue={selectedKhuVuc}
          value={selectedKhuVuc || { ma_kv: '', ten_kv: '' }}
          onSelect={(value) => setSelectedKhuVuc(value)}
        />
      </Box>
    </ModalBase>
  );
}

export default ModalChuyenKhuVuc;
