import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';
import FilterContainer from '../FilterContainer';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import FilterList from '../FilterList';
import FilterSelectApi from '../FilterSelectApi';

function FilterBoPhan({ setCondition }) {
  const originFilter = {
    bo_phan: '',
    phu_trach: null,
    parent: [],
  };
  const showAlert = useAlertContext();
  const { asyncSearchList } = useApisContext();
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);
  const [boPhans, setBoPhans] = useState([]);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  const getAllBoPhan = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'dmbp',
        condition: { page: 1, limit: 99999 },
      });
      if (resp && Array.isArray(resp) && resp.length > 0) {
        const parents = resp
          .filter((item) => !!item.ma_bp_me)
          .map((item) => item.ma_bp_me);
        const bophans = resp.reduce((acc, bp) => {
          if (parents.includes(bp.ma_bp)) {
            return [...acc, { label: bp.ten_bp, value: bp.ma_bp }];
          } else {
            return acc;
          }
        }, []);
        setBoPhans(bophans);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  useEffect(() => {
    let condition = {};
    if (filter.bo_phan) {
      condition.$or = [
        {
          ma_bp: {
            $regex: filter.bo_phan.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_bp: {
            $regex: filter.bo_phan.split(' ').join('.*'),
            $options: 'i',
          },
        },
        { $text: { $search: filter.bo_phan } },
      ];
    }
    if ((filter.parent || []).length > 0) {
      condition.ma_bp_me = { $in: filter.parent };
    }
    if (filter.phu_trach) {
      condition.phu_trach = filter.phu_trach.email;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  useEffect(() => {
    getAllBoPhan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Mã, tên bộ phân"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.bo_phan}
          onSearch={(value) => setFilter({ ...filter, bo_phan: value })}
        />
        <FilterSelectApi
          title="Người phụ trách"
          apiCode="participant"
          placeholder="Chọn người phụ trách"
          searchFileds={['name', 'email']}
          condition={{ status: true }}
          getOptionLabel={(option) => option.name}
          selectedValue={filter.phu_trach}
          value={filter.phu_trach || { name: '', email: '' }}
          onSelect={(newValue) => setFilter({ ...filter, phu_trach: newValue })}
        />
        {boPhans?.length > 0 && (
          <FilterList
            title="Bộ phận cha"
            items={boPhans}
            defaultValues={filter.parent}
            onChange={(value) => setFilter({ ...filter, parent: value })}
          />
        )}
      </Stack>
    </FilterContainer>
  );
}

export default FilterBoPhan;
