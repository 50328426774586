import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { numeralCustom, postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import SelectApiInput from '~/components/input/SelectApiInput';

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  dvt: yup
    .object()
    .typeError('Vui lòng chọn đơn vị tính quy đổi')
    .required('Vui lòng chọn đơn vị tính quy đổi'),
  tu: yup.string().required('Vui lòng nhập số lượng quy đổi'),
});

export default function FormQddvt({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
  onSubmit,
}) {
  const defaultData = {
    vat_tu: null,
    dvt: null,
    tu: 1,
    mau: 1,
    ma_dvt_goc: '',
    gia_ban_nt: 0,
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues?.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : null,
        dvt: defaultValues?.ma_dvt
          ? { ma_dvt: defaultValues.ma_dvt, ten_dvt: defaultValues.ten_dvt }
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    setValue,
    reset,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: defaultData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { vat_tu, dvt, ...fields } = values;
    return {
      ...fields,
      ma_vt: vat_tu?.ma_vt || '',
      ten_vt: vat_tu?.ten_vt || '',
      ma_dvt: dvt?.ma_dvt || '',
      ten_dvt: dvt?.ten_dvt || '',
      mau: 1,
    };
  };

  const handleSave = async (values, mode) => {
    const dataPost = generateDataPost(values);
    if (!!onSubmit) {
      handleClose();
      reset(originalData);
      onSubmit(dataPost);
    } else {
      await postOrPutDataDanhMuc({
        isEdit,
        asyncPostData,
        asyncPutData,
        apiCode: 'dmqddvt',
        data: dataPost,
        handleClose: mode === 1 ? null : handleClose,
        reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
        dataReset: originalData,
        setLoad,
      });
    }
  };

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="900px"
        title="quy đổi"
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="vat_tu"
              render={({ field: { onChange, value } }) => {
                return (
                  <SelectApiInput
                    required
                    readOnly={isEdit}
                    label="Hàng hóa"
                    labelWidth="30%"
                    apiCode="dmvt"
                    placeholder="Chọn một hàng hóa"
                    searchFileds={['ma_vt', 'ten_vt']}
                    getOptionLabel={(option) => option.ten_vt}
                    selectedValue={value}
                    value={value || { ma_vt: '', ten_vt: '' }}
                    onSelect={(val) => {
                      setValue('ma_dvt_goc', val.ma_dvt);
                      onChange(val);
                    }}
                    errorMessage={errors?.vat_tu?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              readOnly
              label="Đơn vị tính gốc"
              labelWidth="30%"
              placeholder="Đơn vị tính tự động theo hàng hóa"
              name="ma_dvt_goc"
              register={register}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="dvt"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  readOnly={isEdit}
                  label="Đơn vị tính"
                  labelWidth="30%"
                  apiCode="dmdvt"
                  placeholder="Chọn đơn vị tính quy đổi"
                  searchFileds={['ma_dvt', 'ten_dvt']}
                  getOptionLabel={(option) => option.ten_dvt}
                  selectedValue={value}
                  value={value || { ma_dvt: '', ten_dvt: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.dvt?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              required
              name="tu"
              type="number"
              labelWidth="30%"
              label="Số lượng quy đổi"
              placeholder="VD: 24 hộp là một thùng"
              register={register}
              errorMessage={errors?.mau?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="gia_ban_nt"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Giá bán"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </ModalForm>
    </>
  );
}
