import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';
import FilterSelectApi from '../FilterSelectApi';
import FilterRadios from '../FilterRadios';
import moment from 'moment';

function FilterLo({ setCondition }) {
  const originFilter = {
    lo: '',
    vat_tu: null,
    expire: 'all',
    trang_thai: 'true',
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.lo) {
      condition.$or = [
        {
          ma_lo: {
            $regex: filter.lo.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_lo: {
            $regex: filter.lo.split(' ').join('.*'),
            $options: 'i',
          },
        },
        { $text: { $search: filter.lo } },
      ];
    }
    if (filter.vat_tu) {
      condition.ma_vt = filter.vat_tu.ma_vt;
    }
    const today = moment().format('YYYY-MM-DD');
    switch (filter.expire) {
      case 'expired':
        condition.han_sd = { $lt: moment().format('YYYY-MM-DD') };
        break;
      case 'three_months_future':
        const threeMonthsFuture = moment()
          .add(3, 'months')
          .format('YYYY-MM-DD');
        condition.han_sd = { $lte: threeMonthsFuture, $gte: today };
        break;
      case 'expire_this_year':
        const thisYear = moment().endOf('years');
        condition.han_sd = { $lte: thisYear, $gte: today };
        break;
      default:
        break;
    }
    switch (filter.trang_thai) {
      case 'true':
        condition.status = true;
        break;
      case 'false':
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Mã, tên lô"
          placeholder="Tìm theo mã hoặc tên lô"
          value={filter.lo}
          onSearch={(value) => setFilter({ ...filter, lo: value })}
        />
        <FilterSelectApi
          apiCode="dmvt"
          searchFileds={['ma_vt', 'ten_vt']}
          title="Hàng hóa"
          placeholder="Chọn một hàng hóa"
          getOptionLabel={(option) => option.ten_vt}
          selectedValue={filter.vat_tu}
          condition={{ ma_lo_yn: true }}
          value={filter.vat_tu || { ma_vt: '', ten_vt: '' }}
          onSelect={(value) => setFilter({ ...filter, vat_tu: value })}
        />
        <FilterRadios
          title="Hạn sử dụng"
          values={[
            { label: 'Tất cả', value: 'all' },
            { label: 'Đã hết hạn', value: 'expired' },
            { label: 'Trong 3 tháng tới', value: 'three_months_future' },
            { label: 'Trong năm nay', value: 'expire_this_year' },
          ]}
          defaultValue={filter.expire}
          onChange={(value) => setFilter({ ...filter, expire: value })}
        />
        <FilterRadios
          title="Trạng thái"
          values={[
            { label: 'Tất cả', value: 'all' },
            { label: 'Còn sử dụng', value: 'true' },
            { label: 'Không sử dụng', value: 'false' },
          ]}
          defaultValue={filter.trang_thai}
          onChange={(value) => setFilter({ ...filter, trang_thai: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterLo;
