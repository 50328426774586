import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { numeralCustom } from '~/utils/helpers';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';
import SelectInput from '~/components/input/SelectInput';

const schema = yup.object({
  detail: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  sl_nhap: yup
    .number()
    .typeError('Số lượng không đúng định dạng')
    .min(1, 'Số lượng tối thiểu là 1')
    .required('Vui lòng nhập số lượng'),
});

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
  hd2,
}) {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          detail: defaultValues.ma_vt ? defaultValues : null,
        }
      : {
          sl_nhap: 1,
          tien_ck: 0,
          ty_le_ck: 0,
        },
  });

  const localDetail = watch('detail');

  const detailHd2 = useMemo(() => {
    if (!hd2 || !localDetail) return null;
    if (localDetail.ma_lo) {
      return hd2.details.find(
        (item) => item.ma_vt === localDetail.ma_vt && item.ma_lo === localDetail.ma_lo
      );
    }else {
      return hd2.details.find(
        (item) => item.ma_vt === localDetail.ma_vt
      );
    }
  }, [hd2, localDetail]);

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} hàng trả`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="detail"
            render={({ field: { value, onChange } }) => (
              <SelectInput
                required
                labelWidth="30%"
                label="Hàng hóa"
                options={hd2?.details || []}
                placeholder="Chọn hàng trả"
                getOptionLabel={(option) => option.ten_vt}
                selectedValue={value}
                value={value || { ma_vt: '', ten_vt: '', ma_lo: '' }}
                onSelect={(val) => {
                  onChange(val);
                  setValue('ma_dvt', val?.ma_dvt || '');
                  setValue('gia_ban', val?.gia_ban ||'');
                  setValue('sl_nhap', val?.sl_xuat ||'');
                  setValue('tien_ck', val?.tien_ck ||'');
                  setValue('ty_le_ck', val?.ty_le_ck || '');
                  setValue('tien_nhap', val?.tien_xuat || '');
                  setValue('tien', val?.tien ||'');
                  setValue('ma_lo', val?.ma_lo || '');
                }}
                errorMessage={errors?.detail?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            readOnly
            labelWidth="30%"
            label="Đơn vị tính"
            placeholder="Đơn vị tính tự động theo hàng trả"
            name="ma_dvt"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="gia_ban"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                readOnly
                label="Giá bán"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="sl_nhap"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  required
                  labelWidth="30%"
                  type="number"
                  label="Số lượng"
                  value={value}
                  onChange={(e) => {
                    let val = e.target.value;
                    if (Number(val) < 1) val = 1;
                    onChange(val);
                    // tinh lai tien_ck
                    const newTienCk =
                      (((detailHd2?.gia_ban || 0) *
                        (detailHd2?.ty_le_ck || 0)) /
                        100) *
                      Number(val);
                    // tinh lai tien hang
                    const newTienHang = (detailHd2?.gia_ban || 0) * Number(val);
                    setValue('tien', newTienHang);
                    // tinh lai tien nhap
                    const newTienNhap =
                      (detailHd2?.gia_ban || 0) * Number(val) - newTienCk;
                    setValue('tien_ck', newTienCk);
                    setValue('tien_nhap', newTienNhap);
                  }}
                  textFieldProps={{ inputProps: { min: 1 } }}
                  errorMessage={errors?.sl_nhap?.message}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="ty_le_ck"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tỷ lệ CK (%)"
                readOnly
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  // luu gia tri
                  const value = numeralCustom(e.target.value).value();
                  onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tien_ck"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                readOnly
                labelWidth="30%"
                label="Tiền chiết khấu"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  // luu gia tri
                  const value = numeralCustom(e.target.value).value();
                  onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tien"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                readOnly
                labelWidth="30%"
                label="Tiền hàng"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  // luu gia tri
                  const value = numeralCustom(e.target.value).value();
                  onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tien_nhap"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                readOnly
                labelWidth="30%"
                label="Tiền trả lại"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  // luu gia tri
                  const value = numeralCustom(e.target.value).value();
                  onChange(value);
                }}
              />
            )}
          />
        </Grid>
        {detailHd2?.ma_lo && (
          <Grid item xs={12} md={6}>
            <TextInput
              label="Số lô"
              placeholder="Số lô tự động theo hàng trả"
              labelWidth="30%"
              name="ma_lo"
              register={register}
              readOnly
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <CollapseSection title="Ghi chú" show>
            <AreaInput
              labelWidth="0%"
              placeholder="Nhập ghi chú sản phẩm"
              name="dien_giai"
              register={register}
            />
          </CollapseSection>
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormAddDetail;
