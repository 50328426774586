import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';

function FilterNghiepVu({ setCondition }) {
  const [filter, setFilter] = useState({
    ma_ct: '',
    nghiep_vu: '',
  });

  useEffect(() => {
    const condition = {
      $or: [
        {
          ma_nghiep_vu: {
            $regex: filter.nghiep_vu.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_nghiep_vu: {
            $regex: filter.nghiep_vu.split(' ').join('.*'),
            $options: 'i',
          },
        },
      ],
    };
    if (filter.ma_ct) {
      condition.ma_ct = {
        $regex: filter.ma_ct,
        $options: 'i',
      };
    }
    setCondition(condition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <Stack sx={{ width: '100%' }} spacing="10px">
      <FilterSearch
        title="Mã chứng từ"
        onSearch={(value) => setFilter({ ...filter, ma_ct: value })}
      />
      <FilterSearch
        title="Mã, tên nghiệp vụ"
        onSearch={(value) => setFilter({ ...filter, nghiep_vu: value })}
      />
    </Stack>
  );
}

export default FilterNghiepVu;
