import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { v4 } from 'uuid';
import * as yup from 'yup';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import ModalBase from '~/components/modal/ModalBase';
import FormDVT from '../../dvt/FormDVT';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';
import { LABEL_KHO } from '~/utils/label.constant';
import DateInput from '~/components/input/DateInput';
import moment from 'moment';

const schema = yup.object({
  kho: yup
    .object()
    .typeError(`Vui lòng chọn ${LABEL_KHO}`)
    .required(`Vui lòng chọn ${LABEL_KHO}`),
  nam: yup.string().required('Vui lòng nhập năm đầu kỳ'),
  ton00: yup
    .number()
    .typeError('Vui lòng nhập tồn đầu kỳ')
    .required('Vui lòng nhập tồn đầu kỳ'),
});

function FormAddTonDayKy({
  open,
  handleClose,
  isEdit,
  addTonDauKy,
  defaultValue = {},
}) {
  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValue
      ? {
          ...defaultValue,
          kho: defaultValue.ma_kho
            ? { ma_kho: defaultValue.ma_kho, ten_kho: defaultValue.ten_kho }
            : null,
        }
      : {
          ton00: 0,
          du00: 0,
          nam: moment().year(),
        },
  });

  const generateDataAdd = (values) => {
    const { kho, ...fields } = values;
    return {
      ...fields,
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
    };
  };

  // handle save
  const handleSave = (values) => {
    const data = generateDataAdd(values);
    addTonDauKy(data, isEdit);
    handleClose();
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="400px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} tồn đầu kỳ`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase
          key={v4()}
          onClick={handleClose}
          startIcon={<MdClose style={{ fontSize: '16px' }} />}
          sx={{
            backgroundColor: 'error.main',
            '&:hover': { backgroundColor: 'error.main' },
          }}
        >
          Đóng
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="kho"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                required
                label={LABEL_KHO}
                apiCode="dmkho"
                placeholder={`Chọn ${LABEL_KHO}`}
                searchFileds={['ma_kho', 'ten_kho']}
                getOptionLabel={(option) => option.ten_kho}
                selectedValue={value}
                value={value || { ma_kho: '', ten_kho: '' }}
                onSelect={onChange}
                FormAdd={FormDVT}
                errorMessage={errors?.kho?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Năm đầu kỳ"
            labelWidth="30%"
            placeholder="Nhập năm đầu kỳ"
            name="nam"
            required
            register={register}
            errorMessage={errors?.nam?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            type="number"
            label="Tồn đầu kỳ"
            labelWidth="30%"
            placeholder="Nhập tồn kho hàng hóa"
            name="ton00"
            required
            register={register}
            errorMessage={errors?.ton00?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="du00"
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = numeralCustom(e.target.value).value();
                  onChange(val);
                }}
                label="Giá trị tồn"
                placeholder="Nhập giá trị tồn"
                InputProps={{ endAdornment: 'VND' }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Số lô"
            labelWidth="30%"
            placeholder="Nhập số lô"
            name="ma_lo"
            register={register}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="han_su_dung"
            render={({ field: { onChange, value } }) => (
              <DateInput
                label="Hạn sử dụng lô"
                labelWidth="30%"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormAddTonDayKy;
