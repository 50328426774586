import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import { Controller } from 'react-hook-form';
import SelectApiInput from '~/components/input/SelectApiInput';

function TaiKhoanSection({ show, setCollapses, control }) {
  return (
    <CollapseSection
      title="Tài khoản mặc định"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, tai_khoan: !prev.tai_khoan }))
      }
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tk_tien"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="TK tiền"
                  apiCode="account"
                  placeholder="Chọn tài khoản"
                  searchFileds={['tk', 'ten_tk']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.tk}
                  renderOption={({ key, ...optionProps }, option) => {
                    return (
                      <Typography key={key} {...optionProps}>
                        {option.tk} - {option.ten_tk}
                      </Typography>
                    );
                  }}
                  selectedValue={value || null}
                  value={value || { tk: '', ten_tk: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tk_diem"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="TK điểm"
                  apiCode="account"
                  placeholder="Chọn tài khoản"
                  searchFileds={['tk', 'ten_tk']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.tk}
                  renderOption={({ key, ...optionProps }, option) => {
                    return (
                      <Typography key={key} {...optionProps}>
                        {option.tk} - {option.ten_tk}
                      </Typography>
                    );
                  }}
                  selectedValue={value || null}
                  value={value || { tk: '', ten_tk: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tk_cn"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="TK nợ"
                  apiCode="account"
                  placeholder="Chọn tài khoản"
                  searchFileds={['tk', 'ten_tk']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.tk}
                  renderOption={({ key, ...optionProps }, option) => {
                    return (
                      <Typography key={key} {...optionProps}>
                        {option.tk} - {option.ten_tk}
                      </Typography>
                    );
                  }}
                  selectedValue={value || null}
                  value={value || { tk: '', ten_tk: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tk_dt"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="TK doanh thu"
                  apiCode="account"
                  placeholder="Chọn tài khoản"
                  searchFileds={['tk', 'ten_tk']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.tk}
                  renderOption={({ key, ...optionProps }, option) => {
                    return (
                      <Typography key={key} {...optionProps}>
                        {option.tk} - {option.ten_tk}
                      </Typography>
                    );
                  }}
                  selectedValue={value || null}
                  value={value || { tk: '', ten_tk: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tk_tl"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="TK trả lại"
                  apiCode="account"
                  placeholder="Chọn tài khoản"
                  searchFileds={['tk', 'ten_tk']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.tk}
                  renderOption={({ key, ...optionProps }, option) => {
                    return (
                      <Typography key={key} {...optionProps}>
                        {option.tk} - {option.ten_tk}
                      </Typography>
                    );
                  }}
                  selectedValue={value || null}
                  value={value || { tk: '', ten_tk: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tk_ck"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="TK chiết khấu"
                  apiCode="account"
                  placeholder="Chọn tài khoản"
                  searchFileds={['tk', 'ten_tk']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.tk}
                  renderOption={({ key, ...optionProps }, option) => {
                    return (
                      <Typography key={key} {...optionProps}>
                        {option.tk} - {option.ten_tk}
                      </Typography>
                    );
                  }}
                  selectedValue={value || null}
                  value={value || { tk: '', ten_tk: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="tk_gv"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="TK giá vốn"
                  apiCode="account"
                  placeholder="Chọn tài khoản"
                  searchFileds={['tk', 'ten_tk']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.tk}
                  renderOption={({ key, ...optionProps }, option) => {
                    return (
                      <Typography key={key} {...optionProps}>
                        {option.tk} - {option.ten_tk}
                      </Typography>
                    );
                  }}
                  selectedValue={value || null}
                  value={value || { tk: '', ten_tk: '' }}
                  onSelect={onChange}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
    </CollapseSection>
  );
}

export default TaiKhoanSection;
