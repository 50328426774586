import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import InfoSection from './info-section/InfoSection';
import TaiKhoanSection from './taikhoan-section/TaiKhoanSection';
import DescriptionSection from './description-section/DescriptionSection';

const schemaBase = yup.object({
  ten: yup.string().required('Vui lòng nhập tên'),
});

const defaultData = {
  ten: '',
  tai_khoan_nh: '',
  chu_tai_khoan: '',
  ngan_hang: '',
  dien_giai: '',
  kieu_so1: 0,
  tk_tien: null,
  tk_diem: null,
  tk_cn: null,
  tk_ck: null,
  tk_dt: null,
  tk_gv: null,
  tk_tl: null,
  hinh_thuc: 0,
  status: true,
};

function FormPTTT({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        tk_tien: defaultValues.tk_tien
          ? {
              tk: defaultValues.tk_tien,
            }
          : null,
        tk_diem: defaultValues.tk_diem
          ? {
              tk: defaultValues.tk_diem,
            }
          : null,
        tk_cn: defaultValues.tk_cn
          ? {
              tk: defaultValues.tk_cn,
            }
          : null,
        tk_dt: defaultValues.tk_dt
          ? {
              tk: defaultValues.tk_dt,
            }
          : null,
        tk_tl: defaultValues.tk_tl
          ? {
              tk: defaultValues.tk_tl,
            }
          : null,
        tk_ck: defaultValues.tk_ck
          ? {
              tk: defaultValues.tk_ck,
            }
          : null,
        tk_gv: defaultValues.tk_gv
          ? {
              tk: defaultValues.tk_gv,
            }
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schemaBase),
  });
  const [collapses, setCollapses] = useState({ tai_khoan: true, mo_ta: true });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { tk_tien, tk_diem, tk_cn, tk_dt, tk_tl, tk_ck, tk_gv, ...fields } =
      values;
    return {
      ...fields,
      tk_tien: tk_tien?.tk,
      tk_diem: tk_diem?.tk,
      tk_cn: tk_cn?.tk,
      tk_dt: tk_dt?.tk,
      tk_tl: tk_tl?.tk,
      tk_ck: tk_ck?.tk,
      tk_gv: tk_gv?.tk,
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'ptthanhtoan',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="800px"
      title="phương thức thanh toán"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack gap={2}>
        <InfoSection
          control={control}
          errors={errors}
          isEdit={isEdit}
          register={register}
        />
        <DescriptionSection
          register={register}
          setCollapses={setCollapses}
          show={collapses.mo_ta}
        />
        <TaiKhoanSection
          show={collapses.tai_khoan}
          setCollapses={setCollapses}
          control={control}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormPTTT;
