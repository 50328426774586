import { IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import ButtonBase from '~/components/button/ButtonBase';
import CollapseSection from '~/components/collapse/CollapseSection';
import TableDisplay from '~/components/table/TableDisplay';
import FormAddDetail from './FormAddDetail';
import { cloneDeep } from 'lodash';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import { IoMdTrash } from 'react-icons/io';

const columns = [
  {
    name: 'Mã hàng',
    selector: (row) => row.ma_vt,
    wrap: true,
  },
  {
    name: 'Tên hàng',
    selector: (row) => row.ten_vt,
    wrap: true,
  },
  {
    name: 'Tỷ lệ CK',
    selector: (row) => row.ty_le_ck,
    wrap: true,
  },
  {
    name: 'Tiền CK',
    selector: (row) => row.tien_ck,
    wrap: true,
  },
];

function ChiTietSection({ show, setCollapses, details, setDetails }) {
  const showAlert = useAlertContext();
  const [openForm, setOpenForm] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleClear, setToggleClear] = useState(false);

  // add a detail
  const addDetail = (detail, isEdit = false) => {
    const { vat_tu, ...detailValue } = detail;
    if (!isEdit) {
      const existed = details.find((item) => item.ma_vt === vat_tu.ma_vt);
      if (existed) {
        showAlert({
          type: 'warning',
          message: `Hàng hóa '${vat_tu.ten_vt}' đã có trong danh sách`,
        });
        return;
      }
    }
    const detailData = {
      ...detailValue,
      ma_vt: vat_tu.ma_vt,
      ten_vt: vat_tu.ten_vt,
      tk_vt: '1561',
    };
    if (isEdit) {
      const index = details.findIndex((item) => item.ma_vt === vat_tu.ma_vt);
      if (index >= 0) {
        const detailsCopy = cloneDeep(details);
        detailsCopy.splice(index, 1, detailData);
        setDetails(detailsCopy);
      }
    } else {
      setDetails([...details, detailData]);
    }
  };

  // handleSelectRowsChange
  const handleSelectRowsChange = ({ selectedRows: _selectedRows }) => {
    setSelectedRows(_selectedRows);
  };
  // delete detail
  const handleDeleteDetail = () => {
    if (!selectedRows || selectedRows.length <= 0) return;
    const ids = selectedRows.map((item) => item.ma_vt);
    let detailsCopy = cloneDeep(details);
    detailsCopy = detailsCopy.filter((item) => !ids.includes(item.ma_vt));
    setDetails(detailsCopy);
  };

  // click on row
  const handleRowClicked = (row) => {
    setDefaultValues(row);
    setOpenForm(true);
    setIsEdit(true);
  };
  // handle close form
  const handleCloseForm = () => {
    setIsEdit(false);
    setDefaultValues(null);
    setOpenForm(false);
  };

  useEffect(() => {
    setSelectedRows([]);
    setToggleClear((prev) => !prev);
  }, [details]);

  return (
    <>
      {openForm && (
        <FormAddDetail
          open={openForm}
          handleClose={handleCloseForm}
          addDetail={addDetail}
          defaultValues={defaultValues}
          isEdit={isEdit}
        />
      )}
      <CollapseSection
        title="Danh sách hàng hóa"
        show={show}
        onCollapse={() =>
          setCollapses((prev) => ({ ...prev, chitiet: !prev.chitiet }))
        }
      >
        <Stack spacing="5px" alignItems="flex-start">
          <Stack direction="row" alignItems="center" spacing="5px">
            <ButtonBase
              variant="outlined"
              startIcon={<BsPlusCircle style={{ fontSize: '14px' }} />}
              onClick={() => setOpenForm(true)}
            >
              Thêm hàng
            </ButtonBase>
            {!!selectedRows && selectedRows.length > 0 && (
              <IconButton
                sx={{
                  backgroundColor: 'error.main',
                  color: 'whitish.pureWhite',
                  borderRadius: '4px',
                  width: '28px',
                  height: '28px',
                  '&:hover': {
                    backgroundColor: 'error.main',
                  },
                }}
                onClick={handleDeleteDetail}
              >
                <IoMdTrash size={14} />
              </IconButton>
            )}
          </Stack>
          {details && Array.isArray(details) && details.length > 0 && (
            <TableDisplay
              columns={columns}
              data={details || []}
              onRowClicked={handleRowClicked}
              handleDelete={handleDeleteDetail}
              selectableRows
              onSelectedRowsChange={handleSelectRowsChange}
              clearSelectedRows={toggleClear}
            />
          )}
        </Stack>
      </CollapseSection>
    </>
  );
}

export default ChiTietSection;
