import React from 'react';
import { Stack } from '@mui/material';
import Pbl from './pbl/Pbl';
import Hd2 from './hd2/Hd2';
import useAuthorize from '~/hooks/useAuthorize';
import Cart from './cart/Cart';

function TakecareSection({ customer }) {
  const allowAuthorize = useAuthorize();

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      {allowAuthorize({ type: 'view', module: 'hd2' }) && (
        <Hd2 customer={customer} />
      )}
      {allowAuthorize({ type: 'view', module: 'pbl' }) && (
        <Pbl customer={customer} />
      )}
      {allowAuthorize({ type: 'view', module: 'cart2' }) && (
        <Cart customer={customer} />
      )}
    </Stack>
  );
}

export default TakecareSection;
