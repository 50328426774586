import { Box } from '@mui/material';
import React, { forwardRef } from 'react';
import TableBase from './TableBase';
import Tablepagination from './components/Tablepagination';

function TableCustomPagination(
  {
    apiCode,
    columns,
    data,
    title,
    loading,
    paginationOption,
    handleRowPerPageChange,
    setPaginationOption,
    setSelectedRows,
    handleRowClicked,
    getListData,
    toggleCleared,
    setToggleCleared,
    expandableRows,
    expandOnRowClicked,
    expandableRowsComponent,
    expandableRowsHideExpander,
    selectableRows = true,
    wrapperSx = {},
    fixedHeaderScrollHeight,
  },
  ref
) {
  return (
    <>
      <Box
        sx={{
          paddingTop: '10px',
          height: 'calc(100vh - 50px - 42px - 10px - 30px - 10px - 42px)',
          ...wrapperSx,
        }}
      >
        <TableBase
          apiCode={apiCode}
          columns={columns || []}
          data={data || []}
          title={title}
          selectableRows={selectableRows}
          progressPending={loading}
          paginationTotalRows={paginationOption.totalRows}
          paginationPerPage={paginationOption.limit}
          onChangeRowsPerPage={handleRowPerPageChange}
          onChangePage={(value) =>
            setPaginationOption({ ...paginationOption, page: value })
          }
          onSelectedRowsChange={setSelectedRows}
          onRowClicked={!expandOnRowClicked ? handleRowClicked : undefined}
          loadData={getListData}
          toggleCleared={toggleCleared}
          setToggleCleared={setToggleCleared}
          expandOnRowClicked={expandOnRowClicked}
          expandableRows={expandableRows}
          expandableRowsComponent={expandableRowsComponent}
          expandableRowsHideExpander={expandableRowsHideExpander}
          fixedHeaderScrollHeight={
            fixedHeaderScrollHeight ||
            `calc(100vh - 50px - 42px - 10px - 30px - 10px - 42px - 10px)`
          }
          ref={ref}
        />
      </Box>
      {paginationOption.totalRows > 0 && (
        <Box sx={{ height: '42px', flexShrink: 0 }}>
          <Tablepagination
            currentPage={paginationOption.page}
            onChangePage={(value) =>
              setPaginationOption({ ...paginationOption, page: value })
            }
            rowsPerPage={paginationOption.limit}
            rowCount={paginationOption.totalRows}
            onChangeRowsPerPage={handleRowPerPageChange}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
          />
        </Box>
      )}
    </>
  );
}

export default forwardRef(TableCustomPagination);
