import React, { useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import CheckboxInput from '~/components/input/CheckboxInput';
import { LABEL_KHO } from '~/utils/label.constant';
import FormCustomer from '../../customer/FormCustomer';
import FormNhomKhach from '../../nhkh/FormNhomKhach';

function InfoSection({ register, errors, control, setValue }) {
  const storeData = useSelector((state) => state.store);

  useEffect(() => {
    if (storeData && storeData.data.length === 1) {
      setValue('kho', storeData.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="kho"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  labelWidth="30%"
                  label={LABEL_KHO}
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO}`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.kho?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Phát hành"
                  name="status"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="khach_hang"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Khách hàng"
                  apiCode="customer"
                  placeholder="Chọn khách hàng"
                  searchFileds={['ma_kh', 'ten_kh']}
                  condition={{ kh_yn: true, ncc_yn: { $ne: true } }}
                  getOptionLabel={(option) => option.ten_kh}
                  selectedValue={value}
                  value={value || { ma_kh: '', ten_kh: '' }}
                  onSelect={onChange}
                  FormAdd={FormCustomer}
                />
              )}
            />
            <Controller
              control={control}
              name="nhom_khach"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Nhóm khách hàng"
                  apiCode="dmnhkh"
                  placeholder="Chọn nhóm khách hàng"
                  searchFileds={['group_name']}
                  condition={{ isvendor: { $ne: true } }}
                  getOptionLabel={(option) => option.group_name}
                  selectedValue={value}
                  value={value || { _id: '', group_name: '' }}
                  onSelect={onChange}
                  FormAdd={FormNhomKhach}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
