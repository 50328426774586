import React from 'react';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import { LABEL_KHO } from '~/utils/label.constant';
import FormKho from '../../kho/FormKho';
import DatetimeInput from '~/components/input/DatetimeInput';

function InfoSection({ register, control, errors }) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextInput
            label="Số chứng từ"
            labelWidth="30%"
            placeholder="Nhập hoặc tạo tự động"
            name="so_ct"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ngay_ct"
            render={({ field: { onChange, value } }) => (
              <DatetimeInput
                label="Ngày chứng từ"
                labelWidth="30%"
                value={value}
                onChange={onChange}
                errorMessage={errors?.ngay_ct?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="kho_xuat"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                required
                label={`${LABEL_KHO} xuất`}
                labelWidth="30%"
                apiCode="dmkho"
                placeholder={`Chọn ${LABEL_KHO} xuất`}
                searchFileds={['ma_kho', 'ten_kho']}
                getOptionLabel={(option) => option.ten_kho}
                selectedValue={value}
                value={value || { ma_kho: '', ten_kho: '' }}
                onSelect={onChange}
                FormAdd={FormKho}
                errorMessage={errors?.kho_xuat?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="kho_nhap"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                required
                label={`${LABEL_KHO} nhập`}
                labelWidth="30%"
                apiCode="dmkho"
                placeholder={`Chọn ${LABEL_KHO} nhập`}
                searchFileds={['ma_kho', 'ten_kho']}
                getOptionLabel={(option) => option.ten_kho}
                selectedValue={value}
                value={value || { ma_kho: '', ten_kho: '' }}
                onSelect={onChange}
                FormAdd={FormKho}
                errorMessage={errors?.kho_nhap?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="trang_thai"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                label="Trạng thái"
                labelWidth="30%"
                apiCode="trangthai"
                placeholder="Chọn trạng thái"
                searchFileds={['ten_trang_thai']}
                getOptionLabel={(option) => option.ten_trang_thai}
                selectedValue={value}
                value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
                onSelect={onChange}
                withIdApp={false}
                condition={{ ma_ct: 'PDN', status: true }}
                errorMessage={errors?.trang_thai?.message}
              />
            )}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
