import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

function CheckboxInput({
  label,
  checked,
  onChange,
  readOnly,
  disabled,
  labelSx = {},
  wrapperSx = {},
}) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        ...wrapperSx,
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            readOnly={readOnly}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            size="small"
            sx={{ color: '#999' }}
          />
        }
        label={label}
        sx={{
          '& .MuiFormControlLabel-label': { fontSize: '14px', ...labelSx },
        }}
      />
    </Box>
  );
}

export default CheckboxInput;
