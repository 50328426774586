import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { BsTrash } from 'react-icons/bs';
import { PiTextColumns } from 'react-icons/pi';
import { SiMicrosoftexcel } from 'react-icons/si';
import { TbTableExport } from 'react-icons/tb';
import ButtonBase from '~/components/button/ButtonBase';
import FilterProduct from '~/components/filter/product/FilterProduct';
import FormProduct from '~/components/form/product/FormProduct';
import ListLayout from '~/components/layouts/ListLayout';
import ModalColumns from '~/components/modal/ModalColumns';
import ModalExportExcel from '~/components/modal/ModalExportExcel';
import ModalImportExcel from '~/components/modal/ModalImportExcel';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { numeralCustom } from '~/utils/helpers';
import ProductImage from '~/assets/img/product.png';
import useToken from '~/hooks/useToken';
import useLinkImage from '~/hooks/useLinkImage';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import ModalChuyenNhomHang from '~/components/modal/ModalChuyenNhomHang';
import { BiTransferAlt } from 'react-icons/bi';
import MenuHover from '~/components/menu/MenuHover';
import { FiLock } from 'react-icons/fi';
import useAuthorize from '~/hooks/useAuthorize';

const apiCode = 'dmvt';
const title = 'hàng hóa';

function ListVtPage() {
  const token = useToken();
  const theme = useTheme();
  const allowAuthorize = useAuthorize();
  const generateLinkImage = useLinkImage(token);
  const { asyncSearchList, asyncPutData } = useApisContext();
  const [data, setData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [load, setLoad] = useState(1);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [condition, setCondition] = useState({ date_created: 1 });
  const [showColumnsState, setShowColumnsState] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  ]);
  const [openExcel, setOpenExcel] = useState(false);
  const [openModalColumns, setOpenModalColumns] = useState(false);
  const [openFormProduct, setOpenFormProduct] = useState(false);
  const [opentExportExcel, setOpenExportExcel] = useState(false);
  const [openModalChuyenNhomHang, setOpenModalChuyenNhomHang] = useState(false);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [paginationOption, setPaginationOption] = useState({
    limit: 10,
    page: 1,
    totalRows: 0,
  });
  const btnDeleteRef = useRef();
  const renderCountRef = useRef(1);

  const columns = [
    // 0 ảnh
    {
      name: 'Ảnh',
      width: '50px',
      wrap: true,
      center: true,
      selector: (row) => {
        const url = row.picture || row.picture2 || row.picture3;
        return (
          <MenuHover
            disablePortal={false}
            placement="right"
            content={
              <Box
                sx={{ width: '180px', height: '180px', borderRadius: '4px' }}
              >
                <Avatar
                  src={url ? generateLinkImage(url) : ProductImage}
                  imgProps={{ loading: 'lazy' }}
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: '4px',
                  }}
                />
              </Box>
            }
          >
            <Avatar
              src={url ? generateLinkImage(url) : ProductImage}
              imgProps={{ loading: 'lazy' }}
              sx={{
                width: '30px',
                height: '30px',
                borderRadius: '4px',
              }}
            />
          </MenuHover>
        );
      },
    },
    // 1 mã
    {
      name: 'Mã',
      selector: (row) => row.ma_vt,
      minWidth: '120px',
      maxWidth: '120px',
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.status === false,
          style: { color: theme.palette.error.main },
        },
      ],
    },
    // 2 tên
    {
      name: 'Tên',
      selector: (row) => row.ten_vt,
      cell: (row) => {
        return (
          <Typography sx={{ pointerEvents: 'none' }}>
            {row.ten_vt}{' '}
            {row?.exfields?.hoa_don && (
              <Chip
                component="span"
                size="small"
                variant="filled"
                color="warning"
                label={`Hóa đơn${
                  row.thue_suat_nk > 0 ? ` ${row.thue_suat_nk}%` : ''
                }`}
                sx={{ '& .MuiChip-label': { color: 'common.white' } }}
              />
            )}{' '}
            {row?.ty_le_ck0 > 0 && (
              <Chip
                component="span"
                size="small"
                variant="filled"
                color="error"
                label={`-${row.ty_le_ck0}%`}
                sx={{ '& .MuiChip-label': { color: 'common.white' } }}
              />
            )}
          </Typography>
        );
      },
      minWidth: '200px',
      wrap: true,
      conditionalCellStyles: [
        {
          when: (row) => row.status === false,
          style: { color: theme.palette.error.main },
        },
      ],
    },
    // 3 tên tiếng anh
    {
      name: 'Tên tiếng anh',
      selector: (row) => row.ten_vt_en,
      width: '140px',
      wrap: true,
    },
    // 4 Loại xe
    {
      name: 'Loại xe',
      selector: (row) => row.loai_vt,
      width: '120px',
      wrap: true,
    },
    // 5 Đời xe
    {
      name: 'Đời xe',
      selector: (row) => row.tg_sx,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Nhãn hiệu',
      selector: (row) => row.ten_nvt,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Nhà sản xuất',
      selector: (row) => row.nha_sx,
      width: '120px',
      wrap: true,
    },
    // 6 giá vốn
    {
      name: 'Giá vốn',
      selector: (row) => row.gia_mua,
      format: (row) => numeralCustom(row.gia_mua).format(),
      minWidth: '100px',
      maxWidth: '100px',
      center: true,
    },
    // 7 giá bán
    {
      name: 'Giá bán',
      selector: (row) => row.gia_ban_le,
      format: (row) => numeralCustom(row.gia_ban_le).format(),
      minWidth: '100px',
      maxWidth: '100px',
      center: true,
    },
    // 8 Nhà sản xuất
    {
      name: 'Tồn kho',
      selector: (row) => row.ton00,
      format: (row) => numeralCustom(row.ton00).format(),
      minWidth: '100px',
      maxWidth: '100px',
      center: true,
    },
  ];

  // row per page change
  const handleRowPerPageChange = (value) => {
    setPaginationOption({ ...paginationOption, page: 1, limit: value });
  };
  // handle row clicked
  const handleRowClicked = (row) => {
    const index = data.findIndex((item) => item._id === row._id);
    setCurrentIndex(index);
    setOpenFormProduct(true);
    setIsEdit(true);
  };
  // handle close form
  const handleCloseForm = (setStateFunc) => {
    setStateFunc(false);
    setCurrentIndex(-1);
  };
  // get products
  const getListData = async () => {
    if (loading) return;
    setLoading(true);
    const resp = await asyncSearchList({
      apiCode,
      condition: {
        limit: paginationOption.limit,
        page: paginationOption.page,
        q: condition,
      },
    });
    const respCount = await asyncSearchList({
      apiCode,
      condition: {
        count: 1,
        q: condition,
      },
    });
    if (respCount) {
      setPaginationOption({
        ...paginationOption,
        totalRows: respCount.rows_number,
      });
    }
    if (resp) {
      setData(resp);
    }
    setLoading(false);
  };

  const exportToExcel = async () => {
    setOpenExportExcel(true);
  };

  // handle stop sell
  const handleStopSell = async () => {
    if (selectedRows && selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        const row = selectedRows[i];
        const resp = await asyncPutData({
          apiCode,
          uniqueValue: row._id,
          data: { status: false },
        });
        if (!resp?.message && i === selectedRows.length - 1) {
          setSelectedRows([]);
          setToggleCleared(!toggleCleared);
          setLoad(load + 1);
        }
      }
    } else {
      return;
    }
  };

  // handle delete
  const handleDelete = () => {
    btnDeleteRef?.current?.click();
  };
  // handle clear select
  const handleClearSelect = () => {
    setSelectedRows([]);
    setToggleCleared(!toggleCleared);
  };

  const currentColumns = useMemo(() => {
    if (!showColumnsState) return [];
    const result = (columns || []).reduce((acc, col, index) => {
      if ((showColumnsState || []).includes(index)) {
        return [...acc, col];
      } else {
        return acc;
      }
    }, []);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showColumnsState]);

  useEffect(() => {
    if (renderCountRef.current > 1) {
      getListData();
      setSelectedRows([]);
      setToggleCleared(!toggleCleared);
    }
    renderCountRef.current += 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationOption.limit, paginationOption.page, load]);

  useEffect(() => {
    setPaginationOption({ ...paginationOption, page: 1 });
    setLoad(load + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition]);

  return (
    <>
      <ListLayout
        ref={btnDeleteRef}
        title={title}
        apiCode={apiCode}
        columns={currentColumns}
        data={data}
        getListData={getListData}
        handleRowClicked={handleRowClicked}
        handleRowPerPageChange={handleRowPerPageChange}
        loading={loading}
        paginationOption={paginationOption}
        renderOuter={() => (
          <>
            <ModalColumns
              open={openModalColumns}
              onClose={() => setOpenModalColumns(false)}
              columns={columns}
              showColumns={showColumnsState}
              setShowColumns={setShowColumnsState}
            />
            <ModalChuyenNhomHang
              open={openModalChuyenNhomHang}
              handleClose={() => setOpenModalChuyenNhomHang(false)}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              setToggleCleared={setToggleCleared}
              setLoad={setLoad}
            />
            {openFormProduct && (
              <FormProduct
                open={openFormProduct}
                defaultValues={data[currentIndex]}
                isEdit={isEdit}
                handleClose={() => handleCloseForm(setOpenFormProduct)}
                setLoad={setLoad}
                currentIndex={currentIndex}
                rows={data}
                setCurrentIndex={setCurrentIndex}
              />
            )}
            {openExcel && (
              <ModalImportExcel
                open={openExcel}
                handleClose={() => setOpenExcel(false)}
                apiCode={apiCode}
                setLoad={setLoad}
              />
            )}
            {opentExportExcel && (
              <ModalExportExcel
                open={opentExportExcel}
                handleClose={() => setOpenExportExcel(false)}
                apiCode={apiCode}
                filenameExport="danh_sach_san_pham.xlsx"
              />
            )}
          </>
        )}
        renderFilter={() => <FilterProduct setCondition={setCondition} />}
        renderHeaderList={() => (
          <>
            {selectedRows.length > 0 && (
              <Badge
                badgeContent={selectedRows.length}
                color="secondary"
                sx={{
                  '& .MuiBadge-badge': {
                    right: 8,
                    top: 2,
                    border: `2px solid #fff`,
                    padding: '0 4px',
                  },
                }}
              >
                <MenuHover
                  content={
                    <Stack sx={{ minWidth: '150px' }}>
                      {allowAuthorize({ type: 'update', module: apiCode }) && (
                        <MenuItem
                          sx={{ columnGap: '10px' }}
                          onClick={() => setOpenModalChuyenNhomHang(true)}
                        >
                          <BiTransferAlt size={14} />
                          <Typography sx={{ fontSize: '14px' }}>
                            Chuyển nhóm hàng
                          </Typography>
                        </MenuItem>
                      )}
                      <MenuItem
                        sx={{ columnGap: '10px' }}
                        onClick={handleClearSelect}
                      >
                        <MdClose size={14} />
                        <Typography sx={{ fontSize: '14px' }}>
                          Bỏ chọn tất cả
                        </Typography>
                      </MenuItem>
                      {allowAuthorize({ type: 'update', module: apiCode }) && (
                        <MenuItem
                          sx={{ columnGap: '10px', color: 'warning.main' }}
                          onClick={handleStopSell}
                        >
                          <FiLock size={14} />
                          <Typography
                            sx={{ fontSize: '14px', color: 'warning.main' }}
                          >
                            Ngừng kinh doanh
                          </Typography>
                        </MenuItem>
                      )}
                      {allowAuthorize({ type: 'delete', module: apiCode }) && (
                        <MenuItem
                          sx={{ columnGap: '10px', color: 'error.main' }}
                          onClick={handleDelete}
                        >
                          <BsTrash size={14} />
                          <Typography
                            sx={{ fontSize: '14px', color: 'error.main' }}
                          >
                            Xóa bỏ
                          </Typography>
                        </MenuItem>
                      )}
                    </Stack>
                  }
                >
                  <Button
                    variant="contained"
                    sx={{
                      height: '30px',
                      textTransform: 'none',
                      color: 'whitish.pureWhite',
                    }}
                  >
                    Thao tác
                  </Button>
                </MenuHover>
              </Badge>
            )}
            {allowAuthorize({ type: 'add', module: apiCode }) && (
              <ButtonBase
                startIcon={<AiOutlinePlusCircle fontSize="14px" />}
                onClick={() => {
                  setOpenFormProduct(true);
                  setIsEdit(false);
                }}
              >
                Thêm mới
              </ButtonBase>
            )}
            {allowAuthorize({ type: 'add', module: apiCode }) && (
              <ButtonBase
                startIcon={<SiMicrosoftexcel fontSize="14px" />}
                onClick={() => setOpenExcel(true)}
              >
                Nhập excel
              </ButtonBase>
            )}
            {allowAuthorize({ type: 'view', module: apiCode }) && (
              <ButtonBase
                startIcon={<TbTableExport fontSize="14px" />}
                onClick={exportToExcel}
              >
                Xuất excel
              </ButtonBase>
            )}
            <IconButton
              sx={{
                width: '30px',
                height: '30px',
                backgroundColor: 'primary.main',
                color: 'whitish.pureWhite',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'primary.main',
                },
              }}
              onClick={() => setOpenModalColumns(true)}
            >
              <PiTextColumns size={14} />
            </IconButton>
          </>
        )}
        setPaginationOption={setPaginationOption}
        setSelectedRows={setSelectedRows}
        setToggleCleared={setToggleCleared}
        toggleCleared={toggleCleared}
      />
    </>
  );
}

export default ListVtPage;
