import React, { useId } from 'react';
import WrapperInput from './WrapperInput';
import { MdClose } from 'react-icons/md';
import { IconButton, Stack } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { BiCalendar } from 'react-icons/bi';

function DatetimeInput({
  name,
  disabled,
  label,
  labelWidth = '20%',
  required,
  errorMessage,
  value,
  open,
  onClose,
  onChange = () => {},
  readOnly,
  ...props
}) {
  const id = useId();

  const handleClear = () => {
    onChange(null);
  };

  const handleDateChange = (date) => {
    onChange(date);
  };

  return (
    <WrapperInput
      id={id}
      label={label}
      labelWidth={labelWidth}
      required={required}
      errorMessage={errorMessage}
      {...props}
    >
      <DateTimePicker
        open={open}
        onClose={onClose}
        value={!!value ? moment(value) : null}
        onChange={handleDateChange}
        dayOfWeekFormatter={(date) => date.format('ddd')}
        readOnly={readOnly}
        disabled={disabled}
        slots={{
          openPickerIcon: () => <BiCalendar size={14} />,
          inputAdornment: ({ children }) => {
            return (
              <Stack
                direction="row"
                alignItems="center"
                sx={{ paddingRight: '5px' }}
              >
                {!!value && !disabled ? (
                  <IconButton
                    className="btn-clear-date"
                    sx={{
                      display: 'none',
                      backgroundColor: 'whitish.whiteSoft',
                      padding: '4px',
                    }}
                    onClick={handleClear}
                  >
                    <MdClose size={12} />
                  </IconButton>
                ) : null}
                {children}
              </Stack>
            );
          },
        }}
        slotProps={{
          textField: {
            variant: 'standard',
            fullWidth: true,
            size: 'small',
            name,
            sx: {
              '&:hover .btn-clear-date': {
                display: 'flex',
              },
              '& .MuiInputBase-root': {
                paddingLeft: 0,
              },
              '& .MuiFormLabel-root': {
                fontSize: '12px',
                fontWeight: 600,
                color: 'primary.main',
                paddingRight: '5px',
                transform: 'translate(14px, -12px)',
                backgroundColor: 'whitish.pureWhite',
              },
              '& .MuiInputBase-input': {
                fontSize: '14px',
                '&:placeholder': {
                  fontSize: '14px',
                },
              },
            },
          },
        }}
      />
    </WrapperInput>
  );
}

export default DatetimeInput;
