import React from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';

function DescriptionSection({ show, setCollapses, register }) {
  return (
    <CollapseSection
      title="Ghi chú"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, mo_ta: !prev.mo_ta }))
      }
    >
      <AreaInput
        register={register}
        name="dien_giai"
        placeholder="Nhập ghi chú"
      />
    </CollapseSection>
  );
}

export default DescriptionSection;
