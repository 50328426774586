import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { PiNotePencil } from 'react-icons/pi';
import { useSelector } from 'react-redux';
import { useOrderContext } from '../../OrderPage';

function NoteHD() {
  const { currentHd } = useSelector((state) => state.order);
  const { handleUpdateCurrentHd } = useOrderContext();
  const [text, setText] = useState(currentHd?.dien_giai || '');

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleUpdateNote = (e) => {
    if (e.keyCode === 13) {
      if (text === currentHd?.dien_giai) return;
      handleUpdateCurrentHd({ dien_giai: text });
    }
  };

  useEffect(() => {
    setText(currentHd?.dien_giai || '');
  }, [currentHd]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="5px"
      sx={{
        height: '40px',
        backgroundColor: 'whitish.pureWhite',
        borderRadius: '4px',
        padding: '8px',
        border: '1px solid transparent',
        transition: 'all linear 0.2s',
        marginTop: '10px',
        '&:focus-within': {
          borderColor: 'primary.main',
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ color: 'gray' }}
      >
        <PiNotePencil size={20} />
      </Stack>
      <Box
        component="input"
        placeholder="Ghi chú đơn hàng"
        value={text}
        onChange={handleTextChange}
        onKeyUp={handleUpdateNote}
        onBlur={() => {
          handleUpdateNote({ keyCode: 13 });
        }}
        sx={{
          width: '100%',
          height: '100%',
          border: 'none',
          outline: 'none',
        }}
      />
    </Stack>
  );
}

export default NoteHD;
