import { Tooltip } from '@mui/material';
import React from 'react';

function TooltipBase({ children, title, placement, arrow, ...props }) {
  return (
    <Tooltip title={title} placement={placement} arrow={arrow} {...props}>
      {children}
    </Tooltip>
  );
}

export default TooltipBase;
