import React, { useState, useEffect, useRef, useId } from 'react';
import {
  TextField,
  Typography,
  Stack,
  Autocomplete,
  ListItem,
  Button,
  Skeleton,
} from '@mui/material';
import { MdClose } from 'react-icons/md';
import { HiOutlineSelector } from 'react-icons/hi';
import useApisContext from '~/hooks/hookContext/useApisContext';
import WrapperInput from './WrapperInput';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useInfiniteScroll from 'react-infinite-scroll-hook';

function SelectApiInput({
  title,
  label,
  placeholder,
  required = false,
  selectedValue,
  onSelect = () => {},
  apiCode = 'dmvt',
  condition = {},
  getOptionLabel,
  filterOptions = (option) => option,
  renderOption,
  searchFileds = ['ma_vt', 'ten_vt'],
  value,
  defaultValue,
  FormAdd,
  errorMessage,
  disabled = false,
  isOpenDm = true,
  autocompleteProps,
  multiple = false,
  sx = {},
  isOptionEqualToValue = () => true,
  withIdApp = true,
  labelWidth = '20%',
  InputProps = {},
  variant = 'standard',
  readOnly,
  ...props
}) {
  const id = useId();
  const showAlert = useAlertContext();
  const { asyncGetList } = useApisContext();
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const timerRef = useRef();
  const countRenderRef = useRef(1);

  // handle get data
  const handleGetData = async (searchValue, page, oldProps = []) => {
    try {
      setLoading(true);
      const selfCondition = { page, limit: 20, q: condition };
      if (searchValue === '' || searchValue) {
        selfCondition.q.$or = [];
        searchFileds.forEach((searchFiled) =>
          selfCondition.q.$or.push({
            [searchFiled]: {
              $regex: searchValue.split(' ').join('.*'),
              $options: 'i',
            },
          })
        );
        // selfCondition.q.$or.push({ $text: { $search: searchValue } });
      }
      const resp = await asyncGetList({
        apiCode,
        condition: selfCondition,
        withIdApp,
      });
      const respCount = await asyncGetList({
        apiCode,
        condition: { ...selfCondition, count: 1 },
        withIdApp,
      });
      setCount(respCount?.rows_number || 0);
      if (resp && Array.isArray(resp)) {
        setOptions([...oldProps, ...resp]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
      setPage(page + 1);
    }
  };
  const hasNextPage = options.length < count;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => handleGetData(search, page, options),
  });

  const handleReset = () => {
    setSearchText('');
    setOptions([]);
    setPage(1);
    setCount(0);
  };

  // open form add
  const openFormAdd = () => {
    setOpenForm(true);
  };

  const renderLoading = (sentryRef, key) => {
    return (
      <Stack
        ref={sentryRef}
        key={key}
        spacing="4px"
        sx={{ width: '100%', marginTop: '4px', padding: '0 5px' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '30%', height: '10px' }}
          />
          <Skeleton
            variant="rounded"
            animation="wave"
            sx={{ width: '68%', height: '10px' }}
          />
        </Stack>
        <Skeleton
          variant="rounded"
          animation="wave"
          sx={{ width: '100%', height: '10px' }}
        />
      </Stack>
    );
  };

  React.useEffect(() => {
    if ((search === '' || search.length > 1) && countRenderRef.current > 1) {
      handleGetData(search, 1, []);
      setPage(1);
    }
    countRenderRef.current += 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  React.useEffect(() => {
    timerRef.current = setTimeout(() => {
      setSearch(searchText.trim());
    }, 500);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [searchText]);
  useEffect(() => {
    setOptions([]);
  }, [apiCode]);
  return (
    <>
      {openForm && (
        <FormAdd
          open={openForm}
          handleClose={() => setOpenForm(false)}
          defaultValues={{ [searchFileds[0]]: search }}
        />
      )}
      <WrapperInput
        id={id}
        label={label}
        labelWidth={labelWidth}
        required={required}
        errorMessage={errorMessage}
        {...props}
      >
        <Autocomplete
          multiple={multiple}
          readOnly={readOnly}
          disabled={disabled}
          isOptionEqualToValue={isOptionEqualToValue}
          clearOnBlur={false}
          options={options}
          value={value}
          defaultValue={defaultValue}
          onBlur={handleReset}
          inputValue={searchText}
          onInputChange={(_, val) => {
            setSearchText(` ${val.trimStart()}`);
          }}
          clearIcon={selectedValue ? <MdClose size={14} /> : null}
          popupIcon={<HiOutlineSelector size={14} />}
          onChange={(e, newValue) => {
            onSelect(newValue);
          }}
          ListboxProps={{
            className: 'custome-scrolly',
            sx: {
              '& .MuiAutocomplete-option': { fontSize: '12px' },
            },
          }}
          noOptionsText={
            <Stack spacing="10px" alignItems="center">
              {loading ? (
                <>{renderLoading()}</>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontStyle: 'italic',
                      textAlign: 'center',
                    }}
                  >
                    Không tìm thấy kết quả
                  </Typography>
                  {!!FormAdd && (
                    <Stack direction="row" alignItems="center" spacing="5px">
                      <Button
                        variant="contained"
                        sx={{
                          color: 'whitish.pureWhite',
                          fontSize: '12px',
                          fontWeight: 400,
                          textTransform: 'none',
                        }}
                        onClick={openFormAdd}
                      >
                        Thêm mới
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          }
          getOptionLabel={
            !!getOptionLabel
              ? (option) => {
                  const val = getOptionLabel(option);
                  return val || '';
                }
              : undefined
          }
          filterOptions={(options) => {
            if (hasNextPage) {
              options.push({ id: 'load-more' });
            }
            return options;
          }}
          renderOption={(optionProps, option, state, ownerState) => {
            if (option.id === 'load-more') {
              return renderLoading(sentryRef, '1234');
            }
            const { key, ...restProps } = optionProps;
            if (renderOption) {
              return renderOption(optionProps, option);
            }
            return (
              <ListItem key={key} {...restProps}>
                {ownerState.getOptionLabel(option)}
              </ListItem>
            );
          }}
          sx={{ width: '100%', ...sx }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              autoComplete="off"
              variant={variant}
              size="small"
              // value={searchText}
              // onChange={(e) => setSearchText(e.target.value)}
              sx={{
                '&:hover .btn-clear-select-api': {
                  display: 'flex',
                },
                '& .MuiInputBase-root': { padding: '0 5px 0 0 !important' },
                '& .MuiFormLabel-root': {
                  fontSize: '12px',
                  fontWeight: 600,
                  color: 'primary.main',
                  transform: 'translate(14px, -12px)',
                  paddingRight: '5px',
                  backgroundColor: 'whitish.pureWhite',
                },
                '& .MuiInputBase-input': {
                  fontSize: '14px',
                  padding: '5px 0 !important',
                  '&:placeholder': {
                    fontSize: '14px',
                  },
                },
              }}
              onFocus={() => {
                handleGetData('', 1, []);
              }}
            />
          )}
          {...autocompleteProps}
        />
      </WrapperInput>
    </>
  );
}

export default SelectApiInput;
