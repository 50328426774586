import React, { useState, useEffect, forwardRef } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DataTable from 'react-data-table-component';
import { FaTrash } from 'react-icons/fa';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useConfirmContext from '~/hooks/hookContext/useConfirmContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';

function TableBase(
  {
    apiCode,
    columns,
    data,
    title,
    onChangePage,
    onChangeRowsPerPage,
    onRowClicked,
    pagination = true,
    onSelectedRowsChange,
    progressPending = false,
    paginationTotalRows,
    paginationPerPage = 20,
    fixedHeaderScrollHeight,
    toggleCleared,
    setToggleCleared,
    loadData = () => {},
    expandableRows,
    expandableRowsComponent,
    expandOnRowClicked,
    expandableRowsHideExpander,
    selectableRows = true,
    isOpenDm,
  },
  ref
) {
  const theme = useTheme();
  const showAlert = useAlertContext();
  const { asyncDelete } = useApisContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const confirmContext = useConfirmContext();
  const [currentRow, setCurrentRow] = useState(null);

  // handle selected row change
  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    onSelectedRowsChange?.(selectedRows);
  };
  // handle delete row
  const handleDeleteRow = async () => {
    if (selectedRows && selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        const row = selectedRows[i];
        const resp = await asyncDelete({ apiCode, uniqueValue: row._id });
        if (!resp?.error && i === selectedRows.length - 1) {
          handleSelectedRowsChange({ selectedRows: [] });
          setToggleCleared(!toggleCleared);
          loadData();
          showAlert({
            type: 'success',
            message: `Đã xóa thành công ${selectedRows.length} dòng`,
          });
        }
      }
    } else {
      return;
    }
  };

  // render delete button
  const renderDeleteButton = () => {
    return (
      <Tooltip placement="top" title="Chuyển vào thùng rác" arrow>
        <IconButton
          ref={ref}
          sx={{
            backgroundColor: 'error.main',
            color: 'whitish.pureWhite',
            borderRadius: '4px',
            '&:hover': { backgroundColor: 'error.main' },
          }}
          onClick={() =>
            confirmContext({
              title: 'Xác nhận',
              onConfirm: handleDeleteRow,
              content: (
                <Box sx={{ padding: '0 10px' }}>
                  <Typography sx={{ fontSize: '14px', textAlign: 'center' }}>
                    Bạn có chắc muốn xóa các dòng đã chọn không ?
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      textAlign: 'center',
                      fontStyle: 'italic',
                      color: 'primary.main',
                      marginTop: '10px',
                    }}
                  >
                    Lưu ý: các dòng đã xóa sẽ không thể khôi phục.
                  </Typography>
                </Box>
              ),
            })
          }
        >
          <FaTrash fontSize="14px" />
        </IconButton>
      </Tooltip>
    );
  };

  useEffect(() => {
    handleSelectedRowsChange({ selectedRows: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiCode]);

  return (
    <>
      <Box sx={{ position: 'fixed', top: 0, left: 0, visibility: 'hidden' }}>
        {renderDeleteButton()}
      </Box>
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        persistTableHead
        noContextMenu
        columns={columns}
        data={Array.isArray(data) ? data : []}
        pointerOnHover
        highlightOnHover
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onRowClicked={(row) => {
          setCurrentRow(row);
          onRowClicked?.(row);
        }}
        selectableRows={selectableRows}
        clearSelectedRows={toggleCleared}
        onSelectedRowsChange={handleSelectedRowsChange}
        responsive
        pagination={pagination}
        paginationServer
        paginationTotalRows={paginationTotalRows}
        paginationPerPage={paginationPerPage}
        paginationDefaultPage={1}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: 'Dòng trên bảng',
          rangeSeparatorText: 'trên',
        }}
        paginationComponent={() => <div></div>}
        selectableRowsHighlight
        progressPending={progressPending}
        // expandable row
        expandableRows={expandableRows}
        expandableRowsComponent={expandableRowsComponent}
        expandableRowsHideExpander={expandableRowsHideExpander}
        expandOnRowClicked={expandOnRowClicked}
        expandableRowExpanded={(row) => row?._id === currentRow?._id}
        onRowExpandToggled={(bool, row) =>
          bool ? undefined : setCurrentRow(null)
        }
        progressComponent={
          <Box
            sx={{
              width: '100%',
              height: '100px',
              paddingTop: '20px',
              textAlign: 'center',
            }}
          >
            <CircularProgress />
          </Box>
        }
        noDataComponent={
          <Typography
            sx={{ fontSize: '14px', textAlign: 'center', padding: '20px 0' }}
          >
            {title ? `Không có ${title}` : 'Không có dữ liệu'}
          </Typography>
        }
        customStyles={{
          headRow: {
            style: {
              minHeight: '32px',
              fontWeight: 600,
              borderBottom: 'none',
              borderRadius: '6px 6px 0 0',
              backgroundColor: theme.palette.primary.main,
              overflow: 'hidden',
            },
          },
          headCells: {
            style: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.whitish.pureWhite,
              paddingLeft: '8px',
              paddingRight: '8px',
              '& input': {
                accentColor: theme.palette.whitish.pureWhite,
              },
            },
          },
          rows: {
            style: {
              '& .rdt-toggle': {
                display: 'none',
              },
              '&:hover .rdt-toggle': {
                display: 'unset',
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: theme.palette.primary.opacity,
            },
            selectedHighlightStyle: {
              backgroundColor: `${theme.palette.primary.opacity} !important`,
            },
          },
          cells: {
            style: {
              paddingLeft: '8px',
              paddingRight: '8px',
              '& input': {
                accentColor: theme.palette.primary.main,
              },
            },
          },
        }}
        conditionalRowStyles={[
          {
            when: (row) => row.status === false,
            style: {
              backgroundColor: theme.palette.error['100'],
            },
          },
        ]}
      />
    </>
  );
}

export default forwardRef(TableBase);
