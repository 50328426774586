import React from 'react';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { FaArrowsSplitUpAndLeft, FaCodeMerge } from 'react-icons/fa6';
import { MdRefresh } from 'react-icons/md';

function FilterContainer({ children, mode, setMode, handleRefreshFilter }) {
  const handleChangeMode = () => {
    setMode(mode === 1 ? 2 : 1);
  };

  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            padding: '5px',
            marginBottom: '10px',
            borderRadius: '6px 6px 0px 0px',
            backgroundColor: 'primary.main',
          }}
        >
          <Stack direction="row" alignItems="center" spacing="5px">
            <Tooltip
              arrow
              placement="top"
              title={mode === 1 ? 'Tách điều kiện' : 'Gộp điều kiện'}
            >
              <IconButton
                onClick={handleChangeMode}
                sx={{ color: 'whitish.pureWhite', padding: '5px' }}
              >
                {mode === 1 ? (
                  <FaCodeMerge size={14} />
                ) : (
                  <FaArrowsSplitUpAndLeft size={14} />
                )}
              </IconButton>
            </Tooltip>
            <Typography
              sx={{
                fontSize: '14px',
                color: 'whitish.pureWhite',
                textWrap: 'nowrap',
              }}
            >
              {mode === 1 ? 'Đang gộp điều kiện' : 'Đang tách điều kiện'}
            </Typography>
          </Stack>
          <Tooltip arrow placement="top" title="Làm mới bộ lọc">
            <IconButton
              sx={{ color: 'whitish.pureWhite' }}
              onClick={handleRefreshFilter}
            >
              <MdRefresh size={14} />
            </IconButton>
          </Tooltip>
        </Stack>
        <Box
          className="hidden-scroll"
          sx={{
            padding: '1px',
            height: 'calc(100vh - 50px - 42px - 20px - 40px - 12px)',
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
}

export default FilterContainer;
