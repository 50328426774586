import React, { useState, useEffect } from 'react';
import ProfileLayout from '~/components/layouts/ProfileLayout';
import { Box, Typography } from '@mui/material';
import { axiosPublic } from '~/utils/httpRequest';
import useToken from '~/hooks/useToken';
import { formatDateDisplay } from '~/utils/helpers';
import TableDisplay from '~/components/table/TableDisplay';
import useResponsive from '~/hooks/useResponsive';
import ModalConfirm from '~/components/modal/ModalConfirm';
import useAlertContext from '~/hooks/hookContext/useAlertContext';

const columns = [
  {
    name: 'Agent',
    selector: (row) => row.agent,
    minWidth: '400px',
    wrap: true,
  },
  {
    name: 'Token',
    selector: (row) => row.token,
    minWidth: '300px',
    wrap: true,
    center: true,
  },
  {
    name: 'Ngày tạo',
    selector: (row) => row.date_created,
    format: (row) => formatDateDisplay(row.date_created, 'DD/MM/YYYY HH:mm:ss'),
    minWidth: '200px',
    center: true,
    wrap: true,
  },
  {
    name: 'Ngày truy cập cuối',
    selector: (row) => row.last_access,
    format: (row) => formatDateDisplay(row.last_access, 'DD/MM/YYYY HH:mm:ss'),
    minWidth: '200px',
    right: true,
    wrap: true,
  },
];

function TokenPage() {
  const mdMatches = useResponsive({ matchKey: 'up', breakpoint: 'md' });
  const showAlert = useAlertContext();
  const token = useToken();

  const [devices, setDevices] = useState([]);
  const [open, setOpen] = useState(false);
  const [deviceSelected, setDeviceSelected] = useState();

  // delete devices
  const handleDelete = async () => {
    try {
      if (!deviceSelected) return;
      const resp = await axiosPublic.delete(
        `/api/token/${deviceSelected._id}?access_token=${token}`
      );
      if (resp && resp.status === 200) {
        await getDevices();
        showAlert({ type: 'success', message: 'Đã xóa thành công' });
      } else {
        showAlert({
          type: 'error',
          message: resp?.response?.data?.message || 'Server error',
        });
      }
    } catch (error) {
      showAlert({ type: 'error', message: error?.message || 'Server error' });
    }
  };

  // handle open confirm
  const handleOpenConfirm = (device) => {
    if (device.token === token) {
      return showAlert({
        type: 'warning',
        message: 'Token này đang được sử dụng',
      });
    } else {
      setDeviceSelected(device);
      setOpen(true);
    }
  };

  // get devices
  const getDevices = async () => {
    try {
      const resp = await axiosPublic.get(`/api/token?access_token=${token}`);
      if (resp && resp.status === 200) {
        setDevices(resp.data);
      } else {
        showAlert({
          type: 'error',
          message: resp?.response?.data?.message || 'Server error',
        });
      }
    } catch (error) {
      showAlert({ type: 'error', message: error?.message || 'Server error' });
    }
  };

  useEffect(() => {
    if (token) {
      getDevices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <ModalConfirm
        title="Xác nhận"
        open={open}
        handleClose={() => setOpen(false)}
        onConfirm={handleDelete}
      >
        <Typography
          sx={{ fontSize: '14px', fontStyle: 'italic', textAlign: 'center' }}
        >
          Bạn có chắc muốn xóa token này không ?
        </Typography>
      </ModalConfirm>
      <ProfileLayout>
        <Box>
          <TableDisplay
            columns={columns}
            data={devices}
            uniqueKey="token"
            handleDelete={handleOpenConfirm}
            fixedHeaderScrollHeight={
              mdMatches
                ? 'calc(100vh - 50px - 42px - 20px - 40px)'
                : 'calc(100vh - 50px - 20px - 40px - 50px)'
            }
          />
        </Box>
      </ProfileLayout>
    </>
  );
}

export default TokenPage;
