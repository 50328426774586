import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalForm from '~/components/modal/ModalForm';
import useApisContext from '~/hooks/hookContext/useApisContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import { Stack } from '@mui/material';
import InfoSection from './info-section/InfoSection';
import ChitietSection from './chitiet-section/ChiTietSection';
import { useSelector } from 'react-redux';
import { LOAI_CHIET_KHAU } from '~/utils/constants';

const schema = yup.object({
  ma_chietkhau: yup.string().required('Vui lòng nhập mã'),
  ten_chietkhau: yup.string().required('Vui lòng nhập tên'),
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
  hieu_luc_tu: yup
    .date()
    .typeError('Vui lòng chọn ngày bắt đầu')
    .required('Vui lòng chọn ngày bắt đầu'),
  hieu_luc_den: yup
    .date()
    .typeError('Vui lòng chọn ngày kết thúc')
    .required('Vui lòng chọn ngày kết thúc'),
  loai_chiet_khau: yup
    .object()
    .typeError('Vui lòng chọn loại chiết khấu')
    .required('Vui lòng chọn loại chiết khấu'),
});

function FormChietKhau({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const defaultData = {
    ma_chietkhau: '',
    ten_chietkhau: '',
    kho: null,
    hieu_luc_tu: moment().format('YYYY-MM-DD HH:mm'),
    hieu_luc_den: moment().add(7, 'days').format('YYYY-MM-DD HH:mm'),
    status: false,
    // chi tiet
    vat_tu: null,
    nhom_vat_tu: null,
    khach_hang: null,
    nhom_khach: null,
    sl_tu: 0,
    sl_den: 0,
    ty_le_ck: 0,
    tien_ck: 0,
    gt_hd_tu: 0,
    gt_hd_den: 0,
    ap_dung_lan_mua_tu_thu: 1,
    ap_dung_lan_mua_den_thu: 1,
    loai_chiet_khau: null,
  };
  const storeData = useSelector((state) => state.store);
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        kho: defaultValues?.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
        vat_tu: defaultValues?.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : null,
        nhom_vat_tu: defaultValues?.ma_nvt
          ? { _id: defaultValues.ma_nvt, ten_nvt: defaultValues.ten_nvt }
          : null,
        khach_hang: defaultValues?.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        nhom_khach: defaultValues?.nh_kh
          ? { _id: defaultValues.nh_kh, group_name: defaultValues.ten_nh_kh }
          : null,
        hieu_luc_tu: defaultValues.hieu_luc_tu
          ? moment(defaultValues.hieu_luc_tu).format('YYYY-MM-DD HH:mm')
          : null,
        hieu_luc_den: defaultValues.hieu_luc_den
          ? moment(defaultValues.hieu_luc_den).format('YYYY-MM-DD HH:mm')
          : null,
        loai_chiet_khau: defaultValues?.exfields?.loai_chiet_khau
          ? LOAI_CHIET_KHAU.find(
              (l) =>
                l.value === Number(defaultValues?.exfields?.loai_chiet_khau)
            )
          : null,
      }
    : {
        ...defaultData,
        kho: storeData?.data?.length > 0 ? storeData.data[0] : null,
      };
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    register,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [collapses, setCollapses] = useState({ chi_tiet: true });

  const loaiChietKhau = watch('loai_chiet_khau');

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const {
      kho,
      vat_tu,
      nhom_vat_tu,
      khach_hang,
      nhom_khach,
      loai_chiet_khau,
      sl_toi_da,
      sl_toi_da_tren_khach,
      ...fields
    } = values;
    return {
      ...fields,
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      ma_vt: vat_tu?.ma_vt || '',
      ten_vt: vat_tu?.ten_vt || '',
      ma_nvt: nhom_vat_tu?._id || '',
      ten_nvt: nhom_vat_tu?.ten_nvt || '',
      ma_kh: khach_hang?.ma_kh || '',
      ten_kh: khach_hang?.ten_kh || '',
      nh_kh: nhom_khach?._id || '',
      ten_nh_kh: nhom_khach?.group_name || '',
      exfields: {
        loai_chiet_khau: loaiChietKhau?.value || 1,
        ten_loai_chiet_khau: loaiChietKhau?.label || '',
      },
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmchietkhau',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="chiết khấu, giảm giá"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        <InfoSection
          control={control}
          errors={errors}
          register={register}
          isEdit={isEdit}
        />
        {loaiChietKhau && (
          <ChitietSection
            loaiChietKhau={loaiChietKhau}
            control={control}
            errors={errors}
            show={collapses.chi_tiet}
            setCollapses={setCollapses}
          />
        )}
      </Stack>
    </ModalForm>
  );
}

export default FormChietKhau;
