import {
  FormControl,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

function Tablepagination({
  rowCount,
  rowsPerPage,
  currentPage,
  onChangePage,
  onChangeRowsPerPage,
  paginationRowsPerPageOptions,
  ...props
}) {
  const [pageValue, setPageValue] = useState(currentPage || 1);
  const handleRowsPerPageChange = ({ target: { value } }) => {
    onChangeRowsPerPage(value);
  };

  const handlePageChange = () => {
    if (pageValue === currentPage) return;
    let valueToSet = pageValue;
    if (valueToSet < 1) valueToSet = 1;
    if (valueToSet > maxPage) valueToSet = maxPage;
    setPageValue(valueToSet);
    onChangePage(valueToSet);
  };

  const maxPage = useMemo(() => {
    let result = Math.floor(rowCount / rowsPerPage);
    if (rowCount % rowsPerPage !== 0) {
      ++result;
    }
    return result;
  }, [rowCount, rowsPerPage]);

  useEffect(() => {
    setPageValue(currentPage);
  }, [currentPage]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing="10px"
      sx={{
        width: '100%',
        height: '42px',
        backgroundColor: 'whitish.graySoft',
        padding: '0 10px',
      }}
    >
      <Stack direction="row" alignItems="center" spacing="5px">
        <Typography sx={{ fontSize: '12px' }}>Số dòng:</Typography>
        <FormControl
          sx={{
            width: '65px',
            '& .MuiSelect-select': { padding: '4px 8px' },
            '& fieldset': {
              border: '1px solid',
              borderColor: 'divider',
              outline: 'none',
            },
          }}
        >
          <Select
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            sx={{ fontSize: '12px' }}
          >
            {!!paginationRowsPerPageOptions &&
              paginationRowsPerPageOptions.map((item) => (
                <MenuItem
                  key={item}
                  value={item}
                  sx={{ fontSize: '12px', padding: '2px 4px' }}
                >
                  {item}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack direction="row" alignItems="center">
        <Pagination
          count={maxPage}
          page={currentPage}
          onChange={(_, newPage) => {
            onChangePage(newPage);
            setPageValue(newPage);
          }}
          shape="rounded"
          variant="text"
          size="small"
          showFirstButton
          showLastButton
          siblingCount={1}
          color="primary"
          sx={{
            '& .MuiPaginationItem-root': {
              fontSize: '12px',
              '&.Mui-selected': {
                color: 'whitish.pureWhite',
              },
            },
          }}
        />
        <TextField
          type="number"
          value={pageValue}
          inputProps={{ min: 1, max: maxPage }}
          onChange={(e) => {
            setPageValue(Number(e.target.value));
          }}
          onBlur={handlePageChange}
          onKeyUp={(e) => {
            if (e.which === 13) {
              handlePageChange();
            }
          }}
          sx={{
            '& .MuiInputBase-root': {
              paddingLeft: 0,
              width: '45px',
            },
            '& .MuiFormLabel-root': {
              fontSize: '12px',
              fontWeight: 600,
              color: 'primary.main',
              paddingRight: '5px',
              transform: 'translate(14px, -12px)',
              backgroundColor: 'whitish.pureWhite',
            },
            '& .MuiInputBase-input': {
              fontSize: '12px',
              padding: '2px 4px',
              textAlign: 'center',
            },
          }}
        />
      </Stack>
      <Typography sx={{ fontSize: '12px' }}>
        Hiển thị {1 + (currentPage - 1) * rowsPerPage} -{' '}
        {Math.min(rowCount, currentPage * rowsPerPage)} / Tổng số {rowCount}
      </Typography>
    </Stack>
  );
}

export default Tablepagination;
