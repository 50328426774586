import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import FilterRadios from '../FilterRadios';
import FilterTimeFromTo from '../FilterTimeFromTo';
import FilterSelectApi from '../FilterSelectApi';
import { LABEL_KHO } from '~/utils/label.constant';
import { numeralCustom } from '~/utils/helpers';
import ReportSCTModal from '~/components/modal/report/ReportSCTModal';

const concerns = [
  {
    value: 'congnophaithu',
    label: 'Công nợ phải thu',
    api: 'cdpskh',
    tk: 131,
    modal: ReportSCTModal,
    convertData: (data) => {
      return data;
    },
    columns: [
      {
        name: 'Đối tượng',
        selector: (row) => row.ten_kh,
        wrap: true,
      },
      {
        name: 'Đầu kỳ nợ',
        selector: (row) => row.dk_no_nt,
        format: (row) => {
          return numeralCustom(row.dk_no_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Đầu kỳ có',
        selector: (row) => row.dk_co_nt,
        format: (row) => {
          return numeralCustom(row.dk_co_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Phát sinh nợ',
        selector: (row) => row.ps_no_nt,
        format: (row) => {
          return numeralCustom(row.ps_no_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Phát sinh có',
        selector: (row) => row.ps_co_nt,
        format: (row) => {
          return numeralCustom(row.ps_co_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Cuối kỳ nợ',
        selector: (row) => row.ck_no_nt,
        format: (row) => {
          return numeralCustom(row.ck_no_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Cuối kỳ có',
        selector: (row) => row.ck_co_nt,
        format: (row) => {
          return numeralCustom(row.ck_co_nt).format();
        },
        wrap: true,
      },
    ],
  },
  {
    value: 'congnophaitra',
    label: 'Công nợ phải trả',
    api: 'cdpskh',
    tk: 331,
    modal: ReportSCTModal,
    convertData: (data) => {
      return data;
    },
    columns: [
      {
        name: 'Đối tượng',
        selector: (row) => row.ten_kh,
        wrap: true,
      },
      {
        name: 'Đầu kỳ nợ',
        selector: (row) => row.dk_no_nt,
        format: (row) => {
          return numeralCustom(row.dk_no_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Đầu kỳ có',
        selector: (row) => row.dk_co_nt,
        format: (row) => {
          return numeralCustom(row.dk_co_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Phát sinh nợ',
        selector: (row) => row.ps_no_nt,
        format: (row) => {
          return numeralCustom(row.ps_no_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Phát sinh có',
        selector: (row) => row.ps_co_nt,
        format: (row) => {
          return numeralCustom(row.ps_co_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Cuối kỳ nợ',
        selector: (row) => row.ck_no_nt,
        format: (row) => {
          return numeralCustom(row.ck_no_nt).format();
        },
        wrap: true,
      },
      {
        name: 'Cuối kỳ có',
        selector: (row) => row.ck_co_nt,
        format: (row) => {
          return numeralCustom(row.ck_co_nt).format();
        },
        wrap: true,
      },
    ],
  },
];

function FilterCDPSKH({ setQueryObject = () => {}, setConcern = () => {} }) {
  const [valueConcern, setValueConcern] = useState(concerns[0].value);
  const [timeOption, setTimeOption] = useState();
  const [filter, setFilter] = useState({
    timeFrom: moment().startOf('months').format('YYYY-MM-DD'),
    timeTo: moment().format('YYYY-MM-DD'),
    kho: null,
    customer: null,
    tk: 131,
  });

  useEffect(() => {
    const queryObject = {};
    if (filter.tk) {
      queryObject.tk = filter.tk;
    }
    if (filter.kho) {
      queryObject.ma_kho = filter.kho?.ma_kho;
    }
    if (filter.customer) {
      queryObject.ma_kh = filter.customer?.ma_kh;
    }
    if (filter.timeFrom) {
      queryObject.tu_ngay = filter.timeFrom;
    }
    if (filter.timeTo) {
      queryObject.den_ngay = filter.timeTo;
    }

    setQueryObject(queryObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (valueConcern) {
      const currentConcern = concerns.find(
        (item) => item.value === valueConcern
      );
      setFilter({ ...filter, tk: currentConcern?.tk });
      setConcern(currentConcern);
    }
    // if (valueConcern !== 'thoigian') {
    //   setConcern(concerns.find((item) => item.value === valueConcern));
    // } else {
    //     let currentTimeConcern;
    //     if (timeOption?.value) {
    //       currentTimeConcern = timeConcerns[timeOption.value];
    //     } else {
    //       currentTimeConcern = timeConcerns.fromTo;
    //     }
    //     setConcern(currentTimeConcern);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueConcern, timeOption]);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 50px - 42px - 30px)',
        overflow: 'auto',
        p: 0.5,
      }}
      className="hidden-scroll"
    >
      <Stack gap={1}>
        <FilterRadios
          title="Chọn báo cáo"
          values={concerns}
          defaultValue={valueConcern}
          onChange={(newConcern) => setValueConcern(newConcern)}
        />
        <FilterSelectApi
          title={LABEL_KHO}
          apiCode="dmkho"
          placeholder={`Chọn ${LABEL_KHO}`}
          selectedValue={filter.kho || null}
          value={
            filter.kho
              ? { ma_kho: filter.kho.ma_kho, ten_kho: filter.kho.ten_kho }
              : null
          }
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) => option.ten_kho}
          onSelect={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterSelectApi
          title="Đối tượng"
          apiCode="customer"
          placeholder="Chọn đối tượng"
          selectedValue={filter.customer || null}
          value={
            filter.customer
              ? { ma_kh: filter.customer.ma_kh, ten_kh: filter.customer.ten_kh }
              : null
          }
          searchFileds={['ma_kh', 'ten_kh']}
          getOptionLabel={(option) => option.ten_kh}
          onSelect={(value) => setFilter({ ...filter, customer: value })}
        />
        <FilterTimeFromTo
          defaultTimeFrom={filter.timeFrom}
          defaultTimeTo={filter.timeTo}
          title="Thời gian"
          onSearch={(time) => setFilter({ ...filter, ...time })}
          onOptionChange={setTimeOption}
        />
      </Stack>
    </Box>
  );
}

export default FilterCDPSKH;
