import React, { useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';

const schema = yup.object({
  group_name: yup.string().required('Vui lòng nhập tên chức vụ'),
});

const defaultData = {
  stt: 0,
  group_name: '',
  group_type: 'DMCV',
};

export default function FormPosition({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues || defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'group',
      data: values,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="500px"
      title="Chức vụ"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing="20px">
        <Grid item xs={12}>
          <TextInput
            type="number"
            label="Số thứ tự"
            placeholder="Nhập hoặc tạo tự động"
            name="stt"
            register={register}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            required
            label="Tên chức vụ"
            placeholder="Vui lòng nhập tên chức vụ"
            name="group_name"
            register={register}
            errorMessage={errors?.group_name?.message}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
}
