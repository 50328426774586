import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import ModalForm from '~/components/modal/ModalForm';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import * as yup from 'yup';
import InfoSection from './info-section/InfoSection';
import { useForm } from 'react-hook-form';
import GiaBanSection from './giaban-section/GiaBanSection';
import moment from 'moment';

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
  hieu_luc_tu: yup
    .date()
    .typeError('Vui lòng chọn ngày bắt đầu')
    .required('Vui lòng chọn ngày bắt đầu'),
  hieu_luc_den: yup
    .date()
    .typeError('Vui lòng chọn ngày kết thúc')
    .required('Vui lòng chọn ngày kết thúc'),
  mieu_ta: yup.string().required('Vui lòng nhập miêu tả'),
});

const defaultData = {
  stt_uu_tien: '',
  vat_tu: null,
  kho: null,
  khach_hang: null,
  nhom_khach: null,
  hieu_luc_tu: moment().format('YYYY-MM-DD'),
  hieu_luc_den: moment().add(7, 'days').format('YYYY-MM-DD'),
  status: false,
};

function FormGiaBan({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : null,
        kho: defaultValues.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
        khach_hang: defaultValues.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        nhom_khach: defaultValues.nh_kh
          ? { _id: defaultValues.nh_kh, group_name: defaultValues.ten_nh_kh }
          : null,
        hieu_luc_tu: defaultValues.hieu_luc_tu
          ? moment(defaultValues.hieu_luc_tu).format('YYYY-MM-DD')
          : null,
        hieu_luc_den: defaultValues.hieu_luc_den
          ? moment(defaultValues.hieu_luc_den).format('YYYY-MM-DD')
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    register,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [collapses, setCollapses] = useState({ gia_ban: true, mo_ta: true });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { vat_tu, khach_hang, nhom_khach, kho, ...fields } = values;
    return {
      ...fields,
      ma_vt: vat_tu?.ma_vt || '',
      ten_vt: vat_tu?.ten_vt || '',
      ma_kh: khach_hang?.ma_kh || '',
      ten_kh: khach_hang?.ten_kh || '',
      nh_kh: nhom_khach?._id || '',
      ten_nh_kh: nhom_khach?.group_name || '',
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmgiaban',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="giá bán"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        <InfoSection
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
        />
        <GiaBanSection
          control={control}
          errors={errors}
          show={collapses.gia_ban}
          setCollapses={setCollapses}
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormGiaBan;
