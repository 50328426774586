import React, { useState, useEffect } from 'react';
import { IconButton, Stack } from '@mui/material';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import InputPrice from './InputPrice';

function ChangeNumber({ detail, onNumberChange = () => {} }) {
  const [number, setNumber] = useState(detail?.sl_xuat || 1);

  const handleNumberChange = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      return;
    } else {
      setNumber(Number(value));
    }
  };

  const handleUpdateNumber = (e) => {
    if (e.key === 'Enter') {
      if (detail.sl_xuat !== number) {
        onNumberChange(Number(number));
      }
    }
  };

  useEffect(() => {
    setNumber(detail?.sl_xuat);
  }, [detail]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing="2px"
      sx={{
        '& .action': {
          visibility: 'hidden',
        },
        '&:hover .action': {
          visibility: 'unset',
        },
      }}
    >
      <IconButton
        className="action"
        disabled={detail?.sl_xuat === 1}
        onClick={() => {
          onNumberChange(number - 1);
        }}
      >
        <AiOutlineMinusCircle size="14px" />
      </IconButton>
      <InputPrice
        value={number}
        onKeyUp={handleUpdateNumber}
        onBlur={() => handleUpdateNumber({ key: 'Enter' })}
        onChange={handleNumberChange}
      />
      <IconButton
        className="action"
        onClick={() => {
          onNumberChange(number + 1);
        }}
      >
        <AiOutlinePlusCircle size="14px" />
      </IconButton>
    </Stack>
  );
}

export default ChangeNumber;
