import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import CheckboxInput from '~/components/input/CheckboxInput';
import DateInput from '~/components/input/DateInput';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';

function ChamCongSection({ show, setCollapses, control, errors }) {
  return (
    <CollapseSection
      show={show}
      title="Chấm công và lương"
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, cham_cong: !prev.cham_cong }))
      }
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="thu_viec_tu_ngay"
            render={({ field: { onChange, value } }) => (
              <DateInput
                label="Thử việc từ ngày"
                labelWidth="30%"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="thu_viec_den_ngay"
            render={({ field: { onChange, value } }) => (
              <DateInput
                label="Thử việc đến ngày"
                labelWidth="30%"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ngay_bat_dau_lam"
            render={({ field: { onChange, value } }) => (
              <DateInput
                label="Ngày vào làm"
                labelWidth="30%"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ngay_nghi_viec"
            render={({ field: { onChange, value } }) => (
              <DateInput
                label="Ngày nghỉ việc"
                labelWidth="30%"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ngay_tinh_bhxh"
            render={({ field: { onChange, value } }) => (
              <DateInput
                label="Ngày tính BHXH"
                labelWidth="30%"
                value={value}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="luong_bhxh"
            render={({ field: { onChange, value } }) => (
              <TextInput
                labelWidth="30%"
                label="Lương BHXH"
                placeholder="Nhập lương BHXH"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const number = e.target.value;
                  onChange(numeralCustom(number).value());
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'neutral.iconColor',
                      }}
                    >
                      VND
                    </Typography>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="luong_co_ban"
            render={({ field: { onChange, value } }) => (
              <TextInput
                labelWidth="30%"
                label="Lương cơ bản"
                placeholder="Nhập lương cơ bản"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const number = e.target.value;
                  onChange(numeralCustom(number).value());
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'neutral.iconColor',
                      }}
                    >
                      VND
                    </Typography>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="luong_thu_viec"
            render={({ field: { onChange, value } }) => (
              <TextInput
                labelWidth="30%"
                label="Lương thử việc"
                placeholder="Nhập lương thử việc"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const number = e.target.value;
                  onChange(numeralCustom(number).value());
                }}
                InputProps={{
                  endAdornment: (
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'neutral.iconColor',
                      }}
                    >
                      VND
                    </Typography>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="bhxh"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Đóng bảo hiểm xã hội"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="bhyt"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Đóng bảo hiểm y tế"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="bhtn"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Đóng bảo  hiểm tai nạn"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="cham_cong"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Có chấm công"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            control={control}
            name="tinh_luong"
            render={({ field: { onChange, value } }) => (
              <CheckboxInput
                label="Có tính lương"
                checked={value || false}
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default ChamCongSection;
