import React from 'react';
import { Avatar, Stack, Typography } from '@mui/material';

function Summary({ Icon, title, children, wrapperSx = {} }) {
  return (
    <Stack
      sx={{
        width: '100%',
        backgroundColor: 'primary.fif',
        padding: '5px',
        borderRadius: '8px',
        height: '100%',
        ...wrapperSx,
      }}
      direction="row"
      gap={2}
      alignItems="center"
    >
      {Icon && (
        <Avatar src={Icon} sx={{ width: 50, height: 50, borderRadius: 0 }} />
      )}
      <Stack>
        {title && (
          <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
            {title}
          </Typography>
        )}
        {children}
      </Stack>
    </Stack>
  );
}

export default Summary;
