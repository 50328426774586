import React from 'react';
import { Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProduct from '../../product/FormProduct';
import FormDVT from '../../dvt/FormDVT';

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  sl_km: yup
    .number()
    .typeError('Số lượng tối thiểu là 1')
    .min(1, 'Số lượng tối thiểu là 1'),
});

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          vat_tu: defaultValues.ma_vt
            ? {
                ma_vt: defaultValues.ma_vt,
                ten_vt: defaultValues.ten_vt,
              }
            : null,
          don_vi_tinh: defaultValues.ma_dvt
            ? { ma_dvt: defaultValues.ma_dvt, ten_dvt: defaultValues.ten_dvt }
            : null,
        }
      : {
          sl_km: 1,
          vat_tu: null,
        },
  });

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="500px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} hàng tặng`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Stack spacing="20px">
        <Controller
          control={control}
          name="vat_tu"
          render={({ field: { value, onChange } }) => (
            <SelectApiInput
              labelWidth="30%"
              label="Hàng hóa"
              required
              apiCode="dmvt"
              placeholder="Chọn sản phẩm"
              searchFileds={['ma_vt', 'ten_vt']}
              getOptionLabel={(option) => option.ten_vt}
              selectedValue={value}
              value={value || { ma_vt: '', ten_vt: '' }}
              onSelect={(val) => {
                onChange(val);
                setValue('don_vi_tinh', {
                  ma_dvt: val.ma_dvt,
                  ten_dvt: val.ten_dvt,
                });
              }}
              FormAdd={FormProduct}
              errorMessage={errors?.vat_tu?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="don_vi_tinh"
          render={({ field: { value, onChange } }) => (
            <SelectApiInput
              labelWidth="30%"
              label="Đơn vị tính"
              required
              readOnly
              apiCode="dmdvt"
              placeholder="Chọn đơn vị tính"
              searchFileds={['ma_dvt', 'ten_dvt']}
              getOptionLabel={(option) => option.ten_dvt}
              selectedValue={value}
              value={value || { ma_dvt: '', ten_dvt: '' }}
              onSelect={onChange}
              FormAdd={FormDVT}
            />
          )}
        />
        <TextInput
          type="number"
          labelWidth="30%"
          label="Số khuyến mãi"
          placeholder="Nhập số lượng khuyến mãi"
          name="sl_km"
          register={register}
        />
      </Stack>
    </ModalBase>
  );
}

export default FormAddDetail;
