import { updateUsergroup } from '../reducrers/usergroup.reducer';

export const getUserGroups = async ({
  asyncSearchList,
  showAlert,
  dispatch,
}) => {
  try {
    const data = await asyncSearchList({
      apiCode: 'usergroup',
      condition: { page: 1, limit: 10000, q: { status: true } },
    });
    dispatch(updateUsergroup(data));
  } catch (error) {
    showAlert({
      type: 'error',
      message: error?.message || 'Something went wrong!',
    });
  }
};
