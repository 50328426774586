import React, { useEffect, useState } from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import TableDisplay from '~/components/table/TableDisplay';
import { numeralCustom } from '~/utils/helpers';
import FormAddDvt from './FormAddDvt';
import { Box, Button, Stack } from '@mui/material';
import { cloneDeep } from 'lodash';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';

const columns = [
  {
    name: 'Đơn vị tính',
    selector: (row) => row.ten_dvt,
    wrap: true,
  },
  {
    name: 'Số lượng quy đổi',
    selector: (row) => row.mau,
    wrap: true,
  },
  {
    name: 'Giá bán',
    selector: (row) => row.gia_ban_nt,
    format: (row) => numeralCustom(row.gia_ban_nt).format(),
  },
];

function DvtVariantSection({
  show,
  setCollapses,
  dvts,
  setDvts,
  isEdit,
  product,
}) {
  const showAlert = useAlertContext();
  const [, setBackdrop] = useBackdropContext()
  const { asyncGetList, asyncPutData, asyncPostData } = useApisContext();
  const [data, setData] = useState([]);
  const [openFormAdd, setOpenFormAdd] = useState(false);
  const [isEditRow, setIsEditRow] = useState(false);
  const [defaultValue, setDefaultValue] = useState();
  const [load, setLoad] = useState(0);

  const handleCloseFormAdd = () => setOpenFormAdd(false);

  const handleRowClicked = (row) => {
    setDefaultValue(row);
    setIsEditRow(true);
    setOpenFormAdd(true);
  };

  const handleAdd = async (dataToAdd, isEditRow) => {
    // dang sua san pham
    if (isEdit) {
      try {
        setBackdrop(true)
        if (isEditRow) {
          await asyncPutData({
            apiCode: 'dmqddvt',
            uniqueValue: defaultValue?._id,
            data: {
              ...dataToAdd,
              ma_dvt_goc: product?.ma_dvt,
              ma_vt: product?.ma_vt,
              ten_vt: product?.ten_vt,
              tu: 1,
            },
          });
        } else {
          await asyncPostData({
            apiCode: 'dmqddvt',
            data: {
              ...dataToAdd,
              ma_dvt_goc: product?.ma_dvt,
              ma_vt: product?.ma_vt,
              ten_vt: product?.ten_vt,
              tu: 1,
            },
          });
        }
      } catch (error) {
        showAlert({
          type: 'error',
          message: error?.message || 'Lỗi khi cập nhật đơn vị tính',
        });
      } finally {
        setLoad(load + 1);
        setBackdrop(false)
      }
      // dang them moi san pham
    } else {
      if (isEditRow) {
        const existedIndex = dvts.findIndex(
          (item) => item.ma_dvt === dataToAdd.ma_dvt
        );
        if (existedIndex >= 0) {
          setDvts((prev) => {
            const prevClone = cloneDeep(prev);
            prevClone.splice(existedIndex, 1, dataToAdd);
            return prevClone;
          });
        }
      } else {
        const existedIndex = dvts.findIndex(
          (item) => item.ma_dvt === dataToAdd.ma_dvt
        );
        if (existedIndex >= 0) {
          showAlert({ type: 'warning', message: 'Đơn vị tính đã tồn tại' });
        } else {
          setDvts((prev) => [...prev, dataToAdd]);
        }
      }
    }
  };

  const getData = async () => {
    const resp = await asyncGetList({
      apiCode: 'dmqddvt',
      condition: {
        page: 1,
        limit: 99999,
        q: { ma_vt: product?.ma_vt, ma_dvt: { $ne: product?.ma_dvt || '' } },
      },
    });
    setData(resp);
  };

  useEffect(() => {
    if (product?.ma_vt) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.ma_vt, load]);

  return (
    <>
      {openFormAdd && (
        <FormAddDvt
          open={openFormAdd}
          handleClose={handleCloseFormAdd}
          addDvt={handleAdd}
          isEdit={isEditRow}
          defaultValue={defaultValue}
        />
      )}
      <CollapseSection
        title="Đơn vị tính khác"
        show={show}
        onCollapse={() =>
          setCollapses((prev) => ({ ...prev, dvt_khac: !prev.dvt_khac }))
        }
      >
        <Stack gap={1} alignItems="flex-start">
          <Button variant="outlined" onClick={() => setOpenFormAdd(true)}>
            Thêm đơn vị tính
          </Button>
          <Box sx={{ width: '100%' }}>
            <TableDisplay
              columns={columns}
              data={isEdit ? data : dvts}
              onRowClicked={handleRowClicked}
            />
          </Box>
        </Stack>
      </CollapseSection>
    </>
  );
}

export default DvtVariantSection;
