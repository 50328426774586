import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';

function DinhMucSection({ show, setCollapses, control }) {
  return (
    <CollapseSection
      title="Định mức tồn"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, dinh_muc: !prev.dinh_muc }))
      }
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ton_toi_thieu"
            render={({ field: { onChange, value } }) => (
              <TextInput
                labelWidth="40%"
                label="Tối thiểu"
                placeholder="Tốn kho tối thiểu"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const number = e.target.value;
                  onChange(numeralCustom(number).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ton_toi_da"
            render={({ field: { onChange, value } }) => (
              <TextInput
                labelWidth="40%"
                label="Tối đa"
                placeholder="Tốn kho tối đa"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const number = e.target.value;
                  onChange(numeralCustom(number).value());
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default DinhMucSection;
