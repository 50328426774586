import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import ErrorImage from '~/assets/img/error.png';

function ErrorPage() {
  return (
    <Box sx={{ width: '100vw', height: '100vh' }}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        rowGap="10px"
        sx={{ width: '100%', height: '100%' }}
      >
        <Avatar
          src={ErrorImage}
          alt="Error Image"
          sx={{ width: '200px', height: '200px' }}
        />
        <Typography sx={{ fontSize: '20px', textAlign: 'center' }}>
          Có lỗi xảy ra, vui lòng thử lại !!
        </Typography>
      </Stack>
    </Box>
  );
}

export default ErrorPage;
