import React from 'react';
import { Grid } from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import TextInput from '~/components/input/TextInput';

function NganHangSection({ show, setCollapses, register }) {
  return (
    <CollapseSection
      title="Tài khoản ngân hàng"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, ngan_hang: !prev.ngan_hang }))
      }
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            name="so_tk_ngan_hang"
            label="Số tài khoản"
            placeholder="Nhập số tài khoản ngân hàng"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            name="ten_chu_tk_ngan_hang"
            label="Tên chủ tài khoản"
            placeholder="Nhập tên chủ tài khoản"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            name="phat_hanh_boi_ngan_hang"
            label="Tại ngân hàng"
            placeholder="Nhập tên ngân hàng phát hành"
            register={register}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default NganHangSection;
