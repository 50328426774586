import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import moment from 'moment';
import ModalForm from '~/components/modal/ModalForm';
import InfoSection from './info-section/InfoSection';
import DiaChiSection from './diachi-section/DiaChiSection';
import GhiChuSection from './ghichu-section/GhiChuSection';
import PhanQuyenSection from './phanquyen-section/PhanQuyenSection';
import { QUYEN_TRUY_CAP } from '~/utils/constants';
import TakecareSection from './takecare-section/TakecareSection';
import ThongTinGiaoHangSection from './thongtingiaohang-section/ThongTinGiaoHangSection';

const schema = yup.object({
  ten_kh: yup.string().required('Vui lòng nhập tên khách hàng'),
  trang_thai_obj: yup
    .object()
    .typeError('Vui lòng chọn trạng thái')
    .required('Vui lòng chọn trạng thái'),
  nhom_kh: yup
    .object()
    .typeError('Vui lòng chọn nhóm khách')
    .required('Vui lòng chọn nhóm khách'),
  email: yup.string().email('Email không đúng định dạng'),
  dien_thoai: yup
    .string()
    .required('Vui lòng nhập số điện thoại')
    .matches(/^(0?)(3[2-9]|5[6|8|9]|7[0|6-9]|8[0-6|8|9]|9[0-4|6-9])[0-9]{7}$/, {
      message: 'Số điện thoại không hợp lệ',
      excludeEmptyString: true,
    }),
  id_number: yup.string().matches(/^\d+$/, {
    message: 'Trường này chỉ bao gồm số',
    excludeEmptyString: true,
  }),
});

const defaultData = {
  ma_kh: '',
  ten_kh: '',
  ten_kh2: '', // Tên nhà thuốc, cơ sở kinh doanh
  dien_thoai: '',
  email: '',
  ghi_chu: '',
  id_number: '', // CMND/CCCD
  ngay_sinh: '',
  ma_so_thue: '',
  status: true,
  trang_thai_obj: null,
  tinh_thanh_obj: null,
  quan_huyen_obj: null,
  xa_phuong_obj: null,
  nhom_kh: null,
  phu_trach: null,
  of_user: null,
  visible: null,
  users: [],
  dia_chi: '',
  kh_yn: true,
  ncc_yn: false,
};

function FormCustomer({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        trang_thai_obj: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
        nhom_kh: defaultValues.nh_kh
          ? {
              _id: defaultValues.nh_kh,
              group_name: defaultValues.ten_nh_kh,
            }
          : null,
        phu_trach: defaultValues.phu_trach
          ? {
              email: defaultValues.phu_trach,
              name: defaultValues.ten_phu_trach,
            }
          : null,
        of_user: defaultValues.of_user
          ? { email: defaultValues.of_user }
          : null,
        visible:
          defaultValues.visible_to || defaultValues.visible_to === 0
            ? QUYEN_TRUY_CAP.find(
                (item) => item?.value === defaultValues.visible_to
              )
            : null,
        users:
          defaultValues.visible_to_users?.length > 0
            ? defaultValues.visible_to_users.map((u) => ({ email: u }))
            : [],
        tinh_thanh_obj: defaultValues.tinh_thanh
          ? {
              ma_tinh_thanh: defaultValues.tinh_thanh,
              ten_tinh_thanh: defaultValues.ten_tinh_thanh,
            }
          : null,
        quan_huyen_obj: defaultValues.quan_huyen
          ? {
              ma_quan_huyen: defaultValues.quan_huyen,
              ten_quan_huyen: defaultValues.ten_quan_huyen,
            }
          : null,
        xa_phuong_obj: defaultValues.xa_phuong
          ? {
              ma_xa_phuong: defaultValues.xa_phuong,
              ten_xa_phuong: defaultValues.ten_xa_phuong,
            }
          : null,
        ngay_sinh: defaultValues.ngay_sinh
          ? moment(defaultValues.ngay_sinh).format('YYYY-MM-DD')
          : '',
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [collapses, setCollapses] = useState({
    ghi_chu: true,
    dia_chi: true,
    thong_tin_giao_hang: true,
    phan_quyen: true,
  });

  const handleReset = () => {
    reset(originalData);
  };

  const tinhThanh = watch('tinh_thanh_obj');
  const quanHuyen = watch('quan_huyen_obj');

  const generateDataPost = (values) => {
    const {
      trang_thai_obj,
      tinh_thanh_obj,
      quan_huyen_obj,
      xa_phuong_obj,
      nhom_kh,
      phu_trach,
      visible,
      users,
      of_user,
      ...fields
    } = values;
    return {
      ...fields,
      trang_thai: trang_thai_obj?.ma_trang_thai || '',
      ten_trang_thai: trang_thai_obj?.ten_trang_thai || '',
      tinh_thanh: tinh_thanh_obj?.ma_tinh_thanh || '',
      ten_tinh_thanh: tinh_thanh_obj?.ten_tinh_thanh || '',
      quan_huyen: quan_huyen_obj?.ma_quan_huyen || '',
      ten_quan_huyen: quan_huyen_obj?.ten_quan_huyen || '',
      xa_phuong: xa_phuong_obj?.ma_xa_phuong || '',
      ten_xa_phuong: xa_phuong_obj?.ten_xa_phuong || '',
      nh_kh: nhom_kh?._id || '',
      ten_nh_kh: nhom_kh?.group_name || '',
      phu_trach: phu_trach?.email || '',
      ten_phu_trach: phu_trach?.name || '',
      visible_to: visible?.value,
      visible_to_users: (users || []).map((u) => u.email),
      of_user: of_user?.email || '',
    };
  };

  // handle submit
  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'customer',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="khách hàng"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack
        spacing="20px"
        sx={{
          width: '100%',
          padding: '10px',
          maxHeight: 'calc(90vh - 20px - 39px - 39px)',
          overflow: 'auto',
        }}
      >
        {isEdit && <TakecareSection customer={defaultValues} />}
        <InfoSection
          control={control}
          errors={errors}
          isEdit={isEdit}
          register={register}
        />
        <DiaChiSection
          control={control}
          quanHuyen={quanHuyen}
          register={register}
          setCollapses={setCollapses}
          setValue={setValue}
          show={collapses.dia_chi}
          tinhThanh={tinhThanh}
        />
        {isEdit && (
          <ThongTinGiaoHangSection
            show={collapses.thong_tin_giao_hang}
            setCollapses={setCollapses}
            customer={defaultValues}
            isEdit={isEdit}
          />
        )}
        <PhanQuyenSection
          show={collapses.phan_quyen}
          setCollapses={setCollapses}
          control={control}
        />
        <GhiChuSection
          register={register}
          show={collapses.ghi_chu}
          setCollapses={setCollapses}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormCustomer;
