/* eslint-disable jsx-a11y/iframe-has-title */
import { CircularProgress, Stack } from '@mui/material';
import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import BillLayout from '~/components/layouts/BillLayout';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import MainLayout from './components/Main/MainLayout';
import { createContext } from 'react';
import useSnackbarContext from '~/hooks/hookContext/useSnackbarContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useLocalStorage from '~/hooks/useLocalStorage';
import { cloneDeep } from 'lodash';
import ModalBase from '~/components/modal/ModalBase';
import { useCallback } from 'react';
import useToken from '~/hooks/useToken';
import { PUBLIC_URL } from '~/utils/constants';
import { axiosPublic } from '~/utils/httpRequest';
import connectIndexedDB from '~/utils/indexedDB';
import { v4 } from 'uuid';
import useBackdropContext from '~/hooks/hookContext/useBackdropContext';

const BillContext = createContext();

export const useBillContext = () => {
  const value = useContext(BillContext);
  if (!value) throw new Error('Bill context must be used inside Bill provider');
  return value;
};

function Bill() {
  const alertSnackbar = useSnackbarContext();
  const { asyncSearchList, asyncPostData } = useApisContext();
  const token = useToken();
  const [, setLoading] = useBackdropContext();
  const [khoSelected, setKhoSelected] = useLocalStorage(
    'kho_active',
    undefined
  );
  const [fullScreen, setFullScreen] = useState(() => {
    if (document.fullscreenElement) {
      return true;
    } else {
      return false;
    }
  });
  const [search, setSearch] = useState('');
  const [khos, setKhos] = useState([]);
  const [pbhs, setPbhs] = useState([]);
  const [printFrame, setPrintFrame] = useState(null);
  const [pbhSelected, setPbhSelected] = useLocalStorage(
    'pbh_active',
    undefined
  );
  const [openPayment, setOpenPayment] = useState(false);

  const barcodeRef = useRef('');

  // get product by barcode
  const handleGetProductByBarcode = async (barcode) => {
    try {
      setLoading(true);
      const resp = await asyncSearchList('dmvt', { barcode });
      if (resp.data.length > 0) {
        const product = resp.data[0];
        await add({
          product,
          dvt: { ma_dvt: product.ma_dvt, ten_dvt: product.ten_dvt },
        });
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Internal server error');
    } finally {
      setLoading(false);
      barcodeRef.current = '';
    }
  };

  // them vao detail
  const add = async ({ product, dvt, sl = 1, plus = true }) => {
    const pbhClone = cloneDeep(pbhSelected);
    const productSelected = pbhClone.details.find(
      (item) => item.ma_vt === product.ma_vt && item.ma_dvt === dvt.ma_dvt
    );
    if (productSelected) {
      if (plus) {
        productSelected.sl_xuat += sl;
        productSelected.sl_order += sl;
      } else {
        productSelected.sl_xuat = sl;
        productSelected.sl_order = sl;
      }
      if (productSelected.sl_xuat < 1) {
        productSelected.sl_xuat = 1;
        productSelected.sl_order = 1;
      }
      productSelected.tien_hang_nt =
        productSelected.gia_ban_le_goc * productSelected.sl_xuat;
      productSelected.tien_ck_nt =
        (productSelected.sl_xuat *
          ((productSelected.gia_ban_le_goc || 0) *
            (productSelected.ty_le_ck || 0))) /
        100;
      productSelected.tien_nt =
        productSelected?.gia_ban * productSelected.sl_xuat -
        productSelected?.tien_ck_nt;
    } else {
      const soLuong = plus ? 1 : sl;
      const newDetail = {
        ma_vt: product.ma_vt,
        ten_vt: product.ten_vt,
        ma_dvt: dvt.ma_dvt,
        ten_dvt: dvt.ten_dvt,
        sl_xuat: soLuong,
        sl_order: soLuong,
        theo_doi_lo: product.theo_doi_lo,
        picture:
          product.hinh_anh1 || product.hinh_anh2 || product.hinh_anh3 || '',
        tien_nt: product.gia_ban_le * soLuong,
        tien_ck_nt: 0,
        tien_hang_nt: product.gia_ban_le * soLuong,
        gia_ban_nt: product.gia_ban_le,
        gia_ban: product.gia_ban_le,
        gia_ban_le_goc: product.gia_ban_le,
      };
      if (newDetail.sl_xuat < 1) {
        newDetail.sl_xuat = 1;
      }
      pbhClone.details.push(newDetail);
    }
    await updatePbh(pbhClone);
  };

  const deleteDetail = async (detail) => {
    const pbhSelectedClone = cloneDeep(pbhSelected);
    pbhSelectedClone.details = pbhSelectedClone.details.filter((item) => {
      if (item.ma_vt !== detail.ma_vt) {
        return true;
      } else {
        if (item.ma_dvt === detail.ma_dvt) {
          return false;
        } else {
          return true;
        }
      }
    });
    await updatePbh(pbhSelectedClone);
  };

  // tao phieu ban hang moi
  const createPbh = async () => {
    try {
      const pbhPost = {
        ma_kho: khoSelected.ma_kho,
        ten_kho: khoSelected.ten_kho,
        trang_thai: '1',
        ten_trang_thai: 'Đang có khách',
        date_created: new Date(),
        details: [],
        ty_le_ck_hd: 0,
        tien_ck_hd: 0,
        _id: v4(),
      };
      let resp;
      if (!navigator.onLine) {
        const { add } = await connectIndexedDB({
          databaseName: 'pos_pbhs',
          storeName: 'pbhs',
        });
        resp = pbhPost;
        await add(pbhPost);
      } else {
        resp = await asyncPostData({ apiCode: 'pbl', data: pbhPost });
      }
      setPbhSelected(resp);
      await getPbhs();
      return resp;
    } catch (error) {
      alertSnackbar('error', error?.message || 'Internal server error');
    }
  };
  // cap nhat phieu ban hang
  const updatePbh = async (newPbh) => {
    try {
      const pbhClone = cloneDeep(newPbh);
      // tinh tien_nt cua tung detail
      let tienHang = 0,
        t_ck_nt = 0;
      pbhClone.details.forEach((detail) => {
        detail.tien_nt = detail?.gia_ban * detail.sl_xuat - detail?.tien_ck_nt;
        tienHang += detail.gia_ban_le_goc * detail.sl_xuat;
        t_ck_nt += detail.tien_ck_nt;
      });

      pbhClone.t_tien = tienHang;
      pbhClone.t_tien_nt = tienHang;
      pbhClone.tien_ck_hd = pbhClone.ty_le_ck_hd
        ? (pbhClone.t_tien_nt * pbhClone.ty_le_ck_hd) / 100
        : pbhClone.tien_ck_hd;
      pbhClone.t_ck_nt = t_ck_nt + pbhClone.tien_ck_hd;
      pbhClone.t_tt_nt = pbhClone.t_tien_nt - pbhClone.t_ck_nt;

      setLoading(true);
      let resp;
      if (!navigator.onLine) {
        if (pbhClone.trang_thai !== '6') {
          const { updateOne } = await connectIndexedDB({
            databaseName: 'pos_pbhs',
            storeName: 'pbhs',
          });
          resp = await updateOne(pbhClone);
        } else {
          const { add } = await connectIndexedDB({
            databaseName: 'pos_payment',
            storeName: 'payment',
          });
          const { deleteOne } = await connectIndexedDB({
            databaseName: 'pos_pbhs',
            storeName: 'pbhs',
          });
          await deleteOne(pbhClone._id);
          await add(pbhClone);
        }
        resp = pbhClone;
      } else {
        resp = await asyncPostData({
          apiCode: 'pbl',
          data: pbhClone,
          method: 'put',
          endpoint: `/${pbhClone._id}`,
        });
      }
      await getPbhs();
      return resp;
    } catch (error) {
      alertSnackbar('error', error?.message || 'Internal server error');
    } finally {
      setLoading(false);
    }
  };
  // print default
  const printDefault = (voucher) => {
    setLoading(true);
    let stopRunning = () => {
      setLoading(false);
    };
    let domain = window.location.origin;
    let urlPrintDefault = `${domain}/#/print/${voucher.id_app}/${token}/${voucher._id}`;
    console.log(urlPrintDefault);
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      await setPrintFrame(null);
      let printFrame = (
        <iframe
          onLoad={() => {
            setTimeout(() => {
              let fr = window.frames['printframe'];
              fr.focus();
              stopRunning();
              fr.print();
              resolve();
            }, 3000);
          }}
          name="printframe"
          style={{ display: 'none', width: '100%', height: '100%' }}
          src={urlPrintDefault}
        ></iframe>
      );
      setPrintFrame(printFrame);
    });
  };
  const printLocal = async (url) => {
    setLoading(true);
    let stopRunning = () => {
      setLoading(false);
    };
    let content;
    try {
      const resp = await axiosPublic.get(url);
      if (resp && resp.status === 200) {
        content = resp.data;
      }
    } catch (e) {
      return alertSnackbar('info', e.message || 'Server error');
    }
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      await setPrintFrame(null);
      let printFrame = (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe
          onLoad={() => {
            let fr = window.frames['printframe'];
            fr.focus();
            stopRunning();
            fr.print();
            setTimeout(() => {
              resolve();
            }, 10);
          }}
          name="printframe"
          style={{ display: 'none', width: '100%', height: '100%' }}
          srcDoc={content}
        ></iframe>
      );
      setPrintFrame(printFrame);
    });
  };
  const print = async (
    loai_bill,
    onSuccess,
    onError,
    useDefaultTempleteIfNotFound = true
  ) => {
    setLoading(true);
    let printers = ((khoSelected || {}).printers || []).filter(
      (printer) =>
        printer.id_mau_in &&
        (printer.loai_bill == undefined || printer.loai_bill == loai_bill)
    );
    if (printers.length > 0) {
      await Promise.all(
        printers.map((printer) => {
          let url =
            PUBLIC_URL +
            '/api/' +
            pbhSelected.id_app +
            '/pbl/excel/' +
            printer.id_mau_in +
            '?_id=' +
            pbhSelected._id +
            `&print=1&access_token=` +
            token;
          const print_service_url = printer.dia_chi_may_in;

          return (async () => {
            if (print_service_url) {
              let url_print = `${print_service_url}?url=${btoa(url)}&printer=${
                printer.ten_may_in
              }&width=${printer.do_rong_kho_giay || 0}&height=${
                printer.chieu_dai_kho_giay || 0
              }`;
              try {
                await asyncSearchList({ endpoint: url_print });
              } catch (e) {
                await printLocal(url);
              }
            } else {
              await printLocal(url);
            }
          })();
        })
      );
      setLoading(false);
      if (onSuccess) onSuccess();
    } else {
      //default template print
      if (useDefaultTempleteIfNotFound) {
        try {
          await printDefault(pbhSelected);
          if (onSuccess) onSuccess();
        } catch (e) {
          if (onError) {
            alertSnackbar('error', e.message || 'Server error');
          } else {
            alertSnackbar('error', e.message || 'Server error');
          }
        }
      } else {
        if (onError) {
          alertSnackbar('error', 'Không tìm thấy mẫu in phù hợp');
        } else {
          if (onSuccess) onSuccess();
        }
      }
    }
  };
  const tables = () => {
    return;
  };
  // handle payment
  const handlePayment = useCallback(async () => {
    let error = false;
    console.log(pbhSelected);
    if ((pbhSelected?.tien_thu || 0) < (pbhSelected?.t_tt_nt || 0)) {
      error = true;
      alertSnackbar('error', 'Số tiền nhận chưa đủ');
      return;
    }
    if (!pbhSelected?.pt_thanh_toan) {
      error = true;
      alertSnackbar('error', 'Vui lòng chọn phương thức thanh toán');
      return;
    }
    if (!error) {
      const pbhClone = cloneDeep(pbhSelected);
      pbhClone.ngay_ct = new Date();
      pbhClone.trang_thai = '6';
      pbhClone.ten_trang_thai = 'Đã thanh toán';
      const v = await updatePbh(pbhClone);
      setOpenPayment(false);
      alertSnackbar('success', 'Thanh toán thành công');
      if (v.trang_thai !== '9' && navigator.onLine) {
        print(
          1,
          () => {
            tables();
          },
          () => {
            tables();
          }
        );
      }
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pbhSelected]);

  // get pbh
  const getPbhs = async () => {
    try {
      setLoading(true);
      const { addAll, getAll, clearAll } = await connectIndexedDB({
        databaseName: 'pos_pbhs',
        storeName: 'pbhs',
      });
      if (!navigator.onLine) {
        const pbhsFromLocal = await getAll();
        if (pbhsFromLocal?.length > 0) {
          setPbhs(
            pbhsFromLocal
              .filter((pbh) => pbh.trang_thai === '1')
              .sort(() => -1) || []
          );
        } else {
          await createPbh();
        }
      } else {
        const condition = { ma_kho: khoSelected.ma_kho, trang_thai: '1' };
        const resp = await asyncSearchList({
          apiCode: 'pbl',
          condition: { q: condition },
        });
        if (resp && resp?.length === 0) {
          await createPbh();
        } else {
          setPbhs(resp || []);
          await clearAll();
          await addAll(resp || []);
        }
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Internal server error');
    } finally {
      setLoading(false);
    }
  };
  // get kho
  const getKhos = async () => {
    try {
      setLoading(true);
      const { addAll, getAll, clearAll } = await connectIndexedDB({
        databaseName: 'pos_khos',
        storeName: 'khos',
      });
      if (!navigator.onLine) {
        const khosFromLocal = await getAll();
        setKhos(khosFromLocal || []);
      } else {
        const resp = await asyncSearchList({ apiCode: 'dmkho' });
        setKhos(Array.isArray(resp) ? resp : []);
        await clearAll();
        await addAll(resp);
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Internal server error');
    } finally {
      setLoading(false);
    }
  };
  // get kho
  const getPttts = async () => {
    try {
      setLoading(true);
      const { addAll, clearAll } = await connectIndexedDB({
        databaseName: 'pos_pttts',
        storeName: 'pttts',
      });
      if (navigator.onLine) {
        await clearAll();
        const resp = await asyncSearchList({ apiCode: 'ptthanhtoan' });
        await addAll(resp || []);
      }
    } catch (error) {
      alertSnackbar('error', error?.message || 'Internal server error');
    } finally {
      setLoading(false);
    }
  };
  // number product discounted
  const numberProductDiscounted = useMemo(() => {
    if (pbhSelected) {
      return pbhSelected?.details.reduce((acc, item) => {
        if (item.tien_ck > 0) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
    } else {
      return null;
    }
  }, [pbhSelected]);

  useEffect(() => {
    if (khoSelected) {
      getPbhs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [khoSelected]);
  useEffect(() => {
    if (pbhs.length > 0) {
      if (pbhSelected) {
        const pbhExisted = pbhs.find((pbh) => pbh._id === pbhSelected?._id);
        if (!pbhExisted) {
          setPbhSelected(pbhs[0]);
        } else {
          setPbhSelected(pbhExisted);
        }
      } else {
        setPbhSelected(pbhs[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pbhs]);

  useEffect(() => {
    getKhos();
    getPttts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (khos.length > 0) {
      if (!khoSelected) {
        setKhoSelected(khos[0]);
      } else {
        const maKhos = (khos || []).map((kho) => kho.ma_kho);
        if (!maKhos.includes(khoSelected.ma_kho)) {
          setKhoSelected(khos[0]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [khos, khoSelected]);

  useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.key === 'F9') {
        if (!openPayment) {
          setOpenPayment(true);
        } else {
          handlePayment();
        }
      }
    };
    const handleScanner = async (e) => {
      const textInput = e.key || String.fromCharCode(e.keyCode);
      const targetName = e.target.localName;
      if (textInput && textInput.length === 1 && targetName !== 'input') {
        barcodeRef.current += textInput;
        if (barcodeRef.current.length === 13) {
          await handleGetProductByBarcode(barcodeRef.current);
        }
      }
    };
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleScanner);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleScanner);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePayment, openPayment]);

  useEffect(() => {
    return () => {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    };
  }, []);

  const providerState = {
    // value
    fullScreen,
    search,
    khos,
    khoSelected,
    pbhs,
    pbhSelected,
    numberProductDiscounted,
    openPayment,
    // function
    setFullScreen,
    setSearch,
    setKhoSelected,
    setPbhs,
    setPbhSelected,
    createPbh,
    getPbhs,
    updatePbh,
    add,
    deleteDetail,
    handlePayment,
    setOpenPayment,
  };

  return (
    <>
      {printFrame}
      <BillLayout>
        <BillContext.Provider value={providerState}>
          <Stack
            spacing="5px"
            sx={{
              width: '100%',
              height: 'calc(100vh - 50px)',
              padding: '5px 0',
            }}
          >
            {/* <ModalBase
              open={loading}
              ghost
              hideCloseIcon
              slotProps={{
                backdrop: { style: { backgroundColor: 'transparent' } },
              }}
            >
              <Stack alignItems="center" justifyContent="center">
                <CircularProgress />
              </Stack>
            </ModalBase> */}
            <Header />
            <MainLayout />
            <Footer />
          </Stack>
        </BillContext.Provider>
      </BillLayout>
    </>
  );
}

export default Bill;
