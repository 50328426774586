const { createSlice } = require('@reduxjs/toolkit');

const ptttSlice = createSlice({
  name: 'pttt',
  initialState: {
    data: [],
  },
  reducers: {
    updatePttt(state, action) {
      state.data = action.payload;
    },
  },
});

export const { updatePttt } = ptttSlice.actions;
export default ptttSlice.reducer;
