import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import CheckboxInput from '~/components/input/CheckboxInput';
import DateInput from '~/components/input/DateInput';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';

function InfoSection({ register, errors, control, isEdit }) {
  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              required
              readOnly={isEdit}
              label="Mã voucher"
              labelWidth="30%"
              placeholder="Nhập mã E-voucher"
              name="ma"
              register={register}
              errorMessage={errors?.ma?.message}
            />
            <TextInput
              required
              label="Tên voucher"
              labelWidth="30%"
              placeholder="Nhập tên E-voucher"
              name="ten"
              register={register}
              errorMessage={errors?.ten?.message}
            />
            <Controller
              name="so_tien"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Số tiền giảm"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="ty_le"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Tỷ lệ giảm"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        %
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="so_tien_max"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Tiền giảm tối đa"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="hieu_luc_tu"
              render={({ field: { onChange, value } }) => (
                <DateInput
                  required
                  label="Từ ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_tu?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="hieu_luc_den"
              render={({ field: { onChange, value } }) => (
                <DateInput
                  required
                  label="Đến ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.hieu_luc_den?.message}
                />
              )}
            />
            <TextInput
              required
              type="number"
              labelWidth="30%"
              label="Số lần sử dụng"
              placeholder="Nhập số lần sử dụng"
              name="so_lan_sd"
              register={register}
              errorMessage={errors?.so_lan_sd?.message}
            />
            <Controller
              name="gt_don_hang_toi_thieu"
              control={control}
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Giá trị đơn hàng từ"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    onChange(numeralCustom(e.target.value).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <TextInput
              type="number"
              labelWidth="30%"
              label="SL sản phẩm thỏa ĐK"
              placeholder="Nhập số lượng tối thiểu"
              name="sl_toi_thieu"
              register={register}
            />
            <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Phát hành"
                  name="status"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
