async function swDev() {
  // let swUrl = `${process.env.PUBLIC_URL}/sw.js`;
  // if ('serviceWorker' in navigator) {
  //   try {
  //     await navigator.serviceWorker.register(swUrl, {
  //       scope: '/',
  //     });
  //     console.log('Service worker registration succeeded');
  //   } catch (error) {
  //     console.error(`Registration failed with ${error}`);
  //   }
  // }
  const swUrl = `/sw.js`;
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(swUrl, {
        scope: '/',
      });
      console.log('Service worker register successfully');
      if (registration.installing) {
        console.log('Service worker is installing');
      }
      if (registration.waiting) {
        console.log('Service worker is waiting');
      }
      if (registration.active) {
        console.log('Service worker is active');
      }
    } catch (error) {
      console.error('Service worker is not supported');
    }
  }
}

export default swDev;
