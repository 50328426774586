import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import TextInput from '~/components/input/TextInput';
import ModalForm from '~/components/modal/ModalForm';

const schema = yup.object({
  group_name: yup.string().required('Vui lòng nhập tên nhóm'),
});
const defaultData = { group_name: '', loai_nh: 1 };

function FormNhomKhach({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues || defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };
  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmnhkh',
      data: values,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="500px"
        title="nhóm khách"
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Grid container spacing="20px">
          <Grid item xs={12}>
            <TextInput
              required
              label="Tên nhóm"
              placeholder="Nhập tên nhóm"
              name="group_name"
              register={register}
              errorMessage={errors?.group_name?.message}
            />
          </Grid>
        </Grid>
      </ModalForm>
    </>
  );
}

export default FormNhomKhach;
