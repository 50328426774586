import React, { useState, useEffect, useRef, useId } from 'react';
import {
  TextField,
  Typography,
  Stack,
  Autocomplete,
  ListItem,
  Chip,
  Button,
} from '@mui/material';
import { HiOutlineSelector } from 'react-icons/hi';
import useApisContext from '~/hooks/hookContext/useApisContext';
import WrapperInput from './WrapperInput';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { MdClose } from 'react-icons/md';

function SelectMultiApiInput({
  label,
  placeholder,
  required = false,
  onSelect = () => {},
  apiCode = 'dmvt',
  condition = {},
  getOptionLabel,
  filterOptions = (option) => option,
  renderOption,
  searchFileds = ['ma_vt', 'ten_vt'],
  value,
  defaultValue = [],
  FormAdd,
  errorMessage,
  disabled = false,
  isOpenDm = true,
  autocompleteProps,
  sx = {},
  isOptionEqualToValue = () => true,
  withIdApp = true,
  labelWidth = '20%',
  InputProps = {},
  variant = 'standard',
  limitTags = 2,
  getLimitTagsText = (more) => `+${more}`,
  readOnly,
  ...props
}) {
  const id = useId();
  const showAlert = useAlertContext();
  const { asyncGetList } = useApisContext();
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  // const [load, setLoad] = useState(1);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const timerRef = useRef();

  // handle get data
  const handleGetData = async (search, page) => {
    try {
      setLoading(true);
      const selfCondition = { page, limit: 20, q: condition };
      if (search) {
        selfCondition.q.$or = [];
        searchFileds.forEach((searchFiled) =>
          selfCondition.q.$or.push({
            [searchFiled]: {
              $regex: search.split(' ').join('.*'),
              $options: 'i',
            },
          })
        );
        selfCondition.q.$or.push({ $text: { $search: search } });
      }
      const resp = await asyncGetList({
        apiCode,
        condition: selfCondition,
        withIdApp,
      });
      const respCount = await asyncGetList({
        apiCode,
        condition: { ...selfCondition, count: 1 },
        withIdApp,
      });
      setCount(respCount?.rows_number || 0);
      if (resp) {
        setOptions((prev) => [...prev, ...resp]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
      setPage(page + 1);
    }
  };
  const hasNextPage = options.length < count;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => handleGetData(search, page),
  });

  const handleReset = () => {
    setSearchText('');
    setOptions([]);
    setPage(1);
    setCount(0);
  };

  // open form add
  const openFormAdd = () => {
    setOpenForm(true);
  };

  React.useEffect(() => {
    if (search) {
      handleReset();
      handleGetData(search, 1, []);
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  React.useEffect(() => {
    timerRef.current = setTimeout(() => {
      setSearch(searchText.trim());
    }, 500);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [searchText]);
  useEffect(() => {
    setOptions([]);
  }, [apiCode]);
  return (
    <>
      {openForm && (
        <FormAdd
          open={openForm}
          handleClose={() => setOpenForm(false)}
          defaultValues={{ [searchFileds[0]]: search }}
        />
      )}
      <WrapperInput
        id={id}
        label={label}
        labelWidth={labelWidth}
        required={required}
        errorMessage={errorMessage}
        {...props}
      >
        <Autocomplete
          readOnly={readOnly}
          multiple
          options={options}
          isOptionEqualToValue={isOptionEqualToValue}
          clearOnBlur={true}
          disabled={disabled}
          onBlur={handleReset}
          clearIcon={<MdClose size={14} />}
          popupIcon={<HiOutlineSelector size={14} />}
          getOptionLabel={
            !!getOptionLabel
              ? (option) => {
                  const val = getOptionLabel(option);
                  return val || '';
                }
              : undefined
          }
          limitTags={limitTags}
          getLimitTagsText={getLimitTagsText}
          value={value}
          onChange={(_, newValue) => onSelect(newValue)}
          ListboxProps={{
            className: 'custome-scrolly',
            sx: {
              '& .MuiAutocomplete-option': { fontSize: '12px' },
            },
          }}
          noOptionsText={
            <Stack spacing="10px" alignItems="center">
              {loading ? (
                <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>
                  Đang tải...
                </Typography>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontStyle: 'italic',
                      textAlign: 'center',
                    }}
                  >
                    Không tìm thấy kết quả
                  </Typography>
                  {!!FormAdd && (
                    <Stack direction="row" alignItems="center" spacing="5px">
                      <Button variant="contained" onClick={openFormAdd}>
                        Thêm mới
                      </Button>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          }
          filterOptions={(options) => {
            if (hasNextPage) {
              options.push({ id: 'load-more' });
            }
            return options;
          }}
          renderOption={(optionProps, option, state, ownerState) => {
            if (option.id === 'load-more') {
              return (
                <Typography
                  key="1234"
                  sx={{ fontSize: '12px', textAlign: 'center' }}
                  ref={sentryRef}
                >
                  Đang tải...
                </Typography>
              );
            }
            const { key, ...restProps } = optionProps;
            return (
              <ListItem key={key} {...restProps}>
                {ownerState.getOptionLabel(option)}
              </ListItem>
            );
          }}
          sx={{ width: '100%', ...sx }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...restProps } = getTagProps({ index });
              return (
                <Chip
                  key={key}
                  variant="filled"
                  label={getOptionLabel(option)}
                  size="small"
                  {...restProps}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={placeholder}
              autoComplete="off"
              variant={variant}
              size="small"
              onFocus={() => {
                handleGetData('', 1, []);
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              sx={{
                '&:hover .btn-clear-select-api': {
                  display: 'flex',
                },
                '& .MuiInputBase-root': { padding: '0 5px 0 0 !important' },
                '& .MuiFormLabel-root': {
                  fontSize: '12px',
                  fontWeight: 600,
                  color: 'primary.main',
                  transform: 'translate(14px, -12px)',
                  paddingRight: '5px',
                  backgroundColor: 'whitish.pureWhite',
                },
                '& .MuiInputBase-input': {
                  fontSize: '14px',
                  padding: '5px 0 !important',
                  '&:placeholder': {
                    fontSize: '14px',
                  },
                },
              }}
            />
          )}
          {...autocompleteProps}
        />
      </WrapperInput>
    </>
  );
}

export default SelectMultiApiInput;
