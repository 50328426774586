import { Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import FormKho from '../../kho/FormKho';
import { numeralCustom } from '~/utils/helpers';
import { useSelector } from 'react-redux';
import DateInput from '~/components/input/DateInput';
import FormCustomer from '../../customer/FormCustomer';
import { LABEL_KHO } from '~/utils/label.constant';

function InfoSection({ register, control, errors, setValue }) {
  const storeData = useSelector((state) => state.store);

  useEffect(() => {
    if (storeData && storeData.data.length === 1) {
      setValue('kho', storeData.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  return (
    <CollapseSection show title="Thông tin chung">
      <Grid container spacing="20px">
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              label="Số CT"
              placeholder="Nhập hoặc tạo tự động"
              name="so_ct"
              register={register}
            />
            <Controller
              control={control}
              name="ngay_ct"
              render={({ field: { onChange, value } }) => (
                <DateInput
                  required
                  label="Ngày CT"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.ngay_ct?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="kho"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  label={LABEL_KHO}
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO}`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  FormAdd={FormKho}
                  errorMessage={errors?.kho?.message}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="khach_hang"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  label="Người nộp"
                  apiCode="customer"
                  placeholder="Chọn người nộp"
                  searchFileds={['ma_kh', 'ten_kh']}
                  condition={{ kh_yn: true, ncc_yn: { $ne: true } }}
                  getOptionLabel={(option) => option.ten_kh}
                  selectedValue={value}
                  value={value || { ma_kh: '', ten_kh: '' }}
                  onSelect={onChange}
                  FormAdd={FormCustomer}
                  errorMessage={errors?.khach_hang?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="t_tt_nt"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Giá trị (VND)"
                  placeholder="Giá trị thu"
                />
              )}
            />
            <Controller
              control={control}
              name="trang_thai"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  label="Trạng thái"
                  required
                  apiCode="trangthai"
                  placeholder="Trạng thái"
                  searchFileds={['ten_trang_thai']}
                  getOptionLabel={(option) => option.ten_trang_thai}
                  selectedValue={value}
                  value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
                  onSelect={onChange}
                  withIdApp={false}
                  condition={{ ma_ct: 'PT1', status: true }}
                  errorMessage={errors?.trang_thai?.message}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
