import React, { useEffect, useState } from 'react';
import FilterBox from './FilterBox';
import { IconButton, TextField } from '@mui/material';
import { MdClose } from 'react-icons/md';

function FilterSearch({
  title,
  value = '',
  placeholder = 'Nhập và nhấn Enter để tìm',
  onSearch = () => {},
}) {
  const [searchText, setSearchText] = useState(value);
  const [active, setActive] = useState(false);

  // handle search
  const handleSearch = (e) => {
    // Key code enter = 13
    if (e.keyCode === 13) {
      onSearch(searchText.trim());
      setActive(!!searchText);
    }
  };
  // handle clear
  const handleClear = () => {
    setSearchText('');
    setActive(false);
    onSearch('');
  };
  useEffect(() => {
    onSearch(value);
    setSearchText(value);
    setActive(!!value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FilterBox title={title} active={active}>
      <TextField
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyUp={handleSearch}
        placeholder={placeholder}
        fullWidth
        size="small"
        autoComplete="off"
        sx={{
          '& .MuiInputBase-root ': {
            paddingRight: '5px',
          },
          '& .MuiFormLabel-root': {
            fontSize: '12px',
            paddingRight: '5px',
            transform: 'translate(14px, -12px)',
            backgroundColor: 'whitish.pureWhite',
          },
          '& .MuiInputBase-input': {
            fontSize: '14px',
            '&:placeholder': {
              fontSize: '14px',
            },
          },
        }}
        variant="standard"
        InputProps={{
          endAdornment: (
            <>
              {searchText && (
                <IconButton
                  sx={{
                    backgroundColor: 'whitish.whiteSoft',
                    padding: '4px',
                  }}
                  onClick={handleClear}
                >
                  <MdClose size={12} />
                </IconButton>
              )}
            </>
          ),
        }}
      />
    </FilterBox>
  );
}

export default FilterSearch;
