import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import SelectApiInput from '~/components/input/SelectApiInput';

const schema = yup.object({
  ten_bp: yup.string().required('Vui lòng nhập tên bộ phận'),
});

const defaultData = { ma_bp: '', ten_bp: '', phu_trach: null, parent: null };

export default function FormBoPhan({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        phu_trach: defaultValues.phu_trach
          ? {
              email: defaultValues.phu_trach || '',
              name: defaultValues.ten_phu_trach || '',
            }
          : null,
        parent: defaultValues.ma_bp_me
          ? {
              ma_bp: defaultValues.ma_bp_me || '',
              ten_bp: defaultValues.ten_bp_me || '',
            }
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { phu_trach, parent, ...fields } = values;
    return {
      ...fields,
      phu_trach: phu_trach?.email || '',
      ten_phu_trach: phu_trach?.name || '',
      ma_bp_me: parent?.ma_bp || '',
      ten_bp_me: parent?.ten_bp || '',
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmbp',
      data: isEdit
        ? generateDataPost(values)
        : { ...generateDataPost(values), ma_bp: values.ten_bp },
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="800px"
      title="bộ phận"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <TextInput
            readOnly={isEdit}
            labelWidth="30%"
            label="Mã bộ phận"
            placeholder="Nhập hoặc tạo tự động"
            name="ma_bp"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Tên bộ phận"
            placeholder="Nhập tên bộ phận"
            name="ten_bp"
            required
            register={register}
            errorMessage={errors?.ten_bp?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="phu_trach"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Phụ trách"
                apiCode="participant"
                placeholder="Chọn người phụ trách"
                searchFileds={['name', 'email']}
                condition={{ status: true }}
                getOptionLabel={(option) => option.name}
                selectedValue={value}
                value={value || { ma_kho: '', ten_kho: '' }}
                onSelect={onChange}
                errorMessage={errors?.kho?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="parent"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Là con của"
                apiCode="dmbp"
                placeholder="Chọn bộ phận cha"
                searchFileds={['ma_bp', 'ten_bp']}
                condition={{
                  status: true,
                  ma_bp: { $ne: defaultValues?.ma_bp || '' },
                }}
                getOptionLabel={(option) => option.ma_bp}
                selectedValue={value}
                value={value || { ma_bp: '', ten_bp: '' }}
                onSelect={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
}
