import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalForm from '~/components/modal/ModalForm';
import useApisContext from '~/hooks/hookContext/useApisContext';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import { Stack } from '@mui/material';
import InfoSection from './info-section/InfoSection';
import ChiTietSection from './chitiet-section/ChiTietSection';
import useToken from '~/hooks/useToken';
import BannerSection from './banner-section/BannerSection';
import DescriptionSection from './description-section/DescriptionSection';

const schema = yup.object({
  ma_chietkhau: yup.string().required('Vui lòng nhập mã'),
  ten_chietkhau: yup.string().required('Vui lòng nhập tên'),
  trang_thai: yup
    .object()
    .typeError('Vui lòng chọn trạng thái')
    .required('Vui lòng chọn trạng thái'),
  tu_ngay: yup
    .date()
    .typeError('Vui lòng chọn ngày bắt đầu')
    .required('Vui lòng chọn ngày bắt đầu'),
  den_ngay: yup
    .date()
    .typeError('Vui lòng chọn ngày kết thúc')
    .required('Vui lòng chọn ngày kết thúc'),
});

function FormFlashsale({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const defaultData = {
    ma_chietkhau: '',
    ten_chietkhau: '',
    tu_ngay: moment().add(24, 'hours').format('YYYY-MM-DD HH:mm'),
    den_ngay: moment().add(26, 'hours').format('YYYY-MM-DD HH:mm'),
    trang_thai: null,
    status: false,
  };
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        tu_ngay: defaultValues.tu_ngay
          ? moment(defaultValues.tu_ngay).format('YYYY-MM-DD HH:mm')
          : null,
        den_ngay: defaultValues.den_ngay
          ? moment(defaultValues.den_ngay).format('YYYY-MM-DD HH:mm')
          : null,
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
      }
    : defaultData;
  const token = useToken();
  const { uploadFile, asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [thumbnails, setThumbnails] = useState({
    picture: null,
    picture2: null,
    picture3: null,
  });
  const [collapses, setCollapses] = useState({
    chi_tiet: true,
    banner: true,
    dien_giai: true,
  });
  const [details, setDetails] = useState(defaultValues?.details || []);

  const handleReset = () => {
    reset(originalData);
    setDetails(defaultValues?.details || []);
    setThumbnails({
      picture: null,
      picture2: null,
      picture3: null,
    });
  };

  const generateDataPost = async (values) => {
    const { trang_thai, ...fields } = values;
    const result = { ...fields };
    result.trang_thai = trang_thai?.ma_trang_thai || '';
    result.ten_trang_thai = trang_thai?.ten_trang_thai || '';
    result.trang_thai = trang_thai?.ma_trang_thai || '';
    result.details = details;

    // save picture
    const entrieImages = Object.entries(thumbnails);
    for (let i = 0; i < entrieImages.length; i++) {
      const entry = entrieImages[i];
      if (!!entry[1]) {
        const formData = new FormData();
        formData.append('file', entry[1]);
        const resp = await uploadFile({ formData, folder: 'products', token });
        if (resp) {
          result[entry[0]] = resp.fileUrl;
        }
      } else if (entry[1] === undefined) {
        result[entry[0]] = '';
      }
    }

    return result;
  };

  const handleSave = async (values, mode) => {
    const dataPost = await generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'ecompromotion_flashsales',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="chiết khấu, giảm giá"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        {/* Thong tin chung */}
        <InfoSection control={control} errors={errors} register={register} />
        {/* Chi tiet */}
        <ChiTietSection
          show={collapses.chi_tiet}
          setCollapses={setCollapses}
          details={details}
          setDetails={setDetails}
        />
        {/* Banner */}
        <BannerSection
          show={collapses.banner}
          setCollapses={setCollapses}
          defaultValues={defaultValues}
          setThumbnails={setThumbnails}
        />
        {/* Dien giai */}
        <DescriptionSection
          control={control}
          show={collapses.dien_giai}
          setCollapses={setCollapses}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormFlashsale;
