import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import InfoSection from './info-section/InfoSection';
import { Stack } from '@mui/material';
import GhiChuSection from './ghichu-section/GhiChuSection';
import { QUYEN_TRUY_CAP } from '~/utils/constants';
import UsersSection from './users-section/UsersSection';
import { useSelector } from 'react-redux';
import UserGroupsSection from './usergroups-section/UserGroupsSection';

const schema = yup.object({
  ma_kv: yup.string().required('Vui lòng nhập mã khu vực'),
  ten_kv: yup.string().required('Vui lòng nhập tên khu vực'),
});

const defaultData = {
  ma_kv: '',
  ten_kv: '',
  ghi_chu: '',
  visible: null,
  users: [],
  usergroups: [],
};

export default function FormKhuVuc({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const usergroupData = useSelector((state) => state.usergroup);
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        visible:
          defaultValues.visible_to || defaultValues.visible_to === 0
            ? QUYEN_TRUY_CAP.find(
                (item) => item?.value === defaultValues.visible_to
              )
            : null,
        users:
          defaultValues.visible_to_users?.length > 0
            ? defaultValues.visible_to_users.map((u) => ({ email: u }))
            : [],
        usergroups: defaultValues.visible_to_usergroups
          ? defaultValues.visible_to_usergroups.map((u) => {
              return (
                (usergroupData?.data || []).find((item) => item._id === u) || {
                  group_name: u,
                }
              );
            })
          : [],
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    register,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [collapses, setCollapses] = useState({ users: true, usergroups: true });

  const visible = watch('visible');

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { visible, users, usergroups, ...fields } = values;
    return {
      ...fields,
      visible_to: visible?.value,
      visible_to_users: (users || []).map((u) => u.email),
      visible_to_usergroups: (usergroups || []).map((g) => g._id),
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmkhuvuc',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="khu vực"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        <InfoSection
          errors={errors}
          isEdit={isEdit}
          register={register}
          control={control}
        />
        {visible?.value === 2 && (
          <UsersSection
            show={collapses.users}
            control={control}
            setCollapses={setCollapses}
          />
        )}
        {visible?.value === 5 && (
          <UserGroupsSection
            show={collapses.usergroups}
            control={control}
            setCollapses={setCollapses}
          />
        )}
        <GhiChuSection register={register} />
      </Stack>
    </ModalForm>
  );
}
