import React from 'react';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { numeralCustom } from '~/utils/helpers';
import FormProduct from '../../product/FormProduct';

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
});

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const defaultData = { vat_tu: null, gia_ban_le: 0, tien_ck: 0, ty_le_ck: 0 };
  const originData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : null,
      }
    : defaultData;
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: originData,
  });

  const giaBanLe = watch('gia_ban_le');

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="500px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} hàng hóa`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="vat_tu"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                readOnly={isEdit}
                label="Hàng hóa"
                required
                apiCode="dmvt"
                placeholder="Chọn hàng hóa"
                searchFileds={['ma_vt', 'ten_vt']}
                getOptionLabel={(option) => option.ten_vt}
                selectedValue={value}
                value={value || { ma_vt: '', ten_vt: '' }}
                onSelect={(val) => {
                  onChange(val);
                  setValue('gia_ban_le', val?.gia_ban_le || 0);
                }}
                FormAdd={FormProduct}
                errorMessage={errors?.vat_tu?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="gia_ban_le"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  readOnly
                  label="Giá gốc"
                  labelWidth="30%"
                  placeholder="Giá gốc theo hàng hóa"
                  value={numeralCustom(value).format()}
                  onChange={onChange}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="ty_le_ck"
            control={control}
            render={({ field: { value, onChange } }) => {
              return (
                <TextInput
                  type="number"
                  labelWidth="30%"
                  label="Tỷ lệ chiết khấu"
                  value={value}
                  onChange={(e) => {
                    const val = e.target.value;
                    onChange(val);
                    const newTienCk = ((giaBanLe || 0) * (val || 0)) / 100;
                    setValue('tien_ck', newTienCk);
                  }}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="tien_ck"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tiền chiết khấu"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  const val = numeralCustom(e.target.value).value();
                  onChange(val);
                  const newTyLeCk = ((val || 0) * 100) / (giaBanLe || 0);
                  setValue('ty_le_ck', newTyLeCk);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormAddDetail;
