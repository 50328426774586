import { Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';

function DiaChiSection({
  show,
  setCollapses,
  register,
  errors,
  control,
  setValue,
  watch,
}) {
  const showAlert = useAlertContext();
  const { asyncSearchList } = useApisContext();

  const khachHang = watch('khach_hang');
  const tinhThanh = watch('tinh_thanh_obj');
  const quanHuyen = watch('quan_huyen_obj');
  const xaPhuong = watch('xa_phuong_obj');
  const diaChi = watch('dia_chi');
  const tenNguoiNhan = watch('ten_nguoi_nhan');
  const sdt = watch('sdt_nguoi_nhan');

  const getKhachHang = async () => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'customer',
        condition: {
          q: { ncc_yn: { $ne: true }, kh_yn: true, ma_kh: khachHang?.ma_kh },
        },
      });
      const data = resp[0];

      if (!tinhThanh) {
        const tinh = {
          ma_tinh_thanh: data?.tinh_thanh || '',
          ten_tinh_thanh: data?.ten_tinh_thanh || '',
        };
        setValue('tinh_thanh_obj', tinh);
      }
      if (!quanHuyen) {
        const quan = {
          ma_quan_huyen: data?.quan_huyen || '',
          ten_quan_huyen: data?.ten_quan_huyen || '',
        };
        setValue('quan_huyen_obj', quan);
      }
      if (!xaPhuong) {
        const xa = {
          ma_xa_phuong: data?.xa_phuong || '',
          ten_xa_phuong: data?.ten_xa_phuong || '',
        };
        setValue('xa_phuong_obj', xa);
      }
      if (!diaChi) {
        setValue('dia_chi', data?.dia_chi);
      }
      if (!tenNguoiNhan) {
        setValue('ten_nguoi_nhan', data?.ten_kh);
      }
      if (!sdt) {
        setValue('sdt_nguoi_nhan', data?.dien_thoai);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wront',
      });
    }
  };

  useEffect(() => {
    if (khachHang) {
      getKhachHang();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [khachHang]);

  return (
    <CollapseSection
      title="Thông tin giao hàng"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, dia_chi: !prev.dia_chi }))
      }
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            type="text"
            label="Tên người nhận"
            placeholder="Nhập tên người nhận"
            name="ten_nguoi_nhan"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            type="text"
            label="SĐT người nhận"
            placeholder="Nhập số điện thoại người nhận"
            name="sdt_nguoi_nhan"
            register={register}
            errorMessage={errors?.sdt_nguoi_nhan?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="tinh_thanh_obj"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Tỉnh / thành phố"
                apiCode="tinhthanh"
                placeholder="Chọn tỉnh/thành phố"
                searchFileds={['ten_tinh_thanh']}
                getOptionLabel={(option) => option.ten_tinh_thanh}
                selectedValue={value}
                value={value || { ma_tinh_thanh: '', ten_tinh_thanh: '' }}
                onSelect={(newValue) => {
                  setValue('quan_huyen_obj', null);
                  setValue('xa_phuong_obj', null);
                  onChange(newValue);
                }}
                withIdApp={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="quan_huyen_obj"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                disabled={!tinhThanh}
                labelWidth="30%"
                label="Quận / huyện"
                apiCode="quanhuyen"
                placeholder="Chọn quận / huyện"
                searchFileds={['ten_quan_huyen']}
                condition={{ ma_tinh_thanh: tinhThanh?.ma_tinh_thanh }}
                getOptionLabel={(option) => option.ten_quan_huyen}
                selectedValue={value}
                value={value || { ma_quan_huyen: '', ten_quan_huyen: '' }}
                onSelect={(newValue) => {
                  setValue('xa_phuong_obj', null);
                  onChange(newValue);
                }}
                withIdApp={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="xa_phuong_obj"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                disabled={!quanHuyen}
                labelWidth="30%"
                label="Xã / phường"
                apiCode="xaphuong"
                placeholder="Chọn xã / phường"
                searchFileds={['ten_xa_phuong']}
                condition={{ ma_quan_huyen: quanHuyen?.ma_quan_huyen }}
                getOptionLabel={(option) => option.ten_xa_phuong}
                selectedValue={value}
                value={value || { ma_xa_phuong: '', ten_xa_phuong: '' }}
                onSelect={onChange}
                withIdApp={false}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Địa chỉ cụ thể"
            placeholder="số 5, đường 100A, ấp An Thuận"
            name="dia_chi"
            register={register}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default DiaChiSection;
