import React, { useState, useEffect, memo } from 'react';
import { Stack } from '@mui/material';
import TableDisplay from '~/components/table/TableDisplay';
import useApisContext from '~/hooks/hookContext/useApisContext';
import moment from 'moment';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import CollapseSection from '~/components/collapse/CollapseSection';
import { LABEL_KHO } from '~/utils/label.constant';
const tkctColumns = [
  {
    name: `Mã ${LABEL_KHO}`,
    selector: (row) => row.ma_kho,
    wrap: true,
  },
  {
    name: `Tên ${LABEL_KHO}`,
    selector: (row) => row.ten_kho,
    wrap: true,
    center: true,
  },
  {
    name: 'Số lượng tồn',
    selector: (row) => row.ton_cuoi,
    wrap: true,
    center: true,
  },
  {
    name: 'Đơn vị tính',
    selector: (row) => row.ma_dvt,
    wrap: true,
    right: true,
  },
];

function TonKhoSection({ maVt, show, setCollapses }) {
  const { asyncGetReport } = useApisContext();
  const showAlert = useAlertContext();
  const [tkcts, setTkcts] = useState(null);
  // get tkcts
  const getTkcts = async () => {
    try {
      const today = moment().format('YYYY-MM-DD');
      let resp = await asyncGetReport({
        apiCode: 'thnxt',
        queryObject: {
          tu_ngay: today,
          den_ngay: today,
          ma_vt: maVt,
          groupby: 'ma_vt,ma_kho',
        },
      });
      if (resp && Array.isArray(resp) && resp.length > 0) {
        if (resp.length > 1) {
          resp = resp.slice(0, resp.length - 1);
          return setTkcts(resp);
        }
        setTkcts([]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.response?.data?.message || 'Something went wrong!',
      });
    }
  };
  useEffect(() => {
    getTkcts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maVt]);

  return (
    <CollapseSection
      title="Tồn kho"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, ton_kho: !prev.ton_kho }))
      }
    >
      <Stack sx={{ width: '100%' }} spacing="20px">
        <Stack spacing="10px">
          <TableDisplay
            data={tkcts || []}
            columns={tkctColumns}
            progressPending={!tkcts}
          />
        </Stack>
      </Stack>
    </CollapseSection>
  );
}

export default memo(TonKhoSection);
