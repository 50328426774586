import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';
import DateInput from '~/components/input/DateInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';

function GiaBanSection({ show, setCollapses, control, errors, register }) {
  return (
    <CollapseSection
      title="Chi tiết giá bán"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, gia_ban: !prev.gia_ban }))
      }
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="vat_tu"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                required
                label="Hàng hóa"
                apiCode="dmvt"
                placeholder="Chọn hàng hóa"
                searchFileds={['ma_vt', 'ten_vt']}
                getOptionLabel={(option) => option.ten_vt}
                selectedValue={value}
                value={value || { ma_vt: '', ten_vt: '' }}
                onSelect={onChange}
                errorMessage={errors?.vat_tu?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="gia_ban_le"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Giá bán"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Controller
            name="sl_tu"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Số lượng từ"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="sl_den"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Số lượng đến"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="hieu_luc_tu"
            render={({ field: { onChange, value } }) => (
              <DateInput
                required
                label="Từ ngày"
                labelWidth="30%"
                value={value}
                onChange={onChange}
                errorMessage={errors?.hieu_luc_tu?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="hieu_luc_den"
            render={({ field: { onChange, value } }) => (
              <DateInput
                required
                label="Đến ngày"
                labelWidth="30%"
                value={value}
                onChange={onChange}
                errorMessage={errors?.hieu_luc_den?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <AreaInput
            label="Mô tả"
            labelWidth="14%"
            required
            name="mieu_ta"
            register={register}
            placeholder="Nhập mô tả chương trình giá bán"
            errorMessage={errors?.mieu_ta?.message}
          />
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default GiaBanSection;
