import React from 'react';
import ReactDOM from 'react-dom/client';
import 'animate.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/reducrers/root';
import { PersistGate } from 'redux-persist/integration/react';
import ContextProvider from './context/ContextProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import swDev from './swDev';
import 'moment/locale/vi';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="vi">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ContextProvider>
            <App />
          </ContextProvider>
        </PersistGate>
      </Provider>
    </LocalizationProvider>
  </Router>
);
swDev();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
