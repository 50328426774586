import React from 'react';
import { Box, Container, Stack } from '@mui/material';
import Logo from '~/components/svgs/Logo';
import HeaderActions from './HeaderActions';
import { Link } from 'react-router-dom';
import HeaderActionsMobile from './HeaderActionsMobile';
import useResponsive from '~/hooks/useResponsive';

function Header({ isAdmin = true }) {
  const mdMatch = useResponsive({ matchKey: 'up', breakpoint: 'md' });

  return (
    <Box component="header" sx={{ width: '100%', height: '50%' }}>
      <Container maxWidth="xl" sx={{ height: '100%' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Link to="/">
            <Logo />
          </Link>
          <>
            {!!mdMatch ? (
              <HeaderActions isAdmin={isAdmin} />
            ) : (
              <HeaderActionsMobile isAdmin={isAdmin} />
            )}
          </>
        </Stack>
      </Container>
    </Box>
  );
}

export default Header;
