import React from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import CollapseSection from '~/components/collapse/CollapseSection';
import TextInput from '~/components/input/TextInput';
import { Controller } from 'react-hook-form';
import { numeralCustom } from '~/utils/helpers';
import CheckboxInput from '~/components/input/CheckboxInput';

const hinhthucs = [
  {
    label: 'Offline',
    value: 0,
  },
  {
    label: 'Online',
    value: 1,
  },
  {
    label: 'Cả hai',
    value: 2,
  },
];

function InfoSection({ register, errors, isEdit, control }) {
  return (
    <CollapseSection show title="Thông tin chung">
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="Tên PT"
              placeholder="Thanh toán khi nhận hàng"
              name="ten"
              register={register}
              required
              errorMessage={errors?.ten?.message}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="TK ngân hàng"
              placeholder="3829981"
              name="tai_khoan_nh"
              register={register}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="Chủ tài khoản"
              placeholder="Lê Kim Nhàn"
              name="chu_tai_khoan"
              register={register}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              labelWidth="30%"
              label="Ngân hàng"
              placeholder="BIDV"
              name="ngan_hang"
              register={register}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="kieu_so1"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <TextInput
                    type="number"
                    label="Tỷ lệ CK"
                    labelWidth="30%"
                    value={numeralCustom(value).format()}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Grid>
          {isEdit && (
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Còn sử dụng"
                    name="status"
                    checked={value || false}
                    onChange={onChange}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center">
              <Controller
                name="hinh_thuc"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <RadioGroup
                      row
                      defaultValue={2}
                      value={Number(value)}
                      onChange={(_, val) => {
                        onChange(Number(val));
                      }}
                    >
                      {hinhthucs.map((item) => {
                        return (
                          <FormControlLabel
                            key={item.value}
                            label={item.label}
                            value={item.value}
                            control={<Radio />}
                          />
                        );
                      })}
                    </RadioGroup>
                  );
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </CollapseSection>
  );
}

export default InfoSection;
