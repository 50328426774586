import React, { useEffect, useState } from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import TableDisplay from '~/components/table/TableDisplay';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';


function ThongTinGiaoHangSection({ show, setCollapses, customer, isEdit }) {
  const showAlert = useAlertContext();
  const { asyncGetList } = useApisContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: 'Tên người nhận',
      selector: (row) =>
        `${row.exfields?.ten_nguoi_nhan}${
          customer?.exfields?.id_thongtingiaohang === row._id
            ? '(Mặc định)'
            : ''
        }`,
      width: '150px',
      wrap: true,
    },
    {
      name: 'Khách hàng',
      selector: (row) => (row.ma_kh ? `${row.ten_kh} (${row.ma_kh})` : ''),
      width: '140px',
      wrap: true,
    },
    {
      name: 'Điện thoại',
      selector: (row) => row.dien_thoai,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Tỉnh / thành',
      selector: (row) => row.ten_tinh_thanh,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Quận / huyện',
      selector: (row) => row.ten_quan_huyen,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Xã / phường',
      selector: (row) => row.ten_xa_phuong,
      width: '120px',
      wrap: true,
    },
    {
      name: 'Số nhà, tên đường',
      selector: (row) => row.dia_chi,
      minWidth: '150px',
      wrap: true,
    },
  ];

  const getData = async () => {
    try {
      setLoading(true);
      const resp = await asyncGetList({
        apiCode: 'thongtingiaohang',
        condition: { page: 1, limit: 999999, q: { ma_kh: customer?.ma_kh } },
      });
      setData(resp);
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Lỗi khi tải thông tin giao hàng',
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (isEdit) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <CollapseSection
      title="Thông tin giao hàng"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({
          ...prev,
          thong_tin_giao_hang: !prev.thong_tin_giao_hang,
        }))
      }
    >
      <TableDisplay
        data={data}
        columns={columns}
        selectableRows={false}
        progressPending={loading}
      />
    </CollapseSection>
  );
}

export default ThongTinGiaoHangSection;
