import { TextareaAutosize } from '@mui/material';
import React from 'react';
import { v4 } from 'uuid';
import WrapperInput from './WrapperInput';

function AreaInput({
  register = () => {},
  name,
  placeholder,
  label,
  required = false,
  errorMessage,
  labelWidth = '20%',
  alignItems = 'flex-start',
  ...props
}) {
  const id = v4();

  return (
    <WrapperInput
      id={id}
      label={label}
      labelWidth={labelWidth}
      required={required}
      errorMessage={errorMessage}
      alignItems={alignItems}
      {...props}
    >
      <TextareaAutosize
        {...register(name)}
        placeholder={placeholder}
        spellCheck={false}
        className="no-resize"
        style={{
          width: '100%',
          border: '1px solid #ccc',
          outline: 'none',
          minHeight: '50px',
          borderRadius: '4px',
          padding: '5px',
        }}
      />
    </WrapperInput>
  );
}

export default AreaInput;
