import { Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import FormNhanVien from '../../nhanvien/FormNhanVien';
import FormCustomer from '../../customer/FormCustomer';
import FormPTTT from '../../pttt/FormPTTT';
import { numeralCustom } from '~/utils/helpers';
import { LABEL_KHO } from '~/utils/label.constant';
import DatetimeInput from '~/components/input/DatetimeInput';

function InfoSection({
  register,
  errors,
  setValue,
  control,
  isEdit,
  tienHang = 0,
}) {
  const storeData = useSelector((state) => state.store);

  useEffect(() => {
    if (storeData && storeData.data.length === 1) {
      setValue('kho', storeData.data[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeData]);

  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <TextInput
              labelWidth="30%"
              type="text"
              label="Số CT"
              placeholder="Nhập hoặc tạo tự động"
              name="so_ct"
              register={register}
            />
            <Controller
              control={control}
              name="ngay_ct"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Ngày CT"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.ngay_ct?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="kho"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label={LABEL_KHO}
                  required
                  apiCode="dmkho"
                  placeholder={`Chọn ${LABEL_KHO}`}
                  searchFileds={['ma_kho', 'ten_kho']}
                  condition={{ status: true }}
                  getOptionLabel={(option) => option.ten_kho}
                  selectedValue={value}
                  value={value || { ma_kho: '', ten_kho: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.kho?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="khach_hang"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                required
                  labelWidth="30%"
                  label="Khách hàng"
                  apiCode="customer"
                  placeholder="Chọn khách hàng"
                  searchFileds={['ma_kh', 'ten_kh']}
                  condition={{ ncc_yn: { $ne: true }, kh_yn: true }}
                  getOptionLabel={(option) =>
                    `${option.ten_kh}${
                      option.ma_kh ? ` - ${option.ma_kh}` : ''
                    }`
                  }
                  selectedValue={value}
                  value={value || { ma_kh: '', ten_kh: '' }}
                  onSelect={onChange}
                  FormAdd={FormCustomer}
                  errorMessage={errors?.khach_hang?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="nhan_vien"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Nhân viên"
                  apiCode="dmnv"
                  placeholder="Chọn nhân viên"
                  searchFileds={['ma_nv', 'ten_nv']}
                  getOptionLabel={(option) =>
                    `${option.ten_nv}${
                      option.ma_nv ? ` - ${option.ma_nv}` : ''
                    }`
                  }
                  selectedValue={value}
                  value={value || { ma_nv: '', ten_nv: '' }}
                  onSelect={onChange}
                  FormAdd={FormNhanVien}
                />
              )}
            />
            <Controller
              control={control}
              name="trang_thai"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Trạng thái"
                  required
                  apiCode="trangthai"
                  placeholder="Chọn trạng thái phiếu"
                  searchFileds={['ten_trang_thai']}
                  getOptionLabel={(option) => option.ten_trang_thai}
                  selectedValue={value}
                  value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
                  onSelect={onChange}
                  withIdApp={false}
                  condition={{ ma_ct: 'PBL_PHARMA', status: true }}
                  errorMessage={errors?.trang_thai?.message}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            {/* <Controller
              control={control}
              name="t_tien"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Tiền hàng"
                  placeholder="Tiền hàng"
                />
              )}
            /> */}
            {/* <Controller
              control={control}
              name="vat"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="VAT (%)"
                  placeholder="Thuế GTGT"
                />
              )}
            /> */}
            <Controller
              control={control}
              name="pttt"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="35%"
                  label="PT thanh toán"
                  apiCode="ptthanhtoan"
                  placeholder="Chọn phương thức thanh toán"
                  searchFileds={['ten']}
                  getOptionLabel={(option) => option.ten}
                  selectedValue={value}
                  value={value || { _id: '', ten: '' }}
                  onSelect={onChange}
                  FormAdd={FormPTTT}
                />
              )}
            />
            <Controller
              control={control}
              name="ty_le_ck_hd"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  InputProps={{ endAdornment: '%' }}
                  onChange={(e) => {
                    // luu gia tri
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                    // thay doi tien ck
                    const tienCkNew = (tienHang * val) / 100;
                    setValue('tien_ck_hd', tienCkNew);
                  }}
                  label="Tỷ lệ CK hóa đơn"
                  placeholder="Tỷ lệ chiết khẩu hóa đơn"
                />
              )}
            />
            <Controller
              control={control}
              name="tien_ck_hd"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="35%"
                  disabled={(tienHang || 0) <= 0}
                  value={numeralCustom(value).format()}
                  InputProps={{ endAdornment: 'VND' }}
                  onChange={(e) => {
                    // luu gia tri
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                    // thay doi ty le ck
                    const tyLeCkNew = ((val || 0) * 100) / (tienHang || 1);
                    setValue('ty_le_ck_hd', tyLeCkNew);
                  }}
                  label="Tiền CK hóa đơn"
                  placeholder="Tiền chiết khấu hóa đơn"
                />
              )}
            />
            <Controller
              control={control}
              name="t_ck"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  readOnly
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Tổng chiết khẩu"
                  placeholder="Tổng tiền chiết khẩu"
                />
              )}
            />
            {/* <Controller
              control={control}
              name="tien_thu2"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Tiền vận chuyển"
                  placeholder="Tiền vận chuyển"
                />
              )}
            /> */}
             <Controller
              control={control}
              name="t_tien"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  readOnly
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Tổng tiền hàng"
                  placeholder="Tổng tiền hàng"
                />
              )}
            />
            <Controller
              control={control}
              name="t_tt"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  readOnly
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Tổng thành tiền"
                  placeholder="Tổng thành tiền"
                />
              )}
            />
            {/* <Controller
              control={control}
              name="tien_thu"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Tiền thu"
                  placeholder="Tiền thu"
                />
              )}
            /> */}
            {/* <Controller
              control={control}
              name="phai_tra"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  labelWidth="35%"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const val = numeralCustom(e.target.value).value();
                    onChange(val);
                  }}
                  label="Phải trả lại"
                  placeholder="Phải trả lại"
                />
              )}
            /> */}
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
