import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { numeralCustom } from '~/utils/helpers';
import FormPhuCap from '../../dmphucap/FormPhuCap';
import SelectInput from '~/components/input/SelectInput';
import { THOI_GIAN_TINH } from '~/utils/constants';
import CheckboxInput from '~/components/input/CheckboxInput';
import DateInput from '~/components/input/DateInput';
import moment from 'moment';

const schema = yup.object({
  loai_phu_cap: yup
    .object()
    .typeError('Vui lòng chọn loại phụ cấp')
    .required('Vui lòng chọn loại phụ cấp'),
  tien: yup.string().required('Vui lòng nhập số tiền'),
});

function FormAddPhuCap({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const defaultData = {
    loai_phu_cap: null,
    tien: 0,
    thoi_gian_tinh: null,
    max_phu_cap: 0,
    khong_mien_thue: false,
    max_mien_thue: 0,
    tu_ngay: null,
    den_ngay: null,
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultData,
          ...defaultValues,
          loai_phu_cap: defaultValues?.ma_phu_cap
            ? {
                _id: defaultValues.ma_phu_cap,
                group_name: defaultValues.ten_phu_cap,
              }
            : null,
          thoi_gian_tinh: defaultValues?.dvt
            ? THOI_GIAN_TINH.find((item) => item.value === defaultValues.dvt)
            : null,
          tu_ngay: defaultValues?.tu_ngay
            ? moment(defaultValues.tu_ngay).format('YYYY-MM-DD')
            : null,
          den_ngay: defaultValues?.den_ngay
            ? moment(defaultValues.den_ngay).format('YYYY-MM-DD')
            : null,
        }
      : {},
  });

  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} phụ cấp`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="loai_phu_cap"
              render={({ field: { value, onChange } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  label="Loại phụ cấp"
                  required
                  apiCode="group"
                  placeholder="Chọn phụ cấp"
                  searchFileds={['group_name']}
                  condition={{ status: true, group_type: 'PHUCAP' }}
                  getOptionLabel={(option) => option.group_name}
                  selectedValue={value}
                  value={value || { _id: '', group_name: '' }}
                  onSelect={onChange}
                  FormAdd={FormPhuCap}
                  errorMessage={errors?.loai_phu_cap?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="tien"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  required
                  labelWidth="30%"
                  label="Tiền phụ cấp"
                  placeholder="Nhập số tiền phụ cấp"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const number = e.target.value;
                    onChange(numeralCustom(number).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="thoi_gian_tinh"
              render={({ field: { onChange, value } }) => (
                <SelectInput
                  labelWidth="30%"
                  label="Thời gian tính"
                  placeholder="Chọn thời gian tính"
                  options={THOI_GIAN_TINH}
                  selectedValue={value}
                  value={value || { label: '', value: '' }}
                  onSelect={onChange}
                  getOptionLabel={(option) => option.label}
                />
              )}
            />
            <Controller
              control={control}
              name="khong_mien_thue"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Không miễn thuế"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="max_phu_cap"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Phụ cấp tối đa"
                  placeholder="Nhập tiền phụ cấp tối đa"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const number = e.target.value;
                    onChange(numeralCustom(number).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="max_mien_thue"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  labelWidth="30%"
                  label="Miễn thuế tối đa"
                  placeholder="Nhập số tiền miễn thuế tối đa"
                  value={numeralCustom(value).format()}
                  onChange={(e) => {
                    const number = e.target.value;
                    onChange(numeralCustom(number).value());
                  }}
                  InputProps={{
                    endAdornment: (
                      <Typography
                        sx={{
                          fontSize: '12px',
                          color: 'neutral.iconColor',
                        }}
                      >
                        VND
                      </Typography>
                    ),
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="tu_ngay"
              render={({ field: { onChange, value } }) => (
                <DateInput
                  label="Từ ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="den_ngay"
              render={({ field: { onChange, value } }) => (
                <DateInput
                  label="Đến ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Stack>
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormAddPhuCap;
