import React, { forwardRef, useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  List,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CiSearch } from 'react-icons/ci';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { MdAdd } from 'react-icons/md';
import { HiOutlineSelector } from 'react-icons/hi';
import TooltipBase from '../tooltip/TooltipBase';

function TextSelectApiInput(
  {
    sx = {},
    menuSx = {},
    apiCode = 'dmvt',
    condition = {},
    searchFileds = [],
    placeholder,
    selectedValue,
    getOptionLabel = (option) => {},
    renderOption,
    renderDisplay,
    textDisplay,
    disabled,
    onSelect = () => {},
    withIdApp,
    FormAdd,
    menuWidth = '200px',
    checkActive, // (option) => true | false
    title,
  },
  ref
) {
  const { asyncSearchList } = useApisContext();
  const showAlert = useAlertContext();
  const [anchor, setAnchor] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openFormAdd, setOpenFormAdd] = useState(false);
  const [page, setPage] = useState(1);
  const timerRef = useRef();

  const hasNextPage = options.length < count;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => handleGetData(search, page, options),
  });

  const handleReset = () => {
    setOptions([]);
    setSearchText('');
    setPage(1);
    setCount(0);
  };

  const handleOpenFormAdd = () => {
    setOpenFormAdd(true);
    setAnchor(null);
    handleReset();
  };

  const handleOpenMenu = (e) => {
    setAnchor(e.currentTarget);
    handleGetData('', 1, []);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
    handleReset();
  };

  const handleItemClick = (option) => {
    onSelect(option);
    handleCloseMenu();
  };

  // handle get data
  const handleGetData = async (searchValue, page, oldOptions = []) => {
    if (loading) return;
    try {
      setLoading(true);
      const selfCondition = { page, limit: 10, q: condition };
      if (searchValue || searchValue === '') {
        selfCondition.q.$or = [];
        searchFileds.forEach((searchFiled) =>
          selfCondition.q.$or.push({
            [searchFiled]: {
              $regex: searchValue.split(' ').join('.*'),
              $options: 'i',
            },
          })
        );
        selfCondition.q.$or.push({ $text: { $search: searchValue } });
      }
      const resp = await asyncSearchList({
        apiCode,
        condition: selfCondition,
        withIdApp,
      });
      const respCount = await asyncSearchList({
        apiCode,
        condition: { ...selfCondition, count: 1 },
        withIdApp,
      });
      setCount(respCount?.rows_number || 0);
      if (resp) {
        setOptions([...oldOptions, ...resp]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    } finally {
      setLoading(false);
      setPage(page + 1);
    }
  };

  const renderLoading = (ref) => {
    return (
      <Stack ref={ref} spacing="5px">
        {new Array(2).fill(0).map((_, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="center"
            spacing="5px"
            sx={{ padding: '0 16px' }}
          >
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: '30%', height: '15px' }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: '10%', height: '15px' }}
            />
            <Skeleton
              variant="text"
              animation="wave"
              sx={{ width: '15%', height: '15px' }}
            />
          </Stack>
        ))}
      </Stack>
    );
  };

  React.useEffect(() => {
    if (!!anchor && (search === '' || search.length > 1)) {
      handleGetData(search, 1, []);
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, anchor]);
  React.useEffect(() => {
    timerRef.current = setTimeout(() => {
      setSearch(searchText.trim());
    }, 500);
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [searchText]);

  return (
    <>
      {!!FormAdd && (
        <FormAdd open={openFormAdd} handleClose={() => setOpenFormAdd(false)} />
      )}
      <Menu
        open={!!anchor}
        anchorEl={anchor}
        onClose={handleCloseMenu}
        sx={{ width: 'auto', ...menuSx }}
      >
        <Stack sx={{ padding: '0 5px', width: menuWidth }}>
          <Box sx={{ width: '100%', marginBottom: '5px' }}>
            <TextField
              fullWidth
              placeholder={placeholder || 'Tìm kiếm'}
              autoComplete="off"
              variant="outlined"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ color: 'neutral.iconColor' }}
                  >
                    {FormAdd && (
                      <IconButton
                        sx={{ padding: '4px' }}
                        onClick={handleOpenFormAdd}
                      >
                        <MdAdd size={14} />
                      </IconButton>
                    )}
                    <CiSearch size={16} />
                  </Stack>
                ),
              }}
              sx={{
                borderRadius: '4px',
                backgroundColor: 'whitish.pureWhite',
                border: '1px solid',
                borderColor: 'primary.second',
                '&:hover .btn-clear-select-api': {
                  display: 'flex',
                },
                '& .MuiInputBase-root': { padding: '0 5px 0 0 !important' },
                '& .MuiFormLabel-root': {
                  fontSize: '12px',
                  fontWeight: 600,
                  color: 'primary.main',
                  transform: 'translate(14px, -12px)',
                  paddingRight: '5px',
                  backgroundColor: 'whitish.pureWhite',
                },
                '& .MuiInputBase-input': {
                  fontSize: '12px',
                  padding: '8px !important',
                  '&:placeholder': {
                    fontSize: '12px',
                  },
                },
                '& fieldset': {
                  border: 'none',
                },
              }}
            />
          </Box>
          <Stack
            sx={{
              padding: '5px',
              borderRadius: '4px',
              border: '1px solid',
              borderColor: 'whitish.gray',
            }}
          >
            {!!selectedValue && (
              <MenuItem
                sx={{
                  fontSize: '14px',
                  color: 'error.main',
                  borderRadius: '4px',
                  backgroundColor: 'whitish.graySoft',
                }}
                onClick={() => handleItemClick(null)}
              >
                Bỏ chọn
              </MenuItem>
            )}
            <List
              sx={{
                maxHeight: '200px',
                overflow: 'auto',
                paddingTop: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
                marginTop: '4px',
              }}
            >
              {!!options &&
                options.length > 0 &&
                options.map((option, index) => {
                  const key = `${getOptionLabel(option)}-${index}`;
                  if (!!renderOption && typeof renderOption === 'function') {
                    const optionProps = {
                      key,
                      onClick: () => handleItemClick(option),
                    };
                    const ownerState = { inputValue: search };
                    return renderOption(option, optionProps, ownerState);
                  }
                  const isActive = checkActive?.(option) || false;
                  return (
                    <MenuItem
                      key={key}
                      sx={
                        isActive
                          ? {
                              fontSize: '14px',
                              backgroundColor: 'primary.main',
                              color: 'whitish.pureWhite',
                              borderRadius: '4px',
                              '&:hover': {
                                backgroundColor: 'primary.main',
                                color: 'whitish.pureWhite',
                              },
                            }
                          : {
                              fontSize: '14px',
                              backgroundColor: 'whitish.graySoft',
                              borderRadius: '4px',
                            }
                      }
                      onClick={
                        isActive ? undefined : () => handleItemClick(option)
                      }
                    >
                      {getOptionLabel(option)}
                    </MenuItem>
                  );
                })}
              {(hasNextPage || loading) && <>{renderLoading(sentryRef)}</>}
              {!loading && options.length === 0 && (
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontStyle: 'italic',
                    textAlign: 'center',
                  }}
                >
                  Không tìm thấy kết quả
                </Typography>
              )}
            </List>
          </Stack>
        </Stack>
      </Menu>
      {!!renderDisplay ? (
        renderDisplay({ onClick: handleOpenMenu })
      ) : (
        <TooltipBase title={title} placement="top" arrow>
          <Button
            variant="contained"
            endIcon={<HiOutlineSelector size={12} />}
            disabled={disabled}
            sx={{
              height: '24px',
              textTransform: 'none',
              color: 'initial',
              fontWeight: 400,
              textWrap: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              backgroundColor: 'whitish.gray',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: 'whitish.gray',
                boxShadow: 'none',
              },
              ...sx,
            }}
            onClick={handleOpenMenu}
          >
            {textDisplay || 'Text display'}
          </Button>
        </TooltipBase>
      )}
    </>
  );
}

export default forwardRef(TextSelectApiInput);
