import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { useTheme } from '@emotion/react';

const dataset = new Array(20).fill(0).map((_, index) => {
  return {
    ten_vt: `SP ${index + 1}`,
    sl_xuat: Math.floor(Math.random() * 100) + 1,
  };
});

function HangBanChay() {
  const theme = useTheme();

  return (
    <Paper sx={{ p: 2 }}>
      <Typography sx={{ fontSize: '14px', fontWeight: 550, mb: '10px' }}>
        TOP 20 HÀNG HÓA BÁN CHẠY THEO SỐ LƯỢNG
      </Typography>
      <Box>
        <BarChart
          dataset={dataset}
          xAxis={[
            { scaleType: 'band', dataKey: 'ten_vt', tickPlacement: 'middle' },
          ]}
          series={[
            {
              dataKey: 'sl_xuat',
              label: 'Số lượng bán',
              valueFormatter: (val) => `${val} SP`,
              color: theme.palette.primary.main,
            },
          ]}
          margin={{ top: 50, bottom: 30, left: 30, right: 0 }}
          borderRadius={4}
          height={500}
        />
      </Box>
    </Paper>
  );
}

export default HangBanChay;
