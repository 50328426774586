import React from 'react';
import { Box, IconButton } from '@mui/material';
import {
  MdClose,
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from 'react-icons/md';
import ModalBase from './ModalBase';
import ButtonBase from '../button/ButtonBase';
import { v4 } from 'uuid';
import { FiSave } from 'react-icons/fi';
import { IoMdRefresh } from 'react-icons/io';

function ModalForm({
  children,
  title,
  isEdit,
  currentIndex,
  setCurrentIndex,
  isSubmitting,
  width = '700px',
  rows,
  open,
  handleClose,
  handleSubmit,
  handleSave,
  handleReset,
  startActions = [],
  endActions = [],
  showNavigator = true,
}) {
  return (
    <>
      {/* navigator start */}
      {showNavigator ? (
        <>
          {false ? (
            <>
              {currentIndex !== 0 && (
                <IconButton
                  onClick={() => setCurrentIndex(currentIndex - 1)}
                  sx={{
                    position: 'fixed',
                    zIndex: 10000,
                    top: '50%',
                    left: 0,
                    backgroundColor: 'whitish.pureWhite',
                    color: 'primary.main',
                    transform: 'translate(0, 0)',
                    padding: '8px 10px',
                    boxShadow: '0 0 5px 1px #00000033',
                    borderRadius: '0 4px 4px 0',
                    '&:hover': {
                      backgroundColor: 'whitish.pureWhite',
                    },
                  }}
                >
                  <MdOutlineKeyboardArrowLeft size={18} />
                </IconButton>
              )}
              {currentIndex !== (rows || []).length - 1 && (
                <IconButton
                  onClick={() => setCurrentIndex(currentIndex + 1)}
                  sx={{
                    position: 'fixed',
                    zIndex: 10000,
                    top: '50%',
                    right: 0,
                    backgroundColor: 'whitish.pureWhite',
                    color: 'primary.main',
                    transform: 'translate(0, 0)',
                    padding: '8px 10px',
                    boxShadow: '0 0 5px 1px #00000033',
                    borderRadius: '4px 0 0 4px',
                    '&:hover': {
                      backgroundColor: 'whitish.pureWhite',
                    },
                  }}
                >
                  <MdOutlineKeyboardArrowRight size={18} />
                </IconButton>
              )}
            </>
          ) : null}
        </>
      ) : null}

      {/* navigator end */}
      <ModalBase
        open={open}
        handleClose={handleClose}
        width={width}
        title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} ${title}`}
        actions={[
          ...startActions,
          <ButtonBase
            key={v4()}
            onClick={handleSubmit((values) => handleSave(values, 1))}
            loading={isSubmitting}
            startIcon={<FiSave style={{ fontSize: '16px' }} />}
          >
            Lưu
          </ButtonBase>,
          <ButtonBase
            key={v4()}
            onClick={handleSubmit((values) => handleSave(values, 2))}
            loading={isSubmitting}
            startIcon={<FiSave style={{ fontSize: '16px' }} />}
          >
            Lưu và Đóng
          </ButtonBase>,
          <ButtonBase
            key={v4()}
            onClick={handleReset}
            startIcon={<IoMdRefresh style={{ fontSize: '16px' }} />}
            sx={{
              backgroundColor: 'secondary.main',
              '&:hover': { backgroundColor: 'secondary.main' },
            }}
          >
            Nhập lại
          </ButtonBase>,
          <ButtonBase
            key={v4()}
            onClick={handleClose}
            startIcon={<MdClose style={{ fontSize: '16px' }} />}
            sx={{
              backgroundColor: 'error.main',
              '&:hover': { backgroundColor: 'error.main' },
            }}
          >
            Đóng
          </ButtonBase>,
          ...endActions,
        ]}
      >
        <Box
          sx={{
            maxHeight: 'calc(90vh - 20px - 39px - 39px)',
            overflow: 'auto',
            paddingRight: '4px',
          }}
        >
          {children}
        </Box>
      </ModalBase>
    </>
  );
}

export default ModalForm;
