import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { Stack } from '@mui/material';
import InfoSection from './info-section/InfoSection';
import DescriptionSection from './description-section/DescriptionSection';

const schema = yup.object({
  khach_hang: yup
    .object()
    .typeError('Vui lòng chọn người nộp')
    .required('Vui lòng chọn người nộp'),
  kho: yup
    .object()
    .typeError('Vui lòng chọn kho')
    .required('Vui lòng chọn kho'),
  ngay_ct: yup
    .date()
    .typeError('Vui lòng chọn ngày chứng từ')
    .required('Vui lòng chọn ngày chứng từ'),
  trang_thai: yup
    .object()
    .typeError('Vui lòng chọn trạng thái')
    .required('Vui lòng chọn trạng thái'),
});

const defaultData = {
  so_ct: '',
  ngay_ct: moment().format('YYYY-MM-DD'),
  kho: null,
  trang_thai: null,
  khach_hang: null,
  t_tt_nt: 0,
  dien_giai: '',
};

function FormPT({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        kho: defaultValues.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
        khach_hang: defaultValues.ma_kh
          ? { ma_kh: defaultValues.ma_kh, ten_kh: defaultValues.ten_kh }
          : null,
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai,
              ten_trang_thai: defaultValues.ten_trang_thai,
            }
          : null,
        ngay_ct: defaultValues.ngay_ct ? moment().format('YYYY-MM-DD') : '',
      }
    : defaultData;
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const { asyncPostData, asyncPutData } = useApisContext();
  const [collapses, setCollapses] = useState({ dien_giai: true });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { khach_hang, kho, trang_thai, t_tt_nt, ...fields } = values;
    const result = {
      ...fields,
      ma_kh: khach_hang?.ma_kh || '',
      ten_kh: khach_hang?.ten_kh || '',
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      trang_thai: trang_thai?.ma_trang_thai || '',
      ten_trang_thai: trang_thai?.ten_trang_thai || '',
      tk_no: '1131',
      details: [
        {
          ma_kh: khach_hang?.ma_kh || '',
          tien_nt: t_tt_nt,
          tk_co: '1131',
        },
      ],
    };
    return result;
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'pt1',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="phiếu thu"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        <InfoSection
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
        />
        <DescriptionSection
          show={collapses.dien_giai}
          setCollapses={setCollapses}
          register={register}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormPT;
