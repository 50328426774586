import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import React from 'react';
import { LuMenu } from 'react-icons/lu';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { TbLogout } from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MenuHover from '~/components/menu/MenuHover';
import { useGlobalTheme } from '~/context/themeContext';
import { logoutUser } from '~/redux/actions/auth.action';

const menus = [
  {
    text: 'Quản trị',
    to: '/list/hd2',
  },
];

function Menu() {
  const navigate = useNavigate();
  const [, setThemeId] = useGlobalTheme();
  const dispath = useDispatch();

  const handleClickItem = (path) => {
    navigate(path);
  };

  return (
    <>
      <MenuHover
        content={
          <Stack sx={{ minWidth: '150px' }}>
            {menus.map((menu) => (
              <MenuItem
                key={menu.text}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                }}
                onClick={() => handleClickItem(menu.to)}
              >
                <MdOutlineAdminPanelSettings size={14} />
                {menu.text}
              </MenuItem>
            ))}
            <MenuItem
              sx={{ columnGap: '10px', color: 'error.main' }}
              onClick={() => logoutUser(dispath, setThemeId)}
            >
              <TbLogout fontSize={14} />
              <Typography sx={{ fontSize: '14px', color: 'error.main' }}>
                Đăng xuất
              </Typography>
            </MenuItem>
          </Stack>
        }
      >
        <IconButton sx={{ color: 'whitish.pureWhite' }}>
          <LuMenu size={20} />
        </IconButton>
      </MenuHover>
    </>
  );
}

export default Menu;
