import React, { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Stack, Typography } from '@mui/material';
import DrawerBase from '~/components/drawer/DrawerBase';
import ChangeNumber from './ChangeNumber';
import { numeralCustom } from '~/utils/helpers';
import TableDisplay from '~/components/table/TableDisplay';
import useLinkImage from '~/hooks/useLinkImage';
import { LABEL_KHO } from '~/utils/label.constant';
import ProductImage from '~/assets/img/product.png';
import JoditEditor from 'jodit-react';
import useApisContext from '~/hooks/hookContext/useApisContext';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import moment from 'moment';

const tonKhoColumns = [
  { name: `Mã ${LABEL_KHO}`, selector: (row) => row.ma_kho, left: true },
  {
    name: `Tên ${LABEL_KHO}`,
    selector: (row) => row.ten_kho,
    center: true,
    wrap: true,
  },
  { name: 'Tồn kho', selector: (row) => row.ton_cuoi, center: true },
  { name: 'Đơn vị tính', selector: (row) => row.ma_dvt, right: true },
];

function DrawerDetail({
  open,
  onClose = () => {},
  detail,
  originProduct,
  onNumberChange,
}) {
  const showAlert = useAlertContext();
  const { asyncGetReport } = useApisContext();
  const generateLinkImage = useLinkImage();
  const [tonKhoChiTiet, setTonKhoChiTiet] = useState([]);

  const getThnxt = async () => {
    try {
      const resp = await asyncGetReport({
        apiCode: 'thnxt',
        queryObject: {
          groupBy: 'ma_vt,ma_kho',
          ma_vt: detail.ma_vt,
          tu_ngay: moment().subtract(7, 'days'),
          den_ngay: moment().hours(23).minutes(59).seconds(59),
        },
      });
      if (resp && Array.isArray(resp) && resp.length > 1) {
        resp.pop();
        setTonKhoChiTiet(resp);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong',
      });
    }
  };

  useEffect(() => {
    if (open) {
      getThnxt();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail, open]);

  return (
    <DrawerBase
      open={open}
      onClose={onClose}
      anchor="left"
      title={`${originProduct?.ten_vt} - (${originProduct?.ma_vt})`}
    >
      <Box
        className="hidden-scroll"
        sx={{
          width: '80vw',
          maxWidth: '450px',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Grid container spacing="10px">
          <Grid item xs={12} sm={4}>
            <Box
              sx={{
                width: '100px',
                height: '100px',
                margin: '0 auto',
                position: 'relative',
              }}
            >
              <Avatar
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '4px',
                }}
                src={
                  originProduct?.picture_thumb
                    ? generateLinkImage(originProduct.picture_thumb)
                    : ProductImage
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack sx={{ width: '100%' }}>
              <Stack
                direction="row"
                alignItems="center"
                spacing="20px"
                sx={{
                  padding: '10px 0',
                  borderBottom: '1px solid',
                  borderColor: 'whitish.gray',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  Giá gốc :
                </Typography>
                <Typography sx={{ fontSize: '14px' }}>
                  {numeralCustom(detail.gia_ban).format()}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing="20px"
                sx={{
                  padding: '10px 0',
                  borderBottom: '1px solid',
                  borderColor: 'whitish.gray',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  Số lượng :
                </Typography>
                <ChangeNumber
                  detail={detail}
                  onNumberChange={(number) =>
                    onNumberChange(detail._id, number)
                  }
                />
                <Typography sx={{ fontSize: '14px', color: 'primary.main' }}>
                  Tồn: {originProduct?.ton00} {originProduct?.ten_dvt}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing="20px"
                sx={{
                  padding: '10px 0',
                  borderBottom: '1px solid',
                  borderColor: 'whitish.gray',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  Xuất xứ :
                </Typography>
                <Typography sx={{ fontSize: '14px' }}>
                  {originProduct?.ten_xuat_xu}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                spacing="20px"
                sx={{
                  padding: '10px 0',
                  borderBottom: '1px solid',
                  borderColor: 'whitish.gray',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                  Vị trí :
                </Typography>
                <Typography sx={{ fontSize: '14px' }}>
                  {originProduct?.vi_tri}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing="5px">
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                Mô tả :
              </Typography>
              <JoditEditor
                value={originProduct?.mieu_ta}
                config={{
                  toolbar: false,
                  showCharsCounter: false,
                  showWordsCounter: false,
                  showXPathInStatusbar: false,
                  inline: true,
                  toolbarInlineForSelection: true,
                  showPlaceholder: false,
                  readonly: true,
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing="5px">
              <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                Tồn kho chi tiết:
              </Typography>
              <TableDisplay columns={tonKhoColumns} data={tonKhoChiTiet} />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </DrawerBase>
  );
}

export default DrawerDetail;
