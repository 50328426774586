import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ModalForm from '~/components/modal/ModalForm';
import * as yup from 'yup';
import { Stack } from '@mui/material';
import InfoSection from './info-section/InfoSection';
import moment from 'moment';
import { reset } from 'numeral';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import useApisContext from '~/hooks/hookContext/useApisContext';
import DescriptionSection from './description-section/DescriptionSection';
import ChiTietSection from './chitiet-section/ChiTietSection';
import useAlertContext from '~/hooks/hookContext/useAlertContext';

const schema = yup.object({
  ngay_ct: yup
    .date()
    .typeError('Vui lòng chọn ngày chứng từ')
    .required('Vui lòng chọn ngày chứng từ'),
  trang_thai: yup
    .object()
    .typeError('Vui lòng chọn trạng thái')
    .required('Vui lòng chọn trạng thái'),
  pbl: yup
    .object()
    .typeError('Vui lòng chọn hóa đơn cần trả')
    .required('Vui lòng chọn hóa đơn cần trả'),
});

function FormHD7({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const defaultData = {
    so_ct: '',
    ngay_ct: moment().format('YYYY-MM-DD HH:mm'),
    ma_kh: '',
    ma_kho: '',
    pbl: null,
    trang_thai: null,
    dien_giai: '',
  };

  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        ngay_ct: moment(defaultValues.ngay_ct).format('YYYY-MM-DD HH:mm'),
        trang_thai: defaultValues.trang_thai
          ? {
              ma_trang_thai: defaultValues.trang_thai || '',
              ten_trang_thai: defaultValues.ten_trang_thai || '',
            }
          : null,
      }
    : defaultData;

  const {
    register,
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const showAlert = useAlertContext();
  const { asyncSearchList } = useApisContext();
  const { asyncPostData, asyncPutData } = useApisContext();
  const [collapses, setCollapses] = useState({ mo_ta: true, chit_tiet: true });
  const [details, setDetails] = useState(defaultValues?.details || []);
  const [originPBL, setOriginPBL] = useState();

  const pbl = watch('pbl');

  const handleReset = () => {
    reset(originalData);
    setValue('pbl', originPBL);
    setDetails(defaultValues?.details || []);
  };

  const generateDataPost = (values) => {
    const { trang_thai, pbl, ...data } = values;
    details.forEach((d) => {
      d.id_hd = pbl._id;
      delete d._id;
    });
    const result = {
      ...data,
      trang_thai: trang_thai?.ma_trang_thai || '',
      ten_trang_thai: trang_thai?.ten_trang_thai || '',
      details,
    };
    return result;
  };

  const findPBL = async (id) => {
    try {
      const resp = await asyncSearchList({
        apiCode: 'pbl',
        condition: { q: { _id: id } },
      });
      if (resp && Array.isArray(resp) && resp.length > 0) {
        setValue('pbl', resp[0]);
        setOriginPBL(resp[0]);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Something went wrong!',
      });
    }
  };

  const handleSave = async (values, mode) => {
    if (details?.length === 0) {
      showAlert({
        type: 'warning',
        message: 'Danh sách hàng trả trống',
      });
      return;
    }
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'hd7',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  useEffect(() => {
    if (pbl) {
      const { ma_kho, ma_kh } = pbl;
      setValue('ma_kh', ma_kh);
      setValue('ma_kho', ma_kho);
    } else {
      setValue('ma_kh', '');
      setValue('ma_kho', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pbl]);

  useEffect(() => {
    if (isEdit) {
      findPBL(details[0]?.id_hd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      showNavigator={false}
      width="1200px"
      title="trả hàng"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        <InfoSection
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          isEdit={isEdit}
          details={details}
          setDetails={setDetails}
        />
        {pbl?.details?.length > 0 && (
          <ChiTietSection
            show={collapses.chi_tiet}
            setCollapses={setCollapses}
            hd2={pbl}
            details={details}
            setDetails={setDetails}
          />
        )}
        <DescriptionSection
          show={collapses.mo_ta}
          register={register}
          setCollapses={setCollapses}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormHD7;
