import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';
import FilterRadios from '../FilterRadios';
import FilterSelectApi from '../FilterSelectApi';

function FilterNCC({ setCondition }) {
  const originFilter = {
    khachHang: '',
    dien_thoai: '',
    email: '',
    ma_so_thue: '',
    trang_thai_hoat_dong: 'true',
    nhom_ncc: null,
    tinh_thanh: null,
    quan_huyen: null,
    xa_phuong: null,
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = { ncc_yn: true, kh_yn: false };
    if (filter.khachHang) {
      condition.$or = [
        {
          ma_kh: {
            $regex: filter.khachHang.split(' ').join('.*'),
            $options: 'i',
          },
        },
        {
          ten_kh: {
            $regex: filter.khachHang.split(' ').join('.*'),
            $options: 'i',
          },
        },
        { $text: { $search: filter.khachHang } },
      ];
    }
    if (filter.dien_thoai) {
      condition.dien_thoai = {
        $regex: filter.dien_thoai,
        $options: 'i',
      };
    }
    if (filter.email) {
      condition.email = {
        $regex: filter.email,
        $options: 'i',
      };
    }
    if (filter.ma_so_thue) {
      condition.ma_so_thue = {
        $regex: filter.ma_so_thue,
        $options: 'i',
      };
    }
    if (filter.nhom_ncc) {
      condition.nh_kh = filter.nhom_ncc._id;
    }
    if (filter.tinh_thanh) {
      condition.tinh_thanh = filter.tinh_thanh.ma_tinh_thanh;
    }
    if (filter.quan_huyen) {
      condition.quan_huyen = filter.quan_huyen.ma_quan_huyen;
    }
    if (filter.xa_phuong) {
      condition.xa_phuong = filter.xa_phuong.ma_xa_phuong;
    }
    switch (filter.trang_thai_hoat_dong) {
      case 'true':
        condition.status = true;
        break;
      case 'false':
        condition.status = false;
        break;
      default:
        condition.status = { $in: [true, false] };
        break;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  useEffect(() => {
    setFilter({ ...filter, quan_huyen: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.tinh_thanh]);
  useEffect(() => {
    setFilter({ ...filter, xa_phuong: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.quan_huyen]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Mã, tên NCC"
          placeholder="Tìm theo mã hoặc tên"
          value={filter.khachHang}
          onSearch={(value) => setFilter({ ...filter, khachHang: value })}
        />
        <FilterSearch
          title="Số điện thoại"
          placeholder="Tìm theo số điện thoại"
          value={filter.dien_thoai}
          onSearch={(value) => setFilter({ ...filter, dien_thoai: value })}
        />
        <FilterSearch
          title="Email"
          placeholder="Tìm theo email"
          value={filter.email}
          onSearch={(value) => setFilter({ ...filter, email: value })}
        />
        <FilterSearch
          title="Mã số thuế"
          placeholder="Tìm theo mã số thuế"
          value={filter.ma_so_thue}
          onSearch={(value) => setFilter({ ...filter, ma_so_thue: value })}
        />
        <FilterSelectApi
          title="Nhóm NCC"
          apiCode="dmnhkh"
          placeholder="Chọn nhà cung cấp"
          searchFileds={['group_name']}
          condition={{ isvendor: true }}
          getOptionLabel={(option) => option.group_name}
          selectedValue={filter.nhom_ncc}
          value={filter.nhom_ncc || { _id: '', group_name: '' }}
          onSelect={(newValue) => setFilter({ ...filter, nhom_ncc: newValue })}
        />
        <FilterSelectApi
          title="Tỉnh / thành phố"
          apiCode="tinhthanh"
          placeholder="Chọn tỉnh thành"
          searchFileds={['ten_tinh_thanh']}
          getOptionLabel={(option) => option.ten_tinh_thanh}
          selectedValue={filter.tinh_thanh}
          value={filter.tinh_thanh || { ma_tinh_thanh: '', ten_tinh_thanh: '' }}
          onSelect={(newValue) =>
            setFilter({ ...filter, tinh_thanh: newValue })
          }
          withIdApp={false}
        />
        <FilterSelectApi
          disabled={!filter.tinh_thanh}
          title="Quận / huyện"
          apiCode="quanhuyen"
          placeholder="Chọn quận huyện"
          searchFileds={['ten_quan_huyen']}
          condition={
            filter.tinh_thanh
              ? { ma_tinh_thanh: filter.tinh_thanh.ma_tinh_thanh }
              : {}
          }
          getOptionLabel={(option) => option.ten_quan_huyen}
          selectedValue={filter.quan_huyen}
          value={filter.quan_huyen || { ma_quan_huyen: '', ten_quan_huyen: '' }}
          onSelect={(newValue) =>
            setFilter({ ...filter, quan_huyen: newValue })
          }
          withIdApp={false}
        />
        <FilterSelectApi
          disabled={!filter.quan_huyen}
          title="Xã / phường"
          apiCode="xaphuong"
          placeholder="Chọn xã phường"
          searchFileds={['ten_xa_phuong']}
          condition={
            filter.quan_huyen
              ? { ma_quan_huyen: filter.quan_huyen.ma_quan_huyen }
              : {}
          }
          getOptionLabel={(option) => option.ten_xa_phuong}
          selectedValue={filter.xa_phuong}
          value={filter.xa_phuong || { ma_xa_phuong: '', ten_xa_phuong: '' }}
          onSelect={(newValue) => setFilter({ ...filter, xa_phuong: newValue })}
          withIdApp={false}
        />
        <FilterRadios
          title="Trạng thái hoạt động"
          values={[
            { label: 'Tất cả', value: 'all' },
            { label: 'Còn hoạt động', value: 'true' },
            { label: 'Ngừng hoạt động', value: 'false' },
          ]}
          defaultValue={filter.trang_thai_hoat_dong}
          onChange={(value) =>
            setFilter({ ...filter, trang_thai_hoat_dong: value })
          }
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterNCC;
