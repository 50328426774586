import { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import FilterSearch from '../FilterSearch';
import FilterContainer from '../FilterContainer';
import { LABEL_KHO } from '~/utils/label.constant';
import FilterSelectApi from '../FilterSelectApi';

function FilterCDVT({ setCondition }) {
  const originFilter = {
    nam: '',
    vat_tu: null,
    lo: null,
    kho: null,
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.nam) {
      condition.nam = filter.nam;
    }
    if (filter.kho) {
      condition.ma_kho = filter.kho.ma_kho;
    }
    if (filter.lo) {
      condition.ma_lo = filter.lo.ma_lo;
    }
    if (filter.vat_tu) {
      condition.ma_vt = filter.vat_tu.ma_vt;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Năm đầu kỳ"
          placeholder="Tìm theo năm đầu kỳ"
          value={filter.nam}
          onSearch={(value) => setFilter({ ...filter, nam: value })}
        />
        <FilterSelectApi
          title={LABEL_KHO}
          placeholder={`Chọn ${LABEL_KHO}`}
          apiCode="dmkho"
          selectedValue={filter.kho}
          value={filter.kho || { ma_kho: '', ten_kho: '' }}
          searchFileds={['ma_kho', 'ten_kho']}
          getOptionLabel={(option) => option.ten_kho}
          onSelect={(value) => setFilter({ ...filter, kho: value })}
        />
        <FilterSelectApi
          title="Hàng hóa"
          placeholder="Chọn hàng hóa"
          apiCode="dmvt"
          selectedValue={filter.vat_tu}
          value={filter.vat_tu || { ma_vt: '', ten_vt: '' }}
          searchFileds={['ma_vt', 'ten_vt']}
          getOptionLabel={(option) => option.ten_vt}
          onSelect={(value) => setFilter({ ...filter, vat_tu: value })}
        />
        <FilterSelectApi
          title="Lô - HSD"
          placeholder="Chọn lô"
          apiCode="dmlo"
          selectedValue={filter.lo}
          value={filter.lo || { ma_lo: '' }}
          searchFileds={['ma_lo']}
          getOptionLabel={(option) => option.ma_lo}
          onSelect={(value) => setFilter({ ...filter, lo: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterCDVT;
