import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import ModalBase from '~/components/modal/ModalBase';
import TextInput from '~/components/input/TextInput';
import { numeralCustom } from '~/utils/helpers';
import SelectInput from '~/components/input/SelectInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import DatetimeInput from '~/components/input/DatetimeInput';

const thues = [
  {
    name: 'Hàng hóa, dịch vụ không chịu thuế GTGT',
    value: 0,
    ma_thue: 'KT',
  },
  {
    name: 'Thuế suất thuế GTGT 0%',
    value: 0,
    ma_thue: '00',
  },
  {
    name: 'Thuế suất thuế GTGT 5%',
    value: 5,
    ma_thue: '05',
  },
  {
    name: 'Thuế suất thuế GTGT 10%',
    value: 10,
    ma_thue: '10',
  },
];

const schema = yup.object({
  tinh_chat: yup
    .object()
    .typeError('Vui lòng chọn tính chất')
    .required('Vui lòng chọn tính chất'),
  so_hd: yup.string().required('Vui lòng nhập số hóa đơn'),
  ngay_hd: yup
    .date()
    .typeError('Vui lòng chọn ngày hóa đơn')
    .required('Vui lòng chọn ngày hóa đơn'),
  thue: yup
    .object()
    .typeError('Vui lòng chọn thuế suất')
    .required('Vui lòng chọn thuế suất'),
  tk_thue: yup
    .object()
    .typeError('Vui lòng chọn tài khoản thuế')
    .required('Vui lòng chọn tài khoản thuế'),
  tk_du: yup
    .object()
    .typeError('Vui lòng chọn tài khoản đối ứng')
    .required('Vui lòng chọn tài khoản đối ứng'),
  nha_cung_cap: yup
    .object()
    .typeError('Vui lòng chọn nhà cung cấp')
    .required('Vui lòng chọn nhà cung cấp'),
});

function FormAddVAT({
  open,
  handleClose = () => {},
  isEdit = false,
  addVAT = () => {},
  defaultValue,
  defaultValues,
}) {
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: !!defaultValue
      ? {
          ...defaultValue,
          nha_cung_cap: defaultValue.ma_kh
            ? { ma_kh: defaultValue.ma_kh, ten_kh: defaultValue.ten_kh }
            : null,
          thue: defaultValue.ma_thue
            ? thues.find((item) => item.ma_thue === defaultValue.ma_thue)
            : null,
          tinh_chat: defaultValue.ma_tc
            ? { ma_tc: defaultValue.ma_tc, ten_tc: defaultValue.ten_tc }
            : null,
          tk_du: defaultValue.tk_du_thue
            ? {
                tk: defaultValue.tk_du_thue,
                ten_tk: defaultValue.ten_tk_du_thue,
              }
            : null,
          tk_thue: defaultValue.tk_thue_no
            ? {
                tk: defaultValue.tk_thue_no,
                ten_tk: defaultValue.ten_tk_du_thue,
              }
            : null,
          ngay_hd: moment(defaultValue?.ngay_hd).format('YYYY-MM-DD HH:mm'),
          t_tien_nt: defaultValues?.t_tien_hang_nt || 0,
        }
      : {
          ngay_hd: moment().format('YYYY-MM-DD HH:mm'),
          t_tien_nt: defaultValues?.t_tien_hang_nt || 0,
        },
  });

  const thue = watch('thue');
  const tienHang = watch('t_tien_nt');

  const generateDataAdd = (values) => {
    const { nha_cung_cap, thue, tinh_chat, tk_du, tk_thue, ...fiels } = values;
    const result = {
      ...fiels,
      ma_kh: nha_cung_cap?.ma_kh || '',
      ten_kh: nha_cung_cap?.ten_kh || '',
      ma_thue: thue?.ma_thue || '',
      thue_suat: thue?.value || 0,
      ma_tc: tinh_chat?.ma_tc || 0,
      tk_du_thue: tk_thue?.tk || '',
      tk_thue_no: tk_thue?.tk || 0,
      _id: defaultValue?._id || v4(),
    };
    return result;
  };

  const handleSave = (values) => {
    const dataAdd = generateDataAdd(values);
    addVAT(dataAdd);
    handleClose();
  };

  useEffect(() => {
    const tienThue = ((tienHang || 0) * (thue?.value || 0)) / 100;
    setValue('t_thue_nt', tienThue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thue, tienHang]);

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="800px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} chi phí`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="tinh_chat"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Tính chất"
                required
                apiCode="tc"
                placeholder="Tính chất"
                searchFileds={['ma_tc', 'ten_tc']}
                getOptionLabel={(option) => option.ten_tc || option.ma_tc}
                selectedValue={value}
                value={value || { ma_tc: '', ten_tc: '' }}
                onSelect={onChange}
                isOpenDm={false}
                errorMessage={errors?.tinh_chat?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            required
            label="Số hóa đơn"
            placeholder="Số hóa đơn"
            type="text"
            name="so_hd"
            register={register}
            errorMessage={errors?.so_hd?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Số seri"
            placeholder="Số seri"
            type="text"
            name="so_seri"
            register={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="ngay_hd"
            render={({ field: { onChange, value } }) => (
              <DatetimeInput
                required
                labelWidth="30%"
                label="Ngày hóa đơn"
                value={value}
                onChange={onChange}
                errorMessage={errors?.ngay_hd?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="thue"
            control={control}
            render={({ field: { value, onChange } }) => (
              <SelectInput
                required
                labelWidth="30%"
                label="Thuế suất"
                placeholder="Thuế suất"
                options={thues}
                getOptionLabel={(option) => option.name || option.ma_thue}
                value={value || { name: '', ma_thue: '' }}
                selectedValue={value}
                onSelect={(newValue) => onChange(newValue)}
                errorMessage={errors?.thue?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="tk_thue"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Tài khoản thuế"
                required
                apiCode="account"
                placeholder="Tài khoản thuế"
                searchFileds={['tk', 'ten_tk']}
                condition={{ loai_tk: 1, status: true }}
                getOptionLabel={(option) =>
                  `${option.tk}${option.ten_tk ? ` - ${option.ten_tk}` : ''}`
                }
                selectedValue={value}
                value={value || { tk: '', ten_tk: '' }}
                onSelect={onChange}
                isOpenDm={false}
                errorMessage={errors?.tk_thue?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="tk_du"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Tài khoản đối ứng"
                required
                apiCode="account"
                placeholder="Tài khoản đối ứng"
                searchFileds={['tk', 'ten_tk']}
                condition={{ loai_tk: 1, status: true }}
                getOptionLabel={(option) =>
                  `${option.tk}${option.ten_tk ? ` - ${option.ten_tk}` : ''}`
                }
                selectedValue={value}
                value={value || { tk: '', ten_tk: '' }}
                onSelect={onChange}
                isOpenDm={false}
                errorMessage={errors?.tk_du?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="t_tien_nt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tiền hàng"
                type="text"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="t_thue_nt"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextInput
                labelWidth="30%"
                label="Tiền thuế"
                type="text"
                value={numeralCustom(value).format()}
                onChange={(e) => {
                  onChange(numeralCustom(e.target.value).value());
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="nha_cung_cap"
            render={({ field: { value, onChange } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Nhà cung cấp"
                required
                apiCode="customer"
                placeholder="Nhà cung cấp"
                searchFileds={['ma_kh', 'ten_kh']}
                condition={{ ncc_yn: true, kh_yn: { $ne: true } }}
                getOptionLabel={(option) => option.ten_kh}
                selectedValue={value}
                value={value || { ma_kh: '', ten_kh: '' }}
                onSelect={onChange}
                isOpenDm={false}
                errorMessage={errors?.nha_cung_cap?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Địa chỉ"
            name="dia_chi"
            register={register}
            placeholder="Địa chỉ"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Mã số thuế"
            name="ma_so_thue"
            register={register}
            placeholder="Mã số thuế"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Tên hàng hóa"
            name="ten_vt"
            register={register}
            placeholder="Tên hàng hóa"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Link HĐ điện tử"
            name="exfields.link_hddt"
            register={register}
            placeholder="Link hóa đơn điện tử"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            label="Mã số bí mật"
            name="exfields.ma_so_bi_mat"
            register={register}
            placeholder="Mã số bí mật"
          />
        </Grid>
      </Grid>
    </ModalBase>
  );
}

export default FormAddVAT;
