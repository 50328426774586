import React from 'react';
import { Grid, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import CheckboxInput from '~/components/input/CheckboxInput';
import FormGroupNews from '../../group-news/FormGroupNews';

function InfoSection({ register, errors, control }) {
  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Stack gap={2}>
            <AreaInput
              required
              label="Tiêu đề tin"
              labelWidth="25%"
              placeholder="Nhập tiêu đề tin"
              name="title"
              register={register}
              errorMessage={errors?.title?.message}
            />
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="25%"
                  label="Loại tin"
                  apiCode="group"
                  placeholder="Chọn loại tin"
                  searchFileds={['group_name']}
                  condition={{ status: true, group_type: 'NEWS' }}
                  getOptionLabel={(option) => option.group_name}
                  selectedValue={value}
                  value={value || { _id: '', group_name: '' }}
                  onSelect={onChange}
                  FormAdd={FormGroupNews}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack gap={2}>
            <Controller
              name="status"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxInput
                    label="Hiển thị"
                    checked={value}
                    onChange={onChange}
                  />
                );
              }}
            />
            <Controller
              name="newsfeed"
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <CheckboxInput
                    label="Hiển thị trang chủ"
                    checked={value}
                    onChange={onChange}
                  />
                );
              }}
            />
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
