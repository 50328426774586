import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { Stack } from '@mui/material';
import InfoSection from './info-section/InfoSection';
import MotaSection from './mota-section/MotaSection';
import ContentSection from './content-section/ContentSection';

const schema = yup.object({
  title: yup.string().required('Vui lòng nhập tiêu đề'),
  mo_ta_ngan: yup.string().required('Vui lòng nhập mô tả ngắn'),
  content: yup.string().required('Vui lòng nhập nội dung'),
});

const defaultData = {
  title: '',
  mo_ta_ngan: '',
  content: '',
  newsfeed: false,
  category: null,
  status: true,
};

export default function FormNews({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        category: defaultValues?.category
          ? {
              _id: defaultValues?.category || '',
              group_name: defaultValues?.cate_name || '',
            }
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [collapses, setCollapses] = useState({ mo_ta: true, content: true });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { category, ...fields } = values;
    return {
      ...fields,
      category: category?._id || '',
      cate_name: category?.group_name || '',
    };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'news2',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="800px"
      title="tin tức"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack sx={{ width: '100%' }} gap={2}>
        <InfoSection register={register} errors={errors} control={control} />
        <MotaSection
          show={collapses.mo_ta}
          setCollapses={setCollapses}
          control={control}
          register={register}
          errors={errors}
        />
        <ContentSection
          show={collapses.content}
          setCollapses={setCollapses}
          control={control}
          errors={errors}
        />
      </Stack>
    </ModalForm>
  );
}
