import React from 'react';
import ModalBase from '../ModalBase';
import { Box } from '@mui/material';
import TableDisplay from '~/components/table/TableDisplay';
import { numeralCustom } from '~/utils/helpers';

const columns = [
  {
    name: 'Hàng hóa',
    selector: (row) => `${row.ten_vt} (${row.ma_vt})`,
    wrap: true,
  },
  {
    name: 'Giá bán',
    selector: (row) => row.gia_ban_nt,
    format: (row) => numeralCustom(row.gia_ban_nt).format(),
    width: '120px',
    wrap: true,
  },
  {
    name: 'Số lượng',
    selector: (row) => row.sl_xuat,
    width: '120px',
    wrap: true,
  },
  {
    name: 'Tiền hàng',
    selector: (row) => row.tien_hang,
    format: (row) => numeralCustom(row.tien_hang).format(),
    width: '120px',
    wrap: true,
  },
  {
    name: 'Tiền Ck',
    selector: (row) => row.tien_ck,
    format: (row) => numeralCustom(row.tien_ck).format(),
    width: '120px',
    wrap: true,
  },
  {
    name: 'Tổng tiền ',
    selector: (row) => row.tien_xuat,
    format: (row) => numeralCustom(row.tien_xuat).format(),
    width: '120px',
    wrap: true,
  },
];

function ReportBHCustomerModal({ open, onClose, data }) {
  return (
    <ModalBase
      title={`Chi tiết mua hàng - ${data?.ten_kh}`}
      width="1000px"
      open={open}
      handleClose={onClose}
    >
      <Box>
        <TableDisplay columns={columns} data={data?.details || []} />
      </Box>
    </ModalBase>
  );
}

export default ReportBHCustomerModal;
