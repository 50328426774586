import React from 'react';
import { Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { FiSave } from 'react-icons/fi';
import { v4 } from 'uuid';
import ButtonBase from '~/components/button/ButtonBase';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import ModalBase from '~/components/modal/ModalBase';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProduct from '../../product/FormProduct';

const schema = yup.object({
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  sl_tu: yup
    .number()
    .typeError('Số lượng tối thiểu là 1')
    .min(1, 'Số lượng tối thiểu là 1'),
  sl_den: yup
    .number()
    .typeError('Số lượng tối thiểu là 1')
    .min(1, 'Số lượng tối thiểu là 1'),
});

function FormAddDetail({
  open,
  handleClose,
  isEdit,
  addDetail,
  defaultValues,
}) {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: defaultValues
      ? {
          ...defaultValues,
          vat_tu: defaultValues.ma_vt
            ? {
                ma_vt: defaultValues.ma_vt,
                ten_vt: defaultValues.ten_vt,
              }
            : null,
        }
      : {
          sl_tu: 1,
          sl_den: 1,
          vat_tu: null,
        },
  });
  const handleSave = (values) => {
    return new Promise((resovle) => {
      setTimeout(() => {
        addDetail(values, isEdit);
        handleClose();
        resovle();
      }, 200);
    });
  };
  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      width="500px"
      title={`${isEdit ? 'Chỉnh sửa' : 'Thêm'} hàng`}
      actions={[
        <ButtonBase
          key={v4()}
          onClick={handleSubmit(handleSave)}
          loading={isSubmitting}
          startIcon={<FiSave style={{ fontSize: '16px' }} />}
        >
          Lưu
        </ButtonBase>,
        <ButtonBase key={v4()} variant="outlined" onClick={handleClose}>
          Hủy
        </ButtonBase>,
      ]}
    >
      <Stack spacing="20px">
        <Controller
          control={control}
          name="vat_tu"
          render={({ field: { value, onChange } }) => (
            <SelectApiInput
              labelWidth="30%"
              label="Hàng hóa"
              required
              disabled={isEdit}
              apiCode="dmvt"
              placeholder="Chọn hàng hóa"
              searchFileds={['ma_vt', 'ten_vt']}
              getOptionLabel={(option) => option.ten_vt}
              selectedValue={value}
              value={value || { ma_vt: '', ten_vt: '' }}
              onSelect={onChange}
              FormAdd={FormProduct}
              errorMessage={errors?.vat_tu?.message}
            />
          )}
        />
        <TextInput
          required
          type="number"
          label="Số lượng từ"
          labelWidth="30%"
          placeholder="Số lượng từ"
          name="sl_tu"
          register={register}
          errorMessage={errors?.sl_tu?.message}
        />
        <TextInput
          required
          type="number"
          label="Số lượng đến"
          labelWidth="30%"
          placeholder="Số lượng đến"
          name="sl_den"
          register={register}
          errorMessage={errors?.sl_den?.message}
        />
      </Stack>
    </ModalBase>
  );
}

export default FormAddDetail;
