import React, { useRef } from 'react';
import JoditEditor from 'jodit-react';

function RichtextInput({ value, onChange = () => {}, placeholder }) {
  const editor = useRef(null);

  const config = {
    useSearch: false,
    spellcheck: false,
    enter: 'P',
    defaultMode: '1',
    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    minWidth: null,
    minHeight: 200,
    buttons:
      'paragraph,bold,underline,italic,strikethrough,|,superscript,subscript,|,ul,ol,|,|,font,fontsize,brush,,link,|,align,table,undo,redo',
    editorCssClass: 'alic',
    placeholder: placeholder || 'Typing here...',
    controls: {
      fontsize: {
        list: [
          '8',
          '9',
          '10',
          '11',
          '12',
          '14',
          '16',
          '18',
          '24',
          '30',
          '36',
          '48',
          '60',
          '72',
          '96',
          '100',
        ],
      },
      font: {
        command: 'fontname',
        list: {
          '': 'Default',
          "'Open Sans',sans-serif": 'Open Sans',
          'Helvetica,sans-serif': 'Helvetica',
          'Arial,Helvetica,sans-serif': 'Arial',
          'Georgia,serif': 'Georgia',
          'Impact,Charcoal,sans-serif': 'Impact',
          'Tahoma,Geneva,sans-serif': 'Tahoma',
          "'Times New Roman',Times,serif": 'Times New Roman',
          'Verdana,Geneva,sans-serif': 'Verdana',
        },
      },
    },
  };

  return (
    <JoditEditor
      ref={editor}
      value={value}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={(newValue) => {
        onChange(newValue);
      }}
    />
  );
}

export default RichtextInput;
