import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { LABEL_KHO } from '~/utils/label.constant';
import InfoSection from './info-section/InfoSection';
import PrinterSection from './printer-section/PrinterSection';

const schema = yup.object({
  ma_kho: yup.string().required('Vui lòng nhập mã kho'),
  ten_kho: yup.string().required('Vui lòng nhập tên kho'),
  email: yup.string().email('Email không đúng định dạng'),
  dien_thoai: yup.string().matches(/(84|0[3|5|7|8|9])+([0-9]{8})\b/g, {
    message: 'Số điện thoại không hợp lệ',
    excludeEmptyString: true,
  }),
});

const defaultData = {
  ma_kho: '',
  ten_kho: '',
  dia_chi: '',
  email: '',
  dien_thoai: '',
  status: true,
};

export default function FormKho({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        dien_thoai: defaultValues.exfields?.dien_thoai
          ? defaultValues.exfields?.dien_thoai
          : '',
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [collapses, setCollapses] = useState({ printer: true });
  const [details, setDetails] = useState(defaultValues.printers);

  const handleReset = () => {
    reset(originalData);
    setDetails(defaultValues.printers);
  };

  const generateDataPost = (values) => {
    const { exfields, ...fields } = values;
    return {
      ...fields,
      printers: details,
      exfields: {
        ...exfields,
        dien_thoai: fields.dien_thoai || '',
      },
    };
  };

  const handleSave = async (values, mode) => {
    const dataPost = generateDataPost(values);
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmkho',
      data: dataPost,
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };

  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <>
      <ModalForm
        open={open}
        rows={rows}
        width="800px"
        title={LABEL_KHO}
        isEdit={isEdit}
        isSubmitting={isSubmitting}
        currentIndex={currentIndex}
        handleSave={handleSave}
        handleClose={handleClose}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        setCurrentIndex={setCurrentIndex}
      >
        <Stack spacing={2}>
          <InfoSection
            isEdit={isEdit}
            register={register}
            control={control}
            errors={errors}
          />
          <PrinterSection
            show={collapses.printer}
            setCollapses={setCollapses}
            details={details}
            setDetails={setDetails}
          />
        </Stack>
      </ModalForm>
    </>
  );
}
