import React from 'react';
import ModalBase from './ModalBase';
import { Box, Grid } from '@mui/material';
import CheckboxInput from '../input/CheckboxInput';
import { v4 } from 'uuid';
import { cloneDeep } from 'lodash';
import ButtonBase from '../button/ButtonBase';
import { MdClose } from 'react-icons/md';

function ModalColumns({
  open,
  onClose,
  columns = [],
  showColumns = [],
  setShowColumns,
}) {
  const handleChange = (e, index) => {
    const checked = e.target.checked;
    let showColumnsClone = cloneDeep(showColumns);
    if (checked) {
      showColumnsClone.push(index);
    } else {
      showColumnsClone = showColumns.filter((col) => col !== index);
    }
    setShowColumns(showColumnsClone);
  };

  return (
    <ModalBase
      title="Cột hiển thị"
      open={open}
      handleClose={onClose}
      width="600px"
      actions={[
        <ButtonBase
          key={2}
          onClick={onClose}
          startIcon={<MdClose style={{ fontSize: '16px' }} />}
          sx={{
            backgroundColor: 'error.main',
            '&:hover': { backgroundColor: 'error.main' },
          }}
        >
          Đóng
        </ButtonBase>,
      ]}
    >
      <Box sx={{ width: '100%' }}>
        <Grid container spacing="10px">
          {columns.map((col, index) => (
            <Grid key={v4()} item xs={4}>
              <CheckboxInput
                label={col.name}
                checked={showColumns.includes(index)}
                onChange={(e) => handleChange(e, index)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ModalBase>
  );
}

export default ModalColumns;
