import React from 'react';
import CollapseSection from '~/components/collapse/CollapseSection';
import AreaInput from '~/components/input/AreaInput';

function GhiChuSection({ register }) {
  return (
    <CollapseSection title="Ghi chú" show>
      <AreaInput
        placeholder="Nhập ghi chú khu vực"
        name="ghi_chu"
        register={register}
      />
    </CollapseSection>
  );
}

export default GhiChuSection;
