import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './auth.reducer';
import enterpriseReducer from './enterprise.reducer';
import notifycationReducer from './notifycation.reducer';
import productGroupReducer from './product-group.reducer';
import storeReducer from './store.reducer';
import ptttReducer from './pttt.reducer';
import usergroupReducer from './usergroup.reducer';
import orderReducer from './order.reducer';
import productReducer from './product.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  enterprise: enterpriseReducer,
  notification: notifycationReducer,
  product: productReducer,
  productGroup: productGroupReducer,
  pttt: ptttReducer,
  store: storeReducer,
  usergroup: usergroupReducer,
  order: orderReducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);

export { store, persistor };
