import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import TextInput from '../../input/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import ModalForm from '~/components/modal/ModalForm';
import { ID_APP } from '~/utils/constants';
import SelectApiInput from '~/components/input/SelectApiInput';

const schema = yup.object({
  ten_tinh_thanh: yup.string().required('Vui lòng nhập tên tỉnh thành'),
});

const defaultData = { ma_tinh_thanh: '', ten_tinh_thanh: '' };

export default function FormTinhThanh({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues || defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
    register,
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });

  const handleReset = () => {
    reset(originalData);
  };

  const generateDataPost = (values) => {
    const { ...fields } = values;
    return { ...fields, id_app: ID_APP };
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'tinhthanh',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
      withIdApp: false,
    });
  };
  useEffect(() => {
    reset(originalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="700px"
      title="tỉnh thành"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <TextInput
            labelWidth="30%"
            required
            readOnly
            label="Mã tỉnh"
            placeholder="Nhập mã tỉnh thành"
            name="ma_tinh_thanh"
            register={register}
            errorMessage={errors?.ma_tinh_thanh?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            readOnly
            labelWidth="30%"
            label="Tên tỉnh"
            placeholder="Nhập tên tỉnh thành"
            name="ten_tinh_thanh"
            required
            register={register}
            errorMessage={errors?.ten_tinh_thanh?.message}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="khu_vuc"
            render={({ field: { onChange, value } }) => (
              <SelectApiInput
                labelWidth="30%"
                label="Khu vực"
                apiCode="dmkhuvuc"
                placeholder="Chọn khu vực"
                searchFileds={['ma_kv', 'ten_kv']}
                getOptionLabel={(option) => option.ten_kv}
                selectedValue={value}
                value={value || { ma_kv: '', ten_kv: '' }}
                onSelect={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </ModalForm>
  );
}
