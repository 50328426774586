import React from 'react';
import { Box, Paper, Typography, useTheme } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import moment from 'moment';

const number = moment().date()
const uData = new Array(number).fill(0).map((_, index) => {
  return Math.floor(Math.random() * 1000) + 100;
});
const xLabels = new Array(31).fill(0).map((_, index) => `${index + 1}`);

function DoanhThuThang() {
  const theme = useTheme();
  
  return (
    <Paper sx={{ p: 2 }}>
      <Typography sx={{ fontSize: '14px', fontWeight: 550, mb: '10px' }}>
        DOANH THU THÁNG NÀY
      </Typography>
      <Box>
        <BarChart
          series={[
            {
              data: uData,
              label: 'Doanh thu',
              color: theme.palette.primary.main,
            },
          ]}
          xAxis={[
            { scaleType: 'band', data: xLabels, tickPlacement: 'middle' },
          ]}
          borderRadius={4}
          margin={{ top: 50, bottom: 30, left: 30, right: 0 }}
          height={400}
        />
      </Box>
    </Paper>
  );
}

export default DoanhThuThang;
