import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useOrderContext } from '~/pages/order/OrderPage';

function Bottom() {
  const { saveHd } = useOrderContext();

  return (
    <Box sx={{ height: '42px' }}>
      <Stack
        sx={{ height: '100%', width: '100%' }}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing="10px"
      >
        <Button
          variant="contained"
          sx={{
            color: 'whitish.pureWhite',
            width: '60%',
            height: '100%',
            boxShadow: 'none',
            fontSize: '14px',
            '&:hover': { boxShadow: 'none' },
          }}
          onClick={() => saveHd(5)}
        >
          HOÀN TẤT
        </Button>
      </Stack>
    </Box>
  );
}

export default Bottom;
