import { Stack } from '@mui/material';
import React from 'react';
import FilterSearch from '../FilterSearch';
import { useState } from 'react';
import { useEffect } from 'react';
import FilterContainer from '../FilterContainer';
import FilterSelectApi from '../FilterSelectApi';

function FilterNews({ setCondition }) {
  const originFilter = {
    title: '',
    category: null,
  };
  const [mode, setMode] = useState(1);
  const [filter, setFilter] = useState(originFilter);

  const handleRefreshFilter = () => {
    setFilter(originFilter);
  };

  useEffect(() => {
    let condition = {};
    if (filter.title) {
      condition.title = {
        $regex: filter.title.split(' ').join('.*'),
        $options: 'i',
      };
    }
    if (filter.category) {
      condition.category = filter.category?._id;
    }
    if (mode === 1) {
      setCondition(condition);
    } else {
      const entries = Object.entries(condition);
      condition = entries.reduce(
        (acc, item) => {
          acc.$or.push({ [item[0]]: item[1] });
          return acc;
        },
        { $or: [] }
      );
      setCondition(condition);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, mode]);

  return (
    <FilterContainer
      mode={mode}
      setMode={setMode}
      handleRefreshFilter={handleRefreshFilter}
    >
      <Stack sx={{ width: '100%' }} spacing="10px">
        <FilterSearch
          title="Tiêu đề"
          placeholder="Tìm theo tiêu đề"
          value={filter.title}
          onSearch={(value) => setFilter({ ...filter, title: value })}
        />
        <FilterSelectApi
          apiCode="group"
          title="Loại tin"
          placeholder="Chọn loại tin tức"
          getOptionLabel={(option) => option.group_name}
          searchFileds={['group_name']}
          condition={{ group_type: 'NEWS' }}
          selectedValue={filter.category}
          value={filter.category || { _id: '', group_name: '' }}
          onSelect={(value) => setFilter({ ...filter, category: value })}
        />
      </Stack>
    </FilterContainer>
  );
}

export default FilterNews;
