import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AdminLayout from '~/components/layouts/AdminLayout';
import TableDisplay from '~/components/table/TableDisplay';
import useApisContext from '~/hooks/hookContext/useApisContext';
import moment from 'moment';
import { numeralCustom } from '~/utils/helpers';
import FilterCDPSKH from '~/components/filter/cdpskh/FilterCDPSKH';

const columns = [
  {
    name: 'Đối tượng',
    selector: (row) => row.ten_kh,
    cell: (row, index) => {
      return (
        <Typography sx={index === 0 ? { fontWeight: 600 } : {}}>
          {row.ten_kh}
        </Typography>
      );
    },
    wrap: true,
  },
  {
    name: 'Đầu kỳ nợ',
    selector: (row) => row.dk_no_nt,
    format: (row) => {
      return numeralCustom(row.dk_no_nt).format();
    },
    wrap: true,
  },
  {
    name: 'Đầu kỳ có',
    selector: (row) => row.dk_co_nt,
    format: (row) => {
      return numeralCustom(row.dk_co_nt).format();
    },
    wrap: true,
  },
  {
    name: 'Phát sinh nợ',
    selector: (row) => row.ps_no_nt,
    format: (row) => {
      return numeralCustom(row.ps_no_nt).format();
    },
    wrap: true,
  },
  {
    name: 'Phát sinh có',
    selector: (row) => row.ps_co_nt,
    format: (row) => {
      return numeralCustom(row.ps_co_nt).format();
    },
    wrap: true,
  },
  {
    name: 'Cuối kỳ nợ',
    selector: (row) => row.ck_no_nt,
    format: (row) => {
      return numeralCustom(row.ck_no_nt).format();
    },
    wrap: true,
  },
  {
    name: 'Cuối kỳ có',
    selector: (row) => row.ck_co_nt,
    format: (row) => {
      return numeralCustom(row.ck_co_nt).format();
    },
    wrap: true,
  },
];

function CDPSKHPage() {
  const { asyncGetList } = useApisContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [queryObject, setQueryObject] = useState({});
  const [concern, setConcern] = useState(null);

  const getReport = async () => {
    try {
      setLoading(true);
      const today = moment();
      const condition = {
        type: 1,
        cType: '$and',
        tu_ngay: today.clone().startOf('months').toISOString(),
        den_ngay: today.clone().toISOString(),
        ...(concern?.query || {}),
        ...(queryObject || {}),
      };
      const resp = await asyncGetList({
        apiCode: 'cdpskh',
        condition,
        stringify: false,
      });
      if (!!resp && resp?.length > 0) {
        setData(concern.convertData(resp));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (concern) {
      getReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concern, queryObject]);

  return (
    <AdminLayout>
      <Box sx={{ py: '10px', px: 0 }}>
        <Grid container spacing={2}>
          <Grid xs={12} md={2.5}>
            <FilterCDPSKH
              setConcern={setConcern}
              setQueryObject={setQueryObject}
            />
          </Grid>
          <Grid xs={12} md={9.5}>
            <Stack gap={1}>
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                  Báo cáo công nợ
                </Typography>
              </Stack>
              <TableDisplay
                columns={columns}
                progressPending={loading}
                data={data}
                fixedHeaderScrollHeight="calc(100vh - 50px - 42px - 30px - 30px)"
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </AdminLayout>
  );
}

export default CDPSKHPage;
