import FilterReportBanhang from '~/components/filter/baocaobanhang/FilterReportBanhang';
import FilterBaoCaoHangHoa from '~/components/filter/baocaohanghoa/FilterBaoCaoHangHoa';
import FilterBaoCaoKQHDKD from '~/components/filter/baocaokqhdkd/FilterBaoCaoKQHDKD';
import FilterBaoCaoSoQuy from '~/components/filter/baocaosoquy/FilterBaoCaoSoQuy';
import FilterCDPSKH from '~/components/filter/cdpskh/FilterCDPSKH';

const reports = {
  hanghoa: {
    title: 'Báo cáo hàng hóa',
    Filter: FilterBaoCaoHangHoa,
  },
  banhang: {
    title: 'Báo cáo bán hàng',
    Filter: FilterReportBanhang,
  },
  soquy: {
    title: 'Báo cáo sổ quỹ',
    Filter: FilterBaoCaoSoQuy,
  },
  kqhdkd: {
    title: 'Báo cáo kết quả kinh doanh',
    Filter: FilterBaoCaoKQHDKD,
  },
  cdpskh: {
    title: 'Báo cáo công nợ',
    Filter: FilterCDPSKH,
  },
};
export default reports;
