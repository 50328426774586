import React, { useEffect, useState } from 'react';
import { Badge, Button } from '@mui/material';
import useAlertContext from '~/hooks/hookContext/useAlertContext';
import useApisContext from '~/hooks/hookContext/useApisContext';
import ModalList from './ModalList';

function Hd2({ customer }) {
  const showAlert = useAlertContext();
  const { asyncGetList } = useApisContext();
  const [countAll, setCountAll] = useState(0);
  const [open, setOpen] = useState(false);

  const getAllCountHd2 = async () => {
    try {
      const resp = await asyncGetList({
        apiCode: 'hd2',
        condition: { count: 1, q: { ma_kh: customer?.ma_kh, status: true } },
      });
      if (resp) {
        setCountAll(resp?.rows_number || 0);
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: error?.message || 'Lỗi khi tải đơn đặt hàng',
      });
    }
  };

  useEffect(() => {
    getAllCountHd2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalList
        open={open}
        onClose={() => setOpen(false)}
        defaultCondition={{ ma_kh: customer.ma_kh, status: true }}
      />
      <Badge badgeContent={countAll} color="error">
        <Button
          variant="contained"
          sx={{ color: 'whitish.pureWhite' }}
          onClick={() => setOpen(true)}
        >
          Đơn đặt hàng
        </Button>
      </Badge>
    </>
  );
}

export default Hd2;
