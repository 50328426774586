import { Grid, Stack } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import CheckboxInput from '~/components/input/CheckboxInput';
import DateInput from '~/components/input/DateInput';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';

function InfoSection({ isEdit, register, control, errors }) {
  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={7}>
          <Stack spacing="20px">
            <TextInput
              readOnly={isEdit}
              label="Mã Khách"
              placeholder="Nhập hoặc tạo tự động"
              name="ma_kh"
              register={register}
            />
            <TextInput
              label="Tên Khách"
              placeholder="Nguyễn Văn A"
              name="ten_kh"
              register={register}
              required
              errorMessage={errors?.ten_kh?.message}
            />
            <TextInput
              label="Tên cơ sở KD"
              placeholder="Nhà thuốc Hoa Hồng"
              name="ten_kh2"
              register={register}
            />
            <Controller
              control={control}
              name="nhom_kh"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  label="Nhóm khách"
                  apiCode="dmnhkh"
                  placeholder="Chọn nhóm khách"
                  searchFileds={['group_name']}
                  condition={{ isvendor: { $ne: true } }}
                  getOptionLabel={(option) => option.group_name}
                  selectedValue={value}
                  value={value || { _id: '', group_name: '' }}
                  onSelect={onChange}
                  errorMessage={errors?.nhom_kh?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="trang_thai_obj"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  required
                  label="Trạng thái"
                  apiCode="trangthai"
                  placeholder="Trạng thái"
                  searchFileds={['ten_trang_thai']}
                  getOptionLabel={(option) => option.ten_trang_thai}
                  selectedValue={value}
                  value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
                  onSelect={onChange}
                  withIdApp={false}
                  condition={{ ma_ct: 'CUSTOMER', status: true }}
                  errorMessage={errors?.trang_thai_obj?.message}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing="20px">
            <TextInput
              labelWidth="30%"
              label="Mã số thuế"
              placeholder="Mã số thuế"
              name="ma_so_thue"
              register={register}
            />
            <TextInput
              labelWidth="30%"
              label="Số điện thoại"
              required
              placeholder="0358 500 772"
              name="dien_thoai"
              register={register}
              errorMessage={errors.dien_thoai?.message}
            />
            <TextInput
              labelWidth="30%"
              label="Email"
              placeholder="ntt@gmail.com"
              name="email"
              register={register}
              errorMessage={errors.email?.message}
            />
            <TextInput
              labelWidth="30%"
              label="CMND/CCCD"
              placeholder="012398679811"
              name="id_number"
              register={register}
              errorMessage={errors?.id_number?.message}
            />
            <Controller
              control={control}
              name="ngay_sinh"
              render={({ field: { onChange, value } }) => (
                <DateInput
                  label="Ngày sinh"
                  labelWidth="30%"
                  disableFuture
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {isEdit && (
              <Controller
                control={control}
                name="status"
                render={({ field: { onChange, value } }) => (
                  <CheckboxInput
                    label="Còn hoạt động"
                    name="status"
                    checked={value || false}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
