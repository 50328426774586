import axios from 'axios';
import { API_URL, PUBLIC_URL } from './constants';

// axios public
const axiosPublic = axios.create({
  baseURL: PUBLIC_URL,
});

// axios private
const axiosPrivate = axios.create({
  baseURL: API_URL,
});

export { axiosPublic, axiosPrivate };
