import { Box } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectMultiApiInput from '~/components/input/SelectMultiApiInput';
import FormParticipant from '../../participant/FormParticipant';

function UsersSection({ show, setCollapses, control }) {
  return (
    <CollapseSection
      title="Người dùng có quyền"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, users: !prev.users }))
      }
    >
      <Box>
        <Controller
          control={control}
          name="users"
          render={({ field: { onChange, value } }) => (
            <SelectMultiApiInput
              apiCode="participant"
              placeholder="Chọn người dùng"
              searchFileds={['name', 'name']}
              limitTags={5}
              getOptionLabel={(option) => option.email}
              value={value}
              onSelect={onChange}
              isOptionEqualToValue={(option, value) => {
                return option.email === value.email;
              }}
              FormAdd={FormParticipant}
            />
          )}
        />
      </Box>
    </CollapseSection>
  );
}

export default UsersSection;
