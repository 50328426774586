import React from 'react';
import { Grid, Stack } from '@mui/material';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectApiInput from '~/components/input/SelectApiInput';
import TextInput from '~/components/input/TextInput';
import DatetimeInput from '~/components/input/DatetimeInput';

function InfoSection({ register, errors, control }) {
  return (
    <CollapseSection title="Thông tin chung" show>
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <TextInput
              required
              labelWidth="30%"
              label="Mã"
              placeholder="Nhập mã flashsale"
              name="ma_chietkhau"
              register={register}
              errorMessage={errors?.ma_chietkhau?.message}
            />
            <TextInput
              required
              labelWidth="30%"
              label="Tên CK"
              placeholder="Nhập tên flashsale"
              name="ten_chietkhau"
              register={register}
              errorMessage={errors?.ten_chietkhau?.message}
            />
            <Controller
              control={control}
              name="trang_thai"
              render={({ field: { onChange, value } }) => (
                <SelectApiInput
                  labelWidth="30%"
                  required
                  label="Trạng thái"
                  apiCode="trangthai"
                  placeholder="Trạng thái"
                  searchFileds={['ten_trang_thai']}
                  getOptionLabel={(option) => option.ten_trang_thai}
                  selectedValue={value}
                  value={value || { ma_trang_thai: '', ten_trang_thai: '' }}
                  onSelect={onChange}
                  withIdApp={false}
                  condition={{
                    ma_ct: 'ecompromotion_flashsales',
                    status: true,
                  }}
                  errorMessage={errors?.trang_thai?.message}
                />
              )}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing="20px">
            <Controller
              control={control}
              name="tu_ngay"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Từ ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.tu_ngay?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="den_ngay"
              render={({ field: { onChange, value } }) => (
                <DatetimeInput
                  required
                  label="Đến ngày"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                  errorMessage={errors?.den_ngay?.message}
                />
              )}
            />
            {/* <Controller
              control={control}
              name="status"
              render={({ field: { onChange, value } }) => (
                <CheckboxInput
                  label="Phát hành"
                  name="status"
                  checked={value || false}
                  onChange={onChange}
                />
              )}
            /> */}
          </Stack>
        </Grid>
      </Grid>
    </CollapseSection>
  );
}

export default InfoSection;
