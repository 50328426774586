const { createSlice } = require('@reduxjs/toolkit');

const productGroupSlice = createSlice({
  name: 'productGroup',
  initialState: {
    data: [],
  },
  reducers: {
    updateProductGroup(state, action) {
      state.data = action.payload;
    },
  },
});

export const { updateProductGroup } = productGroupSlice.actions;
export default productGroupSlice.reducer;
