import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import ModalForm from '~/components/modal/ModalForm';
import useApisContext from '~/hooks/hookContext/useApisContext';
import { postOrPutDataDanhMuc } from '~/utils/helpers';
import * as yup from 'yup';
import InfoSection from './info-section/InfoSection';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import ChiTietSection from './chitiet-section/ChiTietSection';
import { LABEL_KHO } from '~/utils/label.constant';

const schema = yup.object({
  stt_uu_tien: yup.string().matches(/[0-9]\b/g, {
    message: 'Số thứ tự phải là số',
    excludeEmptyString: true,
  }),
  vat_tu: yup
    .object()
    .typeError('Vui lòng chọn hàng hóa')
    .required('Vui lòng chọn hàng hóa'),
  kho: yup
    .object()
    .typeError(`Vui lòng chọn ${LABEL_KHO}`)
    .required(`Vui lòng chọn ${LABEL_KHO}`),
  hieu_luc_tu: yup
    .date()
    .typeError('Vui lòng chọn ngày bắt đầu')
    .required('Vui lòng chọn ngày bắt đầu'),
  hieu_luc_den: yup
    .date()
    .typeError('Vui lòng chọn ngày kết thúc')
    .required('Vui lòng chọn ngày kết thúc'),
});

const defaultData = {
  stt_uu_tien: '',
  vat_tu: null,
  nhom_vat_tu: null,
  kho: null,
  sl_tu: 0,
  sl_den: 0,
  hieu_luc_tu: moment().hours(8).minutes(0).format('YYYY-MM-DD HH:mm'),
  hieu_luc_den: moment()
    .hours(16)
    .minutes(30)
    .add(7, 'days')
    .format('YYYY-MM-DD HH:mm'),
  status: false,
};

function FormKhuyenMai({
  open,
  defaultValues,
  isEdit = false,
  currentIndex = -1,
  rows = [],
  setCurrentIndex = () => {},
  handleClose = () => {},
  setLoad = () => {},
}) {
  const originalData = defaultValues
    ? {
        ...defaultData,
        ...defaultValues,
        vat_tu: defaultValues.ma_vt
          ? { ma_vt: defaultValues.ma_vt, ten_vt: defaultValues.ten_vt }
          : null,
        nhom_vat_tu: defaultValues.ma_nvt
          ? { _id: defaultValues.ma_nvt, ten_nvt: defaultValues.ten_nvt }
          : null,
        kho: defaultValues.ma_kho
          ? { ma_kho: defaultValues.ma_kho, ten_kho: defaultValues.ten_kho }
          : null,
        hieu_luc_tu: defaultValues.hieu_luc_tu
          ? moment(defaultValues.hieu_luc_tu).format('YYYY-MM-DD HH:mm')
          : null,
        hieu_luc_den: defaultValues.hieu_luc_den
          ? moment(defaultValues.hieu_luc_den).format('YYYY-MM-DD HH:mm')
          : null,
      }
    : defaultData;
  const { asyncPostData, asyncPutData } = useApisContext();
  const {
    handleSubmit,
    reset,
    control,
    register,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: originalData,
    resolver: yupResolver(schema),
  });
  const [collapses, setCollapses] = useState({ chi_tiet: true });
  const [details, setDetails] = useState(defaultValues?.details_km || []);

  const handleReset = () => {
    reset(originalData);
    setDetails(defaultValues?.details_km || []);
  };

  const generateDataPost = (values) => {
    const { vat_tu, nhom_vat_tu, kho, ...fields } = values;
    const result = {
      ...fields,
      ma_vt: vat_tu?.ma_vt || '',
      ten_vt: vat_tu?.ten_vt || '',
      ma_nvt: nhom_vat_tu?._id || '',
      ten_nvt: nhom_vat_tu?.ten_nvt || '',
      ma_kho: kho?.ma_kho || '',
      ten_kho: kho?.ten_kho || '',
      details_km: details,
    };
    return result;
  };

  const handleSave = async (values, mode) => {
    await postOrPutDataDanhMuc({
      isEdit,
      asyncPostData,
      asyncPutData,
      apiCode: 'dmkhuyenmai',
      data: generateDataPost(values),
      handleClose: mode === 1 ? null : handleClose,
      reset: mode === 1 ? (isEdit ? null : reset) : isEdit ? null : reset,
      dataReset: originalData,
      setLoad,
    });
  };
  useEffect(() => {
    handleReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  return (
    <ModalForm
      open={open}
      rows={rows}
      width="1200px"
      title="khuyến mãi, quà tặng"
      isEdit={isEdit}
      isSubmitting={isSubmitting}
      currentIndex={currentIndex}
      handleSave={handleSave}
      handleClose={handleClose}
      handleReset={handleReset}
      handleSubmit={handleSubmit}
      setCurrentIndex={setCurrentIndex}
    >
      <Stack spacing="20px">
        <InfoSection
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
        />
        <ChiTietSection
          details={details}
          setCollapses={setCollapses}
          setDetails={setDetails}
          show={collapses.chi_tiet}
        />
      </Stack>
    </ModalForm>
  );
}

export default FormKhuyenMai;
