import { Grid } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import CollapseSection from '~/components/collapse/CollapseSection';
import SelectInput from '~/components/input/SelectInput';
import { QUYEN_TRUY_CAP } from '~/utils/constants';
import SelectApiInput from '~/components/input/SelectApiInput';

function PhanQuyenSection({ show, setCollapses, control }) {
  return (
    <CollapseSection
      title="Tài khoản và truy cập"
      show={show}
      onCollapse={() =>
        setCollapses((prev) => ({ ...prev, phan_quyen: !prev.phan_quyen }))
      }
    >
      <Grid container spacing="20px">
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="visible"
            render={({ field: { onChange, value } }) => {
              return (
                <SelectInput
                  labelWidth="30%"
                  label="Quyền truy cập"
                  placeholder="Chọn quyền truy cập"
                  value={value || { label: '', value: '' }}
                  selectedValue={value}
                  onSelect={onChange}
                  options={QUYEN_TRUY_CAP.slice(0, -2)}
                  getOptionLabel={(option) => option.label}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="phu_trach"
            render={({ field: { onChange, value } }) => {
              return (
                <SelectApiInput
                  apiCode="participant"
                  label="Người phụ trách"
                  labelWidth="30%"
                  placeholder="Chọn người phụ trách"
                  getOptionLabel={(option) =>
                    option.name ? `${option.name} (${option.email})` : ''
                  }
                  searchFileds={['email', 'name']}
                  selectedValue={value}
                  value={value || { email: '', name: '' }}
                  onSelect={onChange}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="of_user"
            render={({ field: { onChange, value } }) => {
              return (
                <SelectApiInput
                  apiCode="participant"
                  label="TK đăng nhập"
                  labelWidth="30%"
                  placeholder="Chọn tài khoản đăng nhập"
                  getOptionLabel={(option) => option.email}
                  searchFileds={['email']}
                  selectedValue={value}
                  value={value || { email: '' }}
                  onSelect={onChange}
                />
              );
            }}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="of_user"
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  label="Tài khoản"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </Grid> */}
        {/* <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => {
              return (
                <TextInput
                  type="password"
                  label="Mật khẩu mặc định"
                  labelWidth="30%"
                  value={value}
                  onChange={onChange}
                />
              );
            }}
          />
        </Grid> */}
        {/* {visible?.value === 2 && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="users"
              render={({ field: { onChange, value } }) => (
                <SelectMultiApiInput
                  label="Người dùng có quyền"
                  labelWidth="30%"
                  apiCode="participant"
                  placeholder="Chọn người dùng"
                  searchFileds={['name', 'name']}
                  limitTags={5}
                  getOptionLabel={(option) => option.email}
                  value={value}
                  onSelect={onChange}
                  isOptionEqualToValue={(option, value) => {
                    return option.email === value.email;
                  }}
                  FormAdd={FormParticipant}
                />
              )}
            />
          </Grid>
        )} */}
      </Grid>
    </CollapseSection>
  );
}

export default PhanQuyenSection;
